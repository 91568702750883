import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Card from 'components/card';
import CardRow from 'components/card/row';
import Spinner from 'components/spinner';
import IconEnvelope from 'icons/envelope';
import AuthIllustration from 'illustrations/auth';
import maskContent from 'libs/sentry/mask';
import { countriesApi } from 'services/countries';
import userApi from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { locators, QALocator } from 'utils/tests/QA';

import LABELS from './keys';
import useStyles from './styles';

const ProfileScreen = (props:{ onSubmenuGoBack?: () => void }) => {
  const { classes, theme } = useStyles();
  const authToken = useSelector(selectAuthToken) ?? '';
  const { t } = useTranslation();

  const {
    data: countries,
    isLoading: areCountriesLoading,
  } = countriesApi.useGetCountriesQuery();

  const {
    data: user,
    isLoading: isUserDataLoading,
  } = userApi.useGetUserInfoQuery(authToken);

  const countryCode = user?.data?.country;
  const country = countries?.data.countries.find(country => country.isoAlpha2 === countryCode);
  const email = user?.data?.username;
  const isLoading = isUserDataLoading || areCountriesLoading;

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner
          scale={1.25}
          {...QALocator(locators.page.profile.viewLoadingSpinner)}
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.imageWrapper}>
        <AuthIllustration />
      </div>
      <Card className={classes.card}>
        <CardRow
          label={t(LABELS.EMAIL)}
        >
          <div className={classes.emailWrapper}>
            <IconEnvelope color={theme.colors.text.inactive} />
            <span
              {...maskContent()}
            >
              {email}
            </span>
          </div>
        </CardRow>
        <CardRow
          label={t(LABELS.COUNTRY)}
          iconEnd={<img src={country?.iconUrl} className={classes.countryIcon} alt={country?.name} />}
          {...maskContent()}
        >
          {country?.name}
        </CardRow>
        <span className={classes.message}>{t(LABELS.MESSAGE)}</span>
      </Card>
    </div>
  );
};

export default ProfileScreen;
