import { WithTranslation, withTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import Button from 'components/button';
import Card from 'components/card';
import CardRow from 'components/card/row';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import Spinner from 'components/spinner';
import { OrderCompletionResponse } from 'services/payments/types';
import { Asset } from 'types/assets';
import { Transaction } from 'types/transactions';
import { convertToTitleCase, formatCryptoAmountWithSymbolAtEnd } from 'utils/currencies';

import KEYS, { LABELS } from './keys';
import useStyles from './styles';

interface TransferCryptoScreenProps extends WithTranslation {
  orderAsset?: Asset;
  order?: OrderCompletionResponse | Transaction;
  canGoBack: boolean;
  goBack: () => void;
  proceed: () => void;
}

const TransferCryptoScreen = ({
  orderAsset,
  order,
  proceed,
  goBack,
  canGoBack,
  t,
}: TransferCryptoScreenProps) => {
  const { classes, theme } = useStyles();
  const isSpinnerVisible = !order || !orderAsset;
  const cryptoAmount = (order as OrderCompletionResponse | undefined)?.quote?.cryptoAmount
    ?? (order as Transaction | undefined)?.cryptoAmount;

  const tagOrMemoLabel = orderAsset?.tagName === KEYS.DESTINATION_TAG_API_FIELD_NAME
    ? t(LABELS.DESTINATION_TAG)
    : t(LABELS.MEMO);

  if (isSpinnerVisible) {
    return (
      <div className={classes.container}>
        <Header hasBackButton={canGoBack}>
          {t(LABELS.HEADER)}
        </Header>
        <div className={classes.spinnerWrapper}>
          <Spinner scale={1.25} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Header hasBackButton={canGoBack} onBackClick={goBack}>
        {t(LABELS.HEADER)}
      </Header>
      <div className={classes.gapWrapper}>
        <OptionallyVisible visible={Boolean(order?.depositWalletAddress)}>
          <QRCode
            value={order.depositWalletAddress!}
            size={KEYS.QR_CODE_SIZE}
            fgColor={theme.colors.text.inactive}
            bgColor={theme.colors.background.primary}
          />
        </OptionallyVisible>
        <div className={classes.stepDescription}>
          {t(LABELS.STEP_DESCRIPTION)}
        </div>
        <Card className={classes.card}>
          <OptionallyVisible visible={Boolean(order && orderAsset?.symbol && cryptoAmount)}>
            <CardRow
              isCopyable
              label={t(LABELS.EXPECTED)}
              textToCopy={cryptoAmount}
            >
              {formatCryptoAmountWithSymbolAtEnd(cryptoAmount!, orderAsset!, { truncate: false })}
            </CardRow>
          </OptionallyVisible>
          <OptionallyVisible visible={Boolean(order?.depositWalletAddress && orderAsset?.chainCode)}>
            <CardRow
              isCopyable
              iconStart={(
                <OptionallyVisible visible={Boolean(orderAsset?.iconUrl)}>
                  <img className={classes.networkIcon} src={orderAsset!.iconUrl!} alt={t(LABELS.NETWORK_IMAGE_ALT)} />
                </OptionallyVisible>
              )}
              textToCopy={order!.depositWalletAddress!}
              label={
                t(
                  LABELS.DEPOSIT_ADDRESS,
                  { network: convertToTitleCase(orderAsset!.chainCode) },
                )
              }
            >
              {order?.depositWalletAddress}
            </CardRow>
          </OptionallyVisible>
          <OptionallyVisible visible={Boolean(order?.depositWalletTag)}>
            <CardRow
              isCopyable
              textToCopy={order!.depositWalletTag!}
              label={tagOrMemoLabel}
              inline
            >
              {order?.depositWalletTag}
            </CardRow>
          </OptionallyVisible>
        </Card>
      </div>
      <div className={classes.bottomWrapper}>
        <div className={classes.note}>
          {t(LABELS.NOTE)}
        </div>
        <Button onClick={proceed}>
          {t(LABELS.PROCEED)}
        </Button>
      </div>
    </div>
  );
};

export default withTranslation()(TransferCryptoScreen);
