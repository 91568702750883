import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M10.6673 0.666656H2.66732C1.93398',
  '0.666656 1.33398 1.26666 1.33398',
  '1.99999V11.3333H2.66732V1.99999H10.6673V0.666656ZM12.6673',
  '3.33332H5.33398C4.60065 3.33332 4.00065 3.93332 4.00065',
  '4.66666V14C4.00065 14.7333 4.60065 15.3333 5.33398',
  '15.3333H12.6673C13.4007 15.3333 14.0007 14.7333 14.0007',
  '14V4.66666C14.0007 3.93332 13.4007 3.33332 12.6673',
  '3.33332ZM12.6673 14H5.33398V4.66666H12.6673V14Z',
].join(' ');

export const IconCopy = ({
  width = 16,
  height = 16,
  color,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconCopy;
