import { ScreenNavigation } from 'hooks/useNavigation';
import { canGoBackFromBankDetails, getBankDetailsPreviousStep } from 'navigation/bankDetails';
import getCheckoutPreviousStep from 'navigation/checkout';
import { canGoBackFromCountryCheck, getCountryCheckNextStep, getCountryCheckPreviousStep } from 'navigation/countryCheck';
import { canGoBackFromKyc, getKycNextStep, getKycPreviousStep } from 'navigation/kyc';
import getOtpScreenNextStep, { getOtpScreenPreviousStep } from 'navigation/otp';
import getPaymentMethodNextStep, { canGoBackFromPaymentMethods, getPaymentMethodPreviousStep } from 'navigation/paymentMethod';
import { getQuoteScreenNextStep, getQuoteScreenPreviousStep } from 'navigation/quote';
import canGoBackFromSignInScreen from 'navigation/signIn';
import getTransactionProcessingPreviousStep, { canGoBackFromTransactionProcessingStep } from 'navigation/transactionProcessing';
import canGoBackFromWalletScreen, { getWalletNextStep } from 'navigation/wallet';
import { WorkflowStep } from 'types/step';

type BuySteps = keyof Omit<typeof WorkflowStep, 'AwaitingCrypto' | 'TransferCrypto' | 'RequestNewCard' | 'ConfirmNewQuote'>

export const buyPagesMap : Record<BuySteps, ScreenNavigation> = {
  [WorkflowStep.Quote]: {
    getNextStep: getQuoteScreenNextStep,
    getPreviousStep: getQuoteScreenPreviousStep,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.SignIn]: {
    getNextStep: () => WorkflowStep.Otp,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: canGoBackFromSignInScreen,
  },
  [WorkflowStep.Otp]: {
    getNextStep: getOtpScreenNextStep,
    getPreviousStep: getOtpScreenPreviousStep,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.CountryCheck]: {
    getNextStep: getCountryCheckNextStep,
    getPreviousStep: getCountryCheckPreviousStep,
    getCanGoBackValue: canGoBackFromCountryCheck,
  },
  [WorkflowStep.IpCountryUnavailable]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.CountryCheck,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.CountryUnavailable]: {
    getNextStep: () => WorkflowStep.CountryUnavailableFinal,
    getPreviousStep: () => WorkflowStep.CountryCheck,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.CountryUnavailableFinal]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.CountryUnavailable,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.Kyc]: {
    getNextStep: getKycNextStep,
    getPreviousStep: getKycPreviousStep,
    getCanGoBackValue: canGoBackFromKyc,
  },
  [WorkflowStep.KycRejected]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.Wallet]: {
    getNextStep: getWalletNextStep,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: canGoBackFromWalletScreen,
  },
  [WorkflowStep.PaymentMethods]: {
    getNextStep: getPaymentMethodNextStep,
    getPreviousStep: getPaymentMethodPreviousStep,
    getCanGoBackValue: canGoBackFromPaymentMethods,
  },
  [WorkflowStep.BankDetails]: {
    getNextStep: () => WorkflowStep.Checkout,
    getPreviousStep: getBankDetailsPreviousStep,
    getCanGoBackValue: canGoBackFromBankDetails,
  },
  [WorkflowStep.Checkout]: {
    getNextStep: () => WorkflowStep.TransactionProcessing,
    getPreviousStep: getCheckoutPreviousStep,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.TransactionProcessing]: {
    getNextStep: () => WorkflowStep.OrderProcessed,
    getPreviousStep: getTransactionProcessingPreviousStep,
    getCanGoBackValue: canGoBackFromTransactionProcessingStep,
  },
  [WorkflowStep.OrderProcessed]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.Error]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => true,
  },
};

export default buyPagesMap;
