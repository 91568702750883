import { Shift4ErrorCode } from 'types/errors/shift4';

export const KEYS = {
  REMEMBER_CARD_CHECKBOX_ID: 'rememberCardCheckbox',
  ABORT_ERROR_NAME: 'AbortError',
  SHIFT4: {
    FORM_IDENTIFIER: 'ccForm',
    CARD_NUMBER: 'number',
    CARD_EXPIRY_DATE: 'expiry',
    CARD_CVC: 'cvc',
  },
};

export enum FormField {
  CardNumber = 'cardNumber',
  ExpiryDate = 'expiryDate',
  CardCvc = 'cardCvc',
}

export const ERROR_CODES_TO_FORM_FIELDS_MAPPING: Partial<Record<Shift4ErrorCode, FormField>> = {
  [Shift4ErrorCode.InvalidNumber]: FormField.CardNumber,
  [Shift4ErrorCode.IncompleteNumber]: FormField.CardNumber,
  [Shift4ErrorCode.InvalidExpiry]: FormField.ExpiryDate,
  [Shift4ErrorCode.InvalidExpiryMonth]: FormField.ExpiryDate,
  [Shift4ErrorCode.InvalidExpiryYear]: FormField.ExpiryDate,
  [Shift4ErrorCode.IncompleteExpiry]: FormField.ExpiryDate,
  [Shift4ErrorCode.ExpiredCard]: FormField.ExpiryDate,
  [Shift4ErrorCode.InvalidCvc]: FormField.CardCvc,
  [Shift4ErrorCode.IncorrectCvc]: FormField.CardCvc,
  [Shift4ErrorCode.IncompleteCvc]: FormField.CardCvc,
};

const MESSAGES_NAMESPACE = 'bankDetails';
export const LABELS = {
  SAVE_CARD_CHECKBOX: `${MESSAGES_NAMESPACE}.saveCardCheckbox`,
  SAVE_CARD_CHECKBOX_SHORT: `${MESSAGES_NAMESPACE}.saveCardCheckboxShort`,
  SET_DEFAULT_CARD: `${MESSAGES_NAMESPACE}.setDefaultCard`,
  PAYMENT_FAILED_MESSAGE: `${MESSAGES_NAMESPACE}.paymentFailedMessage`,
  PAY_WITH: `${MESSAGES_NAMESPACE}.payWith`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  STEP_TEXT: `${MESSAGES_NAMESPACE}.stepText`,
  CARD_NOTICE: `${MESSAGES_NAMESPACE}.cardNotice`,
  CARD_NUMBER: `${MESSAGES_NAMESPACE}.cardNumber`,
  EXPIRY: `${MESSAGES_NAMESPACE}.expiry`,
  CVC: `${MESSAGES_NAMESPACE}.cvc`,
  ERROR_INVALID_NUMBER: `${MESSAGES_NAMESPACE}.errorInvalidNumber`,
  ERROR_INCOMPLETE_NUMBER: `${MESSAGES_NAMESPACE}.errorIncompleteNumber`,
  ERROR_INVALID_EXPIRY: `${MESSAGES_NAMESPACE}.errorInvalidExpiry`,
  ERROR_INVALID_EXPIRY_MONTH: `${MESSAGES_NAMESPACE}.errorInvalidExpiryMonth`,
  ERROR_INVALID_EXPIRY_YEAR: `${MESSAGES_NAMESPACE}.errorInvalidExpiryYear`,
  ERROR_INCOMPLETE_EXPIRY: `${MESSAGES_NAMESPACE}.errorIncompleteExpiry`,
  ERROR_EXPIRED_CARD: `${MESSAGES_NAMESPACE}.errorExpiredCard`,
  ERROR_INVALID_CVC: `${MESSAGES_NAMESPACE}.errorInvalidCvc`,
  ERROR_INCORRECT_CVC: `${MESSAGES_NAMESPACE}.errorIncorrectCvc`,
  ERROR_INCOMPLETE_CVC: `${MESSAGES_NAMESPACE}.errorIncompleteCvc`,
  ERROR_INVALID_REQUEST: `${MESSAGES_NAMESPACE}.errorInvalidRequest`,
  ERROR_GATEWAY_ERROR: `${MESSAGES_NAMESPACE}.errorGatewayError`,
  ERROR_RATE_LIMIT_ERROR: `${MESSAGES_NAMESPACE}.errorRateLimitError`,
  CARD_BRAND_ERROR_TEXT: `${MESSAGES_NAMESPACE}.cardBrandErrorText`,
};

export default KEYS;
