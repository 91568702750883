import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';
import hex2rgba from 'utils/colors/convert';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    networkIcon: {
      width: theme.scale(16),
      height: theme.scale(16),
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: theme.scale(12),
      fontWeight: 500,
      gap: theme.scale(12),
    },
    cardRowLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    stepDescription: {
      fontSize: theme.scale(14),
      color: theme.colors.text.inactive,
      textAlign: 'center',
    },
    gapWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.scale(15),
    },
    bottomWrapper: {
      marginTop: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
    },
    note: {
      color: theme.colors.text.beer,
      padding: theme.scale(4),
      background: hex2rgba(theme.colors.text.beer, 0.1),
      borderRadius: theme.border.radius,
      textAlign: 'center',
      ...fontSizeMixin(FontSize.XS),
    },
    qrCode: {
      width: 130,
      height: 130,
      display: 'block',
    },
    qrWrapper: {
      position: 'relative',
      marginTop: 0,
    },
    spinnerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
  }),
);

export default useStyles;
