import { tss } from 'theme';

export const useStyles = tss
  .withParams<{ inline?: boolean }>()
  .create(
    ({ theme, inline = false }) => ({
      container: {
        background: theme.colors.background.containerFill,
        borderRadius: theme.border.radius,
        padding: `${theme.scale(12)}px ${theme.scale(10)}px`,
      },
      rowWrapper: {
        display: 'flex',
        flexDirection: inline ? 'row' : 'column',
        alignItems: inline ? 'center' : 'unset',
        width: '100%',
        gap: theme.scale(5),
      },
      rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.colors.background.primary,
        padding: `${theme.scale(8)}px ${theme.scale(12)}px`,
        fontSize: theme.scale(12),
        fontWeight: 500,
        borderRadius: theme.border.radius,
        gap: theme.scale(5),
        width: '100%',
      },
      rowText: {
        wordBreak: 'break-word',
      },
      label: {
        whiteSpace: 'nowrap',
      },
    }),
  );

export default useStyles;
