import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M7.99996 1.3335L2.66663 3.3335V7.3935C2.66663',
  '10.7602 4.93996 13.9002 7.99996 14.6668C11.06',
  '13.9002 13.3333 10.7602 13.3333 7.3935V3.3335L7.99996',
  '1.3335ZM12 7.3935C12 10.0602 10.3 12.5268 7.99996',
  '13.2802C5.69996 12.5268 3.99996 10.0668 3.99996',
  '7.3935V4.26016L7.99996 2.76016L12 4.26016V7.3935Z',
].join(' ');

export const IconShield = ({
  width = 16,
  height = 16,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
      />
    </svg>
  );
};
export default IconShield;
