import WIDGET_DIMENSIONS from 'constants/dimensions';

import { breakpointValues } from './breakpoints';

export const getScaleRatio = () => {
  const { clientWidth } = document.getElementsByTagName('body')[0];

  if (clientWidth > breakpointValues.widget) {
    return 1;
  }

  if (clientWidth < WIDGET_DIMENSIONS.WIDTH) {
    return 1;
  }

  return clientWidth / WIDGET_DIMENSIONS.WIDTH;
};

export default getScaleRatio;
