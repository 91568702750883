import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';
import hex2rgba from 'utils/colors/convert';

const disclaimerHeight = 50;
export const useStyles = tss
  .withParams<{ preserveSpace?: boolean }>()
  .create(
    ({ theme, preserveSpace }) => ({
      container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.scale(5),
        height: '100%',
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.scale(10),
        zIndex: 1,
        boxShadow: `0px 10px 20px 0px ${hex2rgba(theme.colors.background.primary, 0.5)}`,
        marginTop: preserveSpace ? theme.scale(disclaimerHeight) : 0,
        padding: `${theme.scale(10)}px ${theme.scale(12)}px`,
        transition: '.3s',
      },
      bottomWrapper: {
        position: 'relative',
        marginTop: 'auto',
      },
      absoluteWrapper: {
        position: 'absolute',
        width: '100%',
        bottom: theme.scale(45),
      },
      regulatoryText: {
        ...fontSizeMixin(FontSize.XXS),
        marginBottom: theme.scale(6),
        color: theme.colors.text.disabled,
        textAlign: 'center',
        zIndex: 0,
      },
      totalPriceWrapper: {
        ...fontSizeMixin(FontSize.L),
        color: theme.colors.text.primary,
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 600,
        padding: `0 ${theme.scale(16)}px`,
      },
      thirdPartyButton: {
        padding: `0 ${theme.scale(16)}px`,
      },
      additionalInfoBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.scale(61),
        padding: theme.scale(12),
        background: theme.colors.background.primary,
        borderRadius: theme.border.radius,
        ...fontSizeMixin(FontSize.S),
        color: theme.colors.text.inactive,
      },
      contactSupport: {
        textAlign: 'center',
        ...fontSizeMixin(FontSize.S),
        paddingBottom: theme.scale(5),
      },
      paymentErrorText: {
        color: theme.colors.text.error,
        ...fontSizeMixin(FontSize.S),
      },
      previews: {
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        gridGap: `${theme.scale(12)}px ${theme.scale(5)}px`,
      },
      disclaimer: {
        color: theme.colors.text.beer,
        background: hex2rgba(theme.colors.text.beer, 0.1),
        fontSize: theme.scale(10),
        padding: theme.scale(4),
        marginBottom: theme.scale(10),
        borderRadius: theme.border.radius,
        textAlign: 'center',
        position: 'absolute',
        top: theme.scale(disclaimerHeight),
        width: '100%',
        height: theme.scale(32),
      },
    }),
  );

export default useStyles;
