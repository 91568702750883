import Flow from 'types/flow';

interface Config {
  onRampEnabled: boolean;
  offRampEnabled: boolean;
}

const getAvailableFlows = (config: Config): Flow[] => {
  const flows: Record<Flow, boolean> = {
    [Flow.Buy]: config.onRampEnabled,
    [Flow.Sell]: config.offRampEnabled,
  };

  return Object.entries(flows).reduce<Flow[]>((acc, [flow, isEnabled]) => {
    if (isEnabled) {
      return [...acc, flow as Flow];
    }
    return acc;
  }, []);
};

export default getAvailableFlows;
