import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';

import Button from 'components/button';
import { OrderRow } from 'components/orderRow/order';
import Spinner from 'components/spinner';
import { Asset } from 'types/assets';
import { OrderRecord, OrderSide } from 'types/order';
import PATHS from 'utils/navigation';
import checkIfOrderStatusIsPending from 'utils/order';
import { locators, QALocator } from 'utils/tests/QA';

import OptionallyVisible from '../../components/optionallyVisible';
import LABELS from './keys';
import useStyles from './styles';

interface OrderHistoryScreenProps {
  onSubmenuGoBack?: () => void;
  orderItems: {
    order: OrderRecord;
    cryptoAsset?: Asset;
    fiatAsset?: Asset;
  }[];
  createOrder: () => void;
  isLoading: boolean;
  canLoadNextPage: boolean;
  loadNextPage: () => void;
}

const OrderHistoryScreen = ({
  orderItems,
  createOrder,
  isLoading,
  canLoadNextPage,
  loadNextPage,
}: OrderHistoryScreenProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const orderPath = PATHS.RECEIPT_PATH;
  const orderPendingPath = PATHS.CONTINUE_ORDER_PATH;

  const getOrderPath = (order: OrderRecord) => {
    const isPendingStatus = checkIfOrderStatusIsPending(order.status);

    const isPending = order.side === OrderSide.Sell && isPendingStatus;
    return isPending ? orderPendingPath : orderPath;
  };

  const handleVisibilityChange = (visible: boolean) => {
    if (visible) {
      loadNextPage();
    }
  };

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner
          scale={1.25}
          {...QALocator(locators.page.orderHistory.viewLoadingSpinner)}
        />
      </div>
    );
  }

  if (!orderItems.length) {
    return (
      <div className={classes.placeholderContainer}>
        <div className={classes.placeholder}>
          <span>{t(LABELS.PLACEHOLDER)}</span>
        </div>
        <Button onClick={createOrder}>
          {t(LABELS.CREATE_ORDER)}
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.orderListScrollable}>
        <div className={classes.orderList}>
          {orderItems.map(({ order, fiatAsset, cryptoAsset }, index) => (index ? (
            <div key={order.uuid}>
              <div className={classes.delimeter} />
              <OrderRow order={order} fiatAsset={fiatAsset!} cryptoAsset={cryptoAsset!} orderPath={getOrderPath(order)} />
            </div>
          ) : (
            <OrderRow
              order={order}
              fiatAsset={fiatAsset!}
              cryptoAsset={cryptoAsset!}
              orderPath={getOrderPath(order)}
              key={order.uuid}
            />
          )))}
          <OptionallyVisible visible={canLoadNextPage}>
            <VisibilitySensor active={canLoadNextPage} onChange={handleVisibilityChange}>
              <Spinner
                scale={0.8}
                {...QALocator(locators.page.orderHistory.pageLoadingSpinner)}
              />
            </VisibilitySensor>
          </OptionallyVisible>
        </div>
        <div className={classes.shadow} />
      </div>
    </div>
  );
};

export default OrderHistoryScreen;
