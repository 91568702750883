import { ReactNode } from 'react';

import WorkflowStep from '../step';

export enum ScreenErrorType {
  Generic = 'generic',
  Fatal = 'fatal',
  AuthenticationFailed = 'auth',
  CardTokenizationFailed = 'tokenization',
  UnableToGetPaymentsConfig = 'paymentsConfig',
  PaymentCompletionError = 'paymentComplete',
  RegionUnsupported = 'regionUnsupported',
  KycToken = 'kycToken',
  KycRejected = 'kycRejected',
  UserStatus = 'userStatus',
  UnableToSubscribeToUpdates = 'updatesSubscription',
  UnableToGetCountriesList = 'countries',
  UnableToUpdateUserCountry = 'userCountryUpdate',
  InvalidApiKey = 'invalidApiKey',
  FlowError = 'flowError',
}

export interface ScreenError {
  title: string;
  message: string | ReactNode;
  errorType: ScreenErrorType;
  isFatalError?: boolean;
  goBackToScreen?: WorkflowStep;
}
