import { lazy } from 'react';

import ReceiptRoute from 'pages/receipt/route';
import RootContainer from 'pages/root/container';
import Route from 'pages/route/route';
import PATHS from 'utils/navigation';

const TermsOfUse = lazy(() => import('pages/termsOfUse'));
const PrivacyPolicy = lazy(() => import('pages/privacyPolicy'));
const PartnerPageContainer = lazy(() => import('pages/partner/container'));

const MainRouter = () => (
  <>
    <Route
      path={PATHS.MAIN_FLOW_PATH}
      element={<RootContainer />}
    />
    <Route
      path={PATHS.CONTINUE_ORDER_PATH}
      element={<RootContainer />}
    />
    <Route
      path={PATHS.RECEIPT_PATH}
      element={<ReceiptRoute path={PATHS.RECEIPT_PATH} />}
    />
    <Route
      path={PATHS.TERMS_OF_USE_PATH}
      element={<TermsOfUse />}
    />
    <Route
      path={PATHS.PRIVACY_POLICY_PATH}
      element={<PrivacyPolicy />}
    />
    <Route
      path={PATHS.PARTNER_PAGE_PATH}
      element={<PartnerPageContainer />}
    />
  </>
);

export default MainRouter;
