import { makeLabels } from 'utils/i18n';

const MESSAGES_NAMESPACE = 'components.paymentMethodsList';
export const LABELS = makeLabels({
  SAVED: 'savedCards',
  UNAVAILABLE: 'unavailable',
  CARD_TYPE: 'cardType',
  CARD_TYPE_EXPIRED: 'cardTypeExpired',
  CARD_EXPIRED: 'cardExpired',
  CARD_DEFAULT: 'cardDefault',
  ADD_NEW_CARD: 'addNewCard',
}, MESSAGES_NAMESPACE);

export default LABELS;
