import { TFunction } from 'i18next';

import { Shift4ErrorCode, Shift4ErrorType } from 'types/errors/shift4';

import { LABELS } from './keys';

export const getCardValidationMessage = (errorCode: Shift4ErrorCode, t: TFunction) => {
  const CARD_VALIDATION_ERRORS = {
    [Shift4ErrorCode.InvalidNumber]: t(LABELS.ERROR_INVALID_NUMBER),
    [Shift4ErrorCode.IncompleteNumber]: t(LABELS.ERROR_INCOMPLETE_NUMBER),
    [Shift4ErrorCode.InvalidExpiry]: t(LABELS.ERROR_INVALID_EXPIRY),
    [Shift4ErrorCode.InvalidExpiryMonth]: t(LABELS.ERROR_INVALID_EXPIRY_MONTH),
    [Shift4ErrorCode.InvalidExpiryYear]: t(LABELS.ERROR_INVALID_EXPIRY_YEAR),
    [Shift4ErrorCode.IncompleteExpiry]: t(LABELS.ERROR_INCOMPLETE_EXPIRY),
    [Shift4ErrorCode.ExpiredCard]: t(LABELS.ERROR_EXPIRED_CARD),
    [Shift4ErrorCode.InvalidCvc]: t(LABELS.ERROR_INVALID_CVC),
    [Shift4ErrorCode.IncorrectCvc]: t(LABELS.ERROR_INCORRECT_CVC),
    [Shift4ErrorCode.IncompleteCvc]: t(LABELS.ERROR_INCOMPLETE_CVC),
  };

  return CARD_VALIDATION_ERRORS[errorCode as keyof typeof CARD_VALIDATION_ERRORS];
};

export const getCardGenericMessage = (errorCode: Shift4ErrorType, t: TFunction) => {
  const CARD_GENERIC_ERRORS = {
    [Shift4ErrorType.InvalidRequest]: t(LABELS.ERROR_INVALID_REQUEST),
    [Shift4ErrorType.GatewayError]: t(LABELS.ERROR_GATEWAY_ERROR),
    [Shift4ErrorType.RateLimitError]: t(LABELS.ERROR_RATE_LIMIT_ERROR),
  };

  return CARD_GENERIC_ERRORS[errorCode as keyof typeof CARD_GENERIC_ERRORS];
};
