import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWithCustomHeaders, makeAuthHeader } from 'services/api';
import SERVICES_DATA_CONFIG from 'services/data';
import { Endpoint } from 'services/endpoints';
import { selectUserCacheEntry, USER_TAG } from 'services/user';
import { RootState } from 'state/store';
import { AuthToken } from 'types/authentication';
import { CardPaymentToken } from 'types/card';
import { APIResponse } from 'types/errors/api';
import Flow from 'types/flow';
import HTTPMethod, { ReduxToolkitError, Response, SuccessResponse } from 'types/http';
import PaymentMethod from 'types/payment';
import { ApplePayBridgeAction } from 'types/payments/applePay';
import ConfigQueryParam from 'types/queryParams';
import isRunningInsideIframe from 'utils/iframe';
import Logger from 'utils/logger';
import getQueryParam from 'utils/queryParams';

import KEYS from './keys';
import {
  CreatePaymentMethodPayload,
  OrderCompletionPayload,
  OrderCompletionResponse,
  PaymentMethodResponseEntry,
  PaymentMethodsResponse,
  PaymentsConfigResponse,
  SavedCard,
  SavedCardsResponse,
} from './types';

const REDUCER_PATH = 'paymentsApi';
export const FINISH_PAYMENT_CACHE_KEY = 'FINISH_PAYMENT_CACHE_KEY';
export const CARDS_TAG = 'cards';
// TODO: Delete when ready
export const DISABLED_PAYMENT_METHODS = [
  window.s4cConfig?.features?.applePay ? null : PaymentMethod.ApplePay,
  window.s4cConfig?.features?.googlePay ? null : PaymentMethod.GooglePay,
];

export const paymentsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(makeAuthHeader),
  tagTypes: [USER_TAG, CARDS_TAG],
  keepUnusedDataFor: SERVICES_DATA_CONFIG.STORE_FOREVER_TIME,
  endpoints: builder => ({
    getPaymentsConfig: builder.query<Response<PaymentsConfigResponse>, string>({
      query: () => Endpoint.Payments.Config,
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    }),
    completePayment: builder.mutation<APIResponse<OrderCompletionResponse>, OrderCompletionPayload>({
      query: payload => ({
        body: payload,
        url: Endpoint.Orders,
        method: HTTPMethod.POST,
      }),
      // Invalidates user tag as default payment method may be set to the new card
      invalidatesTags: [CARDS_TAG, USER_TAG],
    }),
    confirmUpdatedQuote: builder.mutation<OrderCompletionResponse, { uuid: string; quoteId: string }>({
      query: ({ uuid, quoteId }) => ({
        body: {
          quoteId,
        },
        url: Endpoint.OrderQuoteByUuid(uuid),
        method: HTTPMethod.POST,
      }),
      invalidatesTags: [CARDS_TAG],
    }),
    updatePaymentMethod: builder.mutation<SuccessResponse, { uuid: string; paymentToken: CardPaymentToken }>({
      query: ({ uuid, paymentToken }) => ({
        url: Endpoint.OrderPaymentByUuid(uuid),
        method: HTTPMethod.POST,
        body: {
          paymentToken: paymentToken.id,
        },
      }),
    }),
    getUserCards: builder.query<SavedCard[], AuthToken>({
      query: () => Endpoint.SavedCards,
      transformResponse: (response: Response<SavedCardsResponse>) => {
        if (!response?.data?.cards) {
          return [];
        }

        return response.data.cards;
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      providesTags: [CARDS_TAG, USER_TAG],
    }),
    deleteUserCard: builder.mutation<SuccessResponse, { authToken: AuthToken; card:CardPaymentToken | SavedCard }>({
      query: ({ card }) => ({
        url: Endpoint.SavedCardById(card.id),
        method: HTTPMethod.DELETE,
      }),
      invalidatesTags: [CARDS_TAG],
      async onQueryStarted({ authToken, card }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            paymentsApi.util.updateQueryData('getUserCards', authToken, draft => draft.filter(c => c.id !== card.id)),
          );
        } catch {
          Logger.error('Unable to set card as default');
        }
      },
    }),
    setDefaultUserCard: builder.mutation<SuccessResponse, { authToken: AuthToken; card: CardPaymentToken | SavedCard }>({
      query: ({ card }) => ({
        url: Endpoint.DefaultSavedCardById(card.id),
        method: HTTPMethod.POST,
      }),
      async onQueryStarted({ authToken, card }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            paymentsApi.util.updateQueryData('getUserCards', authToken, (draft) => {
              const updatedCards = draft.map((c) => {
                if (c.isDefault) {
                  return { ...c, isDefault: false };
                }
                if (c.id === card.id) {
                  return { ...c, isDefault: true };
                }
                return c;
              });

              return updatedCards;
            }),
          );
        } catch {
          Logger.error('Unable to set card as default');
        }
      },
    }),
    getPaymentMethods: builder.query<PaymentMethodResponseEntry[], void>({
      query: () => Endpoint.PaymentMethods,
      transformResponse: (response: Response<PaymentMethodsResponse>) => {
        if (!response?.data?.methods) {
          return [];
        }

        return response.data.methods;
      },
    }),
    createPaymentMethod: builder.mutation<string | null, CreatePaymentMethodPayload>({
      query: payload => ({
        url: Endpoint.Payments.Method,
        method: HTTPMethod.POST,
        body: {
          ...payload,
        },
      }),
      transformResponse: (response: Response<{ clientObjectId: string }>) => {
        if (!response?.data?.clientObjectId) {
          return null;
        }

        return response.data.clientObjectId;
      },
    }),
  }),
});

export const selectPlacedOrder = (state: RootState) => {
  const order = (state[paymentsApi.reducerPath]
    .mutations[FINISH_PAYMENT_CACHE_KEY]?.data as any)?.data as OrderCompletionResponse | {error: ReduxToolkitError };
  if (!order) {
    return undefined;
  }
  return order;
};

const selectDefaultCard = (state: RootState) => {
  if (!state.auth.token) {
    return undefined;
  }

  const cacheEntry = paymentsApi.endpoints.getUserCards.select(state.auth.token)(state);

  return cacheEntry.data?.find(card => card.isDefault && !card.isExpired);
};
export const selectDefaultCardCacheEntry = createSelector([(state: RootState) => state], selectDefaultCard);

export const getAvailableUserPaymentMethod = (
  userDefaultPaymentMethod: PaymentMethod | CardPaymentToken['id'],
  paymentMethods: PaymentMethodResponseEntry[],
  flow: Flow,
) => {
  const defaultPaymentMethodName = getQueryParam(ConfigQueryParam.PaymentMethod) as PaymentMethod ?? userDefaultPaymentMethod;
  if (!defaultPaymentMethodName) {
    return null;
  }

  const paymentMethod = paymentMethods
    .filter(method => filterUnavailableMethods(method, flow))
    .find(availablePaymentMethod => availablePaymentMethod.name === defaultPaymentMethodName);
  const flowAvailabilityMapping: Record<Flow, boolean> = {
    [Flow.Buy]: Boolean(paymentMethod?.onRamp),
    [Flow.Sell]: Boolean(paymentMethod?.offRamp),
  };

  if (flowAvailabilityMapping[flow]) {
    return paymentMethod?.name;
  }

  return null;
};

const filterUnavailableMethods = (method: PaymentMethodResponseEntry, flow: Flow) => {
  const flowMapping: Record<Flow, boolean> = {
    [Flow.Buy]: method.onRamp,
    [Flow.Sell]: method.offRamp,
  };

  if (method.name === PaymentMethod.ApplePay && isRunningInsideIframe()) {
    const widgetId = getQueryParam(ConfigQueryParam.Id);
    window.parent.postMessage({
      action: ApplePayBridgeAction.isApplePayAvailable,
      widgetId,
    }, KEYS.UNSPECIFIED_ORIGIN);
    return window.isTopLevelApplePayAvailable;
  }

  const checkApplePayAvailability = method.name === PaymentMethod.ApplePay && !isRunningInsideIframe();
  if (checkApplePayAvailability) {
    return window.ApplePaySession?.canMakePayments()
      && window.PaymentRequest;
  }

  return flowMapping[flow]
      && !DISABLED_PAYMENT_METHODS.includes(method.name);
};

export const getAvailablePaymentMethods = (state: RootState) => {
  const { flow } = state.flow;
  const paymentMethods = paymentsApi.endpoints.getPaymentMethods.select()(state);
  const availablePaymentMethods = paymentMethods?.data?.filter(method => filterUnavailableMethods(method, flow)).map(method => method.name);

  return availablePaymentMethods || [PaymentMethod.Card];
};
export const selectAvailablePaymentMethods = createSelector([(state: RootState) => state], getAvailablePaymentMethods);

export const selectPaymentMethod = (state: RootState) => {
  const availablePaymentMethods = paymentsApi.endpoints.getPaymentMethods.select()(state);
  const selectedPaymentMethod = state.payment.paymentMethod;
  const userDefaultPaymentMethod = selectUserCacheEntry(state)?.data?.data?.defaultPaymentMethod;
  const paymentMethodFromQueryParam = getQueryParam(ConfigQueryParam.PaymentMethod);
  const paymentMethod = paymentMethodFromQueryParam ?? selectedPaymentMethod ?? userDefaultPaymentMethod;

  if (!paymentMethod || !availablePaymentMethods.data) {
    return null;
  }
  return getAvailableUserPaymentMethod(paymentMethod, availablePaymentMethods.data, state.flow.flow);
};

export const { getPaymentsConfig, completePayment } = paymentsApi.endpoints;
export const { useCreatePaymentMethodMutation } = paymentsApi;

export default paymentsApi;
