import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(15),
      height: '100%',
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.scale(15),
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
    marginTopAuto: {
      marginTop: 'auto',
    },
    terms: {
      color: theme.colors.text.inactive,
      ...fontSizeMixin(FontSize.S),
      width: '100%',
      textAlign: 'center',
      marginBottom: theme.scale(5),
    },
    noHeader: {
      paddingTop: theme.scale(12),
    },
    errorMessage: {
      color: theme.colors.text.error,
      fontWeight: 500,
      textAlign: 'center',
      padding: `${theme.scale(6)}px 0`,
      ...fontSizeMixin(FontSize.S),
    },
  }),
);

export default useStyles;
