import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M2.5 14.875V18H5.625L14.8417',
  '8.78333L11.7167 5.65833L2.5',
  '14.875ZM17.2583 6.36667C17.5833',
  '6.04167 17.5833 5.51667 17.2583',
  '5.19167L15.3083 3.24167C14.9833',
  '2.91667 14.4583 2.91667 14.1333',
  '3.24167L12.6083 4.76667L15.7333',
  '7.89167L17.2583 6.36667Z',
].join(' ');

export const IconEdit = ({
  width = 20,
  height = 21,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 20 21"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconEdit;
