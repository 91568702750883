import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import Card from 'components/card';
import Header from 'components/header';
import COMMON_LABELS from 'constants/messages';
import WalletIllustration from 'illustrations/wallet';
import { locators, QALocator } from 'utils/tests/QA';

import { FormFields, LABELS } from './keys';
import { useStyles } from './styles';
import { WalletPageProps } from './types';
import useWalletAddressForm from './useWalletAddressForm';
import WalletForm from './walletForm';

const WalletPage = ({
  proceed,
  selectedCryptoAsset,
  initialWalletAddress,
  initialTagOrMemo,
  cryptoNetworkName,
  shouldShowDestinationTag,
  shouldShowMemo,
  canGoBack,
  goBack,
}: WalletPageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    form, hasErrors, setWalletAddress, setTagOrMemo, validateFormAndExecuteCallback,
  } = useWalletAddressForm({
    addressRegexp: selectedCryptoAsset?.addressRegexp,
    addressTagRegexp: selectedCryptoAsset?.addressTagRegexp,
    initialWalletAddress,
    initialTagOrMemo,
    errorTexts: {
      [FormFields.WalletAddress]: t(
        LABELS.VALIDATION_WALLET_ADDRESS,
        {
          cryptoNetworkName,
        },
      ),
      [FormFields.TagOrMemo]: shouldShowDestinationTag ? t(
        LABELS.VALIDATION_WALLET_ADDRESS_TAG,
        {
          cryptoNetworkName,
        },
      ) : t(
        LABELS.VALIDATION_WALLET_ADDRESS_MEMO,
        {
          cryptoNetworkName,
        },
      ),
    },
  });

  const isNextStepAllowed = !hasErrors;

  const handleWalletAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWalletAddress(e.target.value);
  };

  const handleTagOrMemoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTagOrMemo(e.target.value);
  };

  const handleContinueClick = async () => {
    await validateFormAndExecuteCallback(() => proceed(form.values[FormFields.WalletAddress], form.values[FormFields.TagOrMemo]));
  };

  return (
    <div className={classes.container}>
      <Header onBackClick={goBack} hasBackButton={canGoBack}>
        {t(LABELS.HEADER)}
      </Header>
      <div className={classes.imageWrapper}>
        <WalletIllustration />
        {t(LABELS.ENTER_WALLET_ADDRESS_TEXT_PARAGRAPH_1)}
      </div>
      <Card className={classes.card}>
        <WalletForm
          onWalletAddressChange={handleWalletAddressChange}
          onTagOrMemoChange={handleTagOrMemoChange}
          walletAddressValue={form.values[FormFields.WalletAddress]}
          walletAddressError={form.errors[FormFields.WalletAddress]}
          tagOrMemoValue={form.values[FormFields.TagOrMemo]}
          tagOrMemoError={form.errors[FormFields.TagOrMemo]}
          selectedCryptoAsset={selectedCryptoAsset}
          shouldShowDestinationTag={shouldShowDestinationTag}
          shouldShowMemo={shouldShowMemo}
        />
      </Card>
      <div className={classes.proceedButtonWrapper}>
        <Button
          isContinue
          onClick={handleContinueClick}
          disabled={!isNextStepAllowed}
          {...QALocator(locators.page.wallet.proceedButton)}
        >
          {t(COMMON_LABELS.CONTINUE)}
        </Button>
      </div>
    </div>
  );
};

export default WalletPage;
