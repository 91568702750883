export const getRootUrl = () => {
  if (!window?.s4cConfig?.backendUrl) {
    throw new Error('No backend URL');
  }

  return window.s4cConfig.backendUrl;
};

export const API_CONFIG = {
  TOKEN_KEY: 'cor-token',
  ROOT_URL: getRootUrl(),
};

export const TERMS_OF_USE_URL = `${window.location.origin}/terms-of-use`;
export const PRIVACY_POLICY_URL = `${window.location.origin}/privacy-policy`;

export default API_CONFIG;
