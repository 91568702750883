import { useTranslation } from 'react-i18next';

import Header from 'components/header';
import GlobeIllustration from 'illustrations/globe';

import LABELS from '../keys';
import useStyles from './styles';

const IpCountryNotSupportedPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Header>
        {t(LABELS.IP_UNSUPPORTED_HEADER)}
      </Header>
      <div className={classes.imageWrapper}>
        <GlobeIllustration />
      </div>
      <div className={classes.captionContainer}>
        <h3 className={classes.title}>
          {t(LABELS.IP_UNSUPPORTED_TITLE)}
        </h3>
        <div className={classes.caption}>
          {t(LABELS.IP_UNSUPPORTED_CAPTION)}
        </div>
      </div>
    </div>
  );
};

export default IpCountryNotSupportedPage;
