import { SCREEN_TRANSITION_DURATION } from 'constants/transitions';
import { tss } from 'theme';

export const useStyles = tss
  .withParams<{ isGoingBack: boolean }>()
  .create(
    ({ isGoingBack }) => ({
      fullSize: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
      },
      spinnerWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      fade: {
        position: 'relative',
        height: '100%',
        width: '100%',
        '& .fade-enter': {
          opacity: 0.25,
          transform: isGoingBack ? 'translateX(-100%)' : 'translateX(100%)',
        },
        '& .fade-exit': {
          opacity: 1,
          transform: 'translateX(0%)',
        },
        '& .fade-enter-active': {
          opacity: 1,
          transform: 'translateX(0%)',
        },
        '& .fade-exit-active': {
          opacity: 0.25,
          transform: isGoingBack ? 'translateX(100%)' : 'translateX(-100%)',

        },
        '& .fade-enter-active, & .fade-exit-active': {
          transition: `opacity 500ms, transform ${SCREEN_TRANSITION_DURATION}ms`,
        },
      },
    }),
  );

export default useStyles;
