import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    disclaimer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(12),
      marginTop: theme.scale(12),
      '& span': {
        ...fontSizeMixin(FontSize.S),
        color: theme.colors.text.inactive,
      },
    },
  }),
);

export default useStyles;
