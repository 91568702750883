export enum ApplePayBridgeAction {
  isApplePayAvailable = 'IS_APPLE_PAY_AVAILABLE',
  createApplePayPaymentRequest = 'CREATE_APPLE_PAY_PAYMENT_REQUEST',
  applePayChargeResponse = 'APPLE_PAY_CHARGE_RESPONSE'
}

export interface ApplePayMethodData {
  supportedMethods: string;
  data: {
      countryCode: string;
  };
}

export interface ApplePayShoppingCartDetails {
  total: {
    label: string;
    amount: {
      currency: string;
      value: string;
    };
  };
}

export interface ApplePayBridgeMessage {
  channel: string;
  widgetId: string;
  status?: ApplePayTransactionStatus;
  action?: ApplePayBridgeAction;
  shift4PublicKey?: string;
  applePayMethodData?: ApplePayMethodData;
  shoppingCartDetails?: ApplePayShoppingCartDetails;
  isApplePayAvailable?: boolean;
}

export enum ApplePayTransactionStatus {
  Success = 'success',
  Fail = 'fail'
}
