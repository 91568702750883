import getEmailFromQueryParams from 'config/email';
import { SavedCard } from 'services/payments/types';
import Flow from 'types/flow';
import PaymentMethod from 'types/payment';
import { WorkflowStep } from 'types/step';
import { Transaction, TransactionStatus } from 'types/transactions';
import { User } from 'types/user';
import { isFinishingOrder } from 'utils/navigation';

import { checkIfShouldSkipQuoteStep } from './queryParams';
import { getQuoteScreenNextStep } from './quote';
import { getQuoteScreenNextStepSell } from './quote.sell';

const KEYS = {
  UNKNOWN_KEY: 'unknown',
};

interface GetInitialStepParams {
  user?: User;
  flow?: Flow;
  orderStatus?: Transaction | null;
  paymentMethod?: {
    default?: PaymentMethod | null;
    defaultCard?: SavedCard;
  };
}

export const getContinueOrderStep = (orderStatus?: Transaction | null) => {
  const finishOrderStep = {
    [TransactionStatus.Initiated]: WorkflowStep.TransferCrypto,
    [TransactionStatus.AwaitingDeposit]: WorkflowStep.TransferCrypto,
    [TransactionStatus.GetQuoteToExecuteFailed]: WorkflowStep.TransactionProcessing,
    [TransactionStatus.ExchangeFailed]: WorkflowStep.TransactionProcessing,
    [TransactionStatus.ExecuteQuoteFailed]: WorkflowStep.TransactionProcessing,
    [TransactionStatus.CaptureChargeFailed]: WorkflowStep.TransactionProcessing,
    [TransactionStatus.PaymentFailed]: WorkflowStep.RequestNewCard,
    [TransactionStatus.Processing]: WorkflowStep.TransactionProcessing,
    [TransactionStatus.AwaitingClientConfirmation]: WorkflowStep.ConfirmNewQuote,
    [TransactionStatus.Completed]: WorkflowStep.OrderProcessed,
    [TransactionStatus.AuthorizationChargeFailed]: WorkflowStep.Error,
    [KEYS.UNKNOWN_KEY]: null,
  }[orderStatus?.status ?? KEYS.UNKNOWN_KEY];

  return finishOrderStep;
};

export const getInitialStep = ({
  user,
  flow,
  orderStatus,
  paymentMethod,
}: GetInitialStepParams): WorkflowStep => {
  const skipQuoteStep = checkIfShouldSkipQuoteStep();
  const finishOrderStep = getContinueOrderStep(orderStatus);
  const isContinueFlow = isFinishingOrder();
  const hasEmailQueryParam = getEmailFromQueryParams();
  const skipAuth = user && !hasEmailQueryParam;

  if (flow === Flow.Sell) {
    if (isContinueFlow) {
      if (skipAuth) {
        if (finishOrderStep) {
          return finishOrderStep;
        }
      }
      return WorkflowStep.SignIn;
    }
  }

  if (skipQuoteStep) {
    if (skipAuth) {
      if (flow === Flow.Sell) {
        return getQuoteScreenNextStepSell({ user, paymentMethod });
      }
      return getQuoteScreenNextStep({ user, paymentMethod });
    }

    return WorkflowStep.SignIn;
  }

  return WorkflowStep.Quote;
};

export default getInitialStep;
