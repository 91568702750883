import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M14.6663 4.00008C14.6663 3.26675 14.0663 2.66675',
  '13.333 2.66675H2.66634C1.93301 2.66675 1.33301 3.26675',
  '1.33301 4.00008V12.0001C1.33301 12.7334 1.93301 13.3334',
  '2.66634 13.3334H13.333C14.0663 13.3334 14.6663 12.7334',
  '14.6663 12.0001V4.00008ZM13.333 4.00008L7.99967 7.33341L2.66634',
  '4.00008H13.333ZM13.333 12.0001H2.66634V5.33341L7.99967 8.66675L13.333',
  '5.33341V12.0001Z',
].join(' ');

export const IconEnvelope = ({
  width = 16,
  height = 16,
  color,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconEnvelope;
