const KEYS = {
  POLLING_INTERVAL: 3000,
  DESTINATION_TAG_API_FIELD_NAME: 'destination_tag',
};

const MESSAGES_NAMESPACE = 'awaitingCrypto';
export const LABELS = {
  DESTINATION_TAG: `${MESSAGES_NAMESPACE}.destinationTag`,
  MEMO: `${MESSAGES_NAMESPACE}.memo`,
  PROCESSING: `${MESSAGES_NAMESPACE}.processing`,
  NOTE: `${MESSAGES_NAMESPACE}.note`,
  CARD_TITLE: `${MESSAGES_NAMESPACE}.cardTitle`,
  WALLET: `${MESSAGES_NAMESPACE}.wallet`,
  NETWORK_IMAGE_ALT: `${MESSAGES_NAMESPACE}.networkImageAlt`,
  DEPOSIT_ADDRESS: `${MESSAGES_NAMESPACE}.depositAddress`,
};

export default KEYS;
