import { AssetWithLabel } from 'types/assets';
import ConfigQueryParam from 'types/queryParams';
import getQueryParam from 'utils/queryParams';

export const filterFiatAssets = (assets: AssetWithLabel[]) => {
  const assetsBySymbol = filterFiatAssetsBySymbol(assets);

  return assetsBySymbol;
};

export const filterCryptoAssets = (assets: AssetWithLabel[]) => {
  const assetsBySymbol = filterCryptoAssetsBySymbol(assets);
  const assetsByNetwork = filterCryptoAssetsByNetwork(assetsBySymbol);

  return assetsByNetwork;
};

const filterFiatAssetsBySymbol = (fiatAssets: AssetWithLabel[]) => {
  const forcedSymbols = getQueryParam(ConfigQueryParam.FiatAssetsList)?.split(',') ?? window.partnerConfig?.supportedFiatCurrenciesCodes;
  if (!forcedSymbols?.length) {
    return fiatAssets;
  }

  const filteredAssets = fiatAssets.filter(asset => forcedSymbols.includes(asset.symbol));
  if (!filteredAssets.length) {
    return fiatAssets;
  }

  return filteredAssets;
};

const filterCryptoAssetsBySymbol = (cryptoAssets: AssetWithLabel[]) => {
  const forcedSymbols = getQueryParam(ConfigQueryParam.CryptoAssetsList)?.split(',')
    ?? window.partnerConfig?.supportedCryptoCurrenciesCodes;

  if (!forcedSymbols?.length) {
    return cryptoAssets;
  }

  const filteredBySymbolAssets = cryptoAssets.filter(asset => forcedSymbols.includes(asset.symbol));
  if (!filteredBySymbolAssets.length) {
    return cryptoAssets;
  }

  return filteredBySymbolAssets;
};

const filterCryptoAssetsByNetwork = (cryptoAssets: AssetWithLabel[]) => {
  const forcedNetworks = getQueryParam(ConfigQueryParam.Networks)?.split(',');
  if (!forcedNetworks?.length) {
    return cryptoAssets;
  }

  const filteredByNetworkAssets = cryptoAssets.filter(asset => forcedNetworks.includes(asset.chainCode));
  if (!filteredByNetworkAssets?.length) {
    return cryptoAssets;
  }

  return filteredByNetworkAssets;
};
