import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWithCustomHeaders, makeAuthHeader } from 'services/api';
import { Endpoint } from 'services/endpoints';

const REDUCER_PATH = 'countriesApi';

export interface CountryItem {
  name: string;
  iconUrl: string;
  isoAlpha2: string;
  isoAlpha3: string;
  isSupported: boolean;
}

interface CountriesResponse {
  data: {
    countries: CountryItem[];
  };
}

export const countriesApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(makeAuthHeader),
  endpoints: builder => ({
    getCountries: builder.query<CountriesResponse, void>({
      query: () => Endpoint.Countries,
    }),
  }),
});

export const { getCountries } = countriesApi.endpoints;

export default countriesApi;
