export const KEYS = {
  WALLET_ADDRESS_INPUT_ID: 'walletAddress',
  DESTINATION_TAG_INPUT_ID: 'tagOrMemo',
  DESTINATION_TAG_API_FIELD_NAME: 'destination_tag',
  MEMO_INPUT_ID: 'tagOrMemo',
  MEMO_NAME_API_FIELD: 'memo',
  TAG_OR_MEMO_PLACEHOLDER: '0000000000',
};

export enum FormFields {
  WalletAddress = 'walletAddress',
  TagOrMemo = 'tagOrMemo',
}

export type FormValues = {
  [FormFields.WalletAddress]: string;
  [FormFields.TagOrMemo]: string;
}

const MESSAGES_NAMESPACE = 'wallet';
export const LABELS = {
  VALIDATION_WALLET_ADDRESS: `${MESSAGES_NAMESPACE}.validationWalletAddress`,
  VALIDATION_WALLET_ADDRESS_TAG: `${MESSAGES_NAMESPACE}.validationWalletAddressTag`,
  VALIDATION_WALLET_ADDRESS_MEMO: `${MESSAGES_NAMESPACE}.validationWalletAddressMemo`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  ENTER_WALLET_ADDRESS_TEXT_PARAGRAPH_1: `${MESSAGES_NAMESPACE}.enterWalletAddressTextParagraph1`,
  RECEIVING_WALLET: `${MESSAGES_NAMESPACE}.receivingWallet`,
  WALLET_ADDRESS: `${MESSAGES_NAMESPACE}.walletAddress`,
  DESTINATION_TAG: `${MESSAGES_NAMESPACE}.destinationTag`,
  MEMO: `${MESSAGES_NAMESPACE}.memo`,
  INFO_BLOCK_TEXT_INTRO: `${MESSAGES_NAMESPACE}.infoBlockTextIntro`,
  INFO_BLOCK_TEXT_PARAGRAPH_1: `${MESSAGES_NAMESPACE}.infoBlockTextParagraph1`,
  INFO_BLOCK_TEXT_PARAGRAPH_2: `${MESSAGES_NAMESPACE}.infoBlockTextParagraph2`,
  INFO_BLOCK_TEXT_PARAGRAPH_3: `${MESSAGES_NAMESPACE}.infoBlockTextParagraph3`,
};

export default KEYS;
