import { ReactNode } from 'react';

import useStyles from './item.styles';

export interface ItemIconProps {
  icon?: ReactNode;
  iconUrl?: string | null;
  label: string;
}

export const ItemIcon = ({
  icon,
  iconUrl,
  label,
}: ItemIconProps) => {
  const { classes } = useStyles();
  if (icon) {
    return (
      <div className={classes.iconContainer}>{icon}</div>
    );
  }

  if (iconUrl) {
    return (
      <div className={classes.iconContainer}>
        <img src={iconUrl} alt={label} className={classes.icon} />
      </div>
    );
  }

  return null;
};

export default ItemIcon;
