import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .withParams<{ listWrapperHeight?: number; hasInputValue: boolean }>()
  .create(
    ({ theme, listWrapperHeight, hasInputValue }) => {
      const categoryLabelHeight = hasInputValue ? theme.scale(27) : theme.scale(10);
      const listHeight = listWrapperHeight ? listWrapperHeight - categoryLabelHeight : '100%';

      return ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.scale(6),
          height: '100%',
        },
        listWrapper: {
          height: '100%',
        },
        list: {
          marginTop: 'auto',
          maxHeight: listHeight,
          overflow: 'auto',
          margin: 'unset',
          padding: 'unset',
          '&::-webkit-scrollbar': {
            width: theme.scale(5),
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: theme.scale(5),
            background: theme.colors.text.inactive,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: theme.colors.background.accentDark,
          },
        },
        input: {
          marginRight: theme.scale(10),
          background: theme.colors.background.containerFill,
          '&.Mui-focused': {
            background: theme.colors.background.primary,
          },
          '&.Mui-focused svg': {
            fill: theme.colors.text.primary,
          },
        },
        searchIcon: {
          fill: theme.colors.text.inactive,
        },
        category: {
          ...fontSizeMixin(FontSize.S),
          color: theme.colors.text.inactive,
          fontWeight: 500,
          margin: `${theme.scale(6)}px 0`,
        },
        categoryPlaceholder: {
          height: theme.scale(10),
        },
      });
    },
  );

export default useStyles;
