import { useFormik } from 'formik';

import { FormFields, FormValues } from './keys';

interface useWalletAddressFormParams {
  addressRegexp?: string;
  addressTagRegexp?: string;
  initialWalletAddress: string;
  initialTagOrMemo: string;
  errorTexts: Partial<Record<FormFields, string>>;
}

const useWalletAddressForm = ({
  addressRegexp,
  addressTagRegexp,
  errorTexts,
  initialWalletAddress,
  initialTagOrMemo,
} : useWalletAddressFormParams) => {
  const walletAddressValidateFn = (values: FormValues) => {
    if (!addressRegexp) {
      return false;
    }

    const walletAddressRegExp = new RegExp(addressRegexp);

    if (values[FormFields.WalletAddress].match(walletAddressRegExp)) {
      return false;
    }

    return errorTexts[FormFields.WalletAddress];
  };

  const walletAddressTagValidateFn = (values: FormValues) => {
    if (!addressTagRegexp) {
      return false;
    }

    if (!values[FormFields.TagOrMemo]) {
      return false;
    }

    const walletAddressTagRegExp = new RegExp(addressTagRegexp);

    if (values[FormFields.TagOrMemo].match(walletAddressTagRegExp)) {
      return false;
    }

    return errorTexts[FormFields.TagOrMemo];
  };

  const formik = useFormik({
    initialValues: {
      [FormFields.WalletAddress]: initialWalletAddress,
      [FormFields.TagOrMemo]: initialTagOrMemo,
    },
    validate: values => ({
      [FormFields.WalletAddress]: walletAddressValidateFn(values),
      [FormFields.TagOrMemo]: walletAddressTagValidateFn(values),
    }),
    onSubmit: () => {},
    validateOnChange: true,
  });

  const validateFormAndExecuteCallback = async (callback: () => void) => {
    const result = await formik.validateForm();

    const hasValidationErrors = Object.values(result).some(Boolean);

    if (!hasValidationErrors) {
      callback();
    }
  };

  const hasErrors = Object.values(formik.errors).some(Boolean);

  const shouldValidateField = true;
  const setWalletAddress = (value: string) => formik.setFieldValue(FormFields.WalletAddress, value, shouldValidateField);
  const setTagOrMemo = (value: string) => formik.setFieldValue(FormFields.TagOrMemo, value);

  return {
    form: formik,
    hasErrors,
    setWalletAddress,
    setTagOrMemo,
    validateFormAndExecuteCallback,
  };
};

export default useWalletAddressForm;
