import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .withParams<{ transitionTime: number }>()
  .create(
    ({ theme, transitionTime }) => ({
      tooltip: {
        ...fontSizeMixin(FontSize.S),
        color: theme.colors.text.primary,
        fontWeight: 500,
        position: 'absolute',
        top: theme.scale(-35),
        background: theme.colors.background.primary,
        borderRadius: theme.border.radius,
        border: `0.5px solid ${theme.colors.background.accent}`,
        padding: `${theme.scale(8)}px ${theme.scale(12)}px`,
        transition: `opacity ${transitionTime}ms ease-in-out`,
        opacity: 0,
      },
      pressing: {
        background: theme.colors.background.highlight,
      },
      entering: {
        opacity: 1,
      },
      entered: {
        opacity: 1,
      },
      exiting: {
        opacity: 0,
      },
      exited: {
        opacity: 0,
      },
    }),
  );

export default useStyles;
