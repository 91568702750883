import WIDGET_DIMENSIONS from 'constants/dimensions';
import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';
import hex2rgba from 'utils/colors/convert';

export const useStyles = tss
  .withParams<{ mobileFrameHeight?: number } >()
  .create(
    ({ theme, mobileFrameHeight }) => ({
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.colors.background.containerFill,
        overflow: 'auto',
        gap: theme.scale(30),
        height: mobileFrameHeight || '100vh',
        [theme.breakpoints.min.widget]: {
          height: `calc(100vh - ${theme.scale(60)}px)`,
          padding: theme.scale(30),
        },
        '& *': {
          fontFamily: theme.fonts.primary,
          color: theme.colors.text.primary,
          boxSizing: 'border-box',
          outlineColor: theme.colors.border.accentDark,
          a: {
            textDecoration: 'none',
            color: theme.colors.text.accentDark,
          },
        },
      },
      content: {
        height: `calc(100% - ${theme.scale(18)}px)`,
      },
      form: {
        overflow: 'hidden',
        background: theme.colors.background.primary,
        width: '100%',
        height: '100%',
        padding: theme.scale(5),
        minHeight: theme.scale(WIDGET_DIMENSIONS.HEIGHT),
        position: 'relative',
        [theme.breakpoints.min.widget]: {
          height: WIDGET_DIMENSIONS.HEIGHT,
          maxWidth: '100%',
          width: WIDGET_DIMENSIONS.WIDTH,
          border: `1px solid ${theme.colors.background.containerFill}`,
          boxShadow: `0px 10px 25px 0px ${hex2rgba(theme.colors.background.accentDark, 0.2)}`,
          borderRadius: 5,
        },
      },
      logo: {
        userSelect: 'none',
        alignSelf: 'flex-start',
        minHeight: theme.scale(37),
        display: 'none',
        [theme.breakpoints.min.widget]: {
          display: 'unset',
        },
      },
      footer: {
        ...fontSizeMixin(FontSize.M),
        alignSelf: 'flex-start',
        color: theme.colors.text.inactive,
        display: 'none',
        [theme.breakpoints.min.widget]: {
          display: 'unset',
        },
      },
    }),
  );

export default useStyles;
