import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.scale(3)}px ${theme.scale(30)}px`,
      height: theme.scale(35),
      width: '100%',
      color: theme.colors.text.inactive,
      position: 'relative',
    },
    marginAlignment: {
      margin: 'auto auto',
      color: 'inherit',
      width: '100%',
      textAlign: 'center',
      ...fontSizeMixin(FontSize.M),
      fontWeight: 600,
    },
    button: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: theme.scale(5),
      '&:focus svg': {
        fill: theme.colors.icons.accentDark,
        outline: theme.colors.border.accentDark,
      },
    },
    backButton: {
      transform: 'rotate(90deg)',
      left: 0,
    },
    menuButton: {
      right: 0,
    },
  }),
);

export default useStyles;
