import PATHS from 'utils/navigation';

export const doesPathMatchCurrentPath = (
  path: string,
  currentPath: string,
) => {
  const indexOfQueryParamsStart = currentPath.indexOf(PATHS.QUERY_START_SYMBOL);
  const hasQueryParams = indexOfQueryParamsStart !== -1;
  const comparableCurrentPath = hasQueryParams ? currentPath.substring(
    0,
    indexOfQueryParamsStart,
  ) : currentPath;

  const pathParts = path.split(PATHS.PATH_PARTS_SEPARATOR);
  const currentPathParts = comparableCurrentPath.split(PATHS.PATH_PARTS_SEPARATOR);

  if (pathParts.length !== currentPathParts.length) {
    return false;
  }

  const doesPathMatch = pathParts.every((pathPart, index) => {
    const isPathPartAVariable = pathPart.startsWith(PATHS.PATH_VARIABLE_START_SYMBOL);
    if (isPathPartAVariable) {
      return true;
    }

    return pathPart === currentPathParts[index];
  });

  return doesPathMatch;
};

export const getPathParams = (
  path: string,
  currentPath: string,
): Record<string, string> => {
  const routeMatches = doesPathMatchCurrentPath(
    path,
    currentPath,
  );

  if (!routeMatches) {
    return {};
  }

  const indexOfQueryParamsStart = currentPath.indexOf(PATHS.QUERY_START_SYMBOL);
  const hasQueryParams = indexOfQueryParamsStart !== -1;
  const comparableCurrentPath = hasQueryParams ? currentPath.substring(
    0,
    indexOfQueryParamsStart,
  ) : currentPath;

  const pathParts = path.split(PATHS.PATH_PARTS_SEPARATOR);
  const currentPathParts = comparableCurrentPath.split(PATHS.PATH_PARTS_SEPARATOR);

  const params = pathParts.reduce((acc, pathPart, index) => {
    const isPathPartAVariable = pathPart.startsWith(PATHS.PATH_VARIABLE_START_SYMBOL);
    if (!isPathPartAVariable) {
      return acc;
    }

    const key = pathPart.substring(1, pathPart.length);
    const value = currentPathParts[index];
    return {
      ...acc,
      [key]: value,
    };
  }, {});

  return params;
};

export default doesPathMatchCurrentPath;
