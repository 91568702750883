import { DEFAULT_CURRENCIES } from 'constants/defaults';
import assetsApi from 'services/assets';
import quotesApi, { QUOTE_CACHE_KEY } from 'services/quote';
import { QuotePayload } from 'services/quote/transactions/payloads';
import { QuoteResponse } from 'services/quote/transactions/responses';
import { AppDispatch, AppStore } from 'state/store';
import { CurrencyType } from 'types/assets';
import Flow from 'types/flow';
import { Order } from 'types/order';
import PaymentMethod from 'types/payment';
import ConfigQueryParam from 'types/queryParams';
import isAssetAmountValid from 'utils/assets';
import Logger from 'utils/logger';
import getQueryParam from 'utils/queryParams';

import { getWalletAddressFromParams, getWalletTagFromParams } from './wallet';

export const getOrderFromQueryParams = async (store: AppStore, dispatch: AppDispatch): Promise<Partial<Order> | null> => {
  const forcedCryptoCodeQueryParamValue = getQueryParam(ConfigQueryParam.CryptoCode);
  const forcedCryptoAmountQueryParamValue = getQueryParam(ConfigQueryParam.CryptoAmount);
  const forcedFiatCodeQueryParamValue = getQueryParam(ConfigQueryParam.FiatCode);
  const forcedFiatAmountQueryParamValue = getQueryParam(ConfigQueryParam.FiatAmount);
  const cryptoCode = forcedCryptoCodeQueryParamValue ?? getQueryParam(ConfigQueryParam.DefaultCryptoCode);
  const cryptoAmount = forcedCryptoAmountQueryParamValue ?? getQueryParam(ConfigQueryParam.DefaultCryptoAmount);
  const fiatCode = forcedFiatCodeQueryParamValue ?? getQueryParam(ConfigQueryParam.DefaultFiatCode);
  const fiatAmount = forcedFiatAmountQueryParamValue ?? getQueryParam(ConfigQueryParam.DefaultFiatAmount);
  const side = store.getState().flow.flow === Flow.Buy ? Flow.Buy : Flow.Sell;
  const paymentMethod = getQueryParam(ConfigQueryParam.PaymentMethod) as PaymentMethod | null;

  const assetsEndpoint = side === Flow.Buy ? assetsApi.endpoints.getActiveAssets : assetsApi.endpoints.getActiveAssetsSell;
  const assetsResult = await dispatch(assetsEndpoint.initiate());
  const assets = assetsResult.data?.data.assets;
  if (!assets) {
    Logger.error('Unable to get assets for query param preset order.');
    return null;
  }
  const requestedCryptoAsset = assets.find(asset => asset.symbol === cryptoCode);
  const requestedFiatAsset = assets.find(asset => asset.symbol === fiatCode);
  if (fiatCode && !requestedFiatAsset) {
    Logger.error('Query param values for requested fiat asset is incorrect. Requested asset is not available in the list of assets.');
    return null;
  }
  if (cryptoCode && !requestedCryptoAsset) {
    Logger.error('Query param values for requested crypto asset is incorrect. Requested asset is not available in the list of assets.');
    return null;
  }

  let isRequestedFiatAmountCorrect = true;
  if (requestedFiatAsset) {
    isRequestedFiatAmountCorrect = isAssetAmountValid(requestedFiatAsset, fiatAmount);
  }
  let isRequestedCryptoAmountCorrect = true;
  if (requestedCryptoAsset) {
    isRequestedCryptoAmountCorrect = isAssetAmountValid(requestedCryptoAsset, cryptoAmount);
  }

  if (!isRequestedCryptoAmountCorrect && !isRequestedFiatAmountCorrect) {
    const invalidAsset = isRequestedCryptoAmountCorrect ? CurrencyType.Fiat : CurrencyType.Crypto;
    Logger.error(`Query param value for requested ${invalidAsset.toUpperCase()} asset amount is incorrect.`);
    return null;
  }

  const walletAddress = getWalletAddressFromParams(requestedCryptoAsset);
  const tagOrMemo = getWalletTagFromParams(requestedCryptoAsset);

  const isFiatAssetForced = Boolean(forcedFiatCodeQueryParamValue);
  const isFiatAmountForced = Boolean(forcedFiatAmountQueryParamValue);
  const isCryptoAssetForced = Boolean(forcedCryptoCodeQueryParamValue);
  const isCryptoAmountForced = Boolean(forcedCryptoAmountQueryParamValue);

  if (!fiatAmount && !cryptoAmount) {
    return {
      selectedCryptoAsset: requestedCryptoAsset,
      selectedFiatAsset: requestedFiatAsset,
      walletAddress,
      tagOrMemo,
      isFiatAssetForced,
      isCryptoAssetForced,
    };
  }

  const quoteCallDirection: Partial<QuotePayload> = fiatAmount
    ? { fiatAmount: fiatAmount! }
    : { cryptoAmount: cryptoAmount! };

  const defaultFiatAsset = requestedFiatAsset?.symbol
    ?? assets.find(({ isIpCountryCurrency }) => isIpCountryCurrency)?.symbol
    ?? DEFAULT_CURRENCIES.FIAT;
  if (!defaultFiatAsset) {
    return null;
  }

  const quoteResult = await dispatch(quotesApi.endpoints.getQuote.initiate({
    payload: {
      cryptoCurrencyCode: requestedCryptoAsset?.symbol ?? DEFAULT_CURRENCIES.CRYPTO,
      fiatCurrencyCode: defaultFiatAsset,
      side,
      paymentMethod: paymentMethod ?? undefined,
      ...quoteCallDirection,
    },
    includeToken: false,
  }, {
    fixedCacheKey: QUOTE_CACHE_KEY,
  }));

  const quote = (quoteResult as { data: QuoteResponse })?.data?.data?.quote;
  if (!quote) {
    Logger.error('Failed to get quote order based on query params.');
    dispatch(quotesApi.util.resetApiState());

    return {
      selectedCryptoAsset: requestedCryptoAsset,
      selectedFiatAsset: requestedFiatAsset,
      walletAddress,
      tagOrMemo,
      isFiatAssetForced,
      isCryptoAssetForced,
    };
  }

  return {
    selectedCryptoAsset: requestedCryptoAsset,
    selectedFiatAsset: requestedFiatAsset,
    fiatAmount: quote.fiatAmount,
    cryptoAmount: quote.cryptoAmount,
    exchangeDirection: fiatAmount ? CurrencyType.Fiat : CurrencyType.Crypto,
    walletAddress,
    tagOrMemo,
    isFiatAssetForced,
    isFiatAmountForced,
    isCryptoAssetForced,
    isCryptoAmountForced,
  };
};

export default getOrderFromQueryParams;
