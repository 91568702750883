import { useTranslation } from 'react-i18next';

import InfoIcon from 'assets/images/info.svg';
import OptionallyVisible from 'components/optionallyVisible';
import RefreshingAssetPrice from 'components/refreshingAssetPrice';
import Tooltip from 'components/tooltip';
import { formatFiatCurrencyI18n } from 'utils/i18n';

import { KEYS, LABELS } from './keys';
import { useStyles } from './quoteAdditionalInfo.styles';
import { QuoteAdditionalInfoPropsSell } from './types';

const QuoteAdditionalInfoSell = ({
  cryptocurrency,
  fiatCurrency,
  price,
  fiatAmount,
  isLoading,
  isError,
  fetchQuote,
  refetch = false,
}: QuoteAdditionalInfoPropsSell) => {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();

  const hasFiatIcon = Boolean(fiatCurrency?.iconUrl);

  return (
    <div className={classes.infoContainer}>
      <div className={classes.flexRow}>
        <div className={classes.textAndIconWrapper}>
          <div className={classes.boldFont}>
            {t(LABELS.YOU_RECEIVE)}
          </div>
          <div className={classes.iconWrapper}>
            <Tooltip
              tooltipProps={{
                className: classes.tooltip,
              }}
              content={(
                <div>
                  {t(LABELS.YOU_RECEIVE_TOOLTIP)}
                </div>
                )}
            >
              <img src={InfoIcon} alt="" />
            </Tooltip>
          </div>
        </div>
        <div className={classes.boldFont}>
          <OptionallyVisible visible={hasFiatIcon}>
            <img
              src={fiatCurrency?.iconUrl!}
              className={classes.cryptoIcon}
              alt={fiatCurrency?.name}
            />
          </OptionallyVisible>
          <span>
            {formatFiatCurrencyI18n(i18n.language)(Number(fiatAmount), fiatCurrency)}
          </span>
        </div>
      </div>
      <div className={cx(classes.flexRow, classes.smallerFont)}>
        <RefreshingAssetPrice
          reFetchIntervalInSeconds={KEYS.REFETCH_INTERVAL_SELL_IN_SECONDS}
          isError={isError}
          isLoading={isLoading}
          cryptocurrency={cryptocurrency}
          fiatCurrency={fiatCurrency}
          fetchQuote={fetchQuote}
          price={price}
          refetch={refetch}
          countdownOverride={t(LABELS.ESTIMATED_PRICE)}
        />
      </div>
    </div>
  );
};

export default QuoteAdditionalInfoSell;
