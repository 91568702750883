import { NavigationEvaluationFunc } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';
import { isFinishingOrder } from 'utils/navigation';

export const canGoBackFromTransferCrypto = (): boolean => !isFinishingOrder();

export const getTransferCryptoPreviousStep: NavigationEvaluationFunc<WorkflowStep> = (params) => {
  if (params.tokenizedCard) {
    return WorkflowStep.Checkout;
  }
  return WorkflowStep.BankDetails;
};

export default getTransferCryptoPreviousStep;
