import Spinner from 'components/spinner';

import useStyles from './styles';

const ParnterPageSpinner = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.spinnerWrapper}>
      <Spinner scale={2} />
    </div>
  );
};

export default ParnterPageSpinner;
