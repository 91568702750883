import type { AppConfig } from 'types/config';
import { Storage } from 'utils/storage';

export const generalConfig: Partial<AppConfig> = window?.s4cConfig || {};
const STORAGE_CONFIG_KEY = 's4cConfig';
let localConfig: any;
if (generalConfig.enableLocalConfig) {
  localConfig = Storage.get(STORAGE_CONFIG_KEY);
  if (!localConfig) {
    Storage.set(STORAGE_CONFIG_KEY, generalConfig);
    localConfig = generalConfig;
  }

  localConfig.save = () => {
    Storage.set(STORAGE_CONFIG_KEY, localConfig);
  };

  localConfig.clear = () => {
    Storage.remove(STORAGE_CONFIG_KEY);
  };

  window.s4cConfig = localConfig;
}

export const exportedLocalConfig = localConfig;
export const appConfig = localConfig || generalConfig;
export default exportedLocalConfig;
