export const truncateNumericValue = (numericValue: string | undefined, decimalPrecision: number): string => {
  if (!numericValue) {
    return '0';
  }

  const decimalIndex = Number(numericValue).toString().indexOf('.');
  if (decimalIndex === -1) {
    return numericValue;
  }

  if (decimalPrecision === 0) {
    return numericValue.split('.')[0];
  }

  return numericValue.slice(0, decimalIndex + decimalPrecision + 1);
};

export default truncateNumericValue;
