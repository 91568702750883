import { tss } from 'theme';
import hex2rgba from 'utils/colors/convert';

export const useStyles = tss
  .withParams<{ isClickable: boolean}>()
  .create(
    ({ theme, isClickable }) => ({
      tile: {
        display: 'flex',
        marginRight: theme.scale(5),
        alignItems: 'center',
        gap: theme.scale(12),
        paddingTop: theme.scale(6),
        paddingRight: theme.scale(6),
        paddingBottom: theme.scale(6),
        cursor: isClickable ? 'pointer' : 'default',
        borderRadius: theme.border.radius,
        '&:hover': {
          background: theme.colors.background.highlight,
        },
      },
      tileDisabled: {
        cursor: 'not-allowed',
        '&:hover': {
          background: 'unset',
        },
      },
      tileHighlighted: {
        background: isClickable ? theme.colors.background.highlight : 'unset',
      },
      cardDetailsWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      cardDetails: {
        display: 'flex',
        flexDirection: 'column',
      },
      cardName: {
        fontSize: theme.scale(14),
      },
      cardType: {
        fontSize: theme.scale(12),
        color: theme.colors.text.inactive,
      },
      expired: {
        color: theme.colors.text.disabled,
      },
      deleting: {
        paddingLeft: theme.scale(10),
        background: `${hex2rgba(theme.colors.text.error, 0.1)} !important`,
      },
      expiredBadge: {
        fontSize: theme.scale(10),
        color: theme.colors.text.error,
        padding: `${theme.scale(2)}px ${theme.scale(4)}px`,
        borderRadius: 5,
        background: hex2rgba(theme.colors.text.error, 0.1),
        textAlign: 'center',
      },
      defaultBadge: {
        fontSize: theme.scale(10),
        color: theme.colors.text.accent,
        padding: `${theme.scale(2)}px ${theme.scale(4)}px`,
        borderRadius: 5,
        background: hex2rgba(theme.colors.text.accent, 0.1),
        textAlign: 'center',
      },
      actions: {
        display: 'flex',
      },
      actionButton: {
        padding: theme.scale(2.5),
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        '& > div': {
          width: theme.scale(20),
          height: theme.scale(20),
          padding: 0,
        },
      },
      actionPlaceholder: {
        width: theme.scale(33.5),
        height: theme.scale(33.5),
      },
      confirmationWrapper: {
        display: 'flex',
        gap: 20,
      },
      confirmationButton: {
        cursor: 'pointer',
        border: 0,
        borderRadius: theme.border.radius,
        '& > div': {
          padding: 0,
        },
      },
      deleteConfirmButton: {
        background: theme.colors.background.error,
      },
      cancelConfirmationButton: {
        background: theme.colors.background.primary,
      },
    }),
  );

export default useStyles;
