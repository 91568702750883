import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .create(
    ({ theme }) => ({
      formFooter: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        ...fontSizeMixin(FontSize.XXS),
        gap: theme.scale(4),
        marginTop: theme.scale(8),
        alignItems: 'center',
        fontFamily: theme.fonts.primary,
      },
      content: {
        display: 'flex',
        color: theme.colors.text.disabled,
        justifyContent: 'center',
        gap: theme.scale(4),
        alignItems: 'center',
      },
    }),
  );

export default useStyles;
