import {
  ComponentType,
  SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';

import { Button } from 'components/button';
import { Card } from 'components/card';
import Logo from 'components/logo';
import MenuList from 'components/menuList';
import { OptionallyVisible } from 'components/optionallyVisible';
import QuoteErrorTile from 'components/quoteErrorTile';
import { SearchableList, SearchableListItemValues } from 'components/searchableList';
import { SlideModal } from 'components/slideModal';
import { Spinner } from 'components/spinner';
import { CONTACT_DATA } from 'constants/contactData';
import usePrevious from 'hooks/usePrevious';
import IconMenu from 'icons/menu';
import Shift4LogoIllustration from 'illustrations/shift4';
import { QuoteErrors } from 'services/quote/transactions/errors';
import { QuotePayload } from 'services/quote/transactions/payloads';
import {
  AssetWithLabel, CurrencyList, CurrencyType, Quote,
} from 'types/assets';
import ApiErrorCode from 'types/errors/api';
import Flow from 'types/flow';
import { Controller, ReduxToolkitApiCall } from 'types/http';
import { Order } from 'types/order';
import { QueryParam } from 'types/queryParams';
import WorkflowStep from 'types/step';
import { User } from 'types/user';
import getAvailableFlows from 'utils/flow';
import Logger from 'utils/logger';
import getQueryParam from 'utils/queryParams';
import { locators, QALocator } from 'utils/tests/QA';

import { LABELS } from './keys';
import QuoteForm from './quoteForm';
import { useStyles } from './styles';

export interface QuoteScreenProps {
  flow: Flow;
  order: Order;
  assets: {
    crypto: AssetWithLabel[];
    fiat: AssetWithLabel[];
    isLoading: boolean;
    isError: boolean;
    defaultCrypto?: AssetWithLabel;
    defaultFiat?: AssetWithLabel;
    defaultFiatAmount: string;
    defaultCryptoAmount: string;
    direction: CurrencyType;
  };
  quote: {
    details?: Quote;
    isLoading: boolean;
    isError: boolean;
    errors: {
      minAmount?: QuoteErrors[ApiErrorCode.InvalidAmount];
      maxAmount?: QuoteErrors[ApiErrorCode.InvalidAmount];
      backendErrorMessage?: string;
      isAbortError: boolean;
    };
  };
  user: {
    details?: User;
    isLoading: boolean;
    isError:boolean;
  };
  isProceedLoading: boolean;
  logoSrc?: string | null;
  proceed: () => void;
  createOrder: (cryptoAsset: AssetWithLabel, order: Order) => void;
  fetchQuote: ReduxToolkitApiCall<Quote>;
  setFlow: (flow: Flow) => void;
  QuoteBreakdown: ComponentType<any>;
  showDotIndicator?: boolean;
  step?: WorkflowStep;
}

const QuoteScreen = ({
  flow,
  assets,
  order,
  quote,
  user,
  isProceedLoading,
  logoSrc,
  proceed,
  createOrder,
  fetchQuote,
  setFlow,
  QuoteBreakdown,
  showDotIndicator,
  step,
}: QuoteScreenProps) => {
  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation();
  const [currenciesList, setCurrenciesList] = useState<CurrencyList | null>(null);
  const [isAssetsModalVisible, setIsAssetsModalVisible] = useState(false);
  const [cryptocurrency, setCryptoCurrency] = useState<AssetWithLabel | undefined>(assets.defaultCrypto);
  const [fiatCurrency, setFiatCurrency] = useState<AssetWithLabel| undefined>(assets.defaultFiat);
  const [changedAmount, setChangedAmount] = useState<CurrencyType>(assets.direction);
  const quoteControllerRef = useRef<Controller | null>(null);
  const [fiatAmount, setFiatAmount] = useState<string>();
  const [cryptoAmount, setCryptoAmount] = useState<string>();
  const [formErrors, setFormErrors] = useState<FormikErrors<{
      [CurrencyType.Fiat]: string;
      [CurrencyType.Crypto]: string;
    }>
    >({});
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const isQuoteGenericErrorVisible = quote.isError && !quote.errors.maxAmount && !quote.errors.minAmount && !quote.errors.isAbortError;
  const hasFormErrors = Object.values(formErrors).some(Boolean);
  const isQuoteDetailsVisible = [fiatAmount, !quote.isError, cryptocurrency, fiatCurrency, !hasFormErrors].every(Boolean);
  const isNextStepAllowed = !hasFormErrors
    && !quote.isError
    && !quote.isLoading
    && fiatAmount
    && cryptoAmount;

  const initialFetchDone = useRef(false);
  const flowQueryParam = getQueryParam(QueryParam.Flow);
  const isFlowQueryParamValid = Object.values(Flow).includes(flowQueryParam as Flow);
  const showFlowSwitch = Boolean(getQueryParam(QueryParam.Flow)) && isFlowQueryParamValid;
  const offRampEnabled = Boolean(window.partnerConfig?.offRampEnabled);
  const onRampEnabled = Boolean(window.partnerConfig?.onRampEnabled);
  const availableFLows = getAvailableFlows({ offRampEnabled, onRampEnabled });
  const isOnlyOneFlowAvailable = availableFLows.length === 1;
  const isPartnerConfigUnavailable = !availableFLows.length;
  // TODO: Get rid of the first parameter after offRamp kick-off.
  const shouldShowTitle = !showFlowSwitch && (isOnlyOneFlowAvailable || isPartnerConfigUnavailable);

  if (flowQueryParam && !isFlowQueryParamValid) {
    Logger.error(`Flow query param value is invalid. Value: ${flowQueryParam}. Valid values are: ${Object.values(Flow).join(', ')}`);
  }

  const makeFetchQuoteCall = async (overrideQuotePayload?: Partial<QuotePayload>) => {
    if (!cryptocurrency || !fiatCurrency) {
      return;
    }

    const quoteCallDirection: Partial<QuotePayload> = changedAmount === CurrencyType.Fiat
      ? { fiatAmount }
      : { cryptoAmount };
    const hasOverridenQuoteDirection = Boolean(overrideQuotePayload?.cryptoAmount || overrideQuotePayload?.fiatAmount);
    const defaultQuoteDirection = hasOverridenQuoteDirection ? {} : quoteCallDirection;

    const quotePayload: QuotePayload = {
      cryptoCurrencyCode: cryptocurrency.symbol,
      fiatCurrencyCode: fiatCurrency.symbol,
      ...defaultQuoteDirection,
      ...overrideQuotePayload,
    };

    const controller = fetchQuote(quotePayload);
    quoteControllerRef.current = controller;
  };

  useEffect(() => {
    const hasValuesToMakeCall = fiatCurrency && cryptocurrency && fiatAmount && cryptoAmount;
    const shouldMakeInitialQuoteCall = !initialFetchDone.current && hasValuesToMakeCall;
    if (shouldMakeInitialQuoteCall) {
      const initialCallDirection: Partial<QuotePayload> = assets.direction === CurrencyType.Fiat
        ? { fiatAmount: assets.defaultFiatAmount }
        : { cryptoAmount: assets.defaultCryptoAmount };

      makeFetchQuoteCall({
        ...initialCallDirection,
      });
      initialFetchDone.current = true;
    }
  }, [fiatCurrency, cryptocurrency, initialFetchDone.current, fiatAmount, cryptoAmount]);

  useEffect(() => () => {
    initialFetchDone.current = false;
  }, [initialFetchDone]);

  useEffect(() => () => {
    quoteControllerRef?.current?.abort();
  }, [quoteControllerRef]);

  const closeCurrenciesList = () => {
    setIsAssetsModalVisible(false);
  };

  const clearCurrenciesList = () => {
    setCurrenciesList(null);
  };

  const onAssetsModalTransitionFinish = () => {
    clearCurrenciesList();
  };

  const openCurrenciesList = (list: CurrencyList) => () => {
    setIsAssetsModalVisible(true);
    setCurrenciesList(list);
  };

  const onCurrencyChange = (handler: typeof setCryptoCurrency | typeof setFiatCurrency) => (
    _: SyntheticEvent<Element, Event>,
    value: NonNullable<string | SearchableListItemValues> | (string | SearchableListItemValues)[] | null,
  ) => {
    if (!value) {
      return;
    }
    const requestedAssetId = (value as SearchableListItemValues).id;
    const assetToSet = assets.crypto.concat(assets.fiat).find(asset => asset.id === requestedAssetId);
    const targetUnderlyingCryptoSymbol = assetToSet?.type === CurrencyType.Crypto ? assetToSet.symbol : cryptocurrency?.symbol;
    const targetUnderlyingFiatSymbol = assetToSet?.type === CurrencyType.Fiat ? assetToSet.symbol : fiatCurrency?.symbol;

    const hasForcedAssets = order.isFiatAssetForced || order.isCryptoAssetForced;
    const forcedDirection: Partial<QuotePayload> = assets.direction === CurrencyType.Fiat
      ? { fiatAmount }
      : { cryptoAmount };
    const directionPayload = hasForcedAssets ? forcedDirection : {};

    handler(assetToSet);
    makeFetchQuoteCall({
      cryptoCurrencyCode: targetUnderlyingCryptoSymbol,
      fiatCurrencyCode: targetUnderlyingFiatSymbol,
      ...directionPayload,
    });
    closeCurrenciesList();
  };

  const handleContinueClick = () => {
    if (!cryptocurrency) {
      return;
    }

    createOrder(
      cryptocurrency,
      {
        selectedCryptoAsset: cryptocurrency,
        selectedFiatAsset: fiatCurrency,
        fiatAmount,
        cryptoAmount,
        exchangeDirection: changedAmount,
      },
    );
    proceed();
  };

  const logo = logoSrc
    ? <Logo src={logoSrc} />
    : <Shift4LogoIllustration height={18} width={87} />;

  const currencyTypeListMapping = {
    [CurrencyList.Fiat]: assets.fiat,
    [CurrencyList.Crypto]: assets.crypto,
  };
  const previousCurrency = usePrevious(currenciesList);
  const targetCurrenciesList = currencyTypeListMapping[currenciesList ?? previousCurrency ?? CurrencyList.Crypto];

  const currencyChangeHandler = {
    [CurrencyList.Fiat]: onCurrencyChange(setFiatCurrency),
    [CurrencyList.Crypto]: onCurrencyChange(setCryptoCurrency),
  }[currenciesList ?? CurrencyList.Crypto];

  const assetsModalTitle = currenciesList === CurrencyList.Fiat
    ? t(LABELS.CHOOSE_FIAT_CURRENCY)
    : t(LABELS.CHOOSE_CRYPTO_CURRENCY);

  const onFlowClick = (flow: Flow) => () => {
    setFlow(flow);
  };

  const closeMenu = () => {
    setIsMenuModalVisible(false);
  };

  const openMenu = () => {
    setIsMenuModalVisible(true);
  };

  if (assets.isLoading || user.isLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner
          scale={1.25}
          {...QALocator(locators.page.quote.viewLoadingSpinner)}
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <button
          type="button"
          className={cx(classes.menuButton)}
          onClick={openMenu}
          {...QALocator(locators.components.common.menuButton)}
        >
          <IconMenu showDotIndicator={showDotIndicator} color={theme.colors.text.inactive} />
        </button>
        {logo}

        <OptionallyVisible visible={showFlowSwitch}>
          <>
            <OptionallyVisible visible={flow === Flow.Buy}>
              <div className={classes.flowButtonsWrapper}>
                <Button className={cx(classes.flowButton, classes.flowButtonActive)}>
                  {t(LABELS.BUY_CRYPTO)}
                </Button>
                <Button className={classes.flowButton} onClick={onFlowClick(Flow.Sell)}>
                  {t(LABELS.SELL)}
                </Button>
              </div>
            </OptionallyVisible>
            <OptionallyVisible visible={flow === Flow.Sell}>
              <div className={classes.flowButtonsWrapper}>
                <Button className={classes.flowButton} onClick={onFlowClick(Flow.Buy)}>
                  {t(LABELS.BUY)}
                </Button>
                <Button className={cx(classes.flowButton, classes.flowButtonActive)}>
                  {t(LABELS.SELL_CRYPTO)}
                </Button>
              </div>
            </OptionallyVisible>
          </>
        </OptionallyVisible>
        <OptionallyVisible visible={shouldShowTitle}>
          <div className={classes.flowTitle}>
            {flow === Flow.Buy ? t(LABELS.BUY_CRYPTO) : t(LABELS.SELL_CRYPTO)}
          </div>
        </OptionallyVisible>

      </div>
      <Card className={classes.card}>
        <QuoteForm
          flow={flow}
          order={order}
          assets={assets}
          quote={quote}
          setChangedAmount={setChangedAmount}
          fetchQuote={fetchQuote}
          openCurrenciesList={openCurrenciesList}
          makeFetchQuoteCall={makeFetchQuoteCall}
          setFiatAmount={setFiatAmount}
          setCryptoAmount={setCryptoAmount}
          setFormErrors={setFormErrors}
          quoteControllerRef={quoteControllerRef}
          fiatCurrency={fiatCurrency}
          setFiatCurrency={setFiatCurrency}
          cryptocurrency={cryptocurrency}
          setCryptoCurrency={setCryptoCurrency}
        />
        <OptionallyVisible visible={isQuoteGenericErrorVisible}>
          <QuoteErrorTile
            fetchQuote={makeFetchQuoteCall}
            isQuoteLoading={quote.isLoading}
            backendErrorMessage={quote.errors.backendErrorMessage}
          />
        </OptionallyVisible>
        <div>
          <OptionallyVisible visible={isQuoteDetailsVisible}>
            <QuoteBreakdown
              step={step}
              quote={quote}
              fiatCurrency={fiatCurrency!}
              cryptocurrency={cryptocurrency!}
            />
          </OptionallyVisible>
        </div>
      </Card>
      <SlideModal
        visible={isAssetsModalVisible}
        onClose={closeCurrenciesList}
        title={assetsModalTitle}
        onTransitionFinished={onAssetsModalTransitionFinish}
      >
        <SearchableList
          options={targetCurrenciesList}
          onChange={currencyChangeHandler}
          searchPlaceholderText={t(LABELS.CURRENCIES_SEARCH)}
          searchResultsLabel={t(LABELS.CURRENCIES_RESULT)}
        />
      </SlideModal>
      <div
        className={classes.marginTopAuto}
      >
        <OptionallyVisible visible={isQuoteGenericErrorVisible}>
          <div className={classes.persistingIssueNote}>
            <Trans
              i18nKey={LABELS.PERSISTING_ISSUE_NOTE}
              components={{
                a: <a href={`mailto:${CONTACT_DATA.SUPPORT_EMAIL_ADDRESS}`}>email</a>,
              }}
              values={{ email: CONTACT_DATA.SUPPORT_EMAIL_ADDRESS }}
            />
          </div>
        </OptionallyVisible>
        <Button
          isContinue
          isLoading={isProceedLoading}
          onClick={handleContinueClick}
          disabled={!isNextStepAllowed}
          {...QALocator(locators.page.quote.proceedButton)}
        >
          {t(LABELS.CONTINUE)}
        </Button>
      </div>
      <MenuList visible={isMenuModalVisible} closeMenu={closeMenu} />
    </div>
  );
};

export default QuoteScreen;
