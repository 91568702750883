import React from 'react';

import IconArrowRight from 'icons/arrowRight';
import { OrderSide } from 'types/order';
import { capitalizeFirstLetter } from 'utils/string';

import { useStyles } from './styles';

export interface OrderTypeBadgeProps {
  side: OrderSide;
  asIcon?: boolean;
}

export const OrderTypeBadge = ({ side, asIcon }: OrderTypeBadgeProps) => {
  const { classes, cx } = useStyles();
  const content = asIcon ? <IconArrowRight /> : <span>{capitalizeFirstLetter(side)}</span>;

  return (
    <div className={cx({
      [classes.buyOrderBadge]: side === OrderSide.Buy,
      [classes.sellOrderBadge]: side === OrderSide.Sell,
      [classes.orderBadge]: true,
      [classes.iconOrderBadge]: asIcon,
      [classes.rotated]: asIcon && side === OrderSide.Sell,
    })}
    >
      {content}
    </div>
  );
};
