import {
  ReactNode,
  useContext, useMemo, useRef, useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Logger from 'utils/logger';

import { CaptchaContext } from './context';

export const CaptchaProvider = ({ children }: { children: ReactNode }) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const context = useContext(CaptchaContext);
  const [isReady, setIsReady] = useState(false);

  const execute = async () => {
    let token = null;
    try {
      token = await recaptchaRef.current?.executeAsync();
    } catch (e) {
      Logger.log('Recaptcha error', e);
    }

    return token ?? null;
  };

  const reset = () => {
    recaptchaRef.current?.reset();
  };

  const contextValue = useMemo(() => ({
    isReady,
    execute,
    reset,
  }), [recaptchaRef.current]);

  if (!window?.s4cConfig?.reCaptchaSiteKey) {
    Logger.error('No recaptcha key provided');

    return null;
  }
  const setCaptchaReady = () => {
    // @ts-ignore grecaptcha has "object" type
    recaptchaRef.current?.props.grecaptcha?.ready(() => {
      setIsReady(true);
      context.isReady = true;
    });
  };

  return (
    <CaptchaContext.Provider value={contextValue}>
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={window.s4cConfig.reCaptchaSiteKey}
        asyncScriptOnLoad={setCaptchaReady}
      />
    </CaptchaContext.Provider>
  );
};

export default CaptchaProvider;
