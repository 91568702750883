import { ThemeMode } from 'types/config';
import ConfigQueryParam from 'types/queryParams';
import getQueryParam from 'utils/queryParams';

export const isDarkMode = () => {
  const themeMode = getQueryParam(ConfigQueryParam.ThemeMode) ?? window.partnerConfig?.widgetThemeMode ?? ThemeMode.Browser;
  const isDarkPreferred = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isBrowserMode = themeMode === ThemeMode.Browser;
  if (isDarkPreferred && isBrowserMode) {
    return true;
  }

  return themeMode === ThemeMode.Dark;
};

export const isLandingPageDarkMode = () => {
  const themeMode = window.partnerConfig?.pageThemeMode ?? ThemeMode.Browser;
  const isDarkPreferred = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isBrowserMode = themeMode === ThemeMode.Browser;
  if (isDarkPreferred && isBrowserMode) {
    return true;
  }

  return themeMode === ThemeMode.Dark;
};

export default isDarkMode;
