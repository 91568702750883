import {
  Dispatch, SetStateAction, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import FormFieldError from 'components/form/common/fieldError';
import OptionallyVisible from 'components/optionallyVisible';
import Spinner from 'components/spinner';
import IconHistory from 'icons/history';
import IconShield from 'icons/shield';
import maskContent from 'libs/sentry/mask';
import { CardPaymentToken } from 'types/card';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { Shift4Error, Shift4ErrorType } from 'types/errors/shift4';
import { CardBrand } from 'types/transactions';
import { User } from 'types/user';
import { locators, QALocator } from 'utils/tests/QA';

import { getCardGenericMessage, getCardValidationMessage } from './cardMessages';
import KEYS, { ERROR_CODES_TO_FORM_FIELDS_MAPPING, FormField, LABELS } from './keys';
import useStyles, { getShift4GlobalStyles } from './styles';
import useShift4Components from './useShift4Components';

interface CardInputsProps {
  shift4PublicKey?: string;
  merchantId?: string;
  showErrorScreen: (errorType: ScreenErrorType, isFatalError?: boolean) => void;
  setTokenizedCard: (tokenizedCard: CardPaymentToken, rememberCard: boolean, setAsDefault: boolean) => void;
  onContinue: () => void;
  finishing: boolean;
  setIsFinishing: Dispatch<SetStateAction<boolean>>;
  setGenericError: Dispatch<SetStateAction<string | null>>;
  setFormLoaded: Dispatch<SetStateAction<boolean>>;
  user?: User;
  rememberCard: boolean;
  setAsDefault: boolean;
  setIsCardBrandAllowed: Dispatch<SetStateAction<boolean>>;
}

const isCardValidationError = (error: unknown): error is Shift4Error => (error as Shift4Error).type === Shift4ErrorType.ValidationError
  || (error as Shift4Error).type === Shift4ErrorType.CardError;

const isGenericError = (error: unknown): error is Shift4Error => (error as Shift4Error).type === Shift4ErrorType.InvalidRequest
  || (error as Shift4Error).type === Shift4ErrorType.GatewayError
  || (error as Shift4Error).type === Shift4ErrorType.RateLimitError;

export const CardInputs = ({
  shift4PublicKey,
  merchantId,
  showErrorScreen,
  setTokenizedCard,
  onContinue,
  finishing,
  setIsFinishing,
  setGenericError,
  setFormLoaded,
  user,
  rememberCard,
  setAsDefault,
  setIsCardBrandAllowed,
}: CardInputsProps) => {
  const {
    classes, css, cx, theme,
  } = useStyles();
  const { t } = useTranslation();

  const {
    components, mounted, shift4Utils,
  } = useShift4Components(shift4PublicKey, merchantId);

  const form = useFormik({
    initialValues: {
      [FormField.CardNumber]: '',
      [FormField.ExpiryDate]: '',
      [FormField.CardCvc]: '',
    },
    onSubmit: () => {},
  });

  const tokenize = async (rememberCard: boolean) => {
    if (!shift4Utils) {
      return false;
    }

    try {
      const additionalData = user?.firstName
        ? { cardholderName: [user.firstName, user.lastName].filter(Boolean).join(' ') }
        : undefined;
      const token: CardPaymentToken & { error?: Shift4Error } = await shift4Utils.createToken(components.current, additionalData);
      if (token?.error) {
        throw token.error;
      }

      const isCardBrandAllowed = [CardBrand.Visa, CardBrand.MasterCard, CardBrand.Maestro].includes(token.brand);

      if (!isCardBrandAllowed) {
        setIsCardBrandAllowed(false);
        return false;
      }

      setTokenizedCard(token, rememberCard, setAsDefault);
    } catch (e) {
      if (isGenericError(e)) {
        form.setErrors({});
        const msg = getCardGenericMessage(e.type, t);
        form.setStatus(msg);
        setGenericError(msg);

        return false;
      }

      if (isCardValidationError(e)) {
        form.setStatus(undefined);
        form.setErrors({});

        const fieldName = ERROR_CODES_TO_FORM_FIELDS_MAPPING[e.code];
        const errorText = getCardValidationMessage(e.code, t);

        if (fieldName) {
          form.setFieldError(fieldName, errorText);
        }

        return false;
      }

      showErrorScreen(ScreenErrorType.CardTokenizationFailed);
      return false;
    } finally {
      setIsFinishing(false);
    }

    return true;
  };

  const continuePayment = async (rememberCard: boolean) => {
    const cardTokenizationSuccess = await tokenize(rememberCard);
    if (cardTokenizationSuccess) {
      onContinue();
    }
  };

  useEffect(() => {
    if (finishing) {
      continuePayment(rememberCard);
    }
  }, [finishing]);

  useEffect(() => {
    if (mounted) {
      setFormLoaded(true);
    }
  }, [mounted]);

  return (
    <>
      <OptionallyVisible visible={!mounted}>
        <div className={classes.loadingOverlay}>
          <Spinner
            color={theme.colors.spinner.inactive}
            {...QALocator(locators.page.bankDetails.spinner)}
          />
        </div>
      </OptionallyVisible>
      <div
        id={KEYS.SHIFT4.FORM_IDENTIFIER}
        className={cx(css(getShift4GlobalStyles(theme)), classes.formItemsWrapper)}
      >
        <div className={classes.formItem}>
          <label className={classes.label} htmlFor={KEYS.SHIFT4.CARD_NUMBER}>
            {t(LABELS.CARD_NUMBER)}
          </label>
          <div className={classes.shift4InputWrapper}>
            <div
              id={KEYS.SHIFT4.CARD_NUMBER}
              data-shift4={KEYS.SHIFT4.CARD_NUMBER}
              className={cx(
                classes.shift4InputField,
                {
                  [classes.formItemInvalid]: Boolean(form.errors[FormField.CardNumber]),
                },
              )}
              {...maskContent()}
            />
          </div>
          <FormFieldError
            errorText={form.errors[FormField.CardNumber]}
            {...QALocator(locators.page.bankDetails.cardNumberError)}
          />
        </div>
        <div className={classes.inline}>
          <div className={classes.formItem}>
            <label className={classes.label} htmlFor={KEYS.SHIFT4.CARD_EXPIRY_DATE}>
              {t(LABELS.EXPIRY)}
            </label>
            <div className={classes.shift4InputWrapper}>
              <div
                id={KEYS.SHIFT4.CARD_EXPIRY_DATE}
                data-shift4={KEYS.SHIFT4.CARD_EXPIRY_DATE}
                className={cx(
                  classes.shift4InputField,
                  classes.expiryDateField,
                  {
                    [classes.formItemInvalid]: Boolean(form.errors[FormField.ExpiryDate]),
                  },
                )}
                {...maskContent()}
              >
                <IconHistory />
              </div>
            </div>
          </div>
          <div className={classes.formItem}>
            <label className={classes.label} htmlFor={KEYS.SHIFT4.CARD_CVC}>
              {t(LABELS.CVC)}
            </label>
            <div className={classes.shift4InputWrapper}>
              <div
                id={KEYS.SHIFT4.CARD_CVC}
                data-shift4={KEYS.SHIFT4.CARD_CVC}
                className={cx(
                  classes.shift4InputField,
                  classes.cvcField,
                  {
                    [classes.formItemInvalid]: Boolean(form.errors[FormField.CardCvc]),
                  },
                )}
                {...maskContent()}
              >
                <IconShield />
              </div>
            </div>
          </div>
        </div>
        <FormFieldError
          errorText={form.errors[FormField.ExpiryDate]}
          {...QALocator(locators.page.bankDetails.expiryDateError)}
        />
        <FormFieldError
          errorText={form.errors[FormField.CardCvc]}
          {...QALocator(locators.page.bankDetails.cardCvcError)}
        />
      </div>
    </>
  );
};

export default CardInputs;
