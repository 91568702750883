import { DEFAULT_CURRENCIES } from 'constants/defaults';
import { Asset, AssetWithLabel } from 'types/assets';

const isDefaultCryptoAsset = (asset: Asset) => asset.symbol === DEFAULT_CURRENCIES.CRYPTO;
const isDefaultFiatAsset = (asset: Asset) => asset.isIpCountryCurrency;
const isFallbackFiatAsset = (asset: Asset) => asset.symbol === DEFAULT_CURRENCIES.FIAT;

export const getFallbackCryptoAsset = (
  allAssets: AssetWithLabel[],
  filteredAssets: AssetWithLabel[],
) => {
  if (!filteredAssets.length) {
    return allAssets?.find(isDefaultCryptoAsset) ?? allAssets?.[0];
  }

  return filteredAssets.find(isDefaultCryptoAsset) ?? filteredAssets?.[0];
};

export const getFallbackFiatAsset = (
  allAssets: AssetWithLabel[],
  filteredAssets: AssetWithLabel[],
) => {
  if (!filteredAssets.length) {
    return allAssets?.find(isDefaultFiatAsset) ?? allAssets?.find(isFallbackFiatAsset) ?? allAssets?.[0];
  }

  return filteredAssets.find(isDefaultFiatAsset) ?? filteredAssets?.find(isFallbackFiatAsset) ?? filteredAssets?.[0];
};
