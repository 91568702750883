import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    header: {
      padding: `${theme.scale(3)}px 0px`,
      height: theme.scale(47),
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.scale(5),
    },
    flowButton: {
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      color: theme.colors.text.disabled,
      fontSize: theme.scale(14),
      fontWeight: 600,
      whiteSpace: 'nowrap',
      '& > div': {
        padding: 'unset',
      },
      '&:hover': {
        color: theme.colors.text.accentDark,
      },
    },
    flowButtonActive: {
      color: theme.colors.text.accentDark,
      background: theme.colors.background.highlight,
      padding: theme.scale(8),
      borderRadius: theme.border.radius,
    },
    flowButtonsWrapper: {
      display: 'flex',
      marginLeft: 'auto',
    },
    logo: {
      maxWidth: theme.scale(87),
      userSelect: 'none',
    },
    label: {
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
    },
    formItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
    },
    currencyButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      borderRadius: theme.border.radius,
      ...fontSizeMixin(FontSize.L),
      fontWeight: 600,
      '&:hover': {
        background: theme.colors.background.highlight,
      },
      '&:disabled': {
        cursor: 'not-allowed',
        '&:hover': {
          background: 'none',
        },
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.scale(6),
        padding: theme.scale(6),
      },
    },
    currencyButtonArrow: {
      fill: theme.colors.text.inactive,
      transform: 'rotate(90deg)',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(12),
    },
    currencyInput: {
      background: theme.colors.background.primary,
      '& > input': {
        ...fontSizeMixin(FontSize.XXL),
        fontWeight: 600,
      },
    },
    marginTopAuto: {
      marginTop: 'auto',
    },
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    quoteMinorInfo: {
      marginTop: theme.scale(-5),
      minHeight: theme.scale(15),
      ...fontSizeMixin(FontSize.S),
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        color: theme.colors.text.inactive,
        fill: theme.colors.text.inactive,
      },
      '& span:first-of-type': {
        order: 1,
      },
    },
    formItemError: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.error,
    },
    currencyIcon: {
      width: theme.scale(20),
      height: theme.scale(20),
      borderRadius: 10,
    },
    persistingIssueNote: {
      ...fontSizeMixin(FontSize.S),
      textAlign: 'center',
      marginBottom: theme.scale(5),
    },
    menuButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.scale(4),
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: theme.scale(0),
      '&:focus svg': {
        fill: theme.colors.icons.accentDark,
        outline: theme.colors.border.accentDark,
      },
    },
    flowTitle: {
      marginLeft: 'auto',
      padding: theme.scale(8),
      color: theme.colors.text.disabled,
      fontSize: theme.scale(14),
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
  }),
);

export default useStyles;
