import ConfigQueryParam from 'types/queryParams';
import getQueryParam from 'utils/queryParams';

export const checkIfShouldSkipQuoteStep = () => Boolean(window.preloadedConfig?.skipQuoteScreen);

export const checkIfShouldSkipWalletStep = (): boolean => {
  const showWalletStep = getQueryParam(ConfigQueryParam.ShowWalletAddressForm) === 'true';
  if (showWalletStep) {
    return false;
  }

  return Boolean(window.preloadedConfig?.skipWalletScreen);
};
