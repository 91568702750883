import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
      height: '100%',
    },
    header: {
      padding: '3px 10px',
      height: theme.scale(47),
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      maxWidth: theme.scale(87),
      userSelect: 'none',
    },
    label: {
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
    },
    formItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
    },
    countrySelectButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      borderRadius: theme.border.radius,
      ...fontSizeMixin(FontSize.L),
      fontWeight: 600,
      '&:hover': {
        background: theme.colors.background.highlight,
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.scale(6),
        padding: theme.scale(6),
      },
    },
    countryIcon: {
      width: theme.scale(16),
      height: theme.scale(16),
      borderRadius: 8,
    },
    countryIconDropdown: {
      width: theme.scale(20),
      height: theme.scale(20),
      borderRadius: 10,
    },
    currencyButtonArrow: {
      fill: theme.colors.text.inactive,
      transform: 'rotate(90deg)',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(12),
      alignItems: 'center',
    },
    countryInput: {
      '&.Mui-disabled': {
        backgroundColor: theme.colors.background.primary,
      },
      '& > input': {
        ...fontSizeMixin(FontSize.M),
        fontWeight: 600,
        color: theme.colors.text.primary,
      },
    },
    proceedButtonWrapper: {
      marginTop: 'auto',
    },
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    cryptoInput: {
      background: theme.colors.background.primary,
    },
    formItemError: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.error,
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.scale(15),
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
  }),
);

export default useStyles;
