import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = 'M4.11057 1.07153L6.53906 3.50003M6.53906 3.50003L4.11057 5.92852M6.53906 3.50003L1.4613 3.50003';

export const IconArrowRight = ({
  width = 8,
  height = 7,
  color,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
        d={path}
      />
    </svg>
  );
};
export default IconArrowRight;
