import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Shift4UtilsContext } from 'components/shift4Context';
import { Shift4Utils } from 'types/card';
import Logger from 'utils/logger';

import KEYS from './keys';
import useStyles, { getShift4ComponentsStyles } from './styles';

export const useShift4Components = (
  publicKey: string | undefined,
  merchantId: string | undefined,
) => {
  const { theme } = useStyles();
  const shift4UtilsCtx = useContext(Shift4UtilsContext);
  const [mounted, setMounted] = useState(false);
  const [utils, setUtils] = useState<Shift4Utils | null>(null);
  const components = useRef(null);
  const { shift4Utils } = shift4UtilsCtx;

  const waitForCardFieldsToMount = async () => Promise.all([
    KEYS.SHIFT4.CARD_NUMBER,
    KEYS.SHIFT4.CARD_EXPIRY_DATE,
    KEYS.SHIFT4.CARD_CVC,
  ].map(async (fieldSelector) => {
    const fieldDOMElement = document.getElementById(fieldSelector);
    const iframe = fieldDOMElement?.querySelector('iframe');
    if (iframe) {
      const fieldLoadedPromise = new Promise((resolve) => {
        iframe.onload = () => {
          resolve(true);
        };
      });

      await fieldLoadedPromise;
    }
  }));

  const mountForm = async (utils: Shift4Utils | null) => {
    if (!utils) {
      return;
    }

    const customStyles = getShift4ComponentsStyles(theme);
    try {
      const shift4Components = utils
        .createComponentGroup(customStyles)
        .automount(`#${KEYS.SHIFT4.FORM_IDENTIFIER}`);
      components.current = shift4Components;
    } catch (e) {
      Logger.error(e);
      return;
    }

    await waitForCardFieldsToMount();
    setMounted(true);
  };

  useEffect(() => {
    if (!publicKey || !merchantId) {
      return;
    }
    shift4UtilsCtx.shift4Utils = shift4Utils ?? window.Shift4?.(publicKey, { merchantId }) ?? null;
    setUtils(shift4UtilsCtx.shift4Utils);
    if (!shift4UtilsCtx.shift4Utils) {
      Logger.warn('Card form utils not available');
      return;
    }

    mountForm(shift4UtilsCtx.shift4Utils);
  }, [publicKey, merchantId]);

  return {
    mounted,
    components,
    shift4Utils: utils,
  };
};

export default useShift4Components;
