import { useThemeContext } from 'theme';
import { QALocator } from 'utils/tests/QA';

import { IconProps } from './types';

const path = [
  'M7.50033 1.66666C4.27449 1.66666 1.66699 4.27416',
  '1.66699 7.49999C1.66699 10.7258 4.27449 13.3333',
  '7.50033 13.3333C10.7262 13.3333 13.3337 10.7258',
  '13.3337 7.49999C13.3337 4.27416 10.7262 1.66666 7.50033',
  '1.66666ZM10.417 9.59416L9.59449 10.4167L7.50033 8.32249L5.40616',
  '10.4167L4.58366 9.59416L6.67783 7.49999L4.58366 5.40582L5.40616',
  '4.58332L7.50033 6.67749L9.59449 4.58332L10.417 5.40582L8.32283',
  '7.49999L10.417 9.59416Z',
].join(' ');

interface IconCloseCircleProps extends IconProps {
  disabled?: boolean;
}

export const IconCloseCircle = ({
  width = 15,
  height = 15,
  className,
  disabled,
  ...otherProps
}: IconCloseCircleProps) => {
  const { theme } = useThemeContext();
  const iconColor = disabled ? theme.colors.text.disabled : theme.colors.icons.error;

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...QALocator(otherProps['data-cy'])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
        fill={iconColor}
      />
    </svg>
  );
};
export default IconCloseCircle;
