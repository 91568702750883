import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import countriesApi, { CountryItem } from 'services/countries';
import userApi from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import { changeStep } from 'state/slices/stepSlice';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { WorkflowStep } from 'types/step';
import { User } from 'types/user';

import CountryCheckScreen from '.';

export const CountryCheckContainer = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const [updateUser, { isLoading: isUpdatingUserCountry, error: updateCountryError }] = userApi.useUpdateUserMutation();
  const { data: countries, isLoading: isGetCountriesLoading, error: getCountriesError } = countriesApi.useGetCountriesQuery();
  const { data: user, isLoading: isUserLoading, error: userError } = userApi.useGetUserInfoQuery(authToken ?? '');
  const [preselectedCountry, setPreselectedCountry] = useState<CountryItem | null>(null);
  const { proceed, goBack } = useNavigation();

  const showErrorScreen = (errorType: ScreenErrorType) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, goBackToScreen: WorkflowStep.Quote }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const updateUserCountry = (country: CountryItem) => {
    let additionalPayload: Partial<User> = {};
    if (country.isSupported) {
      additionalPayload = {
        notifyWhenServiceAvailable: false,
      };
    }

    return updateUser({
      authToken: authToken ?? '',
      user: {
        country: country.isoAlpha2,
        ...additionalPayload,
      },
    });
  };

  useEffect(() => {
    if (updateCountryError) {
      showErrorScreen(ScreenErrorType.UnableToUpdateUserCountry);
    }
  }, [updateCountryError]);

  useEffect(() => {
    if (getCountriesError) {
      showErrorScreen(ScreenErrorType.UnableToGetCountriesList);
    }
  }, [getCountriesError]);

  useEffect(() => {
    if (userError) {
      showErrorScreen(ScreenErrorType.UserStatus);
    }
  }, [userError]);

  useEffect(() => {
    if (!countries) {
      return;
    }

    const userIpCountry = countries.data.countries.find(
      countryItem => countryItem.isoAlpha2 === user?.data?.ipCountry,
    );

    const countryToPreselect = userIpCountry ?? countries.data.countries[0];

    setPreselectedCountry(countryToPreselect);
  }, [countries?.data?.countries, user?.data?.ipCountry]);

  const onCountryUpdate = async (country: CountryItem | null) => {
    if (!country?.isoAlpha2) {
      return;
    }

    const result = await updateUserCountry(country);

    if (!('error' in result)) {
      proceed({ user: result.data?.data });
    }
  };

  return (
    <CountryCheckScreen
      isUpdatingUserCountry={isUpdatingUserCountry}
      isViewLoading={isGetCountriesLoading || isUserLoading}
      countries={countries?.data.countries}
      preselectedCountry={preselectedCountry}
      goBack={goBack}
      onCountryUpdate={onCountryUpdate}
    />
  );
};

export default CountryCheckContainer;
