import { Trans, useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardRow from 'components/card/row';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import Spinner from 'components/spinner';
import { OrderCompletionResponse } from 'services/payments/types';
import { Asset } from 'types/assets';
import { Transaction } from 'types/transactions';
import { convertToTitleCase, formatCryptoAmountWithSymbolAtEnd } from 'utils/currencies';

import KEYS, { LABELS } from './keys';
import useStyles from './styles';

interface AwaitingCryptoProps {
  order?: OrderCompletionResponse | Transaction;
  orderAsset?: Asset;
  canGoBack: boolean;
  goBack: () => void;
}

const AwaitingCrypto = ({
  order,
  orderAsset,
  canGoBack,
  goBack,
}: AwaitingCryptoProps) => {
  const { classes, theme } = useStyles();
  const cryptoAmount = (order as OrderCompletionResponse | undefined)?.quote?.cryptoAmount
    ?? (order as Transaction | undefined)?.cryptoAmount;
  const { t } = useTranslation();

  const tagOrMemoLabel = orderAsset?.tagName === KEYS.DESTINATION_TAG_API_FIELD_NAME
    ? t(LABELS.DESTINATION_TAG)
    : t(LABELS.MEMO);

  return (
    <div className={classes.container}>
      <Header hasBackButton={canGoBack} onBackClick={goBack}>
        {t(LABELS.PROCESSING)}
      </Header>
      <div className={classes.content}>
        <div className={classes.stepDescriptionWrapper}>
          <Spinner scale={2.3} color={theme.colors.spinner.accentDark} />
          <div className={classes.stepDescription}>
            <Trans i18nKey={LABELS.NOTE} components={{ br: <br /> }} />
          </div>
        </div>
        <Card className={classes.card}>
          {t(LABELS.CARD_TITLE)}
          <OptionallyVisible visible={Boolean(order && orderAsset?.symbol && cryptoAmount)}>
            <CardRow
              isCopyable
              textToCopy={cryptoAmount}
              label={t(LABELS.WALLET)}
            >
              {formatCryptoAmountWithSymbolAtEnd(cryptoAmount!, orderAsset!, { truncate: false })}
            </CardRow>
          </OptionallyVisible>
          <OptionallyVisible visible={Boolean(order?.depositWalletAddress && orderAsset?.chainCode)}>
            <CardRow
              isCopyable
              iconStart={(
                <OptionallyVisible visible={Boolean(orderAsset?.iconUrl)}>
                  <img className={classes.networkIcon} src={orderAsset!.iconUrl!} alt={t(LABELS.NETWORK_IMAGE_ALT)} />
                </OptionallyVisible>
              )}
              textToCopy={order!.depositWalletAddress!}
              label={
                t(
                  LABELS.DEPOSIT_ADDRESS,
                  { network: convertToTitleCase(orderAsset!.chainCode) },
                )
              }
            >
              {order?.depositWalletAddress}
            </CardRow>
          </OptionallyVisible>
          <OptionallyVisible visible={Boolean(order?.depositWalletTag)}>
            <CardRow
              isCopyable
              textToCopy={order!.depositWalletTag!}
              label={tagOrMemoLabel}
            >
              {order?.depositWalletTag}
            </CardRow>
          </OptionallyVisible>
        </Card>
      </div>
    </div>
  );
};

export default AwaitingCrypto;
