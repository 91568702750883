import { SavedCard } from 'services/payments/types';
import { Asset } from 'types/assets';
import { CardPaymentToken, Shift4Utils } from 'types/card';
import { verify3DS } from 'utils/shift4';

import { PaymentOutcome } from '../types';

interface PayWithCardParams {
  shift4Utils: Shift4Utils;
  amount: number;
  asset: Asset;
  tokenizedCard: CardPaymentToken | SavedCard;
}

const payWithCard = async ({
  shift4Utils,
  amount,
  asset,
  tokenizedCard,
}: PayWithCardParams): Promise<PaymentOutcome> => {
  try {
    const threeDSVerifiedToken = await verify3DS(shift4Utils, tokenizedCard, amount, asset);

    if (threeDSVerifiedToken.error) {
      throw threeDSVerifiedToken.error;
    }

    return ({
      paymentToken: threeDSVerifiedToken.id,
    });
  } catch (e) {
    return ({
      error: 'Error while verifying 3D Secure token.',
    });
  }
};

export default payWithCard;
