import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .withParams<{ listWrapperHeight?: number }>()
  .create(
    ({ theme, listWrapperHeight }) => {
      const categoryLabelHeight = theme.scale(10);
      const listHeight = listWrapperHeight ? listWrapperHeight - categoryLabelHeight : '100%';

      return ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.scale(8),
          height: '100%',
        },
        listWrapper: {
          height: '100%',
        },
        list: {
          marginTop: 'auto',
          maxHeight: listHeight,
          overflow: 'auto',
          margin: 'unset',
          padding: 'unset',
          '&::-webkit-scrollbar': {
            width: theme.scale(5),
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: theme.scale(5),
            background: theme.colors.text.inactive,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: theme.colors.background.accentDark,
          },
        },
        linkWrapper: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.scale(8),

          a: {
            width: 'auto',
          },
        },
        linkDivider: {
          color: theme.colors.text.inactive,
          paddingBottom: theme.scale(10),
        },
        link: {
          ...fontSizeMixin(FontSize.S),
          color: theme.colors.text.inactive,
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: theme.scale(10),
        },
        message: {
          textAlign: 'center',
          color: theme.colors.text.inactive,
          paddingRight: theme.scale(12),
          ...fontSizeMixin(FontSize.S),
        },
      });
    },
  );

export default useStyles;
