import { useEffect, useState } from 'react';

import PATHS from 'utils/navigation';

export const usePathnameChangeDetector = () => {
  const [currentPath, setCurrentPath] = useState<string>(window.location.pathname || PATHS.DEFAULT_PATH);
  const handlePathChange = () => {
    setCurrentPath(window.location.pathname || PATHS.DEFAULT_PATH);
  };

  useEffect(() => {
    window.addEventListener(PATHS.POP_STATE_EVENT, handlePathChange);
    return () => {
      window.removeEventListener(PATHS.POP_STATE_EVENT, handlePathChange);
    };
  });

  return {
    currentPath,
  };
};

export default usePathnameChangeDetector;
