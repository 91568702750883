import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import Error from 'pages/error';
import { ScreenErrorType } from 'types/errors/errorScreen';

export const KycRejectedContainer = () => {
  const { goBack } = useNavigation();
  const error = getErrorScreenMessage(ScreenErrorType.KycRejected);

  return (
    <Error
      error={error}
      goBack={goBack}
    />
  );
};

export default KycRejectedContainer;
