import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    genericError: {
      color: theme.colors.text.error,
      ...fontSizeMixin(FontSize.S),
    },
    errorTile: {
      gap: theme.scale(12),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    separator: {
      width: '100%',
      height: theme.scale(1),
      background: theme.colors.background.inactive,
    },
    refreshButton: {
      color: theme.colors.text.accentDark,
      border: 'none',
      cursor: 'pointer',
      background: 'none',
      '& > div': {
        display: 'flex',
        gap: theme.scale(8),
      },
    },
  }),
);

export default useStyles;
