const KEYS = {
  DESTINATION_TAG_API_FIELD_NAME: 'destination_tag',
  QR_CODE_SIZE: 72,
  POLLING_INTERVAL: 5000,
};

const MESSAGES_NAMESPACE = 'transferCrypto';
export const LABELS = {
  DESTINATION_TAG: `${MESSAGES_NAMESPACE}.destinationTag`,
  MEMO: `${MESSAGES_NAMESPACE}.memo`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  STEP_DESCRIPTION: `${MESSAGES_NAMESPACE}.stepDescription`,
  EXPECTED: `${MESSAGES_NAMESPACE}.expected`,
  NETWORK_IMAGE_ALT: `${MESSAGES_NAMESPACE}.networkImageAlt`,
  DEPOSIT_ADDRESS: `${MESSAGES_NAMESPACE}.depositAddress`,
  NOTE: `${MESSAGES_NAMESPACE}.note`,
  PROCEED: `${MESSAGES_NAMESPACE}.proceed`,
};

export default KEYS;
