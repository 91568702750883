import { AnyAction } from '@reduxjs/toolkit';

import authenticationApi from 'services/authentication';
import userApi from 'services/user';
import { WidgetEvent } from 'types/events';
import { KycStatus } from 'types/user';
import emitEvent from 'utils/events';

import { SideEffectsMiddlewareHandler } from './types';

let kycStatus: KycStatus | undefined;

const checkIfKycStatusUpdated = (action: AnyAction) => {
  const isFulfilledLoginQuery = authenticationApi.endpoints.passwordlessOtp.matchFulfilled(action);
  if (isFulfilledLoginQuery) {
    kycStatus = undefined;
  }

  const isFulfilledUserInfoQuery = userApi.endpoints.getUserInfo.matchFulfilled(action);
  const isKycStatusUpdated = action.payload?.data?.kycStatus !== undefined && kycStatus !== action.payload?.data?.kycStatus;
  const shouldEmitKycStatusUpdateEvent = kycStatus !== undefined && isFulfilledUserInfoQuery && isKycStatusUpdated;

  if (isKycStatusUpdated) {
    kycStatus = action.payload.data.kycStatus;
  }

  return shouldEmitKycStatusUpdateEvent;
};

const kycStatusUpdateHandler: SideEffectsMiddlewareHandler = (_, action) => {
  const kycUpdated = checkIfKycStatusUpdated(action);
  if (kycUpdated && kycStatus) {
    emitEvent({
      eventType: WidgetEvent.KycStatusUpdate,
      payload: {
        kycStatus,
      },
    });
  }
};

export const kycMiddlewareHandlers: SideEffectsMiddlewareHandler[] = [
  kycStatusUpdateHandler,
];

export default kycMiddlewareHandlers;
