import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import Header from 'components/header';
import LoadingWrapper from 'components/loading';
import Spinner from 'components/spinner';
import CardIllustration from 'illustrations/card';
import PaymentMethod from 'types/payment';

import LABELS from './keys';
import useStyles from './styles';
import Tile from './tile';

interface PaymentMethodsProps {
  paymentMethods: PaymentMethod[];
  onPaymentMethodClick: (method: PaymentMethod) => void;
  isLoading: boolean;
  goBack: () => void;
  errorMessage?: string;
}

const PaymentMethods = ({
  paymentMethods,
  onPaymentMethodClick,
  isLoading,
  goBack,
  errorMessage,
}: PaymentMethodsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const content = errorMessage
    ? <div className={classes.error}>{ errorMessage }</div>
    : (
      <div className={classes.tilesWrapper}>
        {paymentMethods.map(method => (
          <Tile
            key={method}
            method={method}
            onClick={() => onPaymentMethodClick(method)}
          />
        ))}
      </div>
    );

  return (
    <div className={classes.container}>
      <Header
        hasBackButton
        onBackClick={goBack}
      >
        {t(LABELS.HEADER)}
      </Header>
      <div className={classes.imageWrapper}>
        <CardIllustration />
        {t(LABELS.CHOOSE_PAYMENT_METHOD)}
      </div>
      <Card>
        <LoadingWrapper
          isLoading={isLoading}
          loadingIndicator={(
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
        )}
        >
          {content}
        </LoadingWrapper>
      </Card>
    </div>
  );
};

export default PaymentMethods;
