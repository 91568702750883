import { QALocateableElementProps, QALocator } from 'utils/tests/QA';

import useStyles from './styles';

export type FormFieldErrorProps = {
  errorText?: string | JSX.Element;
} & QALocateableElementProps;

const FormFieldError = ({ errorText, ...otherProps }: FormFieldErrorProps) => {
  const { classes } = useStyles();

  if (!errorText) {
    return null;
  }

  return (
    <div
      className={classes.formItemError}
      {...QALocator(otherProps['data-cy'])}
    >
      {errorText}
    </div>
  );
};

export default FormFieldError;
