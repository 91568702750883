import { tss } from 'theme';

export const useStyles = tss
  .create(
    ({ theme }) => ({
      copyButton: {
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        padding: 0,
        '& > div': {
          padding: 0,
        },
      },
      copiedText: {
        color: theme.colors.text.inactive,
        minHeight: 19,
      },
    }),
  );

export default useStyles;
