import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      border: `0.5px solid ${theme.colors.text.accentDark}`,
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      width: '100%',
      display: 'flex',
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
      padding: `${theme.scale(10)}px ${theme.scale(12)}px`,
    },
    content: {
      width: '95%',
    },
    closeIcon: {
      display: 'inline-block',
      position: 'relative',
      top: '-2px',
      fill: theme.colors.text.accentDark,
      height: '100%',
    },
  }),
);

export default useStyles;
