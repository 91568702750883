import { createApi } from '@reduxjs/toolkit/query/react';

import fetchBaseQueryWithCustomHeaders from 'services/api';
import { Endpoint } from 'services/endpoints';
import HTTPMethod from 'types/http';

import {
  PasswordlessOtpPayload,
  PasswordlessOtpResponse,
  PasswordlessStartPayload,
  PasswordlessStartResponse,
} from './types';

const REDUCER_PATH = 'authenticationApi';

export const PASSWORDLESS_START_CACHE_KEY = 'passwordlessStart';

export const authenticationApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(),
  endpoints: builder => ({
    passwordlessStart: builder.mutation<PasswordlessStartResponse, PasswordlessStartPayload>({
      query: payload => ({
        body: payload,
        url: Endpoint.PasswordlessStart,
        method: HTTPMethod.POST,
      }),
    }),
    passwordlessOtp: builder.mutation<PasswordlessOtpResponse, PasswordlessOtpPayload>({
      query: payload => ({
        body: payload,
        url: Endpoint.PasswordlessOtp,
        method: HTTPMethod.POST,
      }),
    }),
  }),
});

export const { passwordlessOtp } = authenticationApi.endpoints;
export default authenticationApi;
