import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M14.25 14.75H3.75V4.25H9V2.75H3.75C2.9175 2.75 2.25 3.425 2.25 4.25V14.75C2.25 15.575 2.9175 16.25',
  '3.75 16.25H14.25C15.075 16.25 15.75 15.575 15.75 14.75V9.5H14.25V14.75ZM10.5 2.75V4.25H13.1925L5.82',
  '11.6225L6.8775 12.68L14.25 5.3075V8H15.75V2.75H10.5Z',
].join(' ');

export const IconExternal = ({
  width = 18,
  height = 19,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 18 19"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconExternal;
