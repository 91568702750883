import { useTranslation } from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';
import { AssetWithLabel } from 'types/assets';
import { formatCryptoAmountWithSymbolAtEnd } from 'utils/currencies';

import { LABELS } from '../keys';
import { useStyles } from './preview.styles';

interface WalletPreviewProps {
  label: string;
  amount: string;
  asset?: AssetWithLabel;
}

const CryptoAmountPreview = ({
  label,
  asset,
  amount,
}: WalletPreviewProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const hasNetworkIcon = Boolean(asset?.cryptoNetworkIconUrl);

  if (!asset) {
    return null;
  }

  return (
    <div className={classes.gap}>
      <div className={classes.infoBlockLabel}>
        {label}
      </div>
      <div className={classes.infoBlockValue}>
        <OptionallyVisible visible={hasNetworkIcon}>
          <img
            src={asset?.cryptoNetworkIconUrl as string}
            className={classes.cryptoIcon}
            alt={asset?.name}
          />
        </OptionallyVisible>
        <div className={classes.walletAddressAndTagBlock}>
          {formatCryptoAmountWithSymbolAtEnd(amount, asset, { truncate: false })}
        </div>
      </div>
      <div className={classes.notice}>{t(LABELS.REVIEW_ORDER_CRYPTO_AMOUNT_NOTICE)}</div>
    </div>
  );
};

export default CryptoAmountPreview;
