import { makeLabels } from 'utils/i18n';

const MESSAGES_NAMESPACE = 'orderProcessed';
export const LABELS = makeLabels({
  ORDER_PROCESSED_NOTE: 'orderProcessedNote',
  ORDER_PROCESSED_NOTE_CARD: 'orderProcessedNoteCard',
  ORDER_PROCESSED_NOTE_APPLE_PAY: 'orderProcessedNoteApplePay',
  ORDER_PROCESSED_NOTE_GOOGLE_PAY: 'orderProcessedNoteGooglePay',
  ORDER_PROCESSED_CHECK: 'orderProcessedCheckNote',
  ORDER_PROCESSED_NOTE_SELL: 'orderProcessedNoteSell',
  BUY_AGAIN: 'buyAgain',
  EXCHANGE_AGAIN: 'exchangeAgain',
  HEADER: 'header',
  CONGRATS: 'congrats',
}, MESSAGES_NAMESPACE);

export default LABELS;
