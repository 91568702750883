import ConfigQueryParam from 'types/queryParams';

export const getQueryParam = (name: string): string | null => {
  const params = new URLSearchParams(window.location.search);
  const currencyTransform = (value: string) => value.toUpperCase();

  const transformMap: Map<string, (value: string) => string> = new Map([
    ['cryptoCurrencyCode', currencyTransform],
    ['fiatCurrencyCode', currencyTransform],
    ['defaultCryptoCurrencyCode', currencyTransform],
    ['defaultFiatCurrencyCode', currencyTransform],
    ['cryptoCurrencyCodeList', currencyTransform],
    ['fiatCurrencyCodeList', currencyTransform],
  ]);

  const param = params.get(name);
  const transform = transformMap.get(name);

  return param && transform ? transform(param) : param;
};

export const appendThemeParam = (url: string) => {
  const themeParam = getQueryParam(ConfigQueryParam.Theme);
  if (!themeParam) {
    return url;
  }

  const searchParams = new URLSearchParams({
    [ConfigQueryParam.Theme]: themeParam,
  });

  return `${url}?${searchParams.toString()}`;
};
export default getQueryParam;
