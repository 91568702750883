export const KEYS = {
  CRYPTO_CURRENCY_INPUT_ID: 'cryptoInput',
  FIAT_CURRENCY_INPUT_ID: 'fiatInput',
  INPUT_DEBOUNCE_TIMEOUT: 700,
  REFETCH_INTERVAL_IN_SECONDS: 30,
  ABORT_ERROR: 'AbortError',
  DELIMETERS: ['.', ','],
};

export enum FormFields {
  Fiat = 'fiat',
  Crypto = 'crypto'
}

const MESSAGES_NAMESPACE = 'quote';
export const LABELS = {
  CHOOSE_FIAT_CURRENCY: `${MESSAGES_NAMESPACE}.chooseFiatCurrency`,
  CHOOSE_CRYPTO_CURRENCY: `${MESSAGES_NAMESPACE}.chooseCryptoCurrency`,
  BUY_CRYPTO: `${MESSAGES_NAMESPACE}.buyCrypto`,
  SELL: `${MESSAGES_NAMESPACE}.sell`,
  BUY: `${MESSAGES_NAMESPACE}.buy`,
  SELL_CRYPTO: `${MESSAGES_NAMESPACE}.sellCrypto`,
  CURRENCIES_SEARCH: `${MESSAGES_NAMESPACE}.currenciesSearch`,
  CURRENCIES_RESULT: `${MESSAGES_NAMESPACE}.currenciesResults`,
  PERSISTING_ISSUE_NOTE: `${MESSAGES_NAMESPACE}.persistingIssueNote`,
  CONTINUE: `${MESSAGES_NAMESPACE}.continue`,
  ERRORS_MAX_AMOUNT: `${MESSAGES_NAMESPACE}.errorsMaxAmount`,
  ERRORS_MIN_AMOUNT: `${MESSAGES_NAMESPACE}.errorsMinAmount`,
  YOU_PAY: `${MESSAGES_NAMESPACE}.youPay`,
  AMOUNT_TO_PAY: `${MESSAGES_NAMESPACE}.amountToPay`,
  YOU_RECEIVE: `${MESSAGES_NAMESPACE}.youReceive`,
  RECEIVED_CRYPTO: `${MESSAGES_NAMESPACE}.receivedCrypto`,
  YOU_RECEIVE_ESTIMATE: `${MESSAGES_NAMESPACE}.youReceiveEstimate`,
  FIAT_YOU_GET: `${MESSAGES_NAMESPACE}.fiatYouGet`,
  REFRESHING: `${MESSAGES_NAMESPACE}.refreshing`,
};
export default KEYS;
