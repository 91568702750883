import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    note: {
      color: theme.colors.text.inactive,
      ...fontSizeMixin(FontSize.M),
      fontWeight: 400,
      textAlign: 'center',
      padding: `0 ${theme.scale(15)}px`,
      margin: 'unset',
    },
    email: {
      textAlign: 'center',
      ...fontSizeMixin(FontSize.M),
      fontWeight: 500,
      margin: 'unset',
      padding: `${theme.scale(8)}px ${theme.scale(12)}px`,
      wordBreak: 'break-word',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.scale(5)}px ${theme.scale(10)}px`,
      gap: theme.scale(15),
      position: 'relative',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(10),
      padding: `${theme.scale(24)}px 0`,
    },
    inputsWrapper: {
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.scale(10),
      },
    },
    otpInput: {
      width: `${theme.scale(40)}px !important`,
      height: theme.scale(43),
      outline: `1px solid ${theme.colors.border.accentDark}`,
      border: 'none',
      borderRadius: theme.border.radius,
      ...fontSizeMixin(FontSize.L),
      transition: theme.colors.background.transition,
      background: 'none',
    },
    inputDisabled: {
      background: theme.colors.background.inactive,
      color: theme.colors.text.inactive,
      outline: 'none',
    },
    proceedButtonWrapper: {
      marginTop: 'auto',
    },
    noHeader: {
      paddingTop: theme.scale(12),
    },
    errorMessage: {
      color: theme.colors.text.error,
      fontWeight: 500,
      textAlign: 'center',
      padding: `${theme.scale(6)}px 0`,
      ...fontSizeMixin(FontSize.S),
    },
  }),
);

export default useStyles;
