import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useNavigation from 'hooks/useNavigation';
import { getPathParams } from 'pages/route/utils';
import assetsApi from 'services/assets';
import { selectPlacedOrder } from 'services/payments';
import { OrderCompletionResponse } from 'services/payments/types';
import transactionApi from 'services/transaction';
import { selectTransactionId } from 'state/slices/orderSlice';
import { OrderStatusStep, OrderStepStatus, Transaction } from 'types/transactions';
import Logger from 'utils/logger';
import PATHS from 'utils/navigation';

import AwaitingCrypto from '.';
import KEYS from './keys';

const AwaitingCryptoContainer = () => {
  const { proceed, canGoBack, goBack } = useNavigation();
  const { orderId } = getPathParams(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  const transactionId = useSelector(selectTransactionId) ?? orderId ?? '';
  const {
    data: transaction,
  } = transactionApi.useGetTransactionStepsStatusQuery({
    transactionId,
    includeToken: true,
  }, {
    pollingInterval: KEYS.POLLING_INTERVAL,
    skip: !transactionId,
  });
  const orderFromCurrentFlow = useSelector(selectPlacedOrder);
  const { data: fetchedOrder } = transactionApi.useGetTransactionQuery(
    {
      transactionId: orderId,
      includeToken: true,
    },
    {
      skip: Boolean(orderFromCurrentFlow) || !orderId,
    },
  );

  const orderCryptoCurrency = (orderFromCurrentFlow as OrderCompletionResponse)?.quote.cryptoCurrencyCode
    ?? fetchedOrder?.data.order.cryptoCurrencyCode;
  const orderAsset = assetsApi
    .useGetActiveAssetsSellQuery()
    .data?.data.assets.find(asset => asset.symbol === orderCryptoCurrency);

  useEffect(() => {
    if (!transaction) {
      return;
    }

    const depositStep = transaction.data.steps.find(step => step.type === OrderStatusStep.Deposit);
    if (!depositStep) {
      Logger.error('Sell - Deposit step is missing in the transaction steps.');
      return;
    }

    const canProceed = depositStep.status === OrderStepStatus.Success;
    if (canProceed) {
      proceed();
    }
  }, [transaction?.data.steps]);

  if ('error' in (orderFromCurrentFlow ?? {})) {
    return null;
  }

  return (
    <AwaitingCrypto
      order={(orderFromCurrentFlow ?? fetchedOrder?.data.order) as OrderCompletionResponse | Transaction}
      orderAsset={orderAsset}
      canGoBack={canGoBack}
      goBack={goBack}
    />
  );
};

export default AwaitingCryptoContainer;
