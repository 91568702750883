import { useEffect, useState } from 'react';

import { QuotePayload } from 'services/quote/transactions/payloads';

interface QuoteRefetchParams {
  isLoading: boolean;
  isError: boolean;
  fetchQuote: (overrideQuotePayload?: Partial<QuotePayload>) => void;
  reFetchIntervalInSeconds: number;
  refetch?: boolean;
}

export const useQuoteRefetch = ({
  isLoading,
  isError,
  fetchQuote,
  reFetchIntervalInSeconds,
  refetch = true,
}: QuoteRefetchParams) => {
  const [count, setCount] = useState(reFetchIntervalInSeconds);

  const handleInterval = (isLoading: boolean) => {
    if (isLoading) {
      return;
    }

    if (count > 1) {
      setCount(count - 1);
    } else {
      setCount(reFetchIntervalInSeconds);
      fetchQuote();
    }
  };

  useEffect(() => {
    if (isError) {
      return () => {};
    }

    let intervalId: ReturnType<typeof setInterval> | null = null;

    if (refetch) {
      intervalId = setInterval(() => handleInterval(isLoading), 1000);
    }

    return () => { if (intervalId) { clearInterval(intervalId); } };
  }, [count, isLoading, isError]);

  return count;
};

export default useQuoteRefetch;
