import Flow from 'types/flow';
import {
  OrderStatusStep, OrderStep, OrderStepStatus,
} from 'types/transactions';

export const KEYS = {
  POLLING_INTERVAL_WHILE_WITHDRAWAL_STATUS_IS_UNKNOWN: 2 * 1000,
  POLLING_INTERVAL_WHILE_WITHDRAWAL_STATUS_IS_NOT_UNKNOWN: 20 * 1000,
  TIMEOUT_BEFORE_ORDER_PROCESSED_REDIRECTION: 2000,
  ORDER_STATUS_POLLING_INTERVAL: 2000,
  ETHEREUM: 'ethereum',
  BITCOIN: 'bitcoin',
};

export const getFailedPaymentSteps = (flow: Flow): OrderStep[] => ({
  [Flow.Buy]: [{
    type: OrderStatusStep.Payment,
    status: OrderStepStatus.Failed,
  }, {
    type: OrderStatusStep.Exchange,
    status: OrderStepStatus.Unknown,
  }, {
    type: OrderStatusStep.Withdrawal,
    status: OrderStepStatus.Unknown,
  }],
  [Flow.Sell]: [{
    type: OrderStatusStep.Payment,
    status: OrderStepStatus.Failed,
  },
  {
    type: OrderStatusStep.Withdrawal,
    status: OrderStepStatus.Unknown,
  }],
})[flow];

const MESSAGES_NAMESPACE = 'transactionProcessing';
export const LABELS = {
  PROCESSING_ORDER_REQUEST_NOTE: `${MESSAGES_NAMESPACE}.processingOrderRequestNote`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  BACK: `${MESSAGES_NAMESPACE}.back`,
  WAITING_FOR_WITHDRAWAL_TEXT: `${MESSAGES_NAMESPACE}.waitingForWithdrawalText`,
  DELAYED_FOR_WITHDRAWAL_TEXT: `${MESSAGES_NAMESPACE}.delayedForWithdrawalText`,
  PAYMENT_STATUS: `${MESSAGES_NAMESPACE}.paymentStatus`,
  EXCHANGE_STATUS: `${MESSAGES_NAMESPACE}.exchangeStatus`,
  WITHDRAWAL_STATUS_STUCK: `${MESSAGES_NAMESPACE}.withdrawalStatusStuck`,
  WITHDRAWAL_STATUS: `${MESSAGES_NAMESPACE}.withdrawalStatus`,
  DEPOSIT_STATUS: `${MESSAGES_NAMESPACE}.depositStatus`,
  EXCHANGE_STATUS_SELL: `${MESSAGES_NAMESPACE}.exchangeStatusSell`,
  WITHDRAWAL_STATUS_SELL: `${MESSAGES_NAMESPACE}.withdrawalStatusSell`,
  CARD_PAYMENT_FAILED: `${MESSAGES_NAMESPACE}.cardPaymentFailed`,
  PLEASE_TRY_AGAIN: `${MESSAGES_NAMESPACE}.pleaseTryAgain`,
  FAIL_EXCHANGE_LONG: `${MESSAGES_NAMESPACE}.failExchangeLong`,
  CARD_AUTHORIZATION_FAILED: `${MESSAGES_NAMESPACE}.cardAuthorizationFailed`,
  PAYMENT_AUTHORIZATION: `${MESSAGES_NAMESPACE}.paymentAuthorization`,
  FAIL_EXCHANGE: `${MESSAGES_NAMESPACE}.failExchange`,
  WITHDRAWAL_STATUS_FIAT: `${MESSAGES_NAMESPACE}.withdrawalStatusFiat`,
};

export default KEYS;
