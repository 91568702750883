export enum Illustration {
  Authentication = 'auth',
  Card = 'card',
  Checkmark = 'checkmark',
  Exchange = 'exchange',
  Globe = 'globe',
  Identity = 'identity',
  Launch = 'launch',
  Notification = 'notification',
  Wallet = 'wallet',
  Shift4 = 'shift4'
}

export type LayeredMask = ({ color: string; layerIds: number[] })
