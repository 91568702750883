import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M9.86667 10.8L7.33333 8.26667V4.66667H8.66667V7.73333L10.8 9.86667L9.86667',
  '10.8ZM8 14C7.16667 14 6.38622 13.8418 5.65867 13.5253C4.93067 13.2084',
  '4.29733 12.7804 3.75867 12.2413C3.21956 11.7027 2.79156 11.0693',
  '2.47467 10.3413C2.15822 9.61378 2 8.83333 2 8C2 7.16667 2.15822',
  '6.386 2.47467 5.658C2.79156 4.93044 3.21956 4.29711 3.75867 3.758C4.29733',
  '3.21933 4.93067 2.79156 5.65867 2.47467C6.38622 2.15822 7.16667 2 8 2C8.91111',
  '2 9.77511 2.19444 10.592 2.58333C11.4084 2.97222 12.1 3.52222 12.6667',
  '4.23333V2.66667H14V6.66667H10V5.33333H11.8333C11.3778 4.71111 10.8167',
  '4.22222 10.15 3.86667C9.48333 3.51111 8.76667 3.33333 8 3.33333C6.7',
  '3.33333 5.59733 3.786 4.692 4.69133C3.78622 5.59711 3.33333 6.7 3.33333',
  '8C3.33333 9.3 3.78622 10.4027 4.692 11.308C5.59733 12.2138 6.7 12.6667 8',
  '12.6667C9.16667 12.6667 10.186 12.2889 11.058 11.5333C11.9304 10.7778 12.4444',
  '9.82222 12.6 8.66667H13.9667C13.8 10.1889 13.1473 11.4582 12.0087 12.4747C10.8696',
  '13.4916 9.53333 14 8 14Z',
].join(' ');

export const IconHistory = ({
  width = 16,
  height = 16,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
      />
    </svg>
  );
};
export default IconHistory;
