import { useTranslation } from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';
import RefreshingAssetPrice from 'components/refreshingAssetPrice';
import { CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION } from 'constants/defaults';
import { getShortenedCryptoCurrencyName } from 'utils/currencies';
import { truncateNumericValue } from 'utils/number';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS, LABELS } from './keys';
import { useStyles } from './quoteAdditionalInfo.styles';
import { QuoteAdditionalInfoProps } from './types';

const QuoteAdditionalInfo = ({
  cryptocurrency,
  fiatCurrency,
  price,
  cryptoAmount,
  isLoading,
  isError,
  fetchQuote,
  refetch = false,
}: QuoteAdditionalInfoProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const hasCryptoIcon = Boolean(cryptocurrency?.iconUrl);

  return (
    <div className={classes.infoContainer}>
      <div className={classes.flexRow}>
        <div className={classes.boldFont}>
          {t(LABELS.YOU_RECEIVE)}
        </div>
        <div className={classes.boldFont}>
          <OptionallyVisible visible={hasCryptoIcon}>
            <img
              src={cryptocurrency?.iconUrl!}
              className={classes.cryptoIcon}
              alt={cryptocurrency?.name}
            />
          </OptionallyVisible>
          <span {...QALocator(locators.page.checkout.youReceiveValue)}>
            {truncateNumericValue(cryptoAmount, cryptocurrency?.displayDecimals ?? CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION)}
          </span>
          {' '}
          <span {...QALocator(locators.page.checkout.youReceiveCryptoCurrencyCode)}>
            {getShortenedCryptoCurrencyName(cryptocurrency)}
          </span>
        </div>
      </div>
      <div className={cx(classes.flexRow, classes.smallerFont)}>
        <RefreshingAssetPrice
          reFetchIntervalInSeconds={KEYS.REFETCH_INTERVAL_IN_SECONDS}
          isError={isError}
          isLoading={isLoading}
          cryptocurrency={cryptocurrency}
          fiatCurrency={fiatCurrency}
          fetchQuote={fetchQuote}
          price={price}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default QuoteAdditionalInfo;
