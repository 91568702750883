import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .withParams<{ disabled: boolean; startAdornment?: boolean; endAdornment?: boolean }>()
  .create(
    ({
      theme, disabled, startAdornment, endAdornment,
    }) => ({
      container: {
        '& .Mui-focused': {
          outline: `1px solid ${theme.colors.border.accentDark}`,
        },
        '& .Mui-error': {
          outline: `1px solid ${theme.colors.text.error}`,
        },
      },
      inputWrapper: {
        background: disabled ? theme.colors.background.inactive : theme.colors.background.primary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.border.radius,
        padding: `${theme.scale(8)}px ${theme.scale(12)}px`,
        overflow: 'hidden',
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      input: {
        paddingLeft: startAdornment ? theme.scale(5) : 0,
        paddingRight: endAdornment ? theme.scale(5) : 0,
        background: 'none',
        border: 'none',
        width: '100%',
        color: disabled ? theme.colors.text.inactive : theme.colors.text.primary,
        ...fontSizeMixin(FontSize.L),
        '&:focus': {
          outline: 'none',
        },
        '&::placeholder': {
          color: theme.colors.text.inactive,
        },
        '&::-webkit-outer-spin-button': {
          display: 'none',
        },
      },
    }),
  );

export default useStyles;
