import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';

import { OrderRow } from 'components/orderRow/order';
import Spinner from 'components/spinner';
import { Asset } from 'types/assets';
import { OrderRecord } from 'types/order';
import PATHS from 'utils/navigation';
import { locators, QALocator } from 'utils/tests/QA';

import OptionallyVisible from '../../components/optionallyVisible';
import LABELS from './keys';
import useStyles from './styles';

interface OrderHistoryScreenProps {
  onSubmenuGoBack?: () => void;
  orderItems: {
    order: OrderRecord;
    cryptoAsset?: Asset;
    fiatAsset?: Asset;
  }[];
  isLoading: boolean;
  canLoadNextPage: boolean;
  loadNextPage: () => void;
}

const PendingOrdersScreen = ({
  orderItems,
  isLoading,
  canLoadNextPage,
  loadNextPage,
}: OrderHistoryScreenProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const orderPath = PATHS.CONTINUE_ORDER_PATH;

  const handleVisibilityChange = (visible: boolean) => {
    if (visible) {
      loadNextPage();
    }
  };

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner
          scale={1.25}
          {...QALocator(locators.page.orderHistory.viewLoadingSpinner)}
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <span className={classes.description}>{t(LABELS.DESCRIPTION)}</span>
      <div className={classes.orderListScrollable}>
        <div className={classes.orderList}>
          {orderItems.map(({ order, fiatAsset, cryptoAsset }, index) => (index ? (
            <div key={order.uuid}>
              <div className={classes.delimeter} />
              <OrderRow
                order={order}
                fiatAsset={fiatAsset!}
                cryptoAsset={cryptoAsset!}
                orderPath={orderPath}
              />
            </div>
          ) : (
            <OrderRow
              order={order}
              fiatAsset={fiatAsset!}
              cryptoAsset={cryptoAsset!}
              orderPath={orderPath}
              key={order.uuid}
            />
          )))}
          <OptionallyVisible visible={canLoadNextPage}>
            <VisibilitySensor active={canLoadNextPage} onChange={handleVisibilityChange}>
              <Spinner
                scale={0.8}
                {...QALocator(locators.page.orderHistory.pageLoadingSpinner)}
              />
            </VisibilitySensor>
          </OptionallyVisible>
        </div>
        <div className={classes.shadow} />
      </div>
    </div>
  );
};

export default PendingOrdersScreen;
