import { useFormik } from 'formik';

import { FormFields } from './keys';

interface UseSignInFormParams {
  initialEmail?: string;
  errorTexts: Record<FormFields, string>;
}

export const useSignInForm = ({
  initialEmail,
  errorTexts,
}: UseSignInFormParams) => {
  const inputLengthValidator = (values: Record<FormFields.Email, string>) => {
    const hasEmailValue = values[FormFields.Email].length;
    if (hasEmailValue) {
      return false;
    }

    return errorTexts[FormFields.Email];
  };

  const form = useFormik({
    initialValues: {
      [FormFields.Email]: initialEmail ?? window.s4cConfig.prefilledValues?.email ?? '',
    },
    validate: values => ({
      [FormFields.Email]: inputLengthValidator(values),
    }),
    onSubmit: () => {},
    validateOnMount: false,
  });

  const hasFormErrors = Object.values(form.errors).some(Boolean);
  const canProceed = form.values[FormFields.Email].length && !hasFormErrors;

  return { form, canProceed };
};

export default useSignInForm;
