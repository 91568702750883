export enum Breakpoint {
  Mobile = 'mobile',
  Widget = 'widget',
  Tablet = 'tablet',
  Laptop = 'laptop',
  Desktop = 'desktop',
}

export const breakpointValues: Record<Breakpoint, number> = {
  [Breakpoint.Mobile]: 0,
  [Breakpoint.Widget]: 500,
  [Breakpoint.Tablet]: 640,
  [Breakpoint.Laptop]: 1024,
  [Breakpoint.Desktop]: 1200,
};
