import { tss } from 'theme';

export const useStyles = tss.create(
  ({ theme }) => ({
    flexAlign: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.scale(3),
      order: 0,
      width: theme.scale(41),
      whiteSpace: 'nowrap',
    },
  }),
);

export default useStyles;
