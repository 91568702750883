import {
  OrderStatus,
  OrderStatusStep,
  OrderStepStatus,
  TransactionStepUIStatus,
} from 'types/transactions';

const getPreviousSteps = ({ steps }: OrderStatus, currentStep: OrderStatusStep) => {
  const index = steps.findIndex(step => step.type === currentStep);
  return steps.slice(0, index);
};

const getStep = (stepName: OrderStatusStep, data: OrderStatus) => data.steps.find(step => step.type === stepName);
/**
 * The logic is in accordance with https://thegivingblock.atlassian.net/browse/TGB-6856
 *
 * In more details:
 * 1. Show a spinner in the transaction step that is being processed.
 * 2. Only one spinner at a time should be displayed.
 */

const shouldDisplayTransactionStepAsLoading = (stepName: OrderStatusStep, data: OrderStatus) => {
  const busyStatuses = [
    OrderStepStatus.Pending,
    OrderStepStatus.Unknown,
    OrderStepStatus.Stuck,
  ];

  const arePreviousStepsCompleted = getPreviousSteps(data, stepName).every(step => step.status === OrderStepStatus.Success);
  const currentStep = getStep(stepName, data);
  if (!currentStep?.status) {
    return false;
  }
  const isCurrentStepBusy = busyStatuses.includes(currentStep.status);

  return isCurrentStepBusy && arePreviousStepsCompleted;
};

/**
 * This logic is in accordance with Figma mockup for https://thegivingblock.atlassian.net/browse/TGB-6788
 */

const shouldDisplayTransactionStepAsDisabledErrored = (stepName: OrderStatusStep, data: OrderStatus) => {
  const erroredStepNameIndex = data.steps.findIndex(step => step.status === OrderStepStatus.Failed);
  const stepNameIndex = data.steps.findIndex(step => step.type === stepName);

  return erroredStepNameIndex > -1 && stepNameIndex > erroredStepNameIndex;
};

/**
 * This logic is in accordance with Figma mockup for https://thegivingblock.atlassian.net/browse/TGB-6788
 */

const shouldDisplayTransactionStepAsDisabledCompleted = (stepName: OrderStatusStep, data: OrderStatus) => {
  const erroredStepNameIndex = data.steps.findIndex(step => step.status === OrderStepStatus.Failed);
  const stepNameIndex = data.steps.findIndex(step => step.type === stepName);

  return erroredStepNameIndex > -1 && stepNameIndex < erroredStepNameIndex;
};

export const getTransactionStepUIStatus = (stepName: OrderStatusStep, data: OrderStatus) => {
  if (shouldDisplayTransactionStepAsLoading(stepName, data)) {
    return TransactionStepUIStatus.InProgress;
  }

  if (shouldDisplayTransactionStepAsDisabledErrored(stepName, data)) {
    return TransactionStepUIStatus.ErrorDisabled;
  }

  if (shouldDisplayTransactionStepAsDisabledCompleted(stepName, data)) {
    return TransactionStepUIStatus.CompletedDisabled;
  }

  const transactionStepStatus = getStep(stepName, data)?.status;

  if (transactionStepStatus === OrderStepStatus.Success) {
    return TransactionStepUIStatus.Completed;
  }

  if (transactionStepStatus === OrderStepStatus.Failed) {
    return TransactionStepUIStatus.Error;
  }

  return TransactionStepUIStatus.NotStarted;
};
export default getTransactionStepUIStatus;
