import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';
import { locators, QALocator } from 'utils/tests/QA';

import { LABELS } from './keys';
import useStyles from './menuList.styles';
import MenuListItem from './menuListItem';

export interface MenuItem {
  label: string;
  icon?: ReactNode;
  submenu?: {
    Component: React.FC<any>;
  } ;
  onOptionClick: () => void;
  isShown?: boolean;
}

interface MenuListProps {
  onMenuClose: () => void;
  items: MenuItem[];
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  hasPendingOrders?: boolean;
}

const MenuList = ({
  items, onMenuClose, termsOfServiceUrl, privacyPolicyUrl, hasPendingOrders,
}: MenuListProps) => {
  const [listWrapperHeight, setListWrapperHeight] = useState<number>();
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles({ listWrapperHeight });
  const [activeItem, setActiveItem] = useState<MenuItem | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (listWrapperRef.current?.clientHeight) {
      setListWrapperHeight(listWrapperRef.current.clientHeight);
    }
  }, [listWrapperRef.current]);

  const onMenuItemClick = (item: MenuItem) => () => {
    item.onOptionClick();

    if (item.submenu) {
      setActiveItem(item);
    }

    if (!item.submenu) {
      onMenuClose();
    }
  };

  const onSubmenuGoBack = () => {
    setActiveItem(null);
  };

  const ActiveItemComponent = activeItem?.submenu?.Component ?? (() => null);

  return (
    <div className={classes.root}>
      <OptionallyVisible visible={!activeItem}>
        <div className={classes.listWrapper} ref={listWrapperRef}>
          <ul
            {...QALocator(locators.components.menu.menuListContent)}
            className={classes.list}
          >
            {items.map(option => (
              <MenuListItem
                key={option.label}
                label={option.label}
                icon={option.icon}
                onOptionClick={onMenuItemClick(option)}
                hasPendingOrders={hasPendingOrders}
              />
            ))}
          </ul>
        </div>
      </OptionallyVisible>
      <OptionallyVisible visible={Boolean(activeItem)}>
        <ActiveItemComponent onGoBack={onSubmenuGoBack} onMenuClose={onMenuClose} />
      </OptionallyVisible>
      <OptionallyVisible visible={Boolean(!activeItem)}>
        <>
          <span className={classes.message}>{t(LABELS.SUPPORT_MESSAGE)}</span>
          <div className={classes.linkWrapper}>
            <a
              className={classes.link}
              target="_blank"
              href={termsOfServiceUrl}
            >
              {t(LABELS.TERMS_OF_USE)}
            </a>
            <span className={classes.linkDivider}>-</span>
            <a
              className={classes.link}
              target="_blank"
              href={privacyPolicyUrl}
            >
              {t(LABELS.PRIVACY_POLICY)}
            </a>
          </div>
        </>
      </OptionallyVisible>
    </div>
  );
};

export default MenuList;
