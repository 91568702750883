/* eslint-disable no-console */

enum LogLevel {
  Error = 'error',
  Warning = 'warning',
  Full = 'full',
}

class Logger {
  static logLevel: boolean | LogLevel | LogLevel[] = window?.s4cConfig?.enableLogs ?? false;

  static shouldLog(...levels: LogLevel[]): boolean {
    if (Logger.logLevel === false) {
      return false;
    }
    if (Logger.logLevel === true || Logger.logLevel === LogLevel.Full) {
      return true;
    }
    if (typeof Logger.logLevel === 'string') {
      return levels.includes(Logger.logLevel);
    }
    if (Array.isArray(Logger.logLevel)) {
      return levels.some(level => (Logger.logLevel as LogLevel[]).includes(level));
    }
    return false;
  }

  static log(...args: any[]): void {
    if (Logger.shouldLog(LogLevel.Full)) {
      console.log(...args);
    }
  }

  static error(...args: any[]): void {
    if (Logger.shouldLog(LogLevel.Error, LogLevel.Full)) {
      console.error(...args);
    }
  }

  static warn(...args: any[]): void {
    if (Logger.shouldLog(LogLevel.Warning, LogLevel.Full)) {
      console.warn(...args);
    }
  }

  static trace(...args: any[]): void {
    if (Logger.shouldLog(LogLevel.Full)) {
      console.trace(...args);
    }
  }
}

export default Logger;
