import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import doesPathMatchCurrentPath from 'pages/route/utils';
import preload from 'preload';
import { selectPaymentMethod } from 'services/payments';
import transactionApi from 'services/transaction';
import { selectFlow } from 'state/slices/flowSlice';
import { selectTransactionId, setOrder } from 'state/slices/orderSlice';
import { changeStep } from 'state/slices/stepSlice';
import { store } from 'state/store';
import Flow from 'types/flow';
import WorkflowStep from 'types/step';
import PATHS from 'utils/navigation';

import OrderProcessedPage from '.';

const OrderProcessedContainer = () => {
  const dispatch = useDispatch();
  const [isResetLoading, setResetIsLoading] = useState(false);
  const selectedPaymentMethod = useSelector(selectPaymentMethod);
  const flow = useSelector(selectFlow);
  const isSell = flow === Flow.Sell;
  const isContinueFlow = doesPathMatchCurrentPath(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  const orderTransactionId = useSelector(selectTransactionId);
  const { data: order } = transactionApi.useGetTransactionQuery({
    transactionId: orderTransactionId!,
    includeToken: true,
  }, {
    skip: !orderTransactionId,
  });

  const { cardBrand, cardLast4 } = order?.data?.order || {};

  const onBuyAgainClick = async () => {
    if (isContinueFlow) {
      dispatch(changeStep(WorkflowStep.Quote));
      return;
    }

    setResetIsLoading(true);

    dispatch(setOrder({}));
    window.preloadedConfig = {};
    await preload(store);

    setResetIsLoading(false);
  };

  return (
    <OrderProcessedPage
      buyAgain={onBuyAgainClick}
      isResetLoading={isResetLoading}
      paymentInfo={{
        cardBrand,
        cardLast4,
        paymentMethod: selectedPaymentMethod,
      }}
      sell={isSell}
    />
  );
};

export default OrderProcessedContainer;
