import { NavigationEvaluationFunc } from 'hooks/useNavigation';
import doesPathMatchCurrentPath from 'pages/route/utils';
import { WorkflowStep } from 'types/step';
import { TransactionStatus } from 'types/transactions';
import PATHS from 'utils/navigation';

import getInitialStep from './init';

export const canGoBackFromBankDetailsSell: NavigationEvaluationFunc<boolean> = ({ user, flow }) => {
  const isContinueFlow = doesPathMatchCurrentPath(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  const initialStep = getInitialStep({ user, flow });

  if (isContinueFlow) {
    return false;
  }

  return initialStep !== WorkflowStep.BankDetails;
};

export const getBankDetailsPreviousStepSell: NavigationEvaluationFunc<WorkflowStep> = ({ user }) => {
  if (!user?.defaultPaymentMethod) {
    return WorkflowStep.PaymentMethods;
  }

  return WorkflowStep.Quote;
};

export const getBankDetailsNextStepSell: NavigationEvaluationFunc<WorkflowStep> = ({ orderStatus }) => {
  const isConfirmingQuote = orderStatus?.status === TransactionStatus.AwaitingClientConfirmation;
  if (isConfirmingQuote) {
    return WorkflowStep.ConfirmNewQuote;
  }

  const isContinueFlow = doesPathMatchCurrentPath(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  if (isContinueFlow) {
    return WorkflowStep.ConfirmNewQuote;
  }

  return WorkflowStep.Checkout;
};

export default canGoBackFromBankDetailsSell;
