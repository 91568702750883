import { Input as MuiInput, InputProps as MuiInputProps } from '@mui/base/Input';

import useStyles from './styles';

export const Input = ({
  ...props
}: MuiInputProps) => {
  const { classes, cx } = useStyles({
    disabled: props.disabled ?? false,
    startAdornment: Boolean(props.startAdornment),
    endAdornment: Boolean(props.endAdornment),
  });

  return (
    <div className={classes.container}>
      <MuiInput
        {...props}
        className={cx(classes.inputWrapper, props.className)}
        slotProps={{
          ...props.slotProps,
          input: {
            ...props.slotProps?.input,
            className: classes.input,
            ...props.slotProps?.input,
          },
        }}
      />
    </div>
  );
};

export default Input;
