import { makeLabels } from 'utils/i18n';

const KEYS = {
  FALLBACK_URL: '#',
};

const MESSAGES_NAMESPACE = 'components.menuList';

export const LABELS = makeLabels({
  PROFILE: 'profile',
  ORDER_HISTORY: 'orderHistory',
  LOGOUT: 'logout',
  LOGIN: 'login',
  CARDS: 'cards',
  TERMS_OF_USE: 'termsOfUse',
  PRIVACY_POLICY: 'privacyPolicy',
  SUPPORT_MESSAGE: 'supportMessage',
  PENDING_ORDERS: 'pendingOrders',
}, MESSAGES_NAMESPACE);

export default KEYS;
