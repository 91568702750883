import { NavigationEvaluationFunc } from 'hooks/useNavigation';
import PaymentMethod from 'types/payment';
import { WorkflowStep } from 'types/step';

import { checkIfShouldSkipWalletStep } from './queryParams';

export const getCheckoutPreviousStep: NavigationEvaluationFunc<WorkflowStep> = (params) => {
  const skipWalletStep = checkIfShouldSkipWalletStep();
  const skipBankDetails = Boolean(params.paymentMethod?.defaultCard || params.paymentMethod?.default !== PaymentMethod.Card);
  if (skipBankDetails) {
    if (skipWalletStep) {
      return WorkflowStep.Quote;
    }
    return WorkflowStep.Wallet;
  }
  return WorkflowStep.BankDetails;
};

export default getCheckoutPreviousStep;
