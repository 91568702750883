import { SavedCard } from 'services/payments/types';
import { Asset } from 'types/assets';
import { CardPaymentToken, Shift4Utils } from 'types/card';

export const verify3DS = async (
  shift4Utils: Shift4Utils,
  token: CardPaymentToken | SavedCard,
  amount: number,
  asset: Asset,
) => {
  const threeDSecureToken = await shift4Utils.verifyThreeDSecure({
    // Charge amount in minor units of a given currency. For example 10€ is represented as "1000" and 10¥ is represented as "10".
    // https://dev.shift4.com/docs/api#charge-object
    amount: Math.round(amount * 10 ** asset.precision),
    currency: asset.symbol,
    card: token.id,
  });

  return threeDSecureToken;
};

export default verify3DS;
