import { EventBody } from 'types/events';
import ConfigQueryParam from 'types/queryParams';

import isRunningInsideIframe from './iframe';
import Logger from './logger';
import getQueryParam from './queryParams';

const UNSPECIFIED_ORIGIN = '*';

export const emitEvent = (event: EventBody) => {
  const widgetInstanceId = getQueryParam(ConfigQueryParam.Id);
  const cantEmitEvent = !widgetInstanceId && isRunningInsideIframe();
  if (cantEmitEvent) {
    Logger.error(`Cannot emit event, widgetInstanceId is unknown. Event: ${JSON.stringify(event)}`);
    return;
  }

  window.parent?.postMessage({ ...event, widgetInstanceId }, UNSPECIFIED_ORIGIN);
};

export default emitEvent;
