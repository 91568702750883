import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOption } from '@mui/base/useOption';

import Button from 'components/button';
import LoadingWrapper from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import Spinner from 'components/spinner';
import CARD_BRANDS_IMAGE_SOURCE from 'constants/cardBrands';
import { IconBin } from 'icons/bin';
import { IconBookmark } from 'icons/bookmark';
import IconCross from 'icons/cross';
import maskContent from 'libs/sentry/mask';
import { SavedCard } from 'services/payments/types';
import { CardPaymentToken } from 'types/card';
import { ReduxToolkitResponse, SuccessResponse } from 'types/http';
import PaymentMethod from 'types/payment';
import { CardBrand } from 'types/transactions';

import Action from './action';
import { LABELS } from './keys';
import useStyles from './listItem.styles';

interface CardsListItemProps {
  card: SavedCard;
  isDefault: boolean;
  isClickable: boolean;
  updateUserProps: {
    updateUser: (paymentMethod: PaymentMethod | CardPaymentToken['id']) => Promise<ReduxToolkitResponse<SuccessResponse>>;
    isLoading: boolean;
  };
  deleteCardProps: {
    deleteCard: (card: SavedCard | CardPaymentToken) => Promise<void>;
    isLoading: boolean;
    error: any;
  };
  defaultCardProps: {
    setDefaultCard: (card: SavedCard | CardPaymentToken) => Promise<ReduxToolkitResponse<SuccessResponse>>;
    isLoading: boolean;
    error: any;
  };
}

const CardsListItem = ({
  card,
  isClickable,
  isDefault,
  updateUserProps,
  deleteCardProps,
  defaultCardProps,
}: CardsListItemProps) => {
  const { classes, cx, theme } = useStyles({ isClickable });
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteApiCallInProgress, setIsDeleteApiCallInProgress] = useState(false);
  const {
    getRootProps,
    highlighted,
  } = useOption({
    disabled: card.isExpired,
    label: card.brand,
    value: card,
  });

  const renderCardBrandIcon = () => {
    const cardBrand = card.brand;
    const cardImageUrl = CARD_BRANDS_IMAGE_SOURCE[card.brand as CardBrand] ?? CARD_BRANDS_IMAGE_SOURCE[CardBrand.Unknown];

    if (isDeleting) {
      return null;
    }

    return (
      <img width={theme.scale(35)} height={theme.scale(20)} alt={cardBrand} src={cardImageUrl} />
    );
  };

  const cardSubLabel = card.isExpired
    ? t(LABELS.CARD_TYPE_EXPIRED, { last4Digits: card.last4 })
    : t(LABELS.CARD_TYPE, { type: card.type, last4Digits: card.last4 });

  const onSetDefaultClick = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsUpdating(true);
    const updateUserResponse = await updateUserProps.updateUser(PaymentMethod.Card);
    if ('error' in updateUserResponse) {
      setIsUpdating(false);
      return;
    }
    await defaultCardProps.setDefaultCard(card);
    setIsUpdating(false);
  };

  const onDeleteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsDeleting(true);
  };

  const onConfirmedDeleteClick = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsDeleteApiCallInProgress(true);
    await deleteCardProps.deleteCard(card);
    setIsDeleteApiCallInProgress(false);
    setIsDeleting(false);
  };

  const onCancelDeleteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsDeleting(false);
  };

  return (
    <li
      {...getRootProps()}
      className={cx(classes.tile, {
        [classes.tileHighlighted]: highlighted,
        [classes.tileDisabled]: card.isExpired,
        [classes.deleting]: isDeleting,
      })}
    >
      {renderCardBrandIcon()}
      <div className={classes.cardDetailsWrapper}>
        <div className={classes.cardDetails}>
          <div
            className={cx(classes.cardName, {
              [classes.expired]: card.isExpired,
            })}
            {...maskContent()}
          >
            {card.brand}
          </div>
          <div
            className={cx(classes.cardType, {
              [classes.expired]: card.isExpired,
            })}
            {...maskContent()}
          >
            {cardSubLabel}
          </div>
        </div>
        <OptionallyVisible visible={card.isExpired}>
          <div className={classes.expiredBadge}>{t(LABELS.CARD_EXPIRED)}</div>
        </OptionallyVisible>
        <OptionallyVisible visible={!card.isExpired && isDefault}>
          <div className={classes.defaultBadge}>{t(LABELS.CARD_DEFAULT)}</div>
        </OptionallyVisible>
        <OptionallyVisible visible={!isDeleting}>
          <div className={classes.actions}>
            <OptionallyVisible visible={!card.isExpired && !isDefault}>
              <Action isLoading={isUpdating}>
                <Button
                  disabled={defaultCardProps.isLoading}
                  className={classes.actionButton}
                  onClick={onSetDefaultClick}
                >
                  <IconBookmark color={theme.colors.text.inactive} />
                </Button>
              </Action>
            </OptionallyVisible>
            <Action isLoading={isDeleting}>
              <Button
                className={classes.actionButton}
                onClick={onDeleteClick}
              >
                <IconBin color={theme.colors.text.inactive} />
              </Button>
            </Action>
          </div>
        </OptionallyVisible>
        <OptionallyVisible visible={isDeleting}>
          <div className={classes.confirmationWrapper}>
            <LoadingWrapper
              isLoading={isDeleteApiCallInProgress}
              loadingIndicator={<Spinner />}
            >
              <Button
                className={cx(classes.confirmationButton, classes.deleteConfirmButton)}
                onClick={onConfirmedDeleteClick}
              >

                <IconBin color={theme.colors.icons.secondary} />
              </Button>
            </LoadingWrapper>
            <Button
              className={cx(classes.confirmationButton, classes.cancelConfirmationButton)}
              onClick={onCancelDeleteClick}
            >
              <IconCross color={theme.colors.icons.accentDark} />
            </Button>
          </div>
        </OptionallyVisible>
      </div>
    </li>
  );
};

export default CardsListItem;
