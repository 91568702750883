import { GlobalStyles } from 'tss-react';

import Button from 'components/button';
import { isDarkMode } from 'theme/darkMode';

interface ApplePayButtonProps {
  onClick: () => void;
}

const ApplePayButton = ({
  onClick,
}: ApplePayButtonProps) => {
  const targetClassName = isDarkMode() ? 'apple-pay-button-white' : 'apple-pay-button-black';
  return (
    <>
      <GlobalStyles
        styles={{
          '@supports (-webkit-appearance: -apple-pay-button)': {
            '.apple-pay-button': {
              display: 'inline-block',
              WebkitAppearance: '-apple-pay-button',
              width: '100%',
              height: '44px',
              cursor: 'pointer',
            },
            '.apple-pay-button-black': {
              '-apple-pay-button-style': 'black',
            },
            '.apple-pay-button-white': {
              '-apple-pay-button-style': 'white',
            },
            '.apple-pay-button-white-with-line': {
              '-apple-pay-button-style': 'white-outline',
            },
          },
          '@supports not (-webkit-appearance: -apple-pay-button)': {
            '.apple-pay-button': {
              display: 'inline-block',
              backgroundSize: '100% 60%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
              borderRadius: '5px',
              padding: '0px',
              boxSizing: 'border-box',
              minWidth: '200px',
              minHeight: '32px',
              maxHeight: '64px',

            },
            '.apple-pay-button-black': {
              backgroundImage: '-webkit-named-image(apple-pay-logo-white)',
              backgroundColor: 'black',
            },
            '.apple-pay-button-white': {
              backgroundImage: '-webkit-named-image(apple-pay-logo-black)',
              backgroundColor: 'white',
            },
            '.apple-pay-button-white-with-line': {
              backgroundImage: '-webkit-named-image(apple-pay-logo-black)',
              backgroundColor: 'white',
              border: '0.5px solid black',
            },
          },
        }}
      />

      <Button onClick={onClick} className={`apple-pay-button ${targetClassName}`} />
    </>
  );
};

export default ApplePayButton;
