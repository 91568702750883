export enum Shift4ErrorType {
  InvalidRequest = 'invalid_request',
  CardError = 'card_error',
  GatewayError = 'gateway_error',
  RateLimitError = 'rate_limit_error',
  ValidationError = 'validation_error'
}
export enum Shift4ErrorCode {
  InvalidNumber = 'invalid_number',
  IncompleteNumber = 'incomplete_number',
  InvalidExpiry = 'invalid_expiry',
  InvalidExpiryMonth = 'invalid_expiry_month',
  InvalidExpiryYear = 'invalid_expiry_year',
  IncompleteExpiry = 'incomplete_expiry',
  InvalidCvc = 'invalid_cvc',
  IncorrectCvc = 'incorrect_cvc',
  IncompleteCvc = 'incomplete_cvc',
  IncorrectZip = 'incorrect_zip',
  ExpiredCard = 'expired_card',
  InsufficientFunds = 'insufficient_funds',
  LostOrStolen = 'lost_or_stolen',
  SuspectedFraud = 'suspected_fraud',
  LimitExceeded = 'limit_exceeded',
  CardDeclined = 'card_declined',
  ProcessingError = 'processing_error',
  Blacklisted = 'blacklisted',
  ExpiredToken = 'expired_token',
  AuthenticationRequired = 'authentication_required',
  DoNotTryAgain = 'do_not_try_again',
  CurrencyNotSupported = 'currency_not_supported',
  BrandNotSupported = 'brand_not_supported',
}

export interface Shift4Error {
  type: Shift4ErrorType;
  code: Shift4ErrorCode;
  message: string;
}
