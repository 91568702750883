import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { Transition } from 'react-transition-group';

import KEYS from './keys';
import useStyles from './styles';

interface TooltipProps {
  content: string | ReactNode;
  children: ReactNode;
  tooltipProps?: any;
}

const Tooltip = ({
  content,
  tooltipProps,
  children,
}: TooltipProps) => {
  const { classes, cx } = useStyles({ transitionTime: KEYS.TOOLTIP_TRANSITION_TIME });
  const [isVisible, setIsVisible] = useState(false);
  const [isPressing, setIsPressing] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const nodeRef = useRef(null);

  const show = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsVisible(true);
  };

  const hide = () => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, KEYS.HIDE_TOOLTIP_TIMEOUT);
  };

  const press = () => {
    setIsPressing(true);
  };

  const unpress = () => {
    setIsPressing(false);
  };

  useEffect(() => {
    window.addEventListener('mouseup', unpress);
    return () => {
      window.removeEventListener('mouseup', unpress);
    };
  }, []);

  return (
    <>
      <Transition
        nodeRef={nodeRef}
        in={isVisible}
        timeout={KEYS.TOOLTIP_TRANSITION_TIME}
        unmountOnExit
      >
        {state => (
          <div
            tabIndex={0}
            role="button"
            ref={nodeRef}
            onMouseDown={press}
            onMouseUp={unpress}
            className={cx(
              classes.tooltip,
              // @ts-ignore
              classes[state],
              tooltipProps?.className,
              {
                [classes.pressing]: isPressing,
              },
            )}
          >
            {content}
          </div>

        )}
      </Transition>
      <div>
        <div
          onMouseEnter={show}
          onMouseLeave={hide}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Tooltip;
