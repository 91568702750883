export const KEYS = {
  COUNTY_INPUT_ID: 'countryInput',
};

const MESSAGES_NAMESPACE = 'countryCheck';
export const LABELS = {
  INFO_BLOCK_TEXT_PARAGRAPH_1: `${MESSAGES_NAMESPACE}.infoBlockTextParagraph1`,
  INFO_BLOCK_TEXT_PARAGRAPH_2: `${MESSAGES_NAMESPACE}.infoBlockTextParagraph2`,
  INFO_BLOCK_TEXT_PARAGRAPH_3: `${MESSAGES_NAMESPACE}.infoBlockTextParagraph3`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  HEADER_CAPTION: `${MESSAGES_NAMESPACE}.headerCaption`,
  COUNTRY_INPUT_TITLE: `${MESSAGES_NAMESPACE}.countryInputTitle`,
  RECEIVED_CRYPTO: `${MESSAGES_NAMESPACE}.receivedCrypto`,
  CHOOSE_COUNTRY: `${MESSAGES_NAMESPACE}.chooseCountry`,
  COUNTRIES_SEARCH: `${MESSAGES_NAMESPACE}.countriesSearch`,
  COUNTRIES_ALPHABETICAL: `${MESSAGES_NAMESPACE}.countriesAlphabetical`,
  CONTINUE: `${MESSAGES_NAMESPACE}.continue`,
};

export default KEYS;
