import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M10.0001 18.3334C8.8473 18.3334 7.76397 18.1145 6.75008 17.6767C5.73619 17.2395 4.85425 16.6459',
  '4.10425 15.8959C3.35425 15.1459 2.76064 14.264 2.32341 13.2501C1.88564 12.2362 1.66675 11.1529',
  '1.66675 10.0001C1.66675 8.8473 1.88564 7.76397 2.32341 6.75008C2.76064 5.73619 3.35425 4.85425',
  '4.10425 4.10425C4.85425 3.35425 5.73619 2.76036 6.75008 2.32258C7.76397 1.88536 8.8473 1.66675',
  '10.0001 1.66675C11.1529 1.66675 12.2362 1.88536 13.2501 2.32258C14.264 2.76036 15.1459 3.35425',
  '15.8959 4.10425C16.6459 4.85425 17.2395 5.73619 17.6767 6.75008C18.1145 7.76397 18.3334 8.8473',
  '18.3334 10.0001C18.3334 11.1529 18.1145 12.2362 17.6767 13.2501C17.2395 14.264 16.6459 15.1459',
  '15.8959 15.8959C15.1459 16.6459 14.264 17.2395 13.2501 17.6767C12.2362 18.1145 11.1529 18.3334',
  '10.0001 18.3334ZM10.0001 16.6667C11.8612 16.6667 13.4376 16.0209 14.7292 14.7292C16.0209 13.4376',
  '16.6667 11.8612 16.6667 10.0001C16.6667 9.25008 16.5451 8.52786 16.3017 7.83341C16.059 7.13897',
  '15.7084 6.50008 15.2501 5.91675L5.91675 15.2501C6.50008 15.7084 7.13897 16.059 7.83341 16.3017C8.52786',
  '16.5451 9.25008 16.6667 10.0001 16.6667ZM4.75008 14.0834L14.0834 4.75008C13.5001 4.29175 12.8612 3.94119',
  '12.1667 3.69841C11.4723 3.45508 10.7501 3.33341 10.0001 3.33341C8.13897 3.33341 6.56258 3.97925 5.27091',
  '5.27091C3.97925 6.56258 3.33341 8.13897 3.33341 10.0001C3.33341 10.7501 3.45508 11.4723 3.69841 12.1667C3.94119',
  '12.8612 4.29175 13.5001 4.75008 14.0834Z',
].join(' ');

export const IconMenuLogout = ({
  width = 20,
  height = 20,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
      />
    </svg>
  );
};
export default IconMenuLogout;
