export const KEYS = {
  FORM_ID: 'form',
  TERMS_OF_SERVICE_EXPANDED_BY_DEFAULT: false,
};

const MESSAGES_NAMESPACE = 'components.layout';
export const LABELS = {
  FOOTER_ZERO_HASH: `${MESSAGES_NAMESPACE}.footerZeroHash`,
  FOOTER: `${MESSAGES_NAMESPACE}.footer`,
  LOGO: `${MESSAGES_NAMESPACE}.logo`,
  TERMS_OF_SERVICE: `${MESSAGES_NAMESPACE}.termsOfService`,
  TERMS_OF_SERVICE_PARAGRAPH_1: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph1`,
  TERMS_OF_SERVICE_PARAGRAPH_2: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph2`,
  TERMS_OF_SERVICE_PARAGRAPH_3: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph3`,
  TERMS_OF_SERVICE_PARAGRAPH_4: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph4`,
  TERMS_OF_SERVICE_PARAGRAPH_5: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph5`,
  TERMS_OF_SERVICE_PARAGRAPH_6: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph6`,
  TERMS_OF_SERVICE_PARAGRAPH_7: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph7`,
  TERMS_OF_SERVICE_PARAGRAPH_8: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph8`,
  TERMS_OF_SERVICE_PARAGRAPH_9: `${MESSAGES_NAMESPACE}.termsOfServiceTextParagraph9`,
  FOOTER_COPYRIGHT: `${MESSAGES_NAMESPACE}.footerCopyright`,
};

export default KEYS;
