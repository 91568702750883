import { ScreenNavigation } from 'hooks/useNavigation';
import canGoBackFromBankDetailsSell, { getBankDetailsNextStepSell, getBankDetailsPreviousStepSell } from 'navigation/bankDetails.sell';
import getCheckoutPreviousStepSell from 'navigation/checkout.sell';
import { canGoBackFromCountryCheck, getCountryCheckNextStep, getCountryCheckPreviousStep } from 'navigation/countryCheck';
import { canGoBackFromKyc, getKycNextStep, getKycPreviousStep } from 'navigation/kyc';
import getOtpScreenNextStepSell from 'navigation/otp.sell';
import getPaymentMethodNextStep from 'navigation/paymentMethod';
import canGoBackFromPaymentMethodsSell from 'navigation/paymentMethod.sell';
import { getQuoteScreenNextStepSell, getQuoteScreenPreviousStepSell } from 'navigation/quote.sell';
import canGoBackFromSignInScreen from 'navigation/signIn';
import { canGoBackFromTransactionProcessingStep } from 'navigation/transactionProcessing';
import getTransactionProcessingPreviousStepSell from 'navigation/transactionProcessing.sell';
import getTransferCryptoPreviousStep, { canGoBackFromTransferCrypto } from 'navigation/transferCrypto';
import { WorkflowStep } from 'types/step';

type SellSteps = keyof Omit<typeof WorkflowStep, 'Wallet'>

export const sellPagesMap: Record<SellSteps, ScreenNavigation> = {
  [WorkflowStep.Quote]: {
    getNextStep: getQuoteScreenNextStepSell,
    getPreviousStep: getQuoteScreenPreviousStepSell,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.SignIn]: {
    getNextStep: () => WorkflowStep.Otp,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: canGoBackFromSignInScreen,
  },
  [WorkflowStep.Otp]: {
    getNextStep: getOtpScreenNextStepSell,
    getPreviousStep: () => WorkflowStep.SignIn,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.CountryCheck]: {
    getNextStep: getCountryCheckNextStep,
    getPreviousStep: getCountryCheckPreviousStep,
    getCanGoBackValue: canGoBackFromCountryCheck,
  },
  [WorkflowStep.IpCountryUnavailable]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.CountryCheck,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.CountryUnavailable]: {
    getNextStep: () => WorkflowStep.CountryUnavailableFinal,
    getPreviousStep: () => WorkflowStep.CountryCheck,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.CountryUnavailableFinal]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.CountryUnavailable,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.Kyc]: {
    getNextStep: getKycNextStep,
    getPreviousStep: getKycPreviousStep,
    getCanGoBackValue: canGoBackFromKyc,
  },
  [WorkflowStep.KycRejected]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.PaymentMethods]: {
    getNextStep: getPaymentMethodNextStep,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: canGoBackFromPaymentMethodsSell,
  },
  [WorkflowStep.BankDetails]: {
    getNextStep: getBankDetailsNextStepSell,
    getPreviousStep: getBankDetailsPreviousStepSell,
    getCanGoBackValue: canGoBackFromBankDetailsSell,
  },
  [WorkflowStep.RequestNewCard]: {
    getNextStep: () => WorkflowStep.OrderProcessed,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.Checkout]: {
    getNextStep: () => WorkflowStep.TransferCrypto,
    getPreviousStep: getCheckoutPreviousStepSell,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.ConfirmNewQuote]: {
    getNextStep: () => WorkflowStep.OrderProcessed,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.TransferCrypto]: {
    getNextStep: () => WorkflowStep.AwaitingCrypto,
    getPreviousStep: getTransferCryptoPreviousStep,
    getCanGoBackValue: canGoBackFromTransferCrypto,
  },
  [WorkflowStep.AwaitingCrypto]: {
    getNextStep: () => WorkflowStep.TransactionProcessing,
    getPreviousStep: () => WorkflowStep.TransferCrypto,
    getCanGoBackValue: () => true,
  },
  [WorkflowStep.TransactionProcessing]: {
    getNextStep: () => WorkflowStep.OrderProcessed,
    getPreviousStep: getTransactionProcessingPreviousStepSell,
    getCanGoBackValue: canGoBackFromTransactionProcessingStep,
  },
  [WorkflowStep.OrderProcessed]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => false,
  },
  [WorkflowStep.Error]: {
    getNextStep: () => WorkflowStep.Quote,
    getPreviousStep: () => WorkflowStep.Quote,
    getCanGoBackValue: () => true,
  },
};

export default sellPagesMap;
