import { createApi } from '@reduxjs/toolkit/query/react';

import fetchBaseQueryWithCustomHeaders from 'services/api';
import { Endpoint } from 'services/endpoints';
import { PartnerConfig } from 'types/config';

const REDUCER_PATH = 'accountsApi';

export interface AccountResponse {
  data: PartnerConfig;
}

export const accountsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(),
  endpoints: builder => ({
    getAccountInfoWithApiKey: builder.query<AccountResponse, void>({
      query: () => Endpoint.AccountMe,
    }),
    getAccountInfo: builder.query<AccountResponse, string>({
      query: slug => Endpoint.Account(slug),
    }),
  }),
});

export default accountsApi;
