import { useThemeContext } from 'theme';
import { QALocator } from 'utils/tests/QA';

import { IconProps } from './types';

const pathDefault = [
  'M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10',
  '17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z',
].join(' ');

const pathOutline = [
  'M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22',
  '12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12',
  '4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z',
].join(' ');

interface IconCheckCircleProps extends IconProps {
  isOutline?: boolean;
  disabled?: boolean;
}

export const IconCheckCircle = ({
  width = 24,
  height = 24,
  color,
  className,
  isOutline = false,
  disabled = false,
  ...otherProps
}: IconCheckCircleProps) => {
  const { theme } = useThemeContext();
  const getIconFillColor = () => {
    if (!color) {
      if (disabled) {
        return theme.colors.text.disabled;
      }
      return (isOutline ? theme.colors.icons.inactive : theme.colors.icons.accentDark);
    }

    return color;
  };

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...QALocator(otherProps['data-cy'])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={isOutline ? pathOutline : pathDefault}
        fill={getIconFillColor()}
      />
    </svg>
  );
};
export default IconCheckCircle;
