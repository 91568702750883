import { ReactNode } from 'react';

import CopyButton from 'components/copyButton';
import OptionallyVisible from 'components/optionallyVisible';

import useStyles from './styles';

interface CardRowProps {
  iconStart?: ReactNode;
  label?: string;
  children: ReactNode;
  isCopyable?: boolean;
  textToCopy?: string;
  inline?: boolean;
  iconEnd?: ReactNode;
}

const CardRow = ({
  iconStart,
  label,
  children,
  isCopyable,
  textToCopy,
  inline = false,
  iconEnd,
}: CardRowProps) => {
  const { classes } = useStyles({ inline });

  return (
    <div className={classes.rowWrapper}>
      <OptionallyVisible visible={Boolean(label)}>
        <label className={classes.label} htmlFor={label}>
          {label}
        </label>
      </OptionallyVisible>
      <div id={label} className={classes.rowContainer}>
        <OptionallyVisible visible={Boolean(iconStart)}>
          <div>
            {iconStart}
          </div>
        </OptionallyVisible>
        <div className={classes.rowText}>
          {children}
        </div>
        <OptionallyVisible visible={Boolean(isCopyable && textToCopy)}>
          <CopyButton textToCopy={textToCopy!} />
        </OptionallyVisible>
        <OptionallyVisible visible={Boolean(iconEnd)}>
          <div>
            {iconEnd}
          </div>
        </OptionallyVisible>
      </div>
    </div>
  );
};

export default CardRow;
