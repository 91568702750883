import { NavigationEvaluationFunc } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';

import getInitialStep from './init';
import { checkIfShouldSkipWalletStep } from './queryParams';

export const getBankDetailsPreviousStep: NavigationEvaluationFunc<WorkflowStep> = ({ user }) => {
  const skipWalletStep = checkIfShouldSkipWalletStep();
  if (skipWalletStep) {
    return WorkflowStep.Quote;
  }

  if (!user?.defaultPaymentMethod) {
    return WorkflowStep.PaymentMethods;
  }

  return WorkflowStep.Wallet;
};

export const canGoBackFromBankDetails: NavigationEvaluationFunc<boolean> = ({ user }) => {
  const initialStep = getInitialStep({ user });
  const shouldSkipWalletStep = checkIfShouldSkipWalletStep();
  if (!shouldSkipWalletStep) {
    return true;
  }

  return initialStep !== WorkflowStep.BankDetails;
};
