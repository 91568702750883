import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.scale(15),
      color: theme.colors.text.inactive,
      marginBottom: theme.scale(15),
      ...fontSizeMixin(FontSize.M),
    },
    error: {
      color: theme.colors.text.error,
      ...fontSizeMixin(FontSize.M),
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: `${theme.scale(10)}px 0`,
    },
    tilesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(8),
    },
  }),
);

export default useStyles;
