import isAmountUnlocked from 'config/amount';
import getEmailFromQueryParams from 'config/email';
import getOrderFromQueryParams from 'config/order';
import { isWalletStepSkippable } from 'config/wallet';
import API_CONFIG from 'constants/api';
import QUERY_PARAMS from 'constants/queryParams';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import getInitialStep from 'navigation/init';
import { doesPathMatchCurrentPath, getPathParams } from 'pages/route/utils';
import accountsApi from 'services/account';
import assetsApi from 'services/assets';
import paymentsApi, { getAvailableUserPaymentMethod } from 'services/payments';
import transactionApi from 'services/transaction';
import userApi from 'services/user';
import { setError } from 'state/slices/errorSlice';
import { changeFlow } from 'state/slices/flowSlice';
import { setOrder, setOrderEmail } from 'state/slices/orderSlice';
import { changeStep } from 'state/slices/stepSlice';
import { AppDispatch, AppStore } from 'state/store';
import { ScreenErrorType } from 'types/errors/errorScreen';
import Flow from 'types/flow';
import { Order } from 'types/order';
import { ApplePayBridgeAction, ApplePayBridgeMessage } from 'types/payments/applePay';
import { PreloadedValues } from 'types/preload';
import ConfigQueryParam from 'types/queryParams';
import WorkflowStep from 'types/step';
import { KycStatus } from 'types/user';
import Color from 'utils/colors/color';
import getAvailableFlows from 'utils/flow';
import isRunningInsideIframe from 'utils/iframe';
import PATHS, { isPartnerPage } from 'utils/navigation';
import getQueryParam from 'utils/queryParams';
import Storage from 'utils/storage';

const canSkipQuoteScreen = (order: Partial<Order>) => [
  order.isFiatAssetForced,
  order.isCryptoAssetForced,
  order.isCryptoAmountForced || order.isFiatAmountForced,
  !isAmountUnlocked(),
].every(Boolean);

const presetFromParams = async (store: AppStore): Promise<PreloadedValues> => {
  let preloadedValues: PreloadedValues = {};
  const hasQueryParamsToPreload = Object.values(ConfigQueryParam).some(queryParam => Boolean(getQueryParam(queryParam)));
  if (!hasQueryParamsToPreload) {
    return preloadedValues;
  }

  const order = await getOrderFromQueryParams(store, store.dispatch);
  if (order) {
    preloadedValues = {
      ...preloadedValues,
      skipQuoteScreen: canSkipQuoteScreen(order),
      skipWalletScreen: isWalletStepSkippable(order.walletAddress, order.tagOrMemo),
    };

    store.dispatch(setOrder(order));
  }

  const shouldSetupEmailFromQueryParams = preloadedValues.skipQuoteScreen && !Storage.get(API_CONFIG.TOKEN_KEY);
  if (shouldSetupEmailFromQueryParams) {
    const email = getEmailFromQueryParams();
    if (email) {
      preloadedValues = {
        ...preloadedValues,
      };
      store.dispatch(setOrderEmail(email));
    }
  }

  return preloadedValues;
};

const applyDashboardConfig = async (store: AppStore) => {
  const apiKey = getQueryParam(QUERY_PARAMS.API_KEY);
  const isLandingPage = isPartnerPage();
  if (!apiKey || isLandingPage) {
    return;
  }

  const config = await store.dispatch(accountsApi.endpoints.getAccountInfoWithApiKey.initiate());
  if ('error' in config) {
    return;
  }

  window.partnerConfig = config.data?.data;

  const { offRampEnabled, onRampEnabled } = window.partnerConfig!;
  const availableFLows = getAvailableFlows({ offRampEnabled, onRampEnabled });
  const defaultFlow = availableFLows[0] || Flow.Buy;

  store.dispatch(changeFlow(defaultFlow));
};

const applyQueryParamTheme = () => {
  const theme = getQueryParam(ConfigQueryParam.Theme);
  const canSetTheme = theme?.length && Color.isValidHexColor(theme);
  if (canSetTheme) {
    window.s4cTheme = theme;
  }
};

const customizeFlow = async (store: AppStore) => {
  const isLandingPage = isPartnerPage();
  const isBothFlowsDisabled = !window?.partnerConfig?.offRampEnabled && !window?.partnerConfig?.onRampEnabled;
  const hasApiKey = Boolean(getQueryParam(QUERY_PARAMS.API_KEY));
  const showNoFlowsAvailableError = isBothFlowsDisabled && !isLandingPage && hasApiKey;
  if (showNoFlowsAvailableError) {
    const error = getErrorScreenMessage(ScreenErrorType.FlowError);
    store.dispatch(setError({ ...error, isFatalError: true }));
    store.dispatch(changeStep(WorkflowStep.Error));
    return;
  }

  const ignoreRoute = [
    doesPathMatchCurrentPath(PATHS.RECEIPT_PATH, window.location.pathname),
    doesPathMatchCurrentPath(PATHS.TERMS_OF_USE_PATH, window.location.pathname),
    doesPathMatchCurrentPath(PATHS.PRIVACY_POLICY_PATH, window.location.pathname),
  ].some(Boolean);
  if (ignoreRoute) {
    return;
  }

  window.preloadedConfig = await presetFromParams(store);

  const token = Storage.get(API_CONFIG.TOKEN_KEY);

  const { flow } = store.getState().flow;

  let orderStatus = null;
  if (!token) {
    const initialStep = getInitialStep({ flow, orderStatus });
    store.dispatch(changeStep(initialStep));
    return;
  }

  const userQueryResult = await store.dispatch(userApi.endpoints.getUserInfo.initiate(token, { forceRefetch: true }));
  const user = userQueryResult.data?.data;
  const pathParams = getPathParams(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  if (user && pathParams.orderId) {
    orderStatus = await store.dispatch(transactionApi.endpoints.getTransaction.initiate({
      transactionId: pathParams.orderId,
      includeToken: true,
    }));
    orderStatus = orderStatus?.data?.data.order;

    if (orderStatus) {
      const flow = orderStatus.side;
      const initialStep = getInitialStep({ flow, orderStatus });
      await store.dispatch(assetsApi.endpoints.getActiveAssetsSell.initiate());
      store.dispatch(changeFlow(orderStatus.side));
      store.dispatch(changeStep(initialStep));
    }
  }

  if (user) {
    let defaultCard;
    if (user.kycStatus === KycStatus.Approved) {
      const cards = await store.dispatch(paymentsApi.endpoints.getUserCards.initiate(token));
      defaultCard = cards.data?.find(card => card.isDefault);
    }
    const availablePaymentMethods = await store.dispatch(paymentsApi.endpoints.getPaymentMethods.initiate());
    const userAvailablePaymentMethod = user.defaultPaymentMethod && availablePaymentMethods.data
      ? getAvailableUserPaymentMethod(user.defaultPaymentMethod, availablePaymentMethods.data, flow)
      : null;
    const initialStep = getInitialStep({
      user,
      flow,
      orderStatus,
      paymentMethod: {
        default: userAvailablePaymentMethod,
        defaultCard,
      },
    });
    store.dispatch(changeStep(initialStep));
    store.dispatch(userApi.endpoints.getUserPendingOrders.initiate(1));
    return;
  }

  const initialStep = getInitialStep({ flow, orderStatus });
  store.dispatch(changeStep(initialStep));
};

const handleApplePayAvailabilityMessage = (message: MessageEvent<ApplePayBridgeMessage>) => {
  window.isTopLevelApplePayAvailable = message.data.isApplePayAvailable ?? false;
};

const handleParentDomainMessage = (message: MessageEvent<ApplePayBridgeMessage>) => {
  const widgetId = getQueryParam(ConfigQueryParam.Id);
  if (message.data.widgetId !== widgetId) {
    return;
  }

  const shouldAskForTheAPAvailability = message.data.action === ApplePayBridgeAction.isApplePayAvailable
      && window.isTopLevelApplePayAvailable === undefined;
  if (shouldAskForTheAPAvailability) {
    handleApplePayAvailabilityMessage(message);
  }
};

const listenToParentDomainMessages = () => {
  window.removeEventListener('message', handleParentDomainMessage);
  window.addEventListener('message', handleParentDomainMessage);
};

const fetchPaymentMethods = async (dispatch: AppDispatch) => {
  const hasPaymentMethodQueryParam = Boolean(getQueryParam(ConfigQueryParam.PaymentMethod));
  if (!hasPaymentMethodQueryParam) {
    return null;
  }

  const paymentMethods = await dispatch(paymentsApi.endpoints.getPaymentMethods.initiate());

  return paymentMethods;
};

export const preload = async (store: AppStore) => {
  await fetchPaymentMethods(store.dispatch);

  if (isRunningInsideIframe()) {
    listenToParentDomainMessages();
  }
  await applyDashboardConfig(store);
  applyQueryParamTheme();
  await customizeFlow(store);
};

export default preload;
