import { Trans } from 'react-i18next';

import LABELS from './keys';
import { useStyles } from './styles';

const CaptchaDisclaimer = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.disclaimerWrapper}>
      <Trans i18nKey={LABELS.CAPTCHA_DISCLAIMER} components={{ br: <br /> }} />
    </div>
  );
};

export default CaptchaDisclaimer;
