import WalletImage from 'assets/images/wallet.svg';
import { getIllustrationCSS } from 'illustrations/styles';
import { Illustration } from 'illustrations/types';
import { tss, useThemeContext } from 'theme';

export const getColorBasedMasks = (theme: ReturnType<typeof useThemeContext>['theme']) => ([
  { color: theme.colors.illustrations.saturation98, layerIds: [1, 4] },
  { color: theme.colors.illustrations.saturation95, layerIds: [0] },
  { color: theme.colors.illustrations.saturation65, layerIds: [3, 8] },
  { color: theme.colors.illustrations.saturation51, layerIds: [5, 6, 7, 9, 11] },
  { color: theme.colors.illustrations.saturation86, layerIds: [10, 24, 30, 31, 32] },
  { color: theme.colors.illustrations.saturation65, layerIds: [2, 12, 13, 22] },
  { color: theme.colors.illustrations.saturation51, layerIds: [14, 23, 27, 28] },
  { color: theme.colors.illustrations.saturation86, layerIds: [15] },
  { color: theme.colors.illustrations.background, layerIds: [16, 17, 18, 19, 20, 21, 25, 29] },
  { color: theme.colors.illustrations.saturation51, layerIds: [26] },
]);

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      ...getIllustrationCSS(
        Illustration.Wallet,
        getColorBasedMasks(theme),
        WalletImage,
      ),
    },
  }),
);

export default useStyles;
