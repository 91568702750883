export enum ApiErrorCode {
  Authentication = 'err.authentication',
  RegionError = 'err.notSupportedRegion',
  RateLimit = 'err.rateLimit',
  WrongEmailOrCode = 'err.wrongUsernameOrVerificationCode',
  ProcessStarted = 'err.kycProcessAlreadyStarted',
  CardAuthorizationFailed = 'err.cardAuthorizationFailed',
  CardCaptureFailed = 'err.cardCaptureFailed',
  InvalidAmount = 'err.invalidAmount',
  InvalidApiKey = 'err.invalidAccountKey',
  FlowError = 'err.flowError',
}

export type APIErrorResponse = {
  data: APIError;
}

export type APIError = {
    errorMessage: string;
    errorType: string;
    meta: {
      errorCode?: ApiErrorCode;
      validationErrorMessage?: string;
      failedAttributes?: {
        attributeName: string;
        message: string;
        path: string[];
      }[];
  };
  requestId: string;
}

export type APIResponse<T extends object> = {
  data: T;
  requestId: string;
}

export default ApiErrorCode;
