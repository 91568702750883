import { ReactNode, useState } from 'react';

import MenuList from 'components/menuList';
import OptionallyVisible from 'components/optionallyVisible';
import IconChevron from 'icons/chevron';
import IconMenu from 'icons/menu';
import { OrderStatus } from 'types/order';
import { locators, QALocator } from 'utils/tests/QA';

import withGlobalHeaderProps, { HeaderPropsProviderValues } from './provider';
import useStyles from './styles';

export interface HeaderProps extends HeaderPropsProviderValues {
  hasBackButton?: boolean;
  onBackClick?: () => void;
  children: ReactNode;
}

export const Header = ({
  hasBackButton = false,
  onBackClick,
  children,
  pendingOrders,
  isContinueFlow,
}: HeaderProps) => {
  const { classes, theme, cx } = useStyles();
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const shouldShowDotIndicator = pendingOrders?.some(order => [
    OrderStatus.PaymentFailed,
    OrderStatus.AwaitingClientConfirmation,
  ].includes(order.status));

  const closeMenu = () => {
    setIsMenuModalVisible(false);
  };

  const openMenu = () => {
    setIsMenuModalVisible(true);
  };

  return (
    <>

      <div className={classes.container}>
        <OptionallyVisible visible={hasBackButton}>
          <button
            type="button"
            className={cx(classes.button, classes.backButton)}
            onClick={onBackClick}
            {...QALocator(locators.components.common.goBackButton)}
          >
            <IconChevron color={theme.colors.text.inactive} />
          </button>
        </OptionallyVisible>
        <div className={classes.marginAlignment}>
          {children}
        </div>
        <button
          type="button"
          className={cx(classes.button, classes.menuButton)}
          onClick={openMenu}
          {...QALocator(locators.components.common.menuButton)}
        >
          <IconMenu showDotIndicator={shouldShowDotIndicator && !isContinueFlow} color={theme.colors.text.inactive} />
        </button>
      </div>
      <MenuList visible={isMenuModalVisible} closeMenu={closeMenu} />
    </>
  );
};

export default withGlobalHeaderProps(Header);
