import { ButtonProps } from 'components/button';
import LoadingWrapper from 'components/loading';
import Spinner from 'components/spinner';

import useStyles from './action.styles';

interface ActionProps extends ButtonProps {
  isLoading: boolean;
}

const Action = ({ isLoading, children }: ActionProps) => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.actionButton}>
      <LoadingWrapper
        isLoading={isLoading}
        loadingIndicator={<Spinner scale={theme.scale(1.25)} />}
      >
        <div>
          {children}
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default Action;
