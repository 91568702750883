import { NavigationEvaluationFunc, NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';
import { KycStatus } from 'types/user';

import getInitialStep from './init';
import { checkIfShouldSkipQuoteStep, checkIfShouldSkipWalletStep } from './queryParams';

export const getKycNextStep: NavigationEvaluationFunc<WorkflowStep> = (
  { user, paymentMethod }: NavigationEvaluationFuncParams,
) => {
  if (!user) {
    throw new Error('Unable to proceed from Kyc screen, no user information');
  }

  const skipWalletScreen = checkIfShouldSkipWalletStep();
  const skipPaymentMethods = Boolean(paymentMethod?.default);
  const skipBankDetails = Boolean(paymentMethod?.defaultCard) || skipPaymentMethods;

  if (user.isResidenceCountrySupported) {
    if (user.isResidenceStateSupported) {
      if (user.isIpCountrySupported) {
        if (user.isIpStateSupported) {
          if (user.kycStatus === KycStatus.Approved) {
            if (skipWalletScreen) {
              if (skipPaymentMethods) {
                if (skipBankDetails) {
                  return WorkflowStep.Checkout;
                }
                return WorkflowStep.BankDetails;
              }
              return WorkflowStep.PaymentMethods;
            }
            return WorkflowStep.Wallet;
          }
          if (user.kycStatus === KycStatus.PendingApproval) {
            return WorkflowStep.Kyc;
          }
          return WorkflowStep.KycRejected;
        }
        return WorkflowStep.IpCountryUnavailable;
      }
      return WorkflowStep.IpCountryUnavailable;
    }
    return WorkflowStep.CountryUnavailable;
  }
  return WorkflowStep.CountryUnavailable;
};

export const getKycPreviousStep: NavigationEvaluationFunc<WorkflowStep> = () => WorkflowStep.Quote;

export const canGoBackFromKyc: NavigationEvaluationFunc<boolean> = ({ user }) => {
  const initialStep = getInitialStep({ user });
  const skipQuoteStep = checkIfShouldSkipQuoteStep();

  if (skipQuoteStep) {
    return false;
  }
  return initialStep !== WorkflowStep.Kyc;
};
