import COMMON_LABELS from 'constants/messages';
import i18n from 'locales/i18next';
import { AssetWithLabel } from 'types/assets';
import ConfigQueryParam from 'types/queryParams';
import getQueryParam from 'utils/queryParams';

const assignGroups = (assets: AssetWithLabel[]) => {
  const popularCodesSetup = getQueryParam(ConfigQueryParam.PopularCryptoList)?.split(',')
    ?? window.partnerConfig?.popularCryptoCurrenciesCodes;

  const fallbackPopularCrypto = window.s4cConfig.prefilledValues?.popularCrypto ?? [];
  const popularCodes = popularCodesSetup ?? fallbackPopularCrypto;

  const popular = assets.filter(asset => popularCodes?.includes(asset.symbol));
  const rest = assets.filter(asset => !popularCodes?.includes((asset.symbol)));

  return [...popular, ...rest].map((asset) => {
    const isSetPopular = popularCodes?.includes(asset.symbol);

    return ({
      ...asset,
      group: isSetPopular ? i18n.t(COMMON_LABELS.POPULAR) : i18n.t(COMMON_LABELS.ALL),
    });
  });
};

export default assignGroups;
