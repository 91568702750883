import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    resendOtpContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(10),
      textAlign: 'center',
    },
    text: {
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
      lineHeight: `${theme.scale(20)}px`,
    },
    buttonBlock: {
      display: 'flex',
      gap: theme.scale(5),
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: theme.scale(20),
    },
    loaderBlock: {
      width: theme.scale(20),
      minHeight: theme.scale(20),
    },
    resendCodeButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      margin: 0,
      padding: 0,
      color: theme.colors.text.accentDark,
      fontWeight: 600,
      ...fontSizeMixin(FontSize.M),
    },
    buttonDisabled: {
      color: theme.colors.text.inactive,
    },
    errorMessage: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.error,
    },
  }),
);

export default useStyles;
