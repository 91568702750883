import { tss } from 'theme';

export const useStyles = tss
  .create(
    ({ theme }) => ({
      googlePayButton: {
        width: '100%',
        button: {
          width: '100%',
        },
      },
      spinnerContainer: {
        height: 44,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  );

export default useStyles;
