import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import OptionallyVisible from 'components/optionallyVisible';
import Spinner from 'components/spinner';
import IconHistory from 'icons/history';
import { QuotePayload } from 'services/quote/transactions/payloads';
import { Quote } from 'types/assets';
import { ReduxToolkitResponse } from 'types/http';
import { locators, QALocator } from 'utils/tests/QA';

import LABELS from './keys';
import useStyles from './styles';

interface QuoteErrorTileProps {
  isQuoteLoading: boolean;
  fetchQuote: (overrideQuotePayload?: Partial<QuotePayload>) => Promise<void> | Promise<ReduxToolkitResponse<{ quote: Quote }>>;
  backendErrorMessage?: string;
}

const QuoteErrorTile = ({
  isQuoteLoading,
  fetchQuote,
  backendErrorMessage,
}: QuoteErrorTileProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  const errorMessage = backendErrorMessage || t(LABELS.ERROR_GENERIC);

  const onFetchQuoteClick = () => {
    fetchQuote();
  };

  return (
    <div className={classes.errorTile}>
      <div className={classes.genericError}>{errorMessage}</div>
      <div className={classes.separator} />
      <OptionallyVisible visible={isQuoteLoading}>
        <Spinner />
      </OptionallyVisible>
      <OptionallyVisible visible={!isQuoteLoading}>
        <Button
          className={classes.refreshButton}
          onClick={onFetchQuoteClick}
          {...QALocator(locators.components.common.quoteErrorTileRefreshButton)}
        >
          <IconHistory color={theme.colors.icons.accentDark} />
          {t(LABELS.REFRESH_BUTTON)}
        </Button>
      </OptionallyVisible>
    </div>
  );
};

export default QuoteErrorTile;
