import LaunchImage from 'assets/images/launch.svg';
import { getIllustrationCSS } from 'illustrations/styles';
import { Illustration } from 'illustrations/types';
import { tss, useThemeContext } from 'theme';

export const getColorBasedMasks = (theme: ReturnType<typeof useThemeContext>['theme']) => ([
  { color: theme.colors.illustrations.saturation95, layerIds: [4] }, // #F3F3F3,
  { color: theme.colors.illustrations.saturation86, layerIds: [1, 2, 3, 8, 9, 10, 11, 12, 20, 22, 35, 36] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation65, layerIds: [16, 17, 25] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation86, layerIds: [18] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation65, layerIds: [19, 23, 24] }, // #A5A5A5
  { color: theme.colors.illustrations.background, layerIds: [21] },
  { color: theme.colors.illustrations.saturation86, layerIds: [35] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation51, layerIds: [5, 7, 13, 14, 15, 31, 32, 33, 34, 41, 42] }, // #818181
  { color: theme.colors.illustrations.background, layerIds: [6, 39, 40] },
  { color: theme.colors.illustrations.saturation86, layerIds: [26, 27, 28, 29, 30, 46] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation95, layerIds: [45] }, // #F3F3F3,
  { color: theme.colors.illustrations.saturation65, layerIds: [37, 38] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation51, layerIds: [43, 44] }, // #818181
]);

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      ...getIllustrationCSS(
        Illustration.Launch,
        getColorBasedMasks(theme),
        LaunchImage,
      ),
    },
  }),
);

export default useStyles;
