import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M18.3334 4.99992V14.9999C18.3334 15.4583 18.1704 15.8508 17.8442 16.1774C17.5176',
  '16.5035 17.1251 16.6666 16.6667 16.6666H3.33341C2.87508 16.6666 2.48286 16.5035',
  '2.15675 16.1774C1.83008 15.8508 1.66675 15.4583 1.66675 14.9999V4.99992C1.66675',
  '4.54159 1.83008 4.14936 2.15675 3.82325C2.48286 3.49659 2.87508 3.33325 3.33341',
  '3.33325H16.6667C17.1251 3.33325 17.5176 3.49659 17.8442 3.82325C18.1704 4.14936',
  '18.3334 4.54159 18.3334 4.99992ZM3.33341 6.66659H16.6667V4.99992H3.33341V6.66659ZM3.33341',
  '9.99992V14.9999H16.6667V9.99992H3.33341ZM3.33341 14.9999V4.99992V14.9999Z',
].join(' ');

export const IconMenuCard = ({
  width = 20,
  height = 20,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
      />
    </svg>
  );
};
export default IconMenuCard;
