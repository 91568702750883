import { t } from 'i18next';

import { LABELS } from 'locales/keys';
import { ScreenError, ScreenErrorType } from 'types/errors/errorScreen';

const errorScreenMessages: () => Record<ScreenErrorType, Omit<ScreenError, 'errorType'>> = () => ({
  [ScreenErrorType.Generic]: {
    title: t(LABELS.GENERIC_TITLE),
    message: t(LABELS.GENERIC_MESSAGE),
  },
  [ScreenErrorType.Fatal]: {
    title: t(LABELS.FATAL_TITLE),
    message: t(LABELS.FATAL_MESSAGE),
  },
  [ScreenErrorType.AuthenticationFailed]: {
    title: t(LABELS.AUTH_TITLE),
    message: t(LABELS.AUTH_MESSAGE),
  },
  [ScreenErrorType.CardTokenizationFailed]: {
    title: t(LABELS.CARD_TITLE),
    message: t(LABELS.CARD_MESSAGE),
  },
  [ScreenErrorType.UnableToGetPaymentsConfig]: {
    title: t(LABELS.PAYMENT_CONFIG_TITLE),
    message: t(LABELS.PAYMENT_CONFIG_MESSAGE),
  },
  [ScreenErrorType.RegionUnsupported]: {
    title: t(LABELS.REGION_UNSUPPORTED_TITLE),
    message: t(LABELS.REGION_UNSUPPORTED_MESSAGE),
  },
  [ScreenErrorType.KycToken]: {
    title: t(LABELS.KYC_TOKEN_TITLE),
    message: t(LABELS.KYC_TOKEN_MESSAGE),
  },
  [ScreenErrorType.KycRejected]: {
    title: t(LABELS.KYC_REJECTED_TITLE),
    message: t(LABELS.KYC_REJECTED_MESSAGE),
  },
  [ScreenErrorType.UserStatus]: {
    title: t(LABELS.USER_STATUS_TITLE),
    message: t(LABELS.USER_STATUS_MESSAGE),
  },
  [ScreenErrorType.PaymentCompletionError]: {
    title: t(LABELS.PAYMENT_COMPLETE_TITLE),
    message: t(LABELS.PAYMENT_COMPLETE_MESSAGE),
  },
  [ScreenErrorType.UnableToGetCountriesList]: {
    title: t(LABELS.COUNTRIES_TITLE),
    message: t(LABELS.COUNTRIES_MESSAGE),
  },
  [ScreenErrorType.UnableToSubscribeToUpdates]: {
    title: t(LABELS.SUBSCRIBE_TO_UPDATES_TITLE),
    message: t(LABELS.SUBSCRIBE_TO_UPDATES_MESSAGE),
  },
  [ScreenErrorType.UnableToUpdateUserCountry]: {
    title: t(LABELS.PATCH_USER_COUNTRY_TITLE),
    message: t(LABELS.PATCH_USER_COUNTRY_MESSAGE),
  },
  [ScreenErrorType.InvalidApiKey]: {
    title: t(LABELS.API_KEY_TITLE),
    message: t(LABELS.API_KEY_MESSAGE),
  },
  [ScreenErrorType.FlowError]: {
    title: t(LABELS.FLOW_ERROR_TITLE),
    message: t(LABELS.FLOW_ERROR_MESSAGE),
  },
});

export const getErrorScreenMessage = (errorType: ScreenErrorType): ScreenError => {
  const error = errorScreenMessages()[errorType];

  return ({
    ...error,
    errorType,
  });
};

export default getErrorScreenMessage;
