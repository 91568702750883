import { tss } from 'theme';

export const useStyles = tss
  .create(
    ({ theme }) => ({
      container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.scale(15),
        alignItems: 'center',
        height: '100%',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },
      stepDescriptionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.scale(15),
      },
      stepDescription: {
        fontSize: theme.scale(14),
        color: theme.colors.text.inactive,
        textAlign: 'center',
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: theme.scale(12),
        fontWeight: 500,
        gap: theme.scale(12),
      },
      cardRowLabel: {
        display: 'flex',
        alignItems: 'center',
      },
      networkIcon: {
        width: theme.scale(16),
        height: theme.scale(16),
      },
    }),
  );

export default useStyles;
