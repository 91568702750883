import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@mui/base';
import { SelectProvider, useSelect } from '@mui/base/useSelect';

import OptionallyVisible from 'components/optionallyVisible';
import CardsListItem from 'components/paymentMethodsList/cardListItem';
import COMMON_LABELS from 'constants/messages';
import { SavedCard } from 'services/payments/types';
import { CardPaymentToken } from 'types/card';
import { ReduxToolkitResponse, SuccessResponse } from 'types/http';
import PaymentMethod from 'types/payment';

import { LABELS } from './keys';
import PaymentMethodListItem from './paymentMethodListItem';
import useStyles from './styles';

interface MappedCard {
  id: string;
  label: string;
  value: SavedCard;
}

interface PaymentMethodsListProps {
  cards: SavedCard[];
  onChange: (value: SavedCard | PaymentMethod | null) => void;
  canSelectPaymentMethod: boolean;
  userDefaultPaymentMethod: PaymentMethod | CardPaymentToken['id'] | null;
  availablePaymentMethods: PaymentMethod[];
  updateUserProps: {
    updateUser: (paymentMethod: PaymentMethod | CardPaymentToken['id']) => Promise<ReduxToolkitResponse<SuccessResponse>>;
    isLoading: boolean;
  };
  deleteCardProps: {
    deleteCard: (card: SavedCard | CardPaymentToken) => Promise<void>;
    isLoading: boolean;
    error: any;
  };
  defaultCardProps: {
    setDefaultCard: (card: SavedCard | CardPaymentToken) => Promise<ReduxToolkitResponse<SuccessResponse>>;
    isLoading: boolean;
    error: any;
  };
}

const PaymentMethodsList = ({
  cards,
  canSelectPaymentMethod,
  userDefaultPaymentMethod,
  availablePaymentMethods,
  onChange,
  updateUserProps,
  defaultCardProps,
  deleteCardProps,
}: PaymentMethodsListProps) => {
  const { classes } = useStyles({ canSelectPaymentMethod });
  const { t } = useTranslation();
  const availableCards = cards.filter(card => !card.isExpired);
  const unavailableCards = cards.filter(card => card.isExpired);
  const listboxRef = useRef<HTMLUListElement>(null);
  const {
    getListboxProps,
    contextValue,
  } = useSelect<SavedCard | PaymentMethod, false>({
    open: true,
    listboxRef,
    onChange: (_, value) => onChange(value),
    getSerializedValue: option => ((option as unknown as SavedCard)?.id
      ? (option as SelectOption< SavedCard >)?.id
      : (option as any)),
    areOptionsEqual: (a, b) => ((a as unknown as SavedCard)?.id ? (a as SavedCard)?.id === (b as SavedCard)?.id : a === b),
  });

  const sortByDefaultFirst = (a: MappedCard, b: MappedCard) => (b.value.isDefault ? 1 : 0) - (a.value.isDefault ? 1 : 0);

  const grouppedMethods = [{
    label: null,
    items: [
      {
        id: PaymentMethod.ApplePay,
        label: t(COMMON_LABELS.APPLE_PAY),
        value: PaymentMethod.ApplePay,
        disabled: !window.s4cConfig?.features?.applePay || !availablePaymentMethods.find(method => method === PaymentMethod.ApplePay),
      },
      {
        id: PaymentMethod.GooglePay,
        label: t(COMMON_LABELS.GOOGLE_PAY),
        value: PaymentMethod.GooglePay,
        disabled: !window.s4cConfig?.features?.googlePay || !availablePaymentMethods.find(method => method === PaymentMethod.GooglePay),
      },
    ].filter(method => !method.disabled),
  },
  {
    label: t(LABELS.SAVED),
    items: availableCards
      .map(card => ({
        id: card.id, label: card.brand, value: card, disabled: card.isExpired,
      }))
      .sort(sortByDefaultFirst),
  },
  {
    label: t(LABELS.UNAVAILABLE),
    items: unavailableCards.map(card => ({
      id: card.id, label: card.brand, value: card, disabled: card.isExpired,
    })),
  }]
    .filter(group => group.items.length);

  return (
    <div className={classes.list}>
      {grouppedMethods.map(paymentMethodsGroup => (
        <ul
          {...getListboxProps()}
          key={paymentMethodsGroup.label}
          className={classes.group}
        >
          <OptionallyVisible visible={Boolean(paymentMethodsGroup.label)}>
            <div className={classes.groupLabel}>
              {paymentMethodsGroup.label}
            </div>
          </OptionallyVisible>
          <SelectProvider value={contextValue}>
            {paymentMethodsGroup.items.map((paymentMethod) => {
              const isDefaultCardApplicable = userDefaultPaymentMethod === PaymentMethod.Card || !userDefaultPaymentMethod;
              const isDefaultCard = Boolean(isDefaultCardApplicable && (paymentMethod.value as SavedCard)?.id
                && (paymentMethod.value as SavedCard)?.isDefault);
              if ((paymentMethod.value as SavedCard)?.id) {
                return (
                  <CardsListItem
                    isDefault={isDefaultCard}
                    isClickable={canSelectPaymentMethod}
                    key={(paymentMethod.value as SavedCard).id}
                    card={(paymentMethod.value as SavedCard)}
                    updateUserProps={updateUserProps}
                    defaultCardProps={defaultCardProps}
                    deleteCardProps={deleteCardProps}
                  />
                );
              }

              return (
                <PaymentMethodListItem
                  isClickable={canSelectPaymentMethod}
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod.value as PaymentMethod}
                  isDefault={userDefaultPaymentMethod === paymentMethod.value}
                  updateUserProps={updateUserProps}
                  label={paymentMethod.label}
                />
              );
            })}
          </SelectProvider>
        </ul>
      ))}
    </div>
  );
};

export default PaymentMethodsList;
