import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(15),
      height: '100%',
      padding: 0,
      paddingBottom: theme.scale(10),
      paddingRight: theme.scale(10),
      overflow: 'hidden',
    },
    placeholderContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: theme.scale(15),
      height: '100%',
      padding: 0,
      paddingBottom: theme.scale(10),
      paddingRight: theme.scale(10),
    },
    orderListScrollable: {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: '100%',
    },
    orderList: {
      overflowY: 'auto',
      maxHeight: '100%',
      paddingRight: 20,
      width: 'calc(100% + 20px)',
      gap: theme.scale(3),
      paddingBottom: theme.scale(30),
    },
    placeholder: {
      flex: 1,
      marginTop: theme.scale(55),
      textAlign: 'center',
      color: theme.colors.text.inactive,
    },
    delimeter: {
      height: 1,
      borderBottom: `1px solid ${theme.colors.separator.primary}`,
    },
    shadow: {
      position: 'absolute',
      bottom: 10,
      width: '100%',
      boxShadow: `0px 0px 35px 35px ${theme.colors.background.primary}`,
    },
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    description: {
      color: theme.colors.text.inactive,
      fontWeight: 500,
      ...fontSizeMixin(FontSize.S),
    },
  }),
);

export default useStyles;
