import { ReactNode } from 'react';

import OptionallyVisible from 'components/optionallyVisible';

import useStyles from './styles';

interface CheckboxProps {
  id: string;
  label?: string | ReactNode;
  value: boolean;
  className?: string;
  onChange: () => void;
}

const Checkbox = ({
  id,
  label,
  value,
  className,
  onChange,
  ...props
}: CheckboxProps) => {
  const { classes, cx, theme } = useStyles();

  return (
    <label
      className={cx(classes.container, className)}
      htmlFor={id}
      {...props}
    >
      <input
        className={classes.input}
        id={id}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <span
        className={classes.fakeCheckbox}
      >
        <OptionallyVisible visible={value}>
          <div className={classes.checkmark}>
            <svg
              aria-hidden="true"
              focusable="false"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={theme.colors.background.primary}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={
              [
                'M19 3H5C3.89 3 3 3.9 3 5V19C3',
                '20.1 3.89 21 5 21H19C20.11 21',
                '21 20.1 21 19V5C21 3.9 20.11 3',
                '19 3ZM10 17L5 12L6.41 10.59L10',
                '14.17L17.59 6.58L19 8L10 17Z',
              ].join(' ')
            }
                fill={theme.colors.icons.accentDark}
              />
            </svg>
          </div>
        </OptionallyVisible>
      </span>
      <div className={classes.text}>
        {label}
      </div>
    </label>
  );
};

export default Checkbox;
