import { Asset, AssetWithLabel } from 'types/assets';

export const formatFiatCurrencyI18n = (locale: string) => (
  value: number,
  fiatCurrency: Asset | AssetWithLabel,
) => (
  Intl.NumberFormat(locale, {
    style: 'currency',
    currency: fiatCurrency.symbol,
    minimumFractionDigits: fiatCurrency.precision,
    maximumFractionDigits: fiatCurrency.precision,
  }).format(value)
);

export const formatFiatCurrencyI18nWithoutSymbol = (locale: string) => (
  value: number,
  fiatCurrency: AssetWithLabel,
) => (
  Intl.NumberFormat(locale, {
    style: 'currency',
    currency: fiatCurrency.symbol,
    minimumFractionDigits: fiatCurrency.precision,
    maximumFractionDigits: fiatCurrency.precision,
    currencyDisplay: 'code',
  })
    .format(value)
    .replace(fiatCurrency.symbol, '')
    .trim()
);

export const makeLabels = <T extends Record<string, string | object>>(
  labels: T,
  prefix: string,
): T => Object.entries(labels).reduce((accumulator, [key, value]) => {
    if (typeof value === 'string') {
      return { ...accumulator, [key]: `${prefix}.${value}` };
    }

    if (typeof value === 'object') {
      return { ...accumulator, [key]: makeLabels(value as Record<string, string | object>, prefix) };
    }

    return accumulator;
  }, labels);
