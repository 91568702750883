import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M4.5 2.13398C5.16667 2.51888 5.16667 3.48113 4.5 3.86603L1.5 5.59808C0.833333',
  '5.98298 7.75338e-08 5.50185 6.8354e-08 4.73205L2.7045e-08 1.26795C1.78652e-08',
  '0.498148 0.833334 0.0170237 1.5 0.401924L4.5 2.13398Z',
].join(' ');

export const IconArrow = ({
  width = 5,
  height = 6,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconArrow;
