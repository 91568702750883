import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { QuoteErrors } from 'services/quote/transactions/errors';
import ApiErrorCode from 'types/errors/api';

export const getMinimumAmountError = (queryError?: FetchBaseQueryError) => {
  const error = queryError?.data as QuoteErrors[ApiErrorCode.InvalidAmount];

  const isTargetError = error?.errorType === ApiErrorCode.InvalidAmount;
  if (!isTargetError) {
    return undefined;
  }

  if ('minAmount' in error.meta) {
    return error;
  }

  return undefined;
};

export const getMaximumAmountError = (queryError?: FetchBaseQueryError) => {
  const error = queryError?.data as QuoteErrors[ApiErrorCode.InvalidAmount];

  const isTargetError = error?.errorType === ApiErrorCode.InvalidAmount;
  if (!isTargetError) {
    return undefined;
  }

  if ('maxAmount' in error.meta) {
    return error;
  }

  return undefined;
};
