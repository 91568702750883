import { ReactNode } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/base/Button';

import OptionallyVisible from 'components/optionallyVisible';
import Spinner, { SpinnerProps } from 'components/spinner';
import IconChevron from 'icons/chevron';

import useStyles from './styles';

export interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
  isContinue?: boolean;
  isBack?: boolean;
  spinnerProps?: SpinnerProps;
  endAdornment?: ReactNode;
}

export const Button = ({
  isLoading = false,
  isContinue = false,
  isBack = false,
  spinnerProps,
  endAdornment,
  children,
  ...props
}: ButtonProps) => {
  const disabled = props.disabled || isLoading;
  const { classes, cx } = useStyles({ disabled });
  const content = isLoading
    ? <Spinner className={classes.spinner} {...spinnerProps} />
    : children;

  return (
    <MuiButton
      disabled={disabled}
      className={classes.button}
      {...props}
    >
      <OptionallyVisible visible={isBack}>
        <div className={cx(classes.chevronWrapper, classes.alignLeft)}>
          <IconChevron className={cx(classes.chevron, classes.invert)} />
        </div>
      </OptionallyVisible>
      <div className={classes.marginAlignment}>
        {content}
      </div>
      <OptionallyVisible visible={isContinue}>
        <div className={classes.chevronWrapper}>
          <IconChevron className={classes.chevron} />
        </div>
      </OptionallyVisible>
      <OptionallyVisible visible={Boolean(endAdornment)}>
        <div className={classes.chevronWrapper}>
          {endAdornment}
        </div>
      </OptionallyVisible>
    </MuiButton>
  );
};

export default Button;
