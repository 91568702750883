import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path1 = [
  'M14 4.85333V3.33333C14 2.6 13.4 2 12.6667 2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2',
  '13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V11.1467C14.3933 10.9133',
  '14.6667 10.4933 14.6667 10V6C14.6667 5.50667 14.3933 5.08667 14 4.85333ZM13.3333',
  '6V10H8.66667V6H13.3333ZM3.33333 12.6667V3.33333H12.6667V4.66667H8.66667C7.93333 4.66667',
  '7.33333 5.26667 7.33333 6V10C7.33333 10.7333 7.93333 11.3333 8.66667 11.3333H12.6667V12.6667H3.33333Z',
].join(' ');

const path2 = [
  'M10.6667 9C11.219 9 11.6667 8.55229 11.6667 8C11.6667 7.44772 11.219 7 10.6667 7C10.1144',
  '7 9.66667 7.44772 9.66667 8C9.66667 8.55229 10.1144 9 10.6667 9Z',
].join(' ');

export const IconWallet = ({
  width = 16,
  height = 16,
  color,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={path1} fill={color} />
      <path d={path2} fill={color} />
    </svg>
  );
};
export default IconWallet;
