import {
  combineReducers, configureStore, PreloadedState,
} from '@reduxjs/toolkit';

import accountsApi from 'services/account';
import assetsApi from 'services/assets';
import authenticationApi from 'services/authentication';
import countriesApi from 'services/countries';
import kycApi from 'services/kyc';
import paymentsApi from 'services/payments';
import quotesApi from 'services/quote';
import transactionApi from 'services/transaction';
import userApi from 'services/user';

import sideEffectsMiddleware from './middlewares/sideEffects/middleware';
import authSlice from './slices/authSlice';
import errorSlice from './slices/errorSlice';
import flowSlice from './slices/flowSlice';
import orderSlice from './slices/orderSlice';
import partnerSlice from './slices/partnerSlice';
import paymentSlice from './slices/paymentSlice';
import stepSlice from './slices/stepSlice';

export const rootReducer = combineReducers({
  step: stepSlice,
  order: orderSlice,
  error: errorSlice,
  auth: authSlice,
  partner: partnerSlice,
  payment: paymentSlice,
  flow: flowSlice,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [quotesApi.reducerPath]: quotesApi.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [kycApi.reducerPath]: kycApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
    countriesApi.middleware,
    assetsApi.middleware,
    quotesApi.middleware,
    authenticationApi.middleware,
    paymentsApi.middleware,
    transactionApi.middleware,
    kycApi.middleware,
    userApi.middleware,
    accountsApi.middleware,
    sideEffectsMiddleware,
  ),
});

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export const store = setupStore();
