import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CaptchaContext } from 'components/captcha/context';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import { getPathParams } from 'pages/route/utils';
import assetsApi from 'services/assets';
import authenticationApi from 'services/authentication';
import { PasswordlessOtpResponse, PasswordlessStartResponse } from 'services/authentication/types';
import paymentsApi, { getAvailableUserPaymentMethod } from 'services/payments';
import transactionApi from 'services/transaction';
import userApi from 'services/user';
import { selectAuthToken, setToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import { selectFlow } from 'state/slices/flowSlice';
import { selectOrder } from 'state/slices/orderSlice';
import { changeStep } from 'state/slices/stepSlice';
import { AuthenticationType, AuthToken } from 'types/authentication';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { ReduxToolkitError, ReduxToolkitResponse } from 'types/http';
import { WorkflowStep } from 'types/step';
import PATHS, { isFinishingOrder } from 'utils/navigation';

import OtpScreen from '.';
import getErrorCodeMessage from './errors';

const OtpScreenContainer = () => {
  const { proceed, goBack, canGoBack } = useNavigation();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken) ?? '';
  const [fetchUserQuery, userQuery] = userApi.useLazyGetUserInfoQuery();
  userApi.useGetUserPendingOrdersQuery(1, { skip: !authToken, refetchOnMountOrArgChange: true });
  const [fetchSavedCardsQuery] = paymentsApi.useLazyGetUserCardsQuery();
  const [fetchPaymentMethodsQuery] = paymentsApi.useLazyGetPaymentMethodsQuery();
  const [sendOtpEmailMutation, otpEmailMutation] = authenticationApi.usePasswordlessStartMutation();
  const [sendOtpCodeMutation] = authenticationApi.usePasswordlessOtpMutation();
  const [fetchUnfinishedOrder] = transactionApi.useLazyGetTransactionQuery();
  const [fetchActiveAssetsSell] = assetsApi.useLazyGetActiveAssetsSellQuery();
  const user = userQuery.data?.data;
  const order = useSelector(selectOrder);
  const { execute, reset } = useContext(CaptchaContext);
  const flow = useSelector(selectFlow);

  const sendOtpCode = (otp: string) => {
    if (!order.email) {
      throw new Error('Cannot send otp code, target email is undefined');
    }

    return sendOtpCodeMutation({
      username: order.email,
      type: AuthenticationType.Email,
      otp,
    }) as Promise<ReduxToolkitResponse<PasswordlessOtpResponse['data']>>;
  };

  const sendOtpEmail = async () => {
    if (!order.email) {
      throw new Error('Cannot send otp code, target email is undefined');
    }
    reset();

    const captchaToken = await execute();
    if (!captchaToken) {
      showErrorScreen(ScreenErrorType.AuthenticationFailed);
      return null;
    }

    return sendOtpEmailMutation({
      type: AuthenticationType.Email,
      username: order.email,
      captchaToken,
    }) as Promise<ReduxToolkitResponse<PasswordlessStartResponse['data']>>;
  };

  const onResendCodeButtonClick = async () => {
    await sendOtpEmail();
  };

  const showErrorScreen = (errorType: ScreenErrorType) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError(message));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const verifyOtpPassword = async (otp: string): Promise<string | null> => {
    const otpCallResult = await sendOtpCode(otp);
    if (!otpCallResult) {
      throw new Error('Unable to send otp code');
    }

    const hasErrorType = Boolean(otpCallResult.error?.data?.errorType);
    if (hasErrorType) {
      const message = getErrorCodeMessage(otpCallResult.error!.data!.errorType as ApiErrorCode.WrongEmailOrCode);

      throw new Error(message);
    }

    if (otpCallResult.error) {
      showErrorScreen(ScreenErrorType.AuthenticationFailed);
      return null;
    }

    if (!otpCallResult.data?.data?.accessToken) {
      showErrorScreen(ScreenErrorType.AuthenticationFailed);
      return null;
    }

    dispatch(setToken(otpCallResult.data.data.accessToken));

    return otpCallResult.data.data.accessToken;
  };

  const fetchUserInfo = async (token: AuthToken) => {
    const userResult = await fetchUserQuery(token);
    if ('error' in userResult) {
      showErrorScreen(ScreenErrorType.AuthenticationFailed);
      return null;
    }

    return userResult.data?.data;
  };

  const fetchExistingOrderStatus = async () => {
    const orderIdFromPath = getPathParams(PATHS.CONTINUE_ORDER_PATH, window.location.pathname)?.orderId;
    const isContinueFlow = isFinishingOrder();
    if (isContinueFlow) {
      await fetchActiveAssetsSell();
    }
    const orderId = orderIdFromPath ?? order.transactionId;

    if (!orderId) {
      return undefined;
    }

    const orderStatus = await fetchUnfinishedOrder({
      transactionId: orderId,
      includeToken: true,
    });

    return orderStatus.data?.data.order;
  };

  const onContinueClick = async (otp: string) => {
    const accessToken = await verifyOtpPassword(otp);
    if (!accessToken) {
      return;
    }

    const user = await fetchUserInfo(accessToken);
    if (!user) {
      showErrorScreen(ScreenErrorType.UserStatus);
      return;
    }

    const orderStatus = await fetchExistingOrderStatus();
    const savedCards = await fetchSavedCardsQuery(accessToken);
    const defaultCard = savedCards.data?.find(card => card.isDefault);
    const paymentMethods = await fetchPaymentMethodsQuery();
    const userPaymentMethod = user.defaultPaymentMethod
      ? getAvailableUserPaymentMethod(user.defaultPaymentMethod, paymentMethods.data ?? [], flow)
      : null;

    proceed({
      user,
      paymentMethod: {
        default: userPaymentMethod,
        defaultCard,
      },
      order,
      orderStatus,
    });
  };

  return (
    <OtpScreen
      email={user?.username ?? order.email}
      canGoBack={canGoBack}
      goBack={goBack}
      onContinueClick={onContinueClick}
      onResendCodeButtonClick={onResendCodeButtonClick}
      otpResendStatus={{
        isLoading: otpEmailMutation.isLoading,
        isError: otpEmailMutation.isError,
        error: otpEmailMutation.error as ReduxToolkitError,
      }}
    />
  );
};

export default OtpScreenContainer;
