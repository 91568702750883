import OptionallyVisible from 'components/optionallyVisible';
import { useThemeContext } from 'theme';

import { IconProps } from './types';

type IconMenuPendingOrdersProps =IconProps & {
  showDotIndicator?: boolean;
}

const path = [
  'M15.8333 6.66666L12.5 9.99999H15C15 12.7583 12.7583 15 10 15C9.15834 15',
  '8.35834 14.7917 7.66668 14.4167L6.45001 15.6333C7.47501 16.2833 8.69168',
  '16.6667 10 16.6667C13.6833 16.6667 16.6667 13.6833 16.6667 9.99999H19.1667L15.8333',
  '6.66666ZM5.00001 9.99999C5.00001 7.24166 7.24168 4.99999 10 4.99999C10.8417 4.99999',
  '11.6417 5.20833 12.3333 5.58333L13.55 4.36666C12.525 3.71666 11.3083 3.33333 10',
  '3.33333C6.31668 3.33333 3.33334 6.31666 3.33334 9.99999H0.833344L4.16668 13.3333L7.50001 9.99999H5.00001Z',
].join(' ');

export const IconMenuPendingOrders = ({
  width = 20,
  height = 20,
  className,
  color,
  showDotIndicator,
}: IconMenuPendingOrdersProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
      <OptionallyVisible visible={Boolean(showDotIndicator)}>
        <circle cx="18" cy="2" r="2" fill={theme.colors.icons.error} />
      </OptionallyVisible>
    </svg>
  );
};
export default IconMenuPendingOrders;
