import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import accountsApi from 'services/account';
import { setError } from 'state/slices/errorSlice';
import { setApiKey } from 'state/slices/partnerSlice';
import { changeStep } from 'state/slices/stepSlice';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { WorkflowStep } from 'types/step';

import { SideEffectsMiddlewareHandler } from './types';

const invalidApiKeyHandler: SideEffectsMiddlewareHandler = (middleware, action) => {
  const isInvalidApiKeyError = action.payload?.data?.errorType === ApiErrorCode.InvalidApiKey;
  if (isInvalidApiKeyError) {
    const error = getErrorScreenMessage(ScreenErrorType.InvalidApiKey);
    middleware.dispatch(setError({ ...error, isFatalError: true }));
    middleware.dispatch(changeStep(WorkflowStep.Error));
  }
};

const apiKeySetterHandler: SideEffectsMiddlewareHandler = (middleware, action) => {
  const isFulfilledAccountInfoQuery = accountsApi.endpoints.getAccountInfo.matchFulfilled(action);
  if (isFulfilledAccountInfoQuery) {
    middleware.dispatch(setApiKey(action.payload.data.publicKey));
  }
};

export const apiKeyMiddlewareHandlers: SideEffectsMiddlewareHandler[] = [
  invalidApiKeyHandler,
  apiKeySetterHandler,
];

export default apiKeyMiddlewareHandlers;
