import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOption } from '@mui/base/useOption';

import ApplePayIcon from 'assets/images/paymentMethods/applePay.svg';
import GooglePayIcon from 'assets/images/paymentMethods/googlePay.svg';
import Button from 'components/button';
import OptionallyVisible from 'components/optionallyVisible';
import Action from 'components/paymentMethodsList/action';
import COMMON_LABELS from 'constants/messages';
import { IconBookmark } from 'icons/bookmark';
import IconCard from 'icons/card';
import { CardPaymentToken } from 'types/card';
import { ReduxToolkitResponse, SuccessResponse } from 'types/http';
import { PaymentMethod } from 'types/payment';

import { LABELS } from './keys';
import { useStyles } from './listItem.styles';

interface PaymentMethodListItemProps {
  paymentMethod: PaymentMethod;
  label: string;
  isDefault: boolean;
  isClickable: boolean;
  updateUserProps: {
    updateUser: (paymentMethod: PaymentMethod | CardPaymentToken['id']) => Promise<ReduxToolkitResponse<SuccessResponse>>;
    isLoading: boolean;
  };
}

const PaymentMethodListItem = ({
  paymentMethod,
  isClickable,
  label,
  isDefault,
  updateUserProps,
}: PaymentMethodListItemProps) => {
  const { classes, cx, theme } = useStyles({ isClickable });
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    getRootProps,
    highlighted,
  } = useOption({
    disabled: false,
    label: paymentMethod,
    value: paymentMethod,
  });

  const renderIcon = () => {
    const icon = ({
      [PaymentMethod.Card]: <IconCard />,
      [PaymentMethod.ApplePay]: <img src={ApplePayIcon} width={35} height={20} alt={t(COMMON_LABELS.APPLE_PAY)} />,
      [PaymentMethod.GooglePay]: <img src={GooglePayIcon} width={35} height={20} alt={t(COMMON_LABELS.GOOGLE_PAY)} />,
    })[paymentMethod];

    return icon;
  };

  const onSetDefaultClick = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsUpdating(true);
    await updateUserProps.updateUser(paymentMethod);
    setIsUpdating(false);
  };

  return (
    <li
      {...getRootProps()}
      className={cx(classes.tile, {
        [classes.tileHighlighted]: highlighted,
      })}
    >
      {renderIcon()}
      <div className={classes.cardDetailsWrapper}>
        <div className={classes.cardDetails}>
          <div
            className={classes.cardName}
          >
            {label}
          </div>
        </div>
        <OptionallyVisible visible={isDefault}>
          <div className={classes.defaultBadge}>{t(LABELS.CARD_DEFAULT)}</div>
        </OptionallyVisible>
        <div className={classes.actions}>
          <OptionallyVisible visible={!isDefault}>
            <Action isLoading={isUpdating}>
              <Button
                disabled={updateUserProps.isLoading}
                className={classes.actionButton}
                onClick={onSetDefaultClick}
              >
                <IconBookmark color={theme.colors.text.inactive} />
              </Button>
            </Action>
          </OptionallyVisible>
          <div className={classes.actionPlaceholder} />
        </div>
      </div>
    </li>
  );
};

export default PaymentMethodListItem;
