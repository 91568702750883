import { OrderStatusStep } from 'types/transactions';

export interface QALocateableElementProps {
  'data-cy'?: string;
}

export const QALocator = (name?: string) => {
  if (!window.s4cConfig?.enableQALocators) {
    return {};
  }

  if (!name) {
    return {};
  }

  return {
    'data-cy': name,
  };
};

export const locators = {
  components: {
    common: {
      slideModalContent: 'slide-modal-content',
      slideModalCloseButton: 'slide-modal-close-button',
      searchInput: 'search-input',
      searchableListContent: 'searchable-list-content',
      spinner: 'spinner',
      goBackButton: 'go-back-button',
      error: 'error',
      quoteErrorTileRefreshButton: 'quote-error-tile-refresh-button',
      menuButton: 'menu-button',
    },
    menu: {
      menuListContent: 'menu-list-content',
    },
  },
  page: {
    quote: {
      viewLoadingSpinner: 'quote-spinner',
      fiatAssetButton: 'fiat-asset-button',
      cryptoAssetButton: 'crypto-asset-button',
      proceedButton: 'quote-proceed-button',
      cryptoInput: 'quote-crypto-input',
      fiatInput: 'quote-fiat-input',
      cryptoInputError: 'quote-crypto-input-error',
      refreshingQuoteInfo: 'quote-refreshing',
    },
    signIn: {
      proceedButton: 'signIn-proceed-button',
      goBackButton: 'signIn-goBack-button',
    },
    otp: {
      proceedButton: 'otp-proceed-button',
      resendOtpButton: 'otp-resend-otp-button',
      resendOtpHelpText: 'otp-resend-otp-help-text',
      resendOtpError: 'otp-resend-otp-error',
    },
    countryCheck: {
      selectCountryButton: 'countryCheck-countrySelect-button',
      proceedButton: 'countryCheck-proceed-button',
      viewLoadingSpinner: 'countryCheck-spinner',
    },
    countryCheckFinal: {
      viewLoadingSpinner: 'countryCheck-final-spinner',
    },
    kyc: {
      proceedButton: 'kyc-proceed-button',
      verifyButton: 'kyc-verify-button',
      verifyButtonSpinner: 'kyc-verify-button-spinner',
      termsErrorMessage: 'kyc-terms-error-message',
      termsAgreementTile: 'kyc-terms-agreement-tile',
      termsCheckbox: 'kyc-terms-checkbox',
    },
    bankDetails: {
      spinner: 'bankDetails-spinner',
      proceedButton: 'bankDetails-proceed-button',
      formGenericError: 'bankDetails-form-generic-error',
      cardNumberError: 'bankDetails-card-number-error',
      expiryDateError: 'bankDetails-expiry-date-error',
      cardCvcError: 'bankDetails-card-cvc-error',
      editCardButton: 'bankDetails-edit-card-button',
      cancelEditCardButton: 'bankDetails-cancel-edit-card-button',
    },
    checkout: {
      paymentInProgress: 'payment-in-progress',
      proceedButton: 'checkout-proceed-button',
      proceedButtonSpinner: 'checkout-proceed-button-spinner',
      youReceiveValue: 'checkout-you-receive-value',
      youReceiveCryptoCurrencyCode: 'checkout-you-receive-crypto-currency-code',
      walletAddress: 'checkout-wallet-address',
      walletAddressTooltip: 'checkout-wallet-address-tooltip',
      editCardButton: 'checkout-edit-card-button',
      editWalletButton: 'checkout-edit-wallet-button',
    },
    transactionProcessing: {
      backButton: 'transaction-processing-back-button',
      [OrderStatusStep.QuoteExecution]: 'transaction-processing-quote-step-status',
      [OrderStatusStep.Deposit]: 'transaction-processing-deposit-step-status',
      [OrderStatusStep.Payment]: 'transaction-processing-payment-step-status',
      [OrderStatusStep.Exchange]: 'transaction-processing-exchange-step-status',
      [OrderStatusStep.Withdrawal]: 'transaction-processing-withdrawal-step-status',
      errorIcon: 'transaction-processing-error-icon',
      errorDisabledIcon: 'transaction-processing-error-disabled-icon',
      completedIcon: 'transaction-processing-completed-icon',
      completedDisabledIcon: 'transaction-processing-completed-disabled-icon',
      stuckWithdrawalSpinner: 'transaction-processing-stuck-withdrawal-spinner',
      waitingForWithdrawalBlock: 'transaction-processing-waiting-for-withdrawal-block',
    },
    unsupportedCountry: {
      proceedButton: 'usupportedCountry-proceed-button',
      viewSpinner: 'usupportedCountry-view-spinner',
    },
    orderProcessed: {
      proceedButton: 'orderProcessed-proceed-button',
    },
    receipt: {
      walletAddress: 'receipt-wallet-address',
      walletAddressTag: 'receipt-wallet-address-tag',
      errorIcon: 'receipt-error-icon',
      errorDisabledIcon: 'receipt-error-disabled-icon',
      completedIcon: 'receipt-completed-icon',
      completedDisabledIcon: 'receipt-completed-disabled-icon',
      pendingStepStatusSpinner: 'receipt-pending-step-status-spinner',
      loadingTransactionSpinner: 'receipt-loading-transaction-spinner',
      transactionProcessingStepsBlock: 'receipt-transaction-processing-steps-block',
      [OrderStatusStep.QuoteExecution]: 'receipt-quote-step-status',
      [OrderStatusStep.Deposit]: 'receipt-deposit-step-status',
      [OrderStatusStep.Payment]: 'receipt-payment-step-status',
      [OrderStatusStep.Exchange]: 'receipt-exchange-step-status',
      [OrderStatusStep.Withdrawal]: 'receipt-withdrawal-step-status',
    },
    wallet: {
      proceedButton: 'wallet-proceed-button',
      destinationTagFormFieldContainer: 'wallet-destination-tag-form-field-container',
      destinationTagInputField: 'wallet-destination-tag-input-field',
      memoFormFieldContainer: 'wallet-memo-form-field-container',
      memoInputField: 'wallet-memo-input-field',
    },
    error: {
      goBackMainButton: 'error-go-back-main',
    },
    profile: {
      viewLoadingSpinner: 'quote-spinner',
    },
    orderHistory: {
      viewLoadingSpinner: 'orders-history-spinner',
      pageLoadingSpinner: 'page-spinner',
    },
  },
};
