import usePathnameChangeDetector from './usePathnameChangeDetector';
import { getPathParams } from './utils';

export const useMatch = (path: string) => {
  const { currentPath } = usePathnameChangeDetector();
  const params = getPathParams(path, currentPath);

  return {
    params,
  };
};

export default useMatch;
