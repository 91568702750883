import { useDispatch, useSelector } from 'react-redux';

import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import { selectError, setError } from 'state/slices/errorSlice';
import { changeStep } from 'state/slices/stepSlice';
import { ScreenErrorType } from 'types/errors/errorScreen';

import ErrorScreen from '.';

export const ErrorScreenContainer = () => {
  const error = useSelector(selectError);
  const genericError = getErrorScreenMessage(ScreenErrorType.Generic);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const clearError = () => {
    dispatch(setError(null));
  };

  const goBack = () => {
    const previousStep = error?.goBackToScreen;
    if (previousStep) {
      dispatch(changeStep(previousStep));
      return;
    }

    navigation.goBack();
  };

  return (
    <ErrorScreen
      error={{
        title: error?.title ?? genericError.title,
        message: error?.message ?? genericError.message,
        errorType: error?.errorType ?? ScreenErrorType.Generic,
        isFatalError: error?.isFatalError,
        goBackToScreen: error?.goBackToScreen,
      }}
      clearError={clearError}
      goBack={goBack}
    />
  );
};

export default ErrorScreenContainer;
