import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    formItemError: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.error,
    },
  }),
);

export default useStyles;
