export enum ConfigQueryParam {
  Id = 'id',
  CryptoCode = 'cryptoCurrencyCode',
  CryptoAmount = 'cryptoAmount',
  FiatCode = 'fiatCurrencyCode',
  FiatAmount = 'fiatAmount',
  DefaultCryptoCode = 'defaultCryptoCurrencyCode',
  DefaultCryptoAmount = 'defaultCryptoAmount',
  DefaultFiatCode = 'defaultFiatCurrencyCode',
  DefaultFiatAmount = 'defaultFiatAmount',
  CryptoAssetsList = 'cryptoCurrencyCodeList',
  FiatAssetsList = 'fiatCurrencyCodeList',
  Networks = 'networks',
  WalletAddress = 'walletAddress',
  WalletTag = 'walletTag',
  ShowWalletAddressForm = 'showWalletAddressForm',
  Email = 'email',
  EnableRedirectURL = 'enableRedirectURL',
  PopularCryptoList = 'cryptoCurrencyCodePopularList',
  LockedAmount = 'lockedAmount',
  Theme = 'themeColor',
  ThemeRadius = 'themeRadius',
  HideIllustrations = 'hideIllustrations',
  ThemeMode = 'themeMode',
  ExternalOrderId = 'externalOrderId',
  ExternalUserId = 'externalUserId',
  RedirectUrl = 'redirectUrl',
  PaymentMethod = 'paymentMethod',
}

export enum QueryParam {
  Flow = 'flow'
}

export default ConfigQueryParam;
