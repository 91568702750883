import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { FocusTrap } from '@mui/base';

import Button from 'components/button';
import { KEYS as LAYOUT_KEYS } from 'components/layout/keys';
import IconCross from 'icons/cross';
import { locators, QALocator } from 'utils/tests/QA';

import KEYS from './keys';
import useStyles from './styles';

export interface SlideModalProps {
  visible: boolean;
  onClose: () => void;
  title: string;
  onTransitionFinished?: () => void;
  children: ReactNode;
}

export const SlideModal = ({
  visible,
  onClose,
  title,
  onTransitionFinished,
  children,
}: SlideModalProps) => {
  const { classes } = useStyles();

  const close = (e: any) => {
    if (e.code === 'Escape') {
      closeList();
    }
  };
  useEffect(() => {
    if (visible) {
      window.addEventListener('keydown', close);
    } else {
      window.removeEventListener('keydown', close);
    }
    return () => window.removeEventListener('keydown', close);
  }, [visible]);

  const closeList = () => {
    if (!visible) {
      return;
    }
    onClose();
  };

  const overlayRef = useRef(null);
  const modalRef = useRef(null);
  const targetElement = document.getElementById(LAYOUT_KEYS.FORM_ID);

  const content = (
    <FocusTrap open={visible}>
      <div tabIndex={-1} className={classes.container}>
        <CSSTransition
          in={visible}
          nodeRef={overlayRef}
          timeout={KEYS.OVERLAY_TRANSITION_DURATION}
          classNames="overlay"
          unmountOnExit
          onExited={onTransitionFinished}
        >
          <div
            className={classes.overlay}
            ref={overlayRef}
          />
        </CSSTransition>
        <CSSTransition
          in={visible}
          nodeRef={modalRef}
          timeout={KEYS.MODAL_TRANSITION_DURATION}
          classNames="modal"
          unmountOnExit
        >
          <div
            className={classes.modalContent}
            ref={modalRef}
            {...QALocator(locators.components.common.slideModalContent)}
          >
            <div className={classes.modalHeader}>
              <h4 className={classes.modalTitle}>
                {title}
              </h4>
              <Button
                className={classes.closeButton}
                onClick={closeList}
                {...QALocator(locators.components.common.slideModalCloseButton)}
              >
                <IconCross className={classes.closeIcon} />
              </Button>
            </div>
            {children}
          </div>
        </CSSTransition>
      </div>
    </FocusTrap>
  );

  if (!targetElement) {
    return content;
  }

  return createPortal(content, targetElement);
};

export default SlideModal;
