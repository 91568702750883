import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ApplePayButton from 'components/applePay';
import Button from 'components/button';
import Card from 'components/card';
import Header from 'components/header';
import LoadingWrapper from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import GooglePayButton from 'components/payment/googlePay';
import QuoteErrorTile from 'components/quoteErrorTile';
import { CONTACT_DATA } from 'constants/contactData';
import PaymentMethod from 'types/payment';
import { formatFiatCurrencyI18n } from 'utils/i18n';
import { appConfig } from 'utils/local';
import Logger from 'utils/logger';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS, LABELS } from './keys';
import { useStyles } from './styles';
import { CheckoutPageProps } from './types';

const CheckoutPage = ({
  quote,
  payment,
  selectedCryptoAsset,
  selectedFiatAsset,
  goBack,
  fetchQuote,
  onPayClick,
  previews,
  proceedLabel,
  additionalInfo,
  QuoteBreakdown,
  isPayReady,
  regulatoryText,
  disclaimer,
  canGoBack,
  payWithGPay,
}: CheckoutPageProps) => {
  const [isQuoteDetailsExpanded, setIsQuoteDetailsExpanded] = useState(false);
  const { classes } = useStyles({ preserveSpace: Boolean(disclaimer && !isQuoteDetailsExpanded) });
  const { t, i18n } = useTranslation();

  const [paymentInProgress, setPaymentInProgress] = useState(false);

  const isQuoteError = quote.isError;
  const isQuoteLoading = quote.isLoading;
  const hasQuoteErrorMessage = Boolean(quote.error?.data?.errorMessage);

  const hasPaymentErrorMessage = Boolean(payment.error?.data?.errorMessage);

  const isQuoteNoErrorAndNoPaymentInProgress = !isQuoteError && !paymentInProgress;

  const isQuoteDetailsVisible = !isQuoteError && !paymentInProgress;
  const isAdditionalQuoteInfoVisible = Boolean(isQuoteNoErrorAndNoPaymentInProgress && !isQuoteLoading);
  const isRefreshingQuoteBlockVisible = Boolean(isQuoteNoErrorAndNoPaymentInProgress && isQuoteLoading);

  const isContinueButtonDisabled = Boolean(isQuoteLoading || paymentInProgress || isQuoteError || !isPayReady);
  const isContinueButtonInLoadingState = Boolean(isQuoteLoading || paymentInProgress);
  const isAmountNotVisibleOnTheButton = payment.method === PaymentMethod.GooglePay || payment.method === PaymentMethod.ApplePay;

  const handleContinueClick = async () => {
    setPaymentInProgress(true);
    await onPayClick();
    setPaymentInProgress(false);
  };

  const handleGPayClick = async (transactionData: {
    fiatAmount: string;
    fiatCurrencyCode: string;
  }, token: string) => {
    if (!payWithGPay) {
      return;
    }

    setPaymentInProgress(true);
    await payWithGPay(transactionData, token);
    setPaymentInProgress(false);
  };

  const renderPaymentButton = () => {
    if (payment.method === PaymentMethod.ApplePay) {
      return (
        <div className={classes.thirdPartyButton}>
          <ApplePayButton onClick={handleContinueClick} />
        </div>
      );
    }

    if (payment.method === PaymentMethod.GooglePay) {
      const transactionData = quote.details
        ? {
          totalPrice: quote.details.fiatAmount,
          currencyCode: quote.details.fiatCurrencyCode,
          countryCode: KEYS.COUNTRY_CODE,
        } : undefined;

      if (!payWithGPay) {
        Logger.error('Payment function is not provided');
        return null;
      }

      return (
        <div className={classes.thirdPartyButton}>
          <GooglePayButton
            paymentRequest={payment.googlePayRequest}
            transactionData={transactionData}
            disabled={isContinueButtonDisabled}
            onPay={handleGPayClick}
            isLoading={isContinueButtonInLoadingState}
            merchantData={appConfig?.googlePay?.merchant}
          />
        </div>
      );
    }

    return (
      <Button
        onClick={handleContinueClick}
        isLoading={isContinueButtonInLoadingState}
        disabled={isContinueButtonDisabled}
        spinnerProps={QALocator(locators.page.checkout.proceedButtonSpinner)}
        {...QALocator(locators.page.checkout.proceedButton)}
      >
        {proceedLabel}
      </Button>
    );
  };

  return (
    <div className={classes.container}>
      <Header hasBackButton={canGoBack} onBackClick={goBack}>
        {t(LABELS.HEADER)}
      </Header>
      <OptionallyVisible visible={Boolean(disclaimer)}>
        <div className={classes.disclaimer}>
          {disclaimer}
        </div>
      </OptionallyVisible>
      <Card className={classes.card}>
        {previews}
        <OptionallyVisible visible={isQuoteDetailsVisible}>
          <QuoteBreakdown
            quote={quote}
            fiatCurrency={selectedFiatAsset}
            cryptocurrency={selectedCryptoAsset}
            onExpand={() => setIsQuoteDetailsExpanded(true)}
            onShrink={() => setIsQuoteDetailsExpanded(false)}
          />
        </OptionallyVisible>

        <OptionallyVisible visible={isAdditionalQuoteInfoVisible}>
          <div>
            {additionalInfo}
          </div>
        </OptionallyVisible>

        <OptionallyVisible visible={paymentInProgress}>
          <div className={classes.additionalInfoBlock} {...QALocator(locators.page.checkout.paymentInProgress)}>
            {t(LABELS.PAYMENT_IN_PROGRESS)}
          </div>
        </OptionallyVisible>

        <OptionallyVisible visible={isRefreshingQuoteBlockVisible}>
          <div className={classes.additionalInfoBlock}>
            {t(LABELS.QUOTE_REFRESHING)}
          </div>
        </OptionallyVisible>

        <OptionallyVisible visible={hasQuoteErrorMessage}>
          <QuoteErrorTile
            fetchQuote={fetchQuote}
            isQuoteLoading={quote.isLoading}
            backendErrorMessage={quote.error?.data?.errorMessage}
          />
        </OptionallyVisible>
      </Card>

      <div className={classes.bottomWrapper}>

        <div className={classes.absoluteWrapper}>

          <OptionallyVisible visible={hasPaymentErrorMessage}>
            <div className={classes.contactSupport}>
              <div className={classes.paymentErrorText}>
                {payment.error?.data?.errorMessage}
              </div>
              <Trans
                i18nKey={LABELS.CONTACT_SUPPORT}
                components={{
                  a: <a href={`mailto:${CONTACT_DATA.SUPPORT_EMAIL_ADDRESS}`}>email</a>,
                }}
                values={{ email: CONTACT_DATA.SUPPORT_EMAIL_ADDRESS }}
              />
            </div>
          </OptionallyVisible>

          <OptionallyVisible visible={hasQuoteErrorMessage}>
            <div className={classes.contactSupport}>
              <Trans
                i18nKey={LABELS.CONTACT_SUPPORT}
                components={{
                  a: <a href={`mailto:${CONTACT_DATA.SUPPORT_EMAIL_ADDRESS}`}>email</a>,
                }}
                values={{ email: CONTACT_DATA.SUPPORT_EMAIL_ADDRESS }}
              />
            </div>
          </OptionallyVisible>

          <div className={classes.regulatoryText}>
            {regulatoryText}
            <OptionallyVisible visible={isAmountNotVisibleOnTheButton}>
              <div className={classes.totalPriceWrapper}>
                <div>{t(LABELS.TOTAL)}</div>
                <LoadingWrapper
                  isLoading={isQuoteLoading}
                  loadingIndicator={<div>{KEYS.QUOTE_LOADING_PLACEHOLDER}</div>}
                >
                  <div>{formatFiatCurrencyI18n(i18n.language)(Number(quote.details?.fiatAmount ?? 0), selectedFiatAsset)}</div>
                </LoadingWrapper>
              </div>
            </OptionallyVisible>
          </div>
        </div>

        {renderPaymentButton()}
      </div>
    </div>
  );
};

export default CheckoutPage;
