import { t } from 'i18next';

import ApiErrorCode from 'types/errors/api';

import { LABELS } from './keys';

export const getErrorCodeMessage = (errorType: ApiErrorCode.RateLimit | ApiErrorCode.WrongEmailOrCode) => ({
  [ApiErrorCode.RateLimit]: t(LABELS.ERRORS_RATE),
  [ApiErrorCode.WrongEmailOrCode]: t(LABELS.ERRORS_CODE),
}[errorType]);

export default getErrorCodeMessage;
