import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import Layout from 'components/layout/root';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import Error from 'pages/error';
import { ScreenErrorType } from 'types/errors/errorScreen';

const Boundary = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <Layout
      showPartnersFooter={false}
    >
      <Error
        error={{
          title: getErrorScreenMessage(ScreenErrorType.Generic).title,
          message: getErrorScreenMessage(ScreenErrorType.Generic).message,
          errorType: ScreenErrorType.Generic,
          isFatalError: true,
        }}
        goBack={resetErrorBoundary}
      />
    </Layout>
  );
};
export default Boundary;
