import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

export const initSentry = () => {
  Sentry.init({
    ...(window?.s4cConfig?.sentry || {}),
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: true,
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
  });
};

export default initSentry;
