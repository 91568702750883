import { NavigationEvaluationFunc, NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';
import { KycStatus, UserStatus } from 'types/user';

import { getContinueOrderStep } from './init';

export const getOtpScreenNextStepSell: NavigationEvaluationFunc<WorkflowStep> = (
  {
    user, paymentMethod, order, orderStatus,
  }: NavigationEvaluationFuncParams,
): WorkflowStep => {
  if (!user) {
    throw new Error('Unable to proceed from OTP screen, no user information');
  }

  const finishOrderStep = getContinueOrderStep(orderStatus);
  if (finishOrderStep) {
    return finishOrderStep;
  }

  const hasEnoughOrderData = [
    order?.selectedFiatAsset,
    order?.selectedCryptoAsset,
    order?.fiatAmount,
    order?.cryptoAmount,
    order?.exchangeDirection,
  ].every(Boolean);

  if (!hasEnoughOrderData) {
    return WorkflowStep.Quote;
  }

  const skipPaymentMethods = Boolean(paymentMethod?.default);
  const skipBankDetails = Boolean(paymentMethod?.defaultCard) || skipPaymentMethods;

  if (user.kycStatus !== null || user.kycStatus === KycStatus.Submitted) {
    if (user.isResidenceCountrySupported) {
      if (user.isResidenceStateSupported) {
        if (user.isIpCountrySupported) {
          if (user.isIpStateSupported) {
            if (user.status === UserStatus.Blocked) {
              return WorkflowStep.KycRejected;
            }
            if (skipPaymentMethods) {
              if (user.kycStatus === KycStatus.Approved) {
                if (skipBankDetails) {
                  return WorkflowStep.Checkout;
                }
                return WorkflowStep.BankDetails;
              }
              return WorkflowStep.PaymentMethods;
            }
            if (user.kycStatus === KycStatus.Submitted) {
              return WorkflowStep.Kyc;
            }
            if (user.kycStatus === KycStatus.Rejected) {
              if (user.isKycRetryAllowed) {
                return WorkflowStep.Kyc;
              }
            }
            if (user.kycStatus === KycStatus.PendingApproval) {
              return WorkflowStep.Kyc;
            }
            return WorkflowStep.KycRejected;
          }
          return WorkflowStep.IpCountryUnavailable;
        }
        return WorkflowStep.IpCountryUnavailable;
      }
      return WorkflowStep.IpCountryUnavailable;
    }
    return WorkflowStep.CountryUnavailable;
  }

  if (user.country) {
    if (user.isResidenceCountrySupported) {
      if (user.isIpCountrySupported) {
        if (user.isIpStateSupported) {
          if (user.status === UserStatus.Blocked) {
            return WorkflowStep.KycRejected;
          }
          return WorkflowStep.Kyc;
        }
      } else {
        return WorkflowStep.IpCountryUnavailable;
      }
    } else {
      return WorkflowStep.CountryUnavailable;
    }
  } else {
    if (user.isIpCountrySupported) {
      if (user.isIpStateSupported) {
        if (user.status === UserStatus.Blocked) {
          return WorkflowStep.KycRejected;
        }
        return WorkflowStep.Kyc;
      }
      return WorkflowStep.IpCountryUnavailable;
    }
    return WorkflowStep.CountryCheck;
  }

  return WorkflowStep.Error;
};

export default getOtpScreenNextStepSell;
