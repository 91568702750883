import CopyButton from 'components/copyButton';
import Tooltip from 'components/tooltip';
import maskContent from 'libs/sentry/mask';
import { locators, QALocator } from 'utils/tests/QA';

import { LABELS } from './keys';
import useStyles from './walletAddressTooltip.styles';

interface WalletAddressTooltipProps {
  walletAddress: string;
}

const WalletAddressTooltip = ({
  walletAddress,
}: WalletAddressTooltipProps) => {
  const { classes } = useStyles();

  const getMaskedWalletAddress = () => {
    const walletAddressFirstTwo = walletAddress.slice(0, 2);
    const walletAddressLastFour = walletAddress.slice(-4);

    return [
      walletAddressFirstTwo,
      LABELS.WALLET_MASK_FILL,
      walletAddressLastFour,
    ].join(' ');
  };

  return (
    <Tooltip
      content={(
        <CopyButton textToCopy={walletAddress} className={classes.walletAddressTooltipContent}>
          <div
            {...QALocator(locators.page.checkout.walletAddressTooltip)}
          >
            {walletAddress}
          </div>
        </CopyButton>
    )}
      tooltipProps={{
        className: classes.walletAddressTooltipContent,
      }}
    >
      <div
        {...maskContent()}
        {...QALocator(locators.page.checkout.walletAddress)}
      >
        {getMaskedWalletAddress()}
      </div>
    </Tooltip>
  );
};

export default WalletAddressTooltip;
