import { UrlParam } from './base';
import Flow from './flow';
import PaymentMethod from './payment';

export enum OrderStepStatus {
  Unknown = 'UNKNOWN',
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Failed = 'FAILED',
  Stuck = 'STUCK',
}

export enum CardBrand {
  Visa = 'Visa',
  Amex = 'American Express',
  MasterCard = 'MasterCard',
  Discover = 'Discover',
  Jcb = 'JCB',
  DinersClub = 'Diners Club',
  Unknown = 'Unknown',
  Maestro = 'Maestro',
}

export interface Transaction {
  accountGroup: string;
  accountLabel: string;
  cryptoCostFiatAmount: number;
  cardBrand: CardBrand;
  cardLast4: string;
  createdAt: string;
  expireTs: string;
  mainCurrency: string;
  networkFeeFiatAmount: number;
  networkFeeCryptoAmount: number;
  oboParticipant: {
    accountGroup: string;
    accountLabel: string;
    participantCode: string;
  };
  onChainStatus: string;
  onChainTransactionHash?: string | null;
  participantCode: string;
  paymentMethod: PaymentMethod | null;
  cryptoPrice: string;
  processingFeeFiatAmount: number;
  processingFeePercent: number;
  cryptoAmount: string;
  quoteId: string;
  requestId: string;
  securionChargeId: string;
  securionMerchantId: string;
  side: Flow;
  status: TransactionStatus;
  failedStatusReason: null;
  fiatAmount: number;
  tradeStatus: string;
  cryptoCurrencyCode: string;
  fiatCurrencyCode: string;
  userUuid: string;
  uuid: string;
  walletAddress: string;
  zeroHashConfirmedQuoteId: string;
  zeroHashExecutedQuoteId: string;
  zeroHashTradeId: string;
  zeroHashWithdrawalRequestId: string;
  depositWalletAddress?: string | null;
  depositWalletTag?: string | null;
}

export enum TransactionStatus {
  Initiated = 'INITIATED',
  AwaitingDeposit = 'AWAITING_DEPOSIT',
  AuthorizationChargeFailed = 'AUTHORIZATION_CHARGE_FAILED',
  GetQuoteToExecuteFailed = 'GET_QUOTE_TO_EXECUTE_FAILED',
  ExecuteQuoteFailed = 'EXECUTE_QUOTE_FAILED',
  CaptureChargeFailed = 'CAPTURE_CHARGE_FAILED',
  Completed = 'COMPLETED',
  ExchangeFailed = 'EXCHANGE_FAILED',
  PaymentFailed = 'PAYMENT_FAILED',
  AwaitingClientConfirmation = 'AWAITING_CLIENT_CONFIRMATION',
  Processing = 'PROCESSING'
}

export enum TransactionStep {
  PaymentStatus = 'paymentStatus',
  ExchangeStatus = 'exchangeStatus',
  WithdrawalStatus = 'withdrawalStatus',
}

export enum OrderStatusStep {
  QuoteExecution = 'quoteExecution',
  Payment = 'payment',
  Exchange = 'exchange',
  Withdrawal = 'withdrawal',
  Deposit = 'deposit',
}

export interface OrderStep {
  type: OrderStatusStep;
  status: OrderStepStatus | null;
}

export enum OrderStatusChallenge {
  Processing = 'processing',
  ApproveNewQuote = 'approveNewQuote',
  OrderCompleted = 'orderCompleted',
}

export interface OrderStatus {
  statusReason: string | null;
  steps: OrderStep[];
  challenge: OrderStatusChallenge;
  onChainExplorerURL: string | null;
}

export type TransactionId = UrlParam;
export enum TransactionStepUIStatus {
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  Completed = 'Completed',
  CompletedDisabled = 'CompletedDisabled',
  Error = 'Error',
  ErrorDisabled = 'ErrorDisabled',
}
