import { CardPaymentToken } from './card';
import { CountryCode } from './countries';
import { ISODate } from './date';
import PaymentMethod from './payment';

export type Email = string;

export type CountryId = string;

export enum KycStatus {
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Locked = 'locked',
  Disabled = 'disabled',
  Divested = 'divested',
  Closed = 'closed',
  PendingUnlock = 'pending_unlock',
  PendingDisable = 'pending_disable',
  PendingApproval = 'pending_approval',
}

export enum UserStatus {
  Active = 'active',
  Blocked = 'blocked'
}

export interface User {
  country: CountryCode | null;
  username: string;
  firstName: string | null;
  lastName: string | null;
  signupAt: ISODate;
  uuid: string;
  kycStatus: KycStatus | null;
  isKycRetryAllowed: boolean;
  isCountrySupported: boolean;
  isResidenceCountrySupported: boolean;
  isIpCountrySupported: boolean;
  isIpStateSupported: boolean;
  isResidenceStateSupported: boolean;
  zHashParticipantCode: string | null;
  ipCountry: CountryCode;
  ipState: string;
  notifyWhenServiceAvailable: boolean;
  isStateSupported: boolean;
  agreedTermsOfService: boolean;
  status: UserStatus;
  defaultPaymentMethod: PaymentMethod | CardPaymentToken['id'] | null;
}
