import { createApi } from '@reduxjs/toolkit/query/react';

import fetchBaseQueryWithCustomHeaders from 'services/api';
import { Endpoint } from 'services/endpoints';
import { Asset } from 'types/assets';

const REDUCER_PATH = 'assetsApi';

export interface AssetsResponse {
  data: {
    assets: Asset[];
  };
}

export const assetsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(),
  endpoints: builder => ({
    getAssets: builder.query<AssetsResponse, void>({
      query: () => Endpoint.Assets,
    }),
    getActiveAssetsSell: builder.query<AssetsResponse, void>({
      query: () => Endpoint.ActiveAssetsSell,
    }),
    getActiveAssets: builder.query<AssetsResponse, void>({
      query: () => Endpoint.ActiveAssets,
    }),
  }),
});

export default assetsApi;
