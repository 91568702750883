import { NavigationEvaluationFunc } from 'hooks/useNavigation';
import doesPathMatchCurrentPath from 'pages/route/utils';
import WorkflowStep from 'types/step';
import PATHS from 'utils/navigation';

import getInitialStep from './init';

export const canGoBackFromPaymentMethodsSell: NavigationEvaluationFunc<boolean> = ({ user, flow }) => {
  const isContinueFlow = doesPathMatchCurrentPath(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  const initialStep = getInitialStep({ user, flow });

  if (isContinueFlow) {
    return false;
  }

  return initialStep !== WorkflowStep.PaymentMethods;
};

export default canGoBackFromPaymentMethodsSell;
