import { keyframes } from 'tss-react';

import { tss } from 'theme';

export const useStyles = tss
  .withParams<{ color?: string; scale: number}>()
  .create(
    ({ theme, color, scale }) => ({
      spinner: {
        position: 'relative',
        width: scale ? 20 * theme.scale(scale) : theme.scale(20),
        height: scale ? 20 * theme.scale(scale) : theme.scale(20),
        '& path': {
          fill: color || theme.colors.spinner.inactive,
          animation: `${keyframes`
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        `} 0.8s linear infinite`,
        },
        '& path:nth-of-type(1)': {
          animationDelay: '-0.8s',
        },
        '& path:nth-of-type(2)': {
          animationDelay: '-0.7s',
        },
        '& path:nth-of-type(3)': {
          animationDelay: '-0.6s',
        },
        '& path:nth-of-type(4)': {
          animationDelay: '-0.5s',
        },
        '& path:nth-of-type(5)': {
          animationDelay: '-0.4s',
        },
        '& path:nth-of-type(6)': {
          animationDelay: '-0.3s',
        },
        '& path:nth-of-type(7)': {
          animationDelay: '-0.2s',
        },
        '& path:nth-of-type(8)': {
          animationDelay: '-0.1s',
        },
        '& path:nth-of-type(9)': {
          animationDelay: '0s',
        },
      },
    }),
  );

export default useStyles;
