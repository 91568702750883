const MESSAGES_NAMESPACE = 'common';
export const COMMON_LABELS = {
  CONTINUE: `${MESSAGES_NAMESPACE}.continue`,
  COPIED: `${MESSAGES_NAMESPACE}.copied`,
  ADD_CARD: `${MESSAGES_NAMESPACE}.addCard`,
  SELECT_PAYMENT_METHOD: `${MESSAGES_NAMESPACE}.selectPaymentMethod`,
  MENU: `${MESSAGES_NAMESPACE}.menu`,
  POPULAR: `${MESSAGES_NAMESPACE}.popular`,
  ALL: `${MESSAGES_NAMESPACE}.all`,
  GENERIC_ERROR: `${MESSAGES_NAMESPACE}.genericError`,
  APPLE_PAY: `${MESSAGES_NAMESPACE}.applePay`,
  GOOGLE_PAY: `${MESSAGES_NAMESPACE}.googlePay`,
};

export default COMMON_LABELS;
