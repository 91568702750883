import { tss } from 'theme';

export const useStyles = tss.create(
  ({ theme }) => ({
    copyButton: {
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      padding: 0,
      '& > div': {
        padding: 0,
      },
    },
    copiedText: {
      color: theme.colors.text.inactive,
    },
    walletAddressTooltipContent: {
      left: theme.scale(10),
      top: theme.scale(15),
      gap: theme.scale(8),
      width: `calc(100% - ${theme.scale(20)}px)`,
      display: 'flex',
      alignItems: 'center',
      flexGrow: 0,
      height: theme.scale(32),
      cursor: 'pointer',
      userSelect: 'none',
      '& > div:first-of-type': {
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);

export default useStyles;
