import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';
import Color from 'utils/colors/color';
import hex2rgba from 'utils/colors/convert';

export const useStyles = tss
  .withParams<{ disabled: boolean }>()
  .create(
    ({ theme, disabled }) => ({
      button: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        background: disabled ? theme.colors.background.inactive : theme.colors.background.accent,
        color: `${disabled ? theme.colors.text.inactive : theme.colors.text.secondary} !important`,
        border: 'unset',
        cursor: disabled ? 'not-allowed' : 'pointer',
        outline: 'unset',
        width: '100%',
        padding: theme.scale(12),
        borderRadius: theme.border.radius,
        fontWeight: 700,
        ...fontSizeMixin(FontSize.L),
        boxShadow: disabled ? 'unset' : `0px 2px 5px 0px ${hex2rgba(theme.colors.background.accent, 0.25)}`,
        transition: theme.colors.background.transition,
        '&:hover': {
          background: disabled ? theme.colors.background.inactive : theme.colors.background.accentDark,
          color: `${disabled
            ? theme.colors.text.inactive
            : new Color(theme.colors.text.accentDark)
              .replaceIfContrastInvalid(theme.colors.text.accentDark, theme.colors.text.accentDarkOpposite)
              .css} !important`,
        },
        '&:hover svg': {
          fill: `${disabled
            ? theme.colors.icons.inactive
            : new Color(theme.colors.icons.accentDark)
              .replaceIfContrastInvalid(theme.colors.icons.accentDark, theme.colors.icons.accentDarkOpposite)
              .css
          }`,
        },
        '&:focus': {
          color: `${disabled ? theme.colors.text.inactive : theme.colors.text.accentDark} !important`,
          outline: disabled ? 'none' : `2px solid ${theme.colors.border.accentDark}`,
          background: disabled ? theme.colors.background.inactive : theme.colors.background.highlight,
        },
        '&:focus svg': {
          fill: disabled ? theme.colors.icons.inactive : theme.colors.icons.accentDark,
        },
      },
      chevron: {
        transform: 'rotate(-90deg)',
        fill: disabled ? theme.colors.text.inactive : theme.colors.text.secondary,
        width: theme.scale(24),
        height: theme.scale(24),
      },
      chevronWrapper: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: theme.scale(40),
        height: '100%',
        right: 0,
        top: 0,
      },
      alignLeft: {
        right: 'unset',
        left: 0,
      },
      invert: {
        transform: 'rotate(90deg)',
      },
      marginAlignment: {
        display: 'flex',
        justifyContent: 'center',
        color: 'inherit !important',
        padding: `0 ${theme.scale(20)}px`,
        width: '100%',
        textAlign: 'center',
      },
      spinner: {
        '& div:after': {
          background: theme.colors.text.inactive,
        },
      },
    }),
  );

export default useStyles;
