import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M14.167 2.5H5.83366C4.91699 2.5 4.16699 3.25 4.16699 4.16667V17.5L10.0003',
  '15L15.8337 17.5V4.16667C15.8337 3.25 15.0837 2.5 14.167 2.5ZM14.167 15L10.0003 13.1833L5.83366 15V4.16667H14.167V15Z',
].join(' ');

export const IconBookmark = ({
  width = 20,
  height = 20,
  color,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={path} />
    </svg>
  );
};

export default IconBookmark;
