import { NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';
import { KycStatus, UserStatus } from 'types/user';

import { checkIfShouldSkipWalletStep } from './queryParams';

export const getQuoteScreenNextStep = ({ user, email, paymentMethod }: NavigationEvaluationFuncParams): WorkflowStep => {
  const skipWalletScreen = checkIfShouldSkipWalletStep();
  const skipSignInScreenDueToExistingEmail = email;
  const skipPaymentMethods = Boolean(paymentMethod?.default);
  const skipBankDetails = Boolean(paymentMethod?.defaultCard) || skipPaymentMethods;

  if (!user) {
    if (skipSignInScreenDueToExistingEmail) {
      return WorkflowStep.Otp;
    }

    return WorkflowStep.SignIn;
  }

  const {
    kycStatus,
    isKycRetryAllowed,
    country,
    isResidenceCountrySupported,
    isResidenceStateSupported,
    isIpCountrySupported,
    isIpStateSupported,
  } = user;

  if (kycStatus === null || kycStatus === KycStatus.Submitted) {
    if (country) {
      if (isResidenceCountrySupported) {
        if (isIpCountrySupported) {
          if (isIpStateSupported) {
            if (user.status === UserStatus.Blocked) {
              return WorkflowStep.KycRejected;
            }
            return WorkflowStep.Kyc;
          }
          return WorkflowStep.IpCountryUnavailable;
        }
        return WorkflowStep.IpCountryUnavailable;
      }
      return WorkflowStep.CountryUnavailable;
    }

    if (isIpCountrySupported) {
      if (isIpStateSupported) {
        if (user.status === UserStatus.Blocked) {
          return WorkflowStep.KycRejected;
        }
        return WorkflowStep.Kyc;
      }
      return WorkflowStep.IpCountryUnavailable;
    }
    return WorkflowStep.CountryCheck;
  }

  if (isResidenceCountrySupported) {
    if (isResidenceStateSupported) {
      if (isIpCountrySupported) {
        if (isIpStateSupported) {
          if (user.status === UserStatus.Blocked) {
            return WorkflowStep.KycRejected;
          }
          if (kycStatus === KycStatus.Approved) {
            if (skipWalletScreen) {
              if (skipPaymentMethods) {
                if (skipBankDetails) {
                  return WorkflowStep.Checkout;
                }
                return WorkflowStep.BankDetails;
              }
              return WorkflowStep.PaymentMethods;
            }
            return WorkflowStep.Wallet;
          }
          if (kycStatus === KycStatus.Rejected) {
            if (isKycRetryAllowed) {
              return WorkflowStep.Kyc;
            }
          }
          if (kycStatus === KycStatus.PendingApproval) {
            return WorkflowStep.Kyc;
          }
          return WorkflowStep.KycRejected;
        }
        return WorkflowStep.IpCountryUnavailable;
      }
      return WorkflowStep.IpCountryUnavailable;
    }
    return WorkflowStep.CountryUnavailable;
  }

  return WorkflowStep.CountryUnavailable;
};

export const getQuoteScreenPreviousStep = (): WorkflowStep => WorkflowStep.Quote;
