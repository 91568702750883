import { tss } from 'theme';

export const useStyles = tss.create(
  ({ theme }) => ({
    actionButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5,
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      '& > div': {
        padding: 0,
      },
    },
  }),
);

export default useStyles;
