import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    listItem: {
      marginRight: theme.scale(5),
      listStyleType: 'none',
      borderRadius: theme.border.radius,
      '&.Mui-focused, &.Mui-focusVisible': {
        background: theme.colors.background.highlight,
      },
      '&:hover': {
        background: theme.colors.background.highlight,
      },
    },
    button: {
      display: 'flex',
      padding: theme.scale(6),
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      width: '100%',
      '& > div': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    record: {
      display: 'flex',
      gap: theme.scale(12),
      textAlign: 'left',
    },
    label: {
      ...fontSizeMixin(FontSize.L),
      margin: 'unset',
      fontWeight: 500,
    },
    subLabel: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.inactive,
      margin: 'unset',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      width: theme.scale(20),
      height: theme.scale(20),
      borderRadius: 10,
    },
    arrowIcon: {
      margin: `0 ${theme.scale(6)}px`,
      fill: theme.colors.text.inactive,
    },
    iconPlaceholder: {
      width: theme.scale(20),
      height: theme.scale(20),
    },
  }),
);

export default useStyles;
