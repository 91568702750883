import { NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';

import getInitialStep from './init';
import { checkIfShouldSkipQuoteStep } from './queryParams';

export const canGoBackFromWalletScreen = ({ user }: NavigationEvaluationFuncParams): boolean => {
  const initialStep = getInitialStep({ user });
  const skipQuoteStep = checkIfShouldSkipQuoteStep();

  if (skipQuoteStep) {
    return false;
  }

  return initialStep !== WorkflowStep.Wallet;
};

export const getWalletNextStep = ({ paymentMethod }: NavigationEvaluationFuncParams): WorkflowStep => {
  const skipPaymentMethods = Boolean(paymentMethod?.default);
  if (skipPaymentMethods) {
    return WorkflowStep.Checkout;
  }
  return WorkflowStep.PaymentMethods;
};

export default canGoBackFromWalletScreen;
