import { useTranslation } from 'react-i18next';

import Header from 'components/header';
import Spinner from 'components/spinner';
import LaunchIllustration from 'illustrations/launch';
import { locators, QALocator } from 'utils/tests/QA';

import LABELS from '../keys';
import useStyles from './styles';

interface NotificationFinalScreenProps {
  countryName?: string;
  isSubscribed: boolean;
  isUserLoading: boolean;
}

const NotificationFinalScreen = ({
  countryName,
  isSubscribed,
  isUserLoading,
}: NotificationFinalScreenProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const headerText = isSubscribed
    ? t(LABELS.UNSUPPORTED_FINAL_HEADER_ENABLED)
    : t(LABELS.UNSUPPORTED_FINAL_HEADER_NOT_ENABLED);

  const titleText = isSubscribed
    ? t(LABELS.UNSUPPORTED_FINAL_TITLE_ENABLED)
    : t(LABELS.UNSUPPORTED_FINAL_TITLE_NOT_ENABLED);

  const captionText = isSubscribed
    ? t(LABELS.UNSUPPORTED_FINAL_CAPTION_ENABLED, { countryName })
    : t(LABELS.UNSUPPORTED_FINAL_CAPTION_NOT_ENABLED);

  if (isUserLoading) {
    return (
      <div className={classes.container}>
        <div className={classes.spinnerWrapper}>
          <Spinner
            scale={1.25}
            {...QALocator(locators.page.countryCheckFinal.viewLoadingSpinner)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Header>
        {headerText}
      </Header>
      <div className={classes.imageWrapper}>
        <LaunchIllustration />
      </div>
      <div className={classes.captionContainer}>
        <h3 className={classes.title}>
          {titleText}
        </h3>
        <div className={classes.caption}>
          {captionText}
        </div>
      </div>
    </div>
  );
};

export default NotificationFinalScreen;
