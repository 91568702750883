import { KycStatus } from './user';

export type WidgetInstanceId = string;

export enum WidgetEvent {
  KycStatusUpdate = 'KYC_STATUS_UPDATED',
  OrderPlaced = 'ORDER_PLACED',
  OrderCompleted = 'ORDER_COMPLETED',
  OrderFailed = 'ORDER_FAILED',
  OrderStuck = 'ORDER_STUCK'
}

export interface KycStatusUpdatePayload {
  kycStatus: KycStatus;
}

export interface EventBody {
  eventType: WidgetEvent;
  payload: KycStatusUpdatePayload | null;
}
