import { ReactElement, ReactNode } from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';
import useQuoteRefetch from 'hooks/useQuoteReFetch';
import IconHistory from 'icons/history';
import { QuotePayload } from 'services/quote/transactions/payloads';
import { AssetWithLabel } from 'types/assets';
import { getShortenedCryptoCurrencyName } from 'utils/currencies';
import { formatFiatCurrencyI18n } from 'utils/i18n';

import LABELS from './keys';
import useStyles from './styles';

interface RefreshingAssetPriceProps {
  reFetchIntervalInSeconds: number;
  fiatCurrency: AssetWithLabel;
  cryptocurrency: AssetWithLabel;
  isLoading: boolean;
  isError: boolean;
  fetchQuote: (overrideQuotePayload?: Partial<QuotePayload>) => void;
  price?: string;
  refetch?: boolean;
  countdownOverride?: ReactNode;
}

const RefreshingAssetPrice = ({
  reFetchIntervalInSeconds,
  cryptocurrency,
  fiatCurrency,
  price,
  isLoading,
  isError,
  fetchQuote,
  refetch = true,
  countdownOverride,
}: RefreshingAssetPriceProps) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const secondsToRefetch = useQuoteRefetch({
    isError,
    isLoading,
    fetchQuote,
    reFetchIntervalInSeconds,
    refetch,
  });

  const isCountdownVisible = refetch && !countdownOverride;

  return (
    <>
      <span className={classes.flexAlign}>
        <OptionallyVisible visible={isCountdownVisible}>
          <Trans
            i18nKey={LABELS.SECONDS_TO_RE_FETCH}
            components={{ IconHistory: <IconHistory height={13} /> }}
            values={{ secondsToRefetch }}
          />
        </OptionallyVisible>
        <OptionallyVisible visible={Boolean(countdownOverride)}>
          {countdownOverride as ReactElement}
        </OptionallyVisible>
      </span>
      <OptionallyVisible visible={Boolean(price)}>
        <span>
          {t(LABELS.PRICE, {
            symbol: getShortenedCryptoCurrencyName(cryptocurrency),
            price: formatFiatCurrencyI18n(i18n.language)(Number(price), fiatCurrency),
          })}
        </span>
      </OptionallyVisible>
    </>
  );
};

export default RefreshingAssetPrice;
