// Temporarily defining currencies on FE
export const DEFAULT_CURRENCIES = {
  CRYPTO: 'BTC',
  FIAT: 'USD',
};

const DEFAULT_FIAT_AMOUNT_FOR_PROD_ENV = '200';
export const DEFAULT_FIAT_AMOUNT = window.s4cConfig?.prefilledValues?.fiatAmount ?? DEFAULT_FIAT_AMOUNT_FOR_PROD_ENV;
export const DEFAULT_CRYPTO_AMOUNT = '0';

export const CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION = 6;
export const FIAT_VALUE_DEFAULT_DECIMAL_PRECISION = 2;
