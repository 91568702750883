import ExchangeImage from 'assets/images/exchange.svg';
import { getIllustrationCSS } from 'illustrations/styles';
import { Illustration } from 'illustrations/types';
import { tss, useThemeContext } from 'theme';

export const getColorBasedMasks = (theme: ReturnType<typeof useThemeContext>['theme']) => ([
  { color: theme.colors.illustrations.saturation95, layerIds: [2] }, // #F3F3F3
  { color: theme.colors.illustrations.saturation86, layerIds: [0, 1, 3, 29, 30, 31] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation51, layerIds: [4, 12, 16, 19, 22, 26] }, // #818181
  { color: theme.colors.illustrations.saturation65, layerIds: [5] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation51, layerIds: [22, 27] }, // #818181
  { color: theme.colors.illustrations.background, layerIds: [18] },
  { color: theme.colors.illustrations.saturation51, layerIds: [19] }, // #818181
  { color: theme.colors.illustrations.saturation65, layerIds: [20] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation51, layerIds: [23] }, // #818181
  { color: theme.colors.illustrations.background, layerIds: [25] },
  { color: theme.colors.illustrations.saturation51, layerIds: [15, 22, 27] }, // #818181
  { color: theme.colors.illustrations.saturation86, layerIds: [13] }, // #DBDBDB
  { color: theme.colors.illustrations.background, layerIds: [6, 7, 8, 9, 10, 11, 14, 17, 21, 24, 28] },
]);

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      ...getIllustrationCSS(
        Illustration.Exchange,
        getColorBasedMasks(theme),
        ExchangeImage,
      ),
    },
  }),
);

export default useStyles;
