import NotificationImage from 'assets/images/notification.svg';
import { getIllustrationCSS } from 'illustrations/styles';
import { Illustration } from 'illustrations/types';
import { tss, useThemeContext } from 'theme';

export const getColorBasedMasks = (theme: ReturnType<typeof useThemeContext>['theme']) => ([
  { color: theme.colors.illustrations.saturation95, layerIds: [0] }, // #F3F3F3,
  { color: theme.colors.illustrations.saturation86, layerIds: [4, 5, 21, 23, 24, 25] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation65, layerIds: [1, 14] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation98, layerIds: [2, 3] }, // #F9F9F9
  { color: theme.colors.illustrations.saturation86, layerIds: [4] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation51, layerIds: [7, 12] }, // #818181
  { color: theme.colors.illustrations.background, layerIds: [8, 9] },
  { color: theme.colors.illustrations.saturation51, layerIds: [11, 15, 16, 17, 18, 19, 20] }, // #818181
  { color: theme.colors.illustrations.saturation65, layerIds: [6, 10, 13, 22] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation86, layerIds: [21] }, // #DBDBDB
]);

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      ...getIllustrationCSS(
        Illustration.Notification,
        getColorBasedMasks(theme),
        NotificationImage,
      ),
    },
  }),
);

export default useStyles;
