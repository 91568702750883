import { FC, useEffect, useState } from 'react';

import ConfigQueryParam from 'types/queryParams';
import getQueryParam from 'utils/queryParams';

import useStyles from './styles';

const Placeholder = () => {
  const { classes } = useStyles({ height: 22 });

  return <div className={classes.placeholder} />;
};

const WithIllustrationController = (Component: FC<any>) => {
  const Wrapper = (props: any) => {
    const [disabled, setDisabled] = useState(getQueryParam(ConfigQueryParam.HideIllustrations) === 'true');

    useEffect(() => {
      if (window.partnerConfig?.displayIllustration === false) {
        setDisabled(true);
      }
    }, [window.partnerConfig]);

    if (disabled) {
      return <Placeholder />;
    }

    return <Component {...props} />;
  };

  return Wrapper;
};

export default WithIllustrationController;
