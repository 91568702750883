import { useState } from 'react';
import Collapsible from 'react-collapsible';
import {
  useTranslation,
} from 'react-i18next';

import LoadingWrapper from 'components/loading';
import Spinner from 'components/spinner';
import IconChevron from 'icons/chevron';
import { AssetWithLabel, Quote } from 'types/assets';
import getCurrencySymbol, { formatCryptoAmountWithSymbolAtEnd } from 'utils/currencies';
import { formatFiatCurrencyI18n, formatFiatCurrencyI18nWithoutSymbol } from 'utils/i18n';

import KEYS, { LABELS } from './keys';
import useStyles from './styles';

interface QuoteDetailsSellProps {
  quote: {
    details?: Quote;
    isLoading: boolean;
    isError: boolean;
  };
  cryptocurrency: AssetWithLabel;
  fiatCurrency: AssetWithLabel;
  onExpand?: () => void;
  onShrink?: () => void;
}

const QuoteDetailsSell = ({
  quote,
  cryptocurrency,
  fiatCurrency,
  onExpand,
  onShrink,
}: QuoteDetailsSellProps) => {
  const [isOpened, setIsOpened] = useState(KEYS.QUOTE_DETAILS_EXPANDED_BY_DEFAULT);
  const [applyBorder, setApplyBorder] = useState(!isOpened);
  const { classes, cx, theme } = useStyles({ isOpened, applyBorder });
  const { t, i18n } = useTranslation();
  const currencySymbol = getCurrencySymbol(i18n.language)(fiatCurrency.symbol);
  const currencyWithoutSymbolFormatter = formatFiatCurrencyI18nWithoutSymbol(i18n.language);

  const toggle = () => {
    if (!isOpened) {
      setApplyBorder(false);
    }
    setIsOpened(!isOpened);
  };

  const onClose = () => {
    setApplyBorder(true);
    onShrink?.();
  };

  const onOpen = () => {
    onExpand?.();
  };

  return (
    <Collapsible
      open={isOpened}
      tabIndex={0}
      handleTriggerClick={toggle}
      onOpening={onOpen}
      onClose={onClose}
      trigger={(
        <div className={classes.collapsibleHeader}>
          <div className={classes.collapsibleHeaderText}>
            <LoadingWrapper
              isLoading={quote.isLoading}
              loadingIndicator={(
                <Spinner
                  className={cx(classes.inlineSpinner, classes.marginRight)}
                  color={theme.colors.spinner.inactive}
                  scale={0.7}
                />
                )}
            >
              <strong className={classes.total}>
                {t(LABELS.SELL)}
                {' '}
                {formatCryptoAmountWithSymbolAtEnd(quote.details?.cryptoAmount ?? '0', cryptocurrency)}
              </strong>
            </LoadingWrapper>
            {' '}
            {t(LABELS.RECEIVE)}
            {' '}
            <LoadingWrapper
              isLoading={quote.isLoading}
              loadingIndicator={(
                <Spinner
                  className={cx(classes.inlineSpinner, classes.marginRight)}
                  color={theme.colors.spinner.inactive}
                  scale={0.7}
                />
                )}
            >
              <div>
                ~
                {formatFiatCurrencyI18n(i18n.language)(Number(quote.details?.fiatAmount ?? 0), fiatCurrency)}
              </div>
            </LoadingWrapper>
          </div>
          <div className={classes.expandButton}>
            <IconChevron className={classes.icon} color={theme.colors.icons.accentDark} />
          </div>
        </div>
      )}
    >
      <div className={classes.gridWrapper}>
        <div className={classes.separator} />
        <div className={classes.grid}>
          <div>{formatCryptoAmountWithSymbolAtEnd(quote.details?.cryptoAmount ?? '0', cryptocurrency)}</div>
          <div>{currencySymbol}</div>
          <div className={classes.alignRight}>
            <LoadingWrapper
              isLoading={quote.isLoading}
              loadingIndicator={(
                <Spinner
                  className={classes.inlineSpinner}
                  color={theme.colors.spinner.inactive}
                  scale={0.6}
                />
            )}
            >
              <div>
                {currencyWithoutSymbolFormatter(Number(quote.details?.cryptoCostFiatAmount ?? 0), fiatCurrency)}
              </div>
            </LoadingWrapper>
          </div>
          <div>{t(LABELS.PROCESSING_FEE)}</div>
          <div>{currencySymbol}</div>
          <div className={classes.alignRight}>
            <LoadingWrapper
              isLoading={quote.isLoading}
              loadingIndicator={(
                <Spinner
                  className={classes.inlineSpinner}
                  color={theme.colors.spinner.inactive}
                  scale={0.6}
                />
            )}
            >
              <div>
                {currencyWithoutSymbolFormatter(Number(quote.details?.processingFeeFiatAmount ?? 0), fiatCurrency)}
              </div>
            </LoadingWrapper>
          </div>
        </div>
        <div className={classes.separator} />
        <div className={classes.collapsibleFooter}>
          <span>
            <strong className={classes.total}>
              {t(LABELS.TOTAL)}
            </strong>
            {' '}
            {t(LABELS.ESTIMATE)}
          </span>
          <LoadingWrapper
            isLoading={quote.isLoading}
            loadingIndicator={(
              <Spinner
                className={cx(classes.inlineSpinner, classes.marginRight)}
                color={theme.colors.spinner.inactive}
                scale={0.7}
              />
                )}
          >
            <strong>
              {formatFiatCurrencyI18n(i18n.language)(Number(quote.details?.fiatAmount ?? 0), fiatCurrency)}
            </strong>
          </LoadingWrapper>
        </div>
      </div>

    </Collapsible>
  );
};

export default QuoteDetailsSell;
