import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import getInitialStep from 'navigation/init';
import { RootState } from 'state/store';
import { WorkflowStep } from 'types/step';

const SLICE_NAME = 'step';

interface StepState {
  step: WorkflowStep | WorkflowStep;
  isAddingCard: boolean;
}

export const initialState: StepState = {
  step: getInitialStep({}),
  isAddingCard: false,
};

export const stepSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<WorkflowStep | WorkflowStep>) => {
      state.step = action.payload;
    },
    setIsAddingCard: (state, action: PayloadAction<boolean>) => {
      state.isAddingCard = action.payload;
    },
  },
});

export const { changeStep, setIsAddingCard } = stepSlice.actions;

export const selectStep = (state: RootState) => state.step.step;
export const selectIsAddingCard = (state: RootState) => state.step.isAddingCard;

export default stepSlice.reducer;
