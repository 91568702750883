import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    label: {
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
    },
    formItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
    },
    formItemError: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.error,
    },
    input: {
      '& > input': {
        ...fontSizeMixin(FontSize.M),
      },
    },
  }),
);

export default useStyles;
