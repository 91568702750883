import AuthImage from 'assets/images/authentication.svg';
import { getIllustrationCSS } from 'illustrations/styles';
import { Illustration } from 'illustrations/types';
import { tss, useThemeContext } from 'theme';

export const getColorBasedMasks = (theme: ReturnType<typeof useThemeContext>['theme']) => ([
  { color: theme.colors.illustrations.saturation95, layerIds: [0] }, // #F3F3F3
  { color: theme.colors.illustrations.saturation86, layerIds: [15, 16, 17, 18, 19, 20, 27, 28, 29] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation98, layerIds: [21] }, // #F9F9F9
  { color: theme.colors.illustrations.saturation51, layerIds: [1, 14, 22, 23, 24, 25, 26, 30] }, // #818181
  { color: theme.colors.illustrations.background, layerIds: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] },
]);

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      ...getIllustrationCSS(
        Illustration.Authentication,
        getColorBasedMasks(theme),
        AuthImage,
      ),
    },
  }),
);

export default useStyles;
