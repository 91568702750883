import {
  tss,
} from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    listOfCardsErrorWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      gap: theme.scale(10),
      paddingRight: theme.scale(10),
      fontWeight: 500,
      color: theme.colors.text.inactive,
      textAlign: 'center',
      ...fontSizeMixin(FontSize.M),
    },
    errorIcon: {
      fill: theme.colors.icons.error,
    },
  }),
);

export default useStyles;
