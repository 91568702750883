import { tss } from 'theme';

export const useStyles = tss.create(
  () => ({
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
    },
  }),
);

export default useStyles;
