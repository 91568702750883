import { makeLabels } from 'utils/i18n';

export const ORDER_PLACEHOLDER = ':orderId';

const MESSAGES_NAMESPACE = 'components.ordersList';
export const LABELS = makeLabels({
  PAYMENT_FAILED: 'paymentFailed',
  AWAITING_CLIENT_CONFIRMATION: 'awaitingClientConfirmation',
  AWAITING_DEPOSIT: 'awaitingDeposit',
  COMPLETED: 'completed',
  PROCESSING: 'processing',
  GENERAL_STATUS_MESSAGE: 'generalStatusMessage',
}, MESSAGES_NAMESPACE);

export default LABELS;
