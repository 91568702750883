import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    infoBlockLabel: {
      display: 'flex',
      alignItems: 'center',
      ...fontSizeMixin(FontSize.S),
    },
    infoBlockValue: {
      display: 'flex',
      width: '100%',
      minHeight: theme.scale(31),
      alignItems: 'center',
      padding: `${theme.scale(8)}px ${theme.scale(12)}px`,
      background: theme.colors.background.primary,
      borderRadius: theme.border.radius,
      gap: theme.scale(8),
      maxHeight: theme.scale(31),
      ...fontSizeMixin(FontSize.S),
    },
    cryptoIcon: {
      width: theme.scale(16),
      height: theme.scale(16),
      borderRadius: '50%',
      background: theme.colors.background.containerFill,
    },
    cardBrandIcon: {
      marginLeft: theme.scale(-5),
    },
    walletAddressAndTagBlock: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(8),
    },
    editButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      marginLeft: 'auto',
      padding: 0,
      '& > div': {
        padding: 'unset',
      },
    },
    addCardButton: {
      marginRight: theme.scale(10),
      marginBottom: theme.scale(10),
    },
    plusIcon: {
      transform: 'rotate(45deg)',
    },
    notice: {
      fontSize: theme.scale(12),
      color: theme.colors.text.inactive,
    },
    gap: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
    },
    listOfCardsErrorWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      gap: theme.scale(10),
      paddingRight: theme.scale(10),
      fontWeight: 500,
      color: theme.colors.text.inactive,
      textAlign: 'center',
      ...fontSizeMixin(FontSize.M),
    },
    errorIcon: {
      fill: theme.colors.icons.error,
    },
  }),
);

export default useStyles;
