import { CreatePaymentMethodPayload } from 'services/payments/types';
import { Shift4Utils } from 'types/card';
import { ReduxToolkitError, Response } from 'types/http';
import PaymentMethod from 'types/payment';
import { ApplePayBridgeAction } from 'types/payments/applePay';

import { PaymentOutcome } from '../types';
import applePayConfig, { MERCHANT_LABEL } from './config';
import KEYS from './keys';

export enum ApplePayTransactionStatus {
  Success = 'success',
  Fail = 'fail'
}

interface PayWithApplePayParams extends CreatePaymentRequestParams {
  createPaymentMethod: (payload: CreatePaymentMethodPayload) => Promise<Response<string | null> | ReduxToolkitError>;
}

interface CreatePaymentRequestParams {
  shift4Utils: Shift4Utils;
  currency: string;
  value: string;
}

interface ApplePayPaymentRequest {
  currency: string;
  value: string;
  shift4PublicKey: string;
  widgetId: string;
  merchantIdentifier: string;
  createPaymentMethod: (payload: CreatePaymentMethodPayload) => Promise<Response<string | null> | ReduxToolkitError>;
}

export const createPaymentRequest = async ({
  shift4Utils,
  currency,
  value,
}: CreatePaymentRequestParams) => {
  const paymentRequest = shift4Utils.createPaymentRequest([applePayConfig], {
    total: {
      label: MERCHANT_LABEL,
      amount: {
        currency,
        value,
      },
    },
  });
  try {
    const paymentResponse = await paymentRequest.show();

    return ({
      paymentResponse,
    });
  } catch (error) {
    return ({
      error,
    });
  }
};

export const payWithApplePayInsideIframe = async ({
  currency,
  value,
  shift4PublicKey,
  widgetId,
  merchantIdentifier,
  createPaymentMethod,
}: ApplePayPaymentRequest): Promise<PaymentOutcome> => {
  const shoppingCartDetails = {
    total: {
      label: MERCHANT_LABEL,
      amount: {
        currency,
        value,
      },
    },
    merchantIdentifier,
  };

  const token = await new Promise<string | undefined>((resolve, reject) => {
    const handleEvent = (event: MessageEvent) => {
      const isPaymentRequest = event.data.action === ApplePayBridgeAction.createApplePayPaymentRequest;
      const hasPaymentResponseDetails = Boolean(event.data?.paymentResponse?.details);

      const isValidPaymentRequest = [isPaymentRequest, hasPaymentResponseDetails].every(Boolean);
      if (isValidPaymentRequest) {
        resolve(event.data.paymentResponse.details.token.paymentData);
      }

      if (!isValidPaymentRequest && event.data.error) {
        reject(event.data.error);
      }
    };

    window.addEventListener('message', handleEvent);
    window.parent.postMessage({
      action: ApplePayBridgeAction.createApplePayPaymentRequest,
      applePayMethodData: applePayConfig,
      shoppingCartDetails,
      shift4PublicKey,
      merchantId: merchantIdentifier,
      widgetId,
    }, KEYS.UNSPECIFIED_ORIGIN);
  });

  if (!token) {
    return ({
      error: 'Unable to obtain token.',
    });
  }

  const paymentMethodCreationResponse = await createPaymentMethod({
    fiatCurrencyCode: currency,
    fiatAmount: value,
    type: PaymentMethod.ApplePay,
    applePay: {
      token,
    },
  });

  if ('error' in paymentMethodCreationResponse || !paymentMethodCreationResponse) {
    return ({
      error: 'Unable to create payment method.',
    });
  }

  const clientObjectId = paymentMethodCreationResponse.data;
  if (!clientObjectId || typeof clientObjectId !== 'string') {
    return ({
      error: 'Unable to obtain client object id.',
    });
  }

  return ({
    paymentToken: clientObjectId,
    complete: () => {
      window.parent.postMessage({
        action: ApplePayBridgeAction.applePayChargeResponse,
        status: ApplePayTransactionStatus.Success,
        widgetId,
      }, KEYS.UNSPECIFIED_ORIGIN);
    },
    onError: () => {
      window.parent.postMessage({
        action: ApplePayBridgeAction.applePayChargeResponse,
        status: ApplePayTransactionStatus.Fail,
        widgetId,
      }, KEYS.UNSPECIFIED_ORIGIN);
    },
  });
};

export const payWithApplePay = async ({
  shift4Utils,
  currency,
  value,
  createPaymentMethod,
}: PayWithApplePayParams): Promise<PaymentOutcome> => {
  const paymentResponse = await createPaymentRequest({
    shift4Utils,
    currency,
    value,
  });
  if ('error' in paymentResponse) {
    return ({
      error: 'Unable to create payment request.',
    });
  }

  const paymentMethodCreationResponse = await createPaymentMethod({
    fiatCurrencyCode: currency,
    fiatAmount: value,
    type: PaymentMethod.ApplePay,
    applePay: {
      token: paymentResponse?.paymentResponse?.details?.token?.paymentData,
    },
  });

  if ('error' in paymentMethodCreationResponse || !paymentMethodCreationResponse) {
    paymentResponse?.paymentResponse.complete(ApplePayTransactionStatus.Fail);
    return ({
      error: 'Unable to create payment method.',
    });
  }

  const clientObjectId = paymentMethodCreationResponse.data;
  if (!clientObjectId || typeof clientObjectId !== 'string') {
    paymentResponse?.paymentResponse?.complete(ApplePayTransactionStatus.Fail);
    return ({
      error: 'Unable to obtain client object id.',
    });
  }

  if (!paymentResponse?.paymentResponse?.complete) {
    return ({
      error: 'Missing complete callback in the response.',
    });
  }

  return ({
    paymentToken: clientObjectId,
    complete: () => paymentResponse.paymentResponse.complete(ApplePayTransactionStatus.Success),
    onError: () => {
      paymentResponse.paymentResponse.complete(ApplePayTransactionStatus.Fail);
    },
  });
};
