import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import ApplePayIcon from 'assets/images/paymentMethods/applePay.svg';
import GooglePayIcon from 'assets/images/paymentMethods/googlePay.svg';
import Button from 'components/button';
import IconArrow from 'icons/arrow';
import IconCard from 'icons/card';
import PaymentMethod from 'types/payment';

import LABELS from './keys';
import useStyles from './tile.styles';

interface TileProps {
  method: PaymentMethod;
  onClick: () => void;
}

const Tile = ({
  method,
  onClick,
}: TileProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const Icon: Record<PaymentMethod, ReactNode> = {
    [PaymentMethod.Card]: <IconCard />,
    [PaymentMethod.ApplePay]: <img src={ApplePayIcon} width={31} height={20} alt={t(LABELS.APPLE_PAY)} />,
    [PaymentMethod.GooglePay]: <img src={GooglePayIcon} width={37.6} height={20} alt={t(LABELS.GOOGLE_PAY)} />,
  };

  const paymentMethodName: Record<PaymentMethod, string> = {
    [PaymentMethod.Card]: t(LABELS.DEBIT_OR_CREDIT_CARD),
    [PaymentMethod.ApplePay]: t(LABELS.APPLE_PAY),
    [PaymentMethod.GooglePay]: t(LABELS.GOOGLE_PAY),
  };

  return (
    <Button
      className={classes.container}
      onClick={onClick}
    >
      {Icon[method]}
      {paymentMethodName[method]}
      <IconArrow className={classes.arrowIcon} />
    </Button>
  );
};

export default Tile;
