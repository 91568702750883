import ConfigQueryParam from 'types/queryParams';
import getQueryParam from 'utils/queryParams';

const DEFAULT_BORDER_RADIUS = 5;
const MAX_BORDER_RADIUS = 20;

const getBorderRadius = (): number => {
  const paramRadius = getQueryParam(ConfigQueryParam.ThemeRadius) ?? window.partnerConfig?.cornerRadius?.toString();
  if (!paramRadius) {
    return DEFAULT_BORDER_RADIUS;
  }

  const radius = parseInt(paramRadius, 10);
  if (Number.isNaN(radius)) {
    return DEFAULT_BORDER_RADIUS;
  }

  if (radius > MAX_BORDER_RADIUS) {
    return MAX_BORDER_RADIUS;
  }

  return radius;
};

export default getBorderRadius;
