import { ReactNode } from 'react';

import Button from 'components/button';
import OptionallyVisible from 'components/optionallyVisible';
import IconArrow from 'icons/arrow';

import useStyles from './item.styles';
import { ItemIcon } from './itemIcon';

export interface SearchableListItemProps {
  icon?: ReactNode;
  iconUrl?: string | null;
  label: string;
  subLabel: string;
  id: string;
}

export const SearchableListItem = ({
  iconUrl,
  icon,
  label,
  subLabel,
  ...props
}: SearchableListItemProps) => {
  const { classes } = useStyles();
  const hasIcon = Boolean(icon || iconUrl);

  return (
    <li className={classes.listItem} {...props}>
      <Button
        className={classes.button}
      >
        <div className={classes.record}>
          <OptionallyVisible visible={hasIcon}>
            <ItemIcon
              icon={icon}
              iconUrl={iconUrl}
              label={label}
            />
          </OptionallyVisible>
          <OptionallyVisible visible={!hasIcon}>
            <div className={classes.iconPlaceholder} />
          </OptionallyVisible>
          <div>
            <h5 className={classes.label}>
              {label}
            </h5>
            <p className={classes.subLabel}>
              {subLabel}
            </p>
          </div>
        </div>
        <IconArrow className={classes.arrowIcon} />
      </Button>
    </li>
  );
};

export default SearchableListItem;
