import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';
import { Asset, AssetWithLabel } from 'types/assets';
import { Order } from 'types/order';
import { TransactionId } from 'types/transactions';

const SLICE_NAME = 'order';

interface OrderState {
  order: Order;
}

const initialState: OrderState = {
  order: {},
};

export const orderSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },
    setOrderEmail: (state, action: PayloadAction<string>) => {
      state.order.email = action.payload;
    },
    setTransactionId: (state, action:PayloadAction<TransactionId>) => {
      state.order.transactionId = action.payload;
    },
    setWalletAddress: (state, action:PayloadAction<string>) => {
      state.order.walletAddress = action.payload;
    },
    setTagOrMemo: (state, action:PayloadAction<string>) => {
      state.order.tagOrMemo = action.payload;
    },
    setExecutedQuoteId: (state, action: PayloadAction<string>) => {
      state.order.quoteId = action.payload;
    },
  },
});

export const {
  setOrder,
  setOrderEmail,
  setTransactionId,
  setWalletAddress,
  setTagOrMemo,
  setExecutedQuoteId,
} = orderSlice.actions;

export const selectOrder = (state: RootState) => state.order.order;
export const selectTransactionId = (state: RootState) => state.order.order.transactionId;
export const selectWalletAddress = (state: RootState) => state.order.order.walletAddress;
const cryptoAsset = (state: RootState) => state.order.order.selectedCryptoAsset;
const fiatAsset = (state: RootState) => state.order.order.selectedFiatAsset;
const mapLabelsToAssets = (asset?: Asset): AssetWithLabel | undefined => {
  if (!asset) {
    return undefined;
  }

  return ({
    ...asset,
    id: asset.symbol,
    label: asset.name,
    subLabel: `${asset.symbol} - ${asset.chainCode}`,
  });
};
export const selectCryptoAsset = createSelector([cryptoAsset], mapLabelsToAssets);
export const selectFiatAsset = createSelector([fiatAsset], mapLabelsToAssets);

export default orderSlice.reducer;
