import { lazy } from 'react';

import useMatch from 'pages/route/useMatch';

const ReceiptContainer = lazy(() => import('./container'));

export interface ReceiptRouteProps {
  path: string;
}

export const ReceiptRoute = ({ path }: ReceiptRouteProps) => {
  const match = useMatch(path);
  const orderId = match?.params?.orderId || '';

  return (
    <ReceiptContainer
      transactionId={orderId}
    />
  );
};

export default ReceiptRoute;
