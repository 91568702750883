import { tss } from 'theme';

export const useStyles = tss
  .create(
    ({ theme }) => ({
      list: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        gap: theme.scale(22),
        '&::-webkit-scrollbar': {
          width: theme.scale(5),
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: theme.scale(5),
          background: theme.colors.text.inactive,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: theme.colors.background.accentDark,
        },
      },
      group: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.scale(3),
        padding: 0,
        margin: 0,
      },
      groupLabel: {
        color: theme.colors.text.inactive,
        fontSize: theme.scale(12),
        fontWeight: 500,
      },
    }),
  );

export default useStyles;
