export const KEYS = {
  REFETCH_INTERVAL_IN_SECONDS: 5,
  REFETCH_INTERVAL_SELL_IN_SECONDS: 30,
  OFFRAMP_3DS_AMOUNT: 1,
  COUNTRY_CODE: 'LT',
  QUOTE_LOADING_PLACEHOLDER: '...',
};

const MESSAGES_NAMESPACE = 'checkout';
export const LABELS = {
  WALLET_MASK_FILL: '••••',
  ESTIMATED_PRICE: `${MESSAGES_NAMESPACE}.estimatedPrice`,
  REGULATORY_TEXT_SELL: `${MESSAGES_NAMESPACE}.regulatoryTextSell`,
  REGULATORY_TEXT: `${MESSAGES_NAMESPACE}.regulatoryText`,
  REVIEW_ORDER_CRYPTO_AMOUNT_NOTICE: `${MESSAGES_NAMESPACE}.reviewOrderCryptoAmountNotice`,
  SELLING: `${MESSAGES_NAMESPACE}.selling`,
  SEND_TO: `${MESSAGES_NAMESPACE}.sendTo`,
  CONFIRM_QUOTE_DISCLAIMER: `${MESSAGES_NAMESPACE}.confirmQuoteDisclaimer`,
  CARD_MISSING: `${MESSAGES_NAMESPACE}.missingCard`,
  SELL_AMOUNT: `${MESSAGES_NAMESPACE}.sellAmount`,
  SELL: `${MESSAGES_NAMESPACE}.sell`,
  PAY_AMOUNT: `${MESSAGES_NAMESPACE}.payAmount`,
  PAY: `${MESSAGES_NAMESPACE}.pay`,
  PAY_WITH: `${MESSAGES_NAMESPACE}.payWith`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  PAYMENT_IN_PROGRESS: `${MESSAGES_NAMESPACE}.paymentInProgress`,
  QUOTE_REFRESHING: `${MESSAGES_NAMESPACE}.quoteRefreshing`,
  CONTACT_SUPPORT: `${MESSAGES_NAMESPACE}.contactSupport`,
  YOU_RECEIVE: `${MESSAGES_NAMESPACE}.youReceive`,
  YOU_RECEIVE_TOOLTIP: `${MESSAGES_NAMESPACE}.youReceiveTooltip`,
  TOTAL: `${MESSAGES_NAMESPACE}.total`,
};

export default KEYS;
