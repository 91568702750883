import { ReactElement } from 'react';

interface LoadingWrapperProps {
  isLoading: boolean;
  loadingIndicator: ReactElement;
  children: ReactElement;
}

export const LoadingWrapper = ({
  isLoading,
  loadingIndicator,
  children,
}: LoadingWrapperProps) => (isLoading ? loadingIndicator : children);

export default LoadingWrapper;
