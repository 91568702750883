import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M12.6654 4.27333L11.7254 3.33333L7.9987 7.06L4.27203 3.33333L3.33203 4.27333L7.0587 8L3.33203',
  '11.7267L4.27203 12.6667L7.9987 8.94L11.7254 12.6667L12.6654 11.7267L8.9387 8L12.6654 4.27333Z',
].join(' ');

export const Close = ({
  width = 16,
  height = 16,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default Close;
