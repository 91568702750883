import Button from 'components/button';
import OptionallyVisible from 'components/optionallyVisible';
import IconEdit from 'icons/edit';
import { AssetWithLabel } from 'types/assets';
import { locators, QALocator } from 'utils/tests/QA';

import WalletAddressTooltip from '../walletAddressTooltip';
import { useStyles } from './preview.styles';

interface WalletPreviewProps {
  label: string;
  walletAddress: string;
  asset?: AssetWithLabel;
  shouldShowMemoOrTag: boolean;
  walletAddressTag?: string;
  canEdit: boolean;
  editWallet: () => void;
}

const WalletPreview = ({
  label,
  walletAddress,
  asset,
  shouldShowMemoOrTag,
  walletAddressTag,
  canEdit,
  editWallet,
}: WalletPreviewProps) => {
  const { classes, theme } = useStyles();

  const hasNetworkIcon = Boolean(asset?.cryptoNetworkIconUrl);

  return (
    <>
      <div className={classes.infoBlockLabel}>
        {label}
      </div>
      <div className={classes.infoBlockValue}>
        <OptionallyVisible visible={hasNetworkIcon}>
          <img
            src={asset?.cryptoNetworkIconUrl as string}
            className={classes.cryptoIcon}
            alt={asset?.name}
          />
        </OptionallyVisible>
        <div className={classes.walletAddressAndTagBlock}>
          <WalletAddressTooltip
            walletAddress={walletAddress}
          />
          <OptionallyVisible visible={shouldShowMemoOrTag}>
            <div>{walletAddressTag}</div>
          </OptionallyVisible>
        </div>
        <OptionallyVisible visible={canEdit}>
          <Button
            className={classes.editButton}
            onClick={editWallet}
            {...QALocator(locators.page.checkout.editWalletButton)}
          >
            <IconEdit color={theme.colors.icons.accentDark} />
          </Button>
        </OptionallyVisible>
      </div>
    </>
  );
};

export default WalletPreview;
