import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import IconArrow from 'icons/arrow';

import { MenuItem } from './menuList';
import useStyles from './menuListItem.styles';

type MenuListItemProps = MenuItem & {
  hasPendingOrders?: boolean;
};

export const MenuListItem = ({
  icon,
  label,
  onOptionClick,
  hasPendingOrders,
}: MenuListItemProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <li className={cx(classes.listItem, {
      [classes.listItemPendingOrders]: hasPendingOrders,
    })}
    >
      <Button
        onClick={onOptionClick}
        className={classes.button}
      >
        <div className={classes.record}>
          <div className={classes.iconContainer}>{icon}</div>
          <h5 className={classes.label}>
            {t(label)}
          </h5>
        </div>
        <IconArrow className={classes.arrowIcon} />
      </Button>
    </li>
  );
};

export default MenuListItem;
