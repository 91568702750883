import { useTranslation } from 'react-i18next';

import InputFormField from 'components/form/fields/input';
import OptionallyVisible from 'components/optionallyVisible';
import maskContent from 'libs/sentry/mask';
import { locators, QALocator } from 'utils/tests/QA';

import { KEYS, LABELS } from './keys';
import { WalletFormProps } from './types';
import WalletInputIcon from './walletInputIcon';

const WalletForm = ({
  onWalletAddressChange,
  onTagOrMemoChange,
  walletAddressValue,
  walletAddressError,
  tagOrMemoValue,
  tagOrMemoError,
  selectedCryptoAsset,
  shouldShowDestinationTag,
  shouldShowMemo,
}: WalletFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <InputFormField
        onChange={onWalletAddressChange}
        id={KEYS.WALLET_ADDRESS_INPUT_ID}
        value={walletAddressValue}
        errorText={walletAddressError}
        label={t(LABELS.RECEIVING_WALLET)}
        placeholder={t(LABELS.WALLET_ADDRESS)}
        leftIcon={(
          <WalletInputIcon
            selectedCryptoAsset={selectedCryptoAsset}
          />
      )}
        {...maskContent()}
      />
      <OptionallyVisible visible={shouldShowDestinationTag}>
        <InputFormField
          onChange={onTagOrMemoChange}
          id={KEYS.DESTINATION_TAG_INPUT_ID}
          value={tagOrMemoValue ?? ''}
          errorText={tagOrMemoError}
          label={t(LABELS.DESTINATION_TAG)}
          placeholder={KEYS.TAG_OR_MEMO_PLACEHOLDER}
          slotProps={{
            input: {
              ...QALocator(locators.page.wallet.destinationTagInputField),
            },
          }}
          {...QALocator(locators.page.wallet.destinationTagFormFieldContainer)}
          {...maskContent()}
        />
      </OptionallyVisible>
      <OptionallyVisible visible={shouldShowMemo}>
        <InputFormField
          onChange={onTagOrMemoChange}
          id={KEYS.MEMO_INPUT_ID}
          value={tagOrMemoValue ?? ''}
          errorText={tagOrMemoError}
          label={t(LABELS.MEMO)}
          placeholder={KEYS.TAG_OR_MEMO_PLACEHOLDER}
          slotProps={{
            input: {
              ...QALocator(locators.page.wallet.memoInputField),
            },
          }}
          {...QALocator(locators.page.wallet.memoFormFieldContainer)}
          {...maskContent()}
        />
      </OptionallyVisible>
    </>
  );
};

export default WalletForm;
