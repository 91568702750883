import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(15),
      height: '100%',
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.scale(15),
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
    title: {
      ...fontSizeMixin(FontSize.XL),
      marginTop: 0,
      fontWeight: 500,
      maxWidth: theme.scale(210),
    },
    caption: {
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
    captionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `0 ${theme.scale(15)}px`,
      textAlign: 'center',
    },
  }),
);

export default useStyles;
