import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(15),
      height: '100%',
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.scale(15),
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: theme.scale(12),
      fontWeight: 500,
      gap: theme.scale(12),
    },
    emailWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.scale(5),
    },
    countryIcon: {
      width: theme.scale(16),
      height: theme.scale(16),
      borderRadius: 8,
    },
    message: {
      textAlign: 'center',
    },
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  }),
);

export default useStyles;
