import { ReactNode } from 'react';

import useStyles from './styles';

export interface CardProps {
  children: ReactNode;
  className?: string;
  id?: string;
}

export const Card = ({
  children,
  className,
  id,
  ...props
}: CardProps) => {
  const { classes, cx } = useStyles();

  return (
    <div
      id={id}
      className={cx(classes.container, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
