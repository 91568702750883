import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .withParams<{ isOpened: boolean; applyBorder: boolean }>()
  .create(
    ({ theme, isOpened, applyBorder }) => ({
      gridWrapper: {
        background: theme.colors.background.primary,
        borderRadius: `0 0 ${theme.border.radius}px ${theme.border.radius}px`,
      },
      grid: {
        display: 'grid',
        padding: `${theme.scale(10)}px ${theme.scale(12)}px`,
        gap: theme.scale(1),
        gridTemplateColumns: '60% 10% 30%',
        rowGap: theme.scale(9),
        ...fontSizeMixin(FontSize.S),
      },
      alignRight: {
        textAlign: 'right',
      },
      total: {
        ...fontSizeMixin(FontSize.S),
        fontWeight: 600,
      },
      collapsibleHeader: {
        background: theme.colors.background.primary,
        padding: `${theme.scale(8)}px ${theme.scale(12)}px`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        borderRadius: applyBorder ? theme.border.radius : `${theme.border.radius}px ${theme.border.radius}px 0 0`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: 'border-radius .2s',
      },
      collapsibleHeaderText: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.scale(5),
        width: `calc(100% - ${theme.scale(20)}px)`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...fontSizeMixin(FontSize.S),
      },
      icon: {
        transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform .5s',
      },
      expandButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.scale(10),
      },
      inlineSpinner: {
        display: 'inline-block',
      },
      marginRight: {
        marginRight: theme.scale(3),
      },
      separator: {
        height: 1,
        background: theme.colors.separator.primary,
        margin: `0 ${theme.scale(9)}px`,
      },
      collapsibleFooter: {
        padding: theme.scale(12),
        paddingTop: theme.scale(9),
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 12,
      },
      processingRow: {
        display: 'flex',
        gap: theme.scale(3),
      },
      infoIcon: {
        display: 'inline-block',
        fill: theme.colors.icons.accentDark,
        cursor: 'pointer',
      },
      closeIcon: {
        display: 'inline-block',
        position: 'relative',
        top: '-2px',
        fill: theme.colors.text.accentDark,
        height: '100%',
      },
      alertContainer: {
        border: `0.5px solid ${theme.colors.text.accentDark}`,
        borderRadius: theme.border.radius,
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        ...fontSizeMixin(FontSize.S),
        fontWeight: 500,
        padding: `${theme.scale(10)}px ${theme.scale(12)}px`,
      },
      alertContent: {
        width: '95%',
      },
    }),
  );

export default useStyles;
