import { Asset, CurrencyType } from './assets';
import { TransactionId } from './transactions';
import { Email } from './user';

export interface Order {
  selectedFiatAsset?: Asset;
  fiatAmount?: string;
  selectedCryptoAsset?: Asset;
  cryptoAmount?: string;
  email?: Email;
  transactionId?: TransactionId;
  walletAddress?: string;
  tagOrMemo?: string;
  exchangeDirection?: CurrencyType;
  isFiatAssetForced?: boolean;
  isFiatAmountForced?: boolean;
  isCryptoAssetForced?: boolean;
  isCryptoAmountForced?: boolean;
  quoteId?: string;
}

export enum OrderSide {
  Buy = 'buy',
  Sell = 'sell',
}

export enum OrderStatus {
  Initiated = 'INITIATED',
  AuthorizationChargeFailed = 'AUTHORIZATION_CHARGE_FAILED',
  GetQuoteToExecuteFailed = 'GET_QUOTE_TO_EXECUTE_FAILED',
  ExecuteQuoteFailed = 'EXECUTE_QUOTE_FAILED',
  CaptureChargeFailed = 'CAPTURE_CHARGE_FAILED',
  Completed = 'COMPLETED',
  SystemError = 'SYSTEM_ERROR',
  Processing = 'PROCESSING',
  ExchangeFailed = 'EXCHANGE_FAILED',
  AwaitingDeposit = 'AWAITING_DEPOSIT',
  AwaitingClientConfirmation = 'AWAITING_CLIENT_CONFIRMATION',
  PaymentFailed = 'PAYMENT_FAILED',
}

export enum OrderWithdrawalStatus {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
}

export enum OrderTradeStatus {
  PendingTrade = 'PENDING_TRADE',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Settled = 'SETTLED',
}

export interface OrderRecord {
  uuid: string;
  userUuid: string;
  participantCode: string;
  accountUuid: string | null;
  status: OrderStatus;
  createdAt: string;
  fiatCurrencyCode: string;
  fiatAmount: string;
  cryptoCurrencyCode: string;
  cryptoAmount: string;
  processingFeeFiatAmount: number;
  processingFeePercent: number;
  networkFeeFiatAmount: string;
  networkFeeCryptoAmount: string;
  cryptoCostFiatAmount: string;
  cryptoPrice: string;
  side: OrderSide;
  paymentMethod: string;
  cardBrand: string | null;
  cardLast4: string | null;
  failedStatusReason: string | null;
  tradeStatus: OrderTradeStatus;
  onChainStatus: OrderWithdrawalStatus;
  onChainTransactionHash: string;
  onChainExplorerURL: string;
  walletAddress: string;
  userEmail: string;
  fiatConversionRate: string | null;
  securionMerchantId: string;
  securionChargeId: string;
}
