import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import paymentsApi from 'services/payments';
import { setTokenizedCard } from 'state/slices/paymentSlice';
import ApiErrorCode from 'types/errors/api';

import { SideEffectsMiddlewareHandler } from './types';

const resetCardTokenHandler: SideEffectsMiddlewareHandler = (middleware, action) => {
  const { matchFulfilled, matchRejected } = paymentsApi.endpoints.completePayment;
  const isPlaceOrderSuccess = matchFulfilled(action);
  const isPlaceOrderEvent = isPlaceOrderSuccess || matchRejected(action);
  if (!isPlaceOrderEvent) {
    return;
  }

  const errorType = ((action.payload as FetchBaseQueryError)?.data as { errorType?: ApiErrorCode })?.errorType;
  const shouldResetCard = isPlaceOrderSuccess
    || errorType === ApiErrorCode.CardCaptureFailed
    || errorType === ApiErrorCode.CardAuthorizationFailed;

  if (shouldResetCard) {
    middleware.dispatch(setTokenizedCard(null));
  }
};

export const cardTokenMiddlewareHandlers: SideEffectsMiddlewareHandler[] = [
  resetCardTokenHandler,
];

export default cardTokenMiddlewareHandlers;
