import { Trans, useTranslation } from 'react-i18next';

import Button from 'components/button';
import Header from 'components/header';
import LaunchIllustration from 'illustrations/launch';
import { PaymentMethod } from 'types/payment';
import { locators, QALocator } from 'utils/tests/QA';

import LABELS from './keys';
import { useStyles } from './styles';

export interface OrderProcessedPageProps {
  buyAgain: () => void;
  isResetLoading: boolean;
  paymentInfo: {
    cardBrand?: string;
    cardLast4?: string;
    paymentMethod?: PaymentMethod | null;
  };
  sell: boolean;
}

const OrderProcessedPage = ({
  buyAgain,
  isResetLoading,
  paymentInfo,
  sell,
}: OrderProcessedPageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { cardBrand, cardLast4, paymentMethod } = paymentInfo;
  const buyMessageKey = {
    [PaymentMethod.Card]: LABELS.ORDER_PROCESSED_NOTE_CARD,
    [PaymentMethod.ApplePay]: LABELS.ORDER_PROCESSED_NOTE_APPLE_PAY,
    [PaymentMethod.GooglePay]: LABELS.ORDER_PROCESSED_NOTE_GOOGLE_PAY,
  }[paymentMethod ?? PaymentMethod.Card];

  const content = sell
    ? (
      <>
        <Trans i18nKey={LABELS.ORDER_PROCESSED_NOTE} components={{ br: <br /> }} />
        <br />
        <br />
        {t(LABELS.ORDER_PROCESSED_NOTE_SELL)}
      </>
    )
    : (
      <>
        <Trans i18nKey={buyMessageKey} components={{ br: <br /> }} values={{ cardBrand, cardLast4 }} />
        <br />
        <br />
        {t(LABELS.ORDER_PROCESSED_CHECK)}
      </>
    );

  const buttonLabel = sell ? t(LABELS.EXCHANGE_AGAIN) : t(LABELS.BUY_AGAIN);

  return (
    <div className={classes.container}>
      <Header hasBackButton={false}>
        {t(LABELS.HEADER)}
      </Header>
      <div className={classes.imageWrapper}>
        <LaunchIllustration />
        <div className={classes.congratz}>
          {t(LABELS.CONGRATS)}
        </div>
        {content}
      </div>
      <div className={classes.proceedButtonWrapper}>
        <Button
          isLoading={isResetLoading}
          onClick={buyAgain}
          {...QALocator(locators.page.orderProcessed.proceedButton)}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default OrderProcessedPage;
