import CardImage from 'assets/images/cardDetails.svg';
import { getIllustrationCSS } from 'illustrations/styles';
import { Illustration } from 'illustrations/types';
import { tss, useThemeContext } from 'theme';

export const getColorBasedMasks = (theme: ReturnType<typeof useThemeContext>['theme']) => ([
  { color: theme.colors.illustrations.saturation95, layerIds: [0] }, // #F3F3F3
  { color: theme.colors.illustrations.saturation86, layerIds: [3] }, // #DBDBDB
  { color: theme.colors.illustrations.saturation51, layerIds: [4, 5, 6, 9, 11, 16, 19, 21] }, // #818181
  { color: theme.colors.illustrations.saturation86, layerIds: [1, 2, 7, 10] }, // #DBDBDB
  { color: theme.colors.illustrations.background, layerIds: [8, 12, 13, 14, 15] },
  { color: theme.colors.illustrations.saturation51, layerIds: [11] }, // #818181
  { color: theme.colors.illustrations.saturation65, layerIds: [17, 20, 26, 27] }, // #A5A5A5
  { color: theme.colors.illustrations.saturation51, layerIds: [19, 21] }, // #818181
  { color: theme.colors.illustrations.background, layerIds: [18, 20, 22, 23, 24, 25] },
  { color: theme.colors.illustrations.saturation65, layerIds: [26, 27, 28] }, // #A5A5A5
]);

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      ...getIllustrationCSS(
        Illustration.Card,
        getColorBasedMasks(theme),
        CardImage,
      ),
    },
  }),
);

export default useStyles;
