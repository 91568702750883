import { useSelector } from 'react-redux';

import Layout from 'components/layout/root';
import QUERY_PARAMS from 'constants/queryParams';
import accountsApi from 'services/account';
import { selectFlow } from 'state/slices/flowSlice';
import { selectStep } from 'state/slices/stepSlice';
import isDarkMode from 'theme/darkMode';
import { WorkflowStep } from 'types/step';
import getQueryParam from 'utils/queryParams';

import Root from '.';

export const RootContainer = () => {
  const step = useSelector(selectStep);
  const flow = useSelector(selectFlow);
  const showPartnersFooter = [
    WorkflowStep.Quote,
    WorkflowStep.Checkout,
  ].includes(step);
  const { data: config, isLoading: isConfigLoading } = accountsApi.useGetAccountInfoWithApiKeyQuery(undefined, {
    skip: !getQueryParam(QUERY_PARAMS.API_KEY),
  });
  const logo = isDarkMode() ? config?.data.widgetLogoDark : config?.data.widgetLogo;

  return (
    <Layout
      showPartnersFooter={showPartnersFooter}
      config={config?.data}
      isConfigLoading={isConfigLoading}
    >
      <Root step={step} logoSrc={logo} flow={flow} />
    </Layout>
  );
};

export default RootContainer;
