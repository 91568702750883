import { makeLabels } from 'utils/i18n';

const MESSAGES_NAMESPACE = 'paymentMethod';

export const LABELS = makeLabels({
  HEADER: 'header',
  CHOOSE_PAYMENT_METHOD: 'choosePaymentMethod',
  DEBIT_OR_CREDIT_CARD: 'debitOrCreditCard',
  APPLE_PAY: 'applePay',
  GOOGLE_PAY: 'googlePay',
}, MESSAGES_NAMESPACE);

export default LABELS;
