import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';
import Flow from 'types/flow';
import { QueryParam } from 'types/queryParams';
import { isFinishingOrder } from 'utils/navigation';
import getQueryParam from 'utils/queryParams';

const SLICE_NAME = 'flow';

interface FlowState {
  flow: Flow;
}

const isSellFlow = () => [
  getQueryParam(QueryParam.Flow) === Flow.Sell,
  isFinishingOrder(),
].some(Boolean);

const initialState: FlowState = {
  flow: isSellFlow() ? Flow.Sell : Flow.Buy,
};

export const flowSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changeFlow: (state, action: PayloadAction<Flow>) => {
      state.flow = action.payload;
    },
  },
});

export const { changeFlow } = flowSlice.actions;

export const selectFlow = (state: RootState) => state.flow.flow;

export default flowSlice.reducer;
