import { NavigationEvaluationFunc, NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';

import { checkIfShouldSkipQuoteStep } from './queryParams';

export const getCountryCheckNextStep: NavigationEvaluationFunc<WorkflowStep> = (
  { user }: NavigationEvaluationFuncParams,
) => {
  if (!user) {
    throw new Error('Unable to proceed from CountryCheck screen, no user information');
  }

  if (user.isResidenceCountrySupported) {
    if (user.isIpCountrySupported) {
      if (user.isIpStateSupported) {
        return WorkflowStep.Kyc;
      }
      return WorkflowStep.IpCountryUnavailable;
    }
    return WorkflowStep.IpCountryUnavailable;
  }
  return WorkflowStep.CountryUnavailable;
};

export const getCountryCheckPreviousStep: NavigationEvaluationFunc<WorkflowStep> = () => WorkflowStep.Quote;

export const canGoBackFromCountryCheck: NavigationEvaluationFunc<boolean> = () => {
  const skipQuoteStep = checkIfShouldSkipQuoteStep();

  if (skipQuoteStep) {
    return false;
  }
  return true;
};
