import {
  Fragment,
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { createFilterOptions, useAutocomplete, UseAutocompleteProps } from '@mui/base/useAutocomplete';

import Input from 'components/input';
import OptionallyVisible from 'components/optionallyVisible';
import IconSearch from 'icons/search';
import { locators, QALocator } from 'utils/tests/QA';

import { SearchableListItem } from './item';
import useStyles from './styles';

export interface SearchableListItemValues {
  id: string;
  icon?: ReactNode;
  iconUrl?: string | null;
  label: string;
  subLabel: string;
  group?: string;
}

export interface SearchableListProps extends UseAutocompleteProps<
  SearchableListItemValues,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
 > {
  searchPlaceholderText: string;
  searchResultsLabel: string;
}

export const SearchableList = ({
  open = true,
  searchPlaceholderText,
  searchResultsLabel,
  ...props
}: SearchableListProps) => {
  const [listWrapperHeight, setListWrapperHeight] = useState<number>();
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const latestCategory = useRef<string | undefined>();

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue,
  } = useAutocomplete({
    open,
    ...props,
    filterOptions: createFilterOptions({
      stringify: option => `${option.id} ${option.label}`,
    }),
    isOptionEqualToValue: (option, value) => option.id === value.id,
  });

  const hasInputValue = Boolean(inputValue);
  const { classes } = useStyles({ listWrapperHeight, hasInputValue });

  useEffect(() => {
    if (listWrapperRef.current?.clientHeight) {
      setListWrapperHeight(listWrapperRef.current.clientHeight);
    }
  }, [listWrapperRef.current]);

  return (
    <div
      className={classes.root}
      {...getRootProps()}
    >
      <Input
        className={classes.input}
        startAdornment={<IconSearch className={classes.searchIcon} />}
        placeholder={searchPlaceholderText}
        slotProps={{
          input: getInputProps(),
        }}
        {...QALocator(locators.components.common.searchInput)}
      />
      <div className={classes.listWrapper} ref={listWrapperRef}>
        <OptionallyVisible visible={hasInputValue}>
          <div className={classes.category}>{searchResultsLabel}</div>
        </OptionallyVisible>
        <OptionallyVisible visible={!hasInputValue}>
          <div className={classes.categoryPlaceholder} />
        </OptionallyVisible>
        <OptionallyVisible visible={Boolean(listWrapperHeight)}>
          <ul
            {...QALocator(locators.components.common.searchableListContent)}
            className={classes.list}
            {...getListboxProps()}
          >
            {(groupedOptions as typeof props.options)
              .map((option, index) => {
                let isCategoryVisible = false;
                if (latestCategory.current !== option.group) {
                  latestCategory.current = option.group;
                  isCategoryVisible = !hasInputValue;
                }
                return (
                  <Fragment key={option.id}>
                    <OptionallyVisible visible={isCategoryVisible}>
                      <div className={classes.category}>{option.group}</div>
                    </OptionallyVisible>

                    <SearchableListItem
                      key={option.id}
                      id={option.id}
                      icon={option.icon}
                      iconUrl={option.iconUrl}
                      label={option.label}
                      subLabel={option.subLabel}
                      {...getOptionProps({ option, index })}
                    />
                  </Fragment>
                );
              })}
          </ul>
        </OptionallyVisible>
      </div>
    </div>
  );
};

export default SearchableList;
