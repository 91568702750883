import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      border: 'none',
      width: '100%',
      background: theme.colors.background.primary,
      cursor: 'pointer',
      borderRadius: theme.border.radius,
      textAlign: 'unset',
      '& > div': {
        display: 'grid',
        textAlign: 'unset',
        gridTemplateColumns: '20% 75% 5%',
        alignItems: 'center',
        padding: `${theme.scale(12)}px ${theme.scale(6)}px`,
        color: theme.colors.text.primary,
        fontWeight: 500,
        ...fontSizeMixin(FontSize.M),
      },
    },
    arrowIcon: {
      margin: `0 ${theme.scale(6)}px`,
      fill: theme.colors.text.inactive,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  }),
);

export default useStyles;
