export const MERCHANT_LABEL = 'Shift4';

const PAYMENTS_PROCESSING_COUNTRY = 'LT';

const applePayConfig = {
  supportedMethods: 'https://apple.com/apple-pay',
  data: {
    countryCode: PAYMENTS_PROCESSING_COUNTRY,
  },
};

export default applePayConfig;
