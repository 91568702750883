import { useEffect, useState } from 'react';

import Spinner from 'components/spinner';
import assetsApi from 'services/assets';
import userApi from 'services/user';
import { OrderRecord } from 'types/order';
import { Pagination } from 'types/pagination';
import Logger from 'utils/logger';
import { decoratePagination } from 'utils/pagination';
import { locators, QALocator } from 'utils/tests/QA';

import PendingOrdersScreen from './index';
import useStyles from './styles';

const PendingOrdersContainer = () => {
  const [orders, setOrders] = useState<OrderRecord[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [areOrdersFetching, setOrdersFetching] = useState<boolean>(false);
  const [areOrdersLoaded, setOrdersLoaded] = useState<boolean>(false);
  const { classes } = useStyles();
  const [getUserPendingOrders] = userApi.useLazyGetUserPendingOrdersQuery();

  const {
    data: assetsResponse,
    isLoading: areAssetsLoading,
  } = assetsApi.useGetAssetsQuery();

  useEffect(() => {
    (async () => {
      await handleLoadNextPage();
      setOrdersLoaded(true);
    })();
  }, []);

  const assets = assetsResponse?.data?.assets || [];

  const isLoading = (!areOrdersLoaded && areOrdersFetching) || areAssetsLoading;

  const handleLoadNextPage = async () => {
    if (areOrdersFetching) {
      return;
    }
    setOrdersFetching(true);
    try {
      const response = await getUserPendingOrders(pagination ? pagination.page + 1 : 1);
      const fetchedOrders = response.data!.data!.orders;
      const nextOrders = fetchedOrders.reduce((output, order) => {
        if (orders.find(existingOrder => existingOrder.uuid === order.uuid)) {
          return output;
        }

        return [...output, order];
      }, orders);

      setOrders(nextOrders);
      setPagination(decoratePagination(response.data!.data!.pagination));
    } catch (e: unknown) {
      Logger.log(e);
    }
    setOrdersFetching(false);
  };

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner
          scale={1.25}
          {...QALocator(locators.page.orderHistory.viewLoadingSpinner)}
        />
      </div>
    );
  }

  const orderItems = orders.map(order => ({
    order,
    fiatAsset: assets.find(asset => asset.symbol === order.fiatCurrencyCode),
    cryptoAsset: assets.find(asset => asset.symbol === order.cryptoCurrencyCode),
  }));

  const canLoadNextPage = Boolean(!areOrdersFetching && pagination && pagination.page < pagination.pages!);

  return (
    <PendingOrdersScreen
      orderItems={orderItems}
      isLoading={isLoading}
      canLoadNextPage={canLoadNextPage}
      loadNextPage={handleLoadNextPage}
    />
  );
};

export default PendingOrdersContainer;
