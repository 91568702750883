import { ChangeEvent, KeyboardEvent } from 'react';
import { InputProps as MuiInputProps } from '@mui/base/Input';

import Button, { ButtonProps } from 'components/button';
import FormFieldError from 'components/form/common/fieldError';
import Input from 'components/input';
import OptionallyVisible from 'components/optionallyVisible';
import IconArrow from 'icons/arrow';
import { AssetWithLabel, CurrencyType } from 'types/assets';
import { locators, QALocator } from 'utils/tests/QA';

import KEYS from './keys';
import useStyles from './styles';

export interface AssetInputProps {
  asset: AssetWithLabel | undefined;
  value: string;
  label: string;
  placeholder: string;
  lang?: string;
  disabled?: boolean;
  buttonProps?: ButtonProps;
  error?: string;
  onAmountChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: MuiInputProps;
}

const AssetInput = ({
  asset,
  value,
  label,
  placeholder,
  lang,
  disabled = false,
  buttonProps,
  error,
  inputProps = {},
  onAmountChange,
}: AssetInputProps) => {
  const { classes } = useStyles();
  const hasIcon = Boolean(asset?.iconUrl);

  const disallowDecimalsInputIfApplicable = (asset: AssetWithLabel | undefined) => (e: KeyboardEvent<HTMLInputElement>) => {
    const shouldIgnoreDelimiters = asset?.displayDecimals === 0 && KEYS.DELIMETERS.includes(e.key);
    if (shouldIgnoreDelimiters) {
      e.preventDefault();
    }
  };

  return (
    <div className={classes.formItem}>
      <label className={classes.label} htmlFor={label}>{label}</label>
      <Input
        {...inputProps}
        className={classes.currencyInput}
        id={label}
        disabled={disabled}
        placeholder={placeholder}
        slotProps={{
          ...inputProps?.slotProps,
          input: {
            ...inputProps?.slotProps?.input,
            type: 'number',
            inputMode: 'decimal',
            lang,
            onKeyDown: disallowDecimalsInputIfApplicable(asset),
          },
        }}
        endAdornment={(
          <Button
            {...buttonProps}
          >
            <OptionallyVisible visible={hasIcon}>
              <img src={asset?.iconUrl!} className={classes.currencyIcon} alt={asset?.symbol} />
            </OptionallyVisible>
            {asset?.symbol}
            <OptionallyVisible visible={!buttonProps?.disabled}>
              <IconArrow width={8} height={8} className={classes.currencyButtonArrow} />
            </OptionallyVisible>
          </Button>
            )}
        value={value}
        onChange={onAmountChange}
        error={Boolean(error)}
        lang={lang}
      />
      <OptionallyVisible visible={Boolean(error)}>
        <FormFieldError
          errorText={error}
          {...(asset?.type === CurrencyType.Crypto ? QALocator(locators.page.quote.cryptoInputError) : {})}
        />
      </OptionallyVisible>
    </div>
  );
};

export default AssetInput;
