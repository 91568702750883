import { ReactNode, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import OptionallyVisible from 'components/optionallyVisible';
import COMMON_LABELS from 'constants/messages';
import IconCopy from 'icons/copy';

import { DISPLAY_COPIED_TEXT_TIMEOUT } from './keys';
import useStyles from './styles';

interface CopyButtonProps {
  textToCopy: string;
  children?: ReactNode;
  className?: string;
}

const CopyButton = ({ textToCopy, children, className }: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (!copied) {
      return;
    }

    setTimeout(() => {
      setCopied(false);
    }, DISPLAY_COPIED_TEXT_TIMEOUT);
  }, [copied]);

  const copy = () => {
    setCopied(true);
  };

  return (
    <CopyToClipboard
      text={textToCopy}
      onCopy={copy}
    >
      <div className={className}>
        {children}
        <OptionallyVisible visible={!copied}>
          <Button className={classes.copyButton}>
            <IconCopy color={theme.colors.icons.accentDark} />
          </Button>
        </OptionallyVisible>
        <OptionallyVisible visible={copied}>
          <div className={classes.copiedText}>
            {t(COMMON_LABELS.COPIED)}
          </div>
        </OptionallyVisible>
      </div>
    </CopyToClipboard>
  );
};

export default CopyButton;
