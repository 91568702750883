import { useTranslation } from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';
import Flow from 'types/flow';
import {
  OrderStatusStep, OrderStep, OrderStepStatus,
} from 'types/transactions';
import { locators, QALocator } from 'utils/tests/QA';
import getTransactionStepLabel from 'utils/transactionStep/getTransactionStepLabel';
import { getTransactionStepUIStatus } from 'utils/transactionStep/getTransactionStepUIStatus';
import isTransactionErrored from 'utils/transactionStep/isTransactionErrored';

import WaitingMessage from './components/waitingMessage';
import KEYS, { LABELS } from './keys';
import TransactionProcessingStep from './transactionProcessingStep';
import { useStyles } from './transactionProcessingSteps.styles';
import { TransactionProcessingStepsProps } from './types';

const TransactionProcessingSteps = ({
  data,
  flow,
  cardAuthorizationErrorMessage,
  chainCode,
}: TransactionProcessingStepsProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const hasError = isTransactionErrored(data);

  /**
   * According to https://thegivingblock.atlassian.net/browse/TGB-7258
   */

  const withdrawalStep = data.steps.find(step => step.type === OrderStatusStep.Withdrawal);
  const shouldShowWaitingForWithdrawalText = !hasError && withdrawalStep && withdrawalStep.status !== OrderStepStatus.Stuck
    && getTransactionStepUIStatus(withdrawalStep.type, data);

  const getLabel = (transactionStep: OrderStatusStep, flow: Flow) => getTransactionStepLabel({
    flow,
    transactionStep,
    transactionStepStatuses: data,
    hasError,
    cardAuthorizationErrorMessage,
    t,
  });

  const shouldShowDelayedText = [KEYS.ETHEREUM, KEYS.BITCOIN].includes(chainCode ?? '');

  const capitalizeFirstLetter = (str: string) => {
    if (!str) {
      return '';
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getStep = (step: OrderStep) => {
    const label = getLabel(step.type, flow);
    return (
      <OptionallyVisible key={step.type} visible={Boolean(label)}>
        <TransactionProcessingStep
          key={step.type}
          label={label!}
          uiStatus={getTransactionStepUIStatus(step.type, data)}
          {...QALocator(locators.page.transactionProcessing[step.type])}
        />
      </OptionallyVisible>
    );
  };

  return (
    <div className={classes.stepsContainer}>
      <div className={classes.stepsList}>
        {data.steps.map(getStep)}
      </div>
      <OptionallyVisible visible={Boolean(shouldShowWaitingForWithdrawalText)}>
        <div
          className={classes.waitingForWithdrawalText}
          {...QALocator(locators.page.transactionProcessing.waitingForWithdrawalBlock)}
        >
          <WaitingMessage
            shouldChangeMessage={shouldShowDelayedText}
            initialMessage={t(LABELS.WAITING_FOR_WITHDRAWAL_TEXT)}
            delayedMessage={t(LABELS.DELAYED_FOR_WITHDRAWAL_TEXT, { name: capitalizeFirstLetter(chainCode ?? '') })}
          />
        </div>
      </OptionallyVisible>
    </div>
  );
};

export default TransactionProcessingSteps;
