import { ReactNode } from 'react';
import {
  Trans,
} from 'react-i18next';

import Shift4LogoIllustration from 'illustrations/shift4';

import { useStyles } from './formFooter.styles';
import { LABELS } from './keys';

interface FormFooterProps {
  showPartnerLogo: boolean;
  children?: ReactNode;
}

const FormFooter = ({ showPartnerLogo, children }: FormFooterProps) => {
  const { classes } = useStyles();

  const footer = showPartnerLogo
    ? (
      <Trans
        i18nKey={LABELS.FOOTER_ZERO_HASH}
        components={{ Shift4LogoIllustration: <Shift4LogoIllustration width={39} height={8} /> }}
      />
    )
    : (
      <Trans i18nKey={LABELS.FOOTER} components={{ Shift4LogoIllustration: <Shift4LogoIllustration width={39} height={8} /> }} />
    );

  return (
    <footer className={classes.formFooter}>
      <div className={classes.content}>
        {footer}
      </div>
      {children}
    </footer>
  );
};

export default FormFooter;
