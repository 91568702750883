import IconWallet from 'icons/wallet';
import { Asset } from 'types/assets';

import { useStyles } from './styles';

interface WalletInputIconProps {
  selectedCryptoAsset?: Asset;
}

const WalletInputIcon = ({
  selectedCryptoAsset,
}: WalletInputIconProps) => {
  const { classes, theme } = useStyles();

  if (selectedCryptoAsset?.cryptoNetworkIconUrl) {
    return (
      <img
        src={selectedCryptoAsset.cryptoNetworkIconUrl}
        alt={selectedCryptoAsset?.name}
        className={classes.networkIcon}
      />
    );
  }

  return (
    <IconWallet color={theme.colors.text.inactive} />
  );
};

export default WalletInputIcon;
