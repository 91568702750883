export const KEYS = {
  FALLBACK_LANGUAGE: 'en',
};

const MESSAGES_NAMESPACE = 'errorScreen';
export const LABELS = {
  GENERIC_TITLE: `${MESSAGES_NAMESPACE}.genericTitle`,
  GENERIC_MESSAGE: `${MESSAGES_NAMESPACE}.genericMessage`,
  FATAL_TITLE: `${MESSAGES_NAMESPACE}.fatalTitle`,
  FATAL_MESSAGE: `${MESSAGES_NAMESPACE}.fatalMessage`,
  AUTH_TITLE: `${MESSAGES_NAMESPACE}.authTitle`,
  AUTH_MESSAGE: `${MESSAGES_NAMESPACE}.authMessage`,
  CARD_TITLE: `${MESSAGES_NAMESPACE}.cardTitle`,
  CARD_MESSAGE: `${MESSAGES_NAMESPACE}.cardMessage`,
  PAYMENT_CONFIG_TITLE: `${MESSAGES_NAMESPACE}.paymentConfigTitle`,
  PAYMENT_CONFIG_MESSAGE: `${MESSAGES_NAMESPACE}.paymentConfigMessage`,
  REGION_UNSUPPORTED_TITLE: `${MESSAGES_NAMESPACE}.regionUnsupportedTitle`,
  REGION_UNSUPPORTED_MESSAGE: `${MESSAGES_NAMESPACE}.regionUnsupportedMessage`,
  KYC_TOKEN_TITLE: `${MESSAGES_NAMESPACE}.kycTokenTitle`,
  KYC_TOKEN_MESSAGE: `${MESSAGES_NAMESPACE}.kycTokenMessage`,
  KYC_REJECTED_TITLE: `${MESSAGES_NAMESPACE}.kycRejectedTitle`,
  KYC_REJECTED_MESSAGE: `${MESSAGES_NAMESPACE}.kycRejectedMessage`,
  USER_STATUS_TITLE: `${MESSAGES_NAMESPACE}.userStatusTitle`,
  USER_STATUS_MESSAGE: `${MESSAGES_NAMESPACE}.userStatusMessage`,
  PAYMENT_COMPLETE_TITLE: `${MESSAGES_NAMESPACE}.paymentCompleteTitle`,
  PAYMENT_COMPLETE_MESSAGE: `${MESSAGES_NAMESPACE}.paymentCompleteMessage`,
  COUNTRIES_TITLE: `${MESSAGES_NAMESPACE}.countriesTitle`,
  COUNTRIES_MESSAGE: `${MESSAGES_NAMESPACE}.countriesMessage`,
  SUBSCRIBE_TO_UPDATES_TITLE: `${MESSAGES_NAMESPACE}.subscribeToUpdatesTitle`,
  SUBSCRIBE_TO_UPDATES_MESSAGE: `${MESSAGES_NAMESPACE}.subscribeToUpdatesMessage`,
  PATCH_USER_COUNTRY_TITLE: `${MESSAGES_NAMESPACE}.patchUserCountryTitle`,
  PATCH_USER_COUNTRY_MESSAGE: `${MESSAGES_NAMESPACE}.patchUserCountryMessage`,
  API_KEY_TITLE: `${MESSAGES_NAMESPACE}.apiKeyTitle`,
  API_KEY_MESSAGE: `${MESSAGES_NAMESPACE}.apiKeyMessage`,
  FLOW_ERROR_TITLE: `${MESSAGES_NAMESPACE}.flowErrorTitle`,
  FLOW_ERROR_MESSAGE: `${MESSAGES_NAMESPACE}.flowErrorMessage`,
};

export default KEYS;
