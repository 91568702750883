import { createContext } from 'react';

type CaptchaContextValues = {
  execute: () => Promise<string | null>;
  reset: () => void;
  isReady: boolean;
}

export const initialValues: CaptchaContextValues = {
  execute: async () => '',
  reset: () => {},
  isReady: false,
};

export const CaptchaContext = createContext(initialValues);

export default CaptchaContextValues;
