import { TFunction } from 'i18next';

import { LABELS } from 'pages/transactionProcessing/keys';
import Flow from 'types/flow';
import {
  OrderStatus, OrderStatusStep, OrderStepStatus,
} from 'types/transactions';

export type getTransactionStepLabelParams = {
  flow: Flow;
  transactionStep: OrderStatusStep;
  transactionStepStatuses: OrderStatus;
  hasError: boolean;
  cardAuthorizationErrorMessage?: string;
  t: TFunction;
};
export const getTransactionStepLabel = (
  {
    flow,
    transactionStep,
    transactionStepStatuses,
    hasError,
    cardAuthorizationErrorMessage,
    t,
  }:getTransactionStepLabelParams,
) => {
  const withdrawalStatus = transactionStepStatuses.steps.find(step => step.type === OrderStatusStep.Withdrawal)?.status;
  const paymentStatus = transactionStepStatuses.steps.find(step => step.type === OrderStatusStep.Payment)?.status;
  const exchangeStatus = transactionStepStatuses.steps.find(step => step.type === OrderStatusStep.Exchange)?.status;

  const nonErrorLabels = {
    [Flow.Buy]: {
      [OrderStatusStep.QuoteExecution]: () => null,
      [OrderStatusStep.Deposit]: () => null,
      [OrderStatusStep.Payment]: () => t(LABELS.PAYMENT_STATUS),
      [OrderStatusStep.Exchange]: () => t(LABELS.EXCHANGE_STATUS),
      [OrderStatusStep.Withdrawal]: () => (withdrawalStatus === OrderStepStatus.Stuck
        ? t(LABELS.WITHDRAWAL_STATUS_STUCK)
        : t(LABELS.WITHDRAWAL_STATUS)
      ),
    },
    [Flow.Sell]: {
      [OrderStatusStep.QuoteExecution]: () => null,
      [OrderStatusStep.Deposit]: () => t(LABELS.DEPOSIT_STATUS),
      [OrderStatusStep.Payment]: () => t(LABELS.PAYMENT_STATUS),
      [OrderStatusStep.Exchange]: () => t(LABELS.EXCHANGE_STATUS_SELL),
      [OrderStatusStep.Withdrawal]: () => (withdrawalStatus === OrderStepStatus.Stuck
        ? t(LABELS.WITHDRAWAL_STATUS_STUCK)
        : t(LABELS.WITHDRAWAL_STATUS_SELL)
      ),
    },
  };

  const errorLabels = {
    [Flow.Buy]: {
      [OrderStatusStep.QuoteExecution]: () => null,
      [OrderStatusStep.Deposit]: () => null,
      [OrderStatusStep.Payment]: () => (paymentStatus === OrderStepStatus.Failed
        ? `${cardAuthorizationErrorMessage || t(LABELS.CARD_PAYMENT_FAILED)} ${t(LABELS.PLEASE_TRY_AGAIN)}` : t(LABELS.PAYMENT_STATUS)),
      [OrderStatusStep.Exchange]: () => (exchangeStatus === OrderStepStatus.Failed
        ? t(LABELS.FAIL_EXCHANGE_LONG) : t(LABELS.EXCHANGE_STATUS)),
      [OrderStatusStep.Withdrawal]: () => t(LABELS.WITHDRAWAL_STATUS),
    },
    [Flow.Sell]: {
      [OrderStatusStep.QuoteExecution]: () => null,
      [OrderStatusStep.Deposit]: () => null,
      [OrderStatusStep.Payment]: () => (paymentStatus === OrderStepStatus.Failed
        ? `${cardAuthorizationErrorMessage || t(LABELS.CARD_AUTHORIZATION_FAILED)} ${t(LABELS.PLEASE_TRY_AGAIN)}`
        : t(LABELS.PAYMENT_AUTHORIZATION)),
      [OrderStatusStep.Exchange]: () => (exchangeStatus === OrderStepStatus.Failed
        ? t(LABELS.FAIL_EXCHANGE) : t(LABELS.EXCHANGE_STATUS)),
      [OrderStatusStep.Withdrawal]: () => t(LABELS.WITHDRAWAL_STATUS_FIAT),
    },
  };
  return hasError ? errorLabels[flow][transactionStep]() : nonErrorLabels[flow][transactionStep]();
};

export default getTransactionStepLabel;
