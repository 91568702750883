import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CaptchaContext } from 'components/captcha/context';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import authenticationApi, { PASSWORDLESS_START_CACHE_KEY } from 'services/authentication';
import { setError } from 'state/slices/errorSlice';
import { selectOrder, setOrderEmail } from 'state/slices/orderSlice';
import { changeStep } from 'state/slices/stepSlice';
import { AuthenticationType } from 'types/authentication';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { HTTPStatus, ReduxToolkitApiCall, ReduxToolkitError } from 'types/http';
import { WorkflowStep } from 'types/step';

import SignIn from '.';

export const SignInContainer = () => {
  const dispatch = useDispatch();
  const order = useSelector(selectOrder);
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [authenticate, { isError, error }] = authenticationApi.usePasswordlessStartMutation({
    fixedCacheKey: PASSWORDLESS_START_CACHE_KEY,
  });
  const { proceed, goBack, canGoBack } = useNavigation();
  const { execute, reset, isReady } = useContext(CaptchaContext);

  const showError = () => {
    const staticErrorScreenMessage = getErrorScreenMessage(ScreenErrorType.AuthenticationFailed);
    dispatch(setError({
      ...staticErrorScreenMessage,
      message: (error as ReduxToolkitError)?.data?.errorMessage ?? staticErrorScreenMessage.message,
    }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const onContinue = (email: string) => {
    dispatch(setOrderEmail(email));
    proceed();
  };

  useEffect(() => {
    if ((error as ReduxToolkitError)?.status === HTTPStatus.BadRequest) {
      return;
    }
    if (error) {
      showError();
    }
  }, [error]);

  const sendOtpEmail = async (username: string) => {
    setIsAuthInProgress(true);
    reset();
    const captchaToken = await execute();
    if (!captchaToken) {
      showError();
      return null;
    }

    const result = await authenticate({
      username,
      type: AuthenticationType.Email,
      captchaToken,
    });

    setIsAuthInProgress(false);

    return result;
  };

  return (
    <SignIn
      authentication={{
        isLoading: isAuthInProgress || !isReady,
        isError,
      }}
      sendOtpEmail={sendOtpEmail as ReduxToolkitApiCall<{ isSuccess: boolean }>}
      initialEmail={order.email}
      proceed={onContinue}
      goBack={goBack}
      canGoBack={canGoBack}
    />
  );
};

export default SignInContainer;
