import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/base';

import Button from 'components/button';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import OptionallyVisible from 'components/optionallyVisible';
import SlideModal from 'components/slideModal';
import CARD_BRANDS_IMAGE_SOURCE from 'constants/cardBrands';
import COMMON_LABELS from 'constants/messages';
import IconBookmark from 'icons/bookmark';
import IconBookmarkFilled from 'icons/bookmarkFilled';
import IconCross from 'icons/cross';
import IconEdit from 'icons/edit';
import IconError from 'icons/error';
import maskContent from 'libs/sentry/mask';
import CardsPageContainer from 'pages/cards';
import { SavedCard } from 'services/payments/types';
import { CardPaymentToken } from 'types/card';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { CardBrand } from 'types/transactions';
import { User } from 'types/user';
import { locators, QALocator } from 'utils/tests/QA';

import CardInputs from './inputs';
import KEYS, { LABELS } from './keys';
import useStyles from './styles';

interface FormProps {
  shift4PublicKey?: string;
  merchantId?: string;
  tokenizedCard: CardPaymentToken | SavedCard | null;
  proceed: () => void;
  showErrorScreen: (errorType: ScreenErrorType, isFatalError?: boolean) => void;
  setTokenizedCard: (tokenizedCard: CardPaymentToken | SavedCard, rememberCard: boolean, setAsDefault: boolean) => void;
  user?: User;
  ctaButtonProps?: Partial<ButtonProps>;
  showSaveControl: boolean;
  showSetDefaultControl: boolean;
  orderError?: string;
  isAddingNewCard: boolean;
  cards: {
    cards: SavedCard[];
    isLoading: boolean;
    error: any;
  };
}

export const Form = ({
  shift4PublicKey,
  merchantId,
  tokenizedCard,
  proceed,
  showErrorScreen,
  setTokenizedCard,
  showSetDefaultControl,
  user,
  ctaButtonProps,
  showSaveControl,
  orderError,
  isAddingNewCard,
  cards,
}: FormProps) => {
  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation();
  const isRememberCardTickedByDefault = !tokenizedCard || isAddingNewCard;
  const [isRememberCardCheckboxTicked, setIsRememberCardCheckboxTicked] = useState(isRememberCardTickedByDefault);
  const [isDefaultCard, setIsDefaultCard] = useState(false);
  const [isAddingNewCardFormVisible, setIsAddingNewCard] = useState(isAddingNewCard);
  const [formLoaded, setFormLoaded] = useState(false);
  const [isCardManagementOverlayVisible, setCardManagementOverlayVisible] = useState(false);

  const [finishing, setFinishing] = useState(false);
  const [genericError, setGenericError] = useState<string | null>(null);
  const [isCardBrandAllowed, setIsCardBrandAllowed] = useState(true);
  const skipTokenization = Boolean(tokenizedCard) && !isAddingNewCardFormVisible;
  const proceedLabel = isRememberCardCheckboxTicked ? t(COMMON_LABELS.ADD_CARD) : t(COMMON_LABELS.CONTINUE);
  const hasDefaultPaymentMethod = Boolean(user?.defaultPaymentMethod);

  const getIsCreditCardFormVisible = () => {
    if (!formLoaded) {
      return false;
    }

    if (!tokenizedCard) {
      return true;
    }

    return isAddingNewCardFormVisible;
  };

  const getIsExistingCardVisible = () => {
    if (isAddingNewCardFormVisible) {
      return false;
    }

    return Boolean(tokenizedCard);
  };

  const getIsRememberCheckboxVisible = () => {
    if (!showSaveControl) {
      return false;
    }

    if (!tokenizedCard) {
      return true;
    }

    return isAddingNewCardFormVisible;
  };

  const getIsSetDefaultCardControlVisible = () => {
    if (!hasDefaultPaymentMethod) {
      return false;
    }

    if (!tokenizedCard && showSaveControl && showSetDefaultControl) {
      return isRememberCardCheckboxTicked;
    }

    if (!tokenizedCard && !isAddingNewCardFormVisible) {
      return showSetDefaultControl;
    }

    if (showSaveControl && showSetDefaultControl) {
      return isRememberCardCheckboxTicked;
    }

    return showSetDefaultControl;
  };

  const isCreditCardFormVisible = getIsCreditCardFormVisible();
  const isExistingCardVisible = getIsExistingCardVisible();
  const isRememberCheckboxVisible = getIsRememberCheckboxVisible();
  const isDefaultControlVisible = getIsSetDefaultCardControlVisible();

  const onContinueClick = async () => {
    if (skipTokenization) {
      proceed();
      return;
    }
    setFinishing(true);
  };

  const onEditClick = () => {
    setCardManagementOverlayVisible(true);
  };

  const onCancelEditClick = () => {
    setCardManagementOverlayVisible(false);
    setIsAddingNewCard(false);
  };

  const onRememberCardClick = () => {
    setIsRememberCardCheckboxTicked(!isRememberCardCheckboxTicked);
    if (!isRememberCardCheckboxTicked) {
      setIsDefaultCard(false);
    }
  };

  const onSetDefaultClick = () => {
    setIsDefaultCard(!isDefaultCard);
  };

  const closeModal = () => {
    setCardManagementOverlayVisible(false);
  };

  const onAddCardClick = () => {
    setCardManagementOverlayVisible(false);
    setIsAddingNewCard(true);
  };

  useEffect(() => {
    if (orderError) {
      setGenericError(orderError);
    }
  }, [orderError]);

  return (
    <>
      <Card
        className={cx(
          classes.card,
        )}
      >
        <OptionallyVisible visible={isExistingCardVisible}>
          <div className={classes.existingCardContainer}>
            <div>
              {t(LABELS.PAY_WITH)}
            </div>
            <div
              className={classes.existingCardField}
              {...maskContent()}
            >
              <OptionallyVisible visible={Boolean(tokenizedCard?.brand)}>
                <img
                  className={classes.cardBrandImage}
                  alt={tokenizedCard?.brand}
                  src={CARD_BRANDS_IMAGE_SOURCE[tokenizedCard?.brand ?? CardBrand.Unknown]}
                />
              </OptionallyVisible>
              <span>
                {`•••• ${tokenizedCard?.last4}`}
              </span>
              <Button
                className={classes.editButton}
                onClick={onEditClick}
                {...QALocator(locators.page.bankDetails.editCardButton)}
              >
                <IconEdit color={theme.colors.icons.accentDark} />
              </Button>
            </div>
          </div>
        </OptionallyVisible>

        <OptionallyVisible visible={!isExistingCardVisible}>
          <CardInputs
            shift4PublicKey={shift4PublicKey}
            merchantId={merchantId}
            showErrorScreen={showErrorScreen}
            setTokenizedCard={setTokenizedCard}
            rememberCard={isRememberCardCheckboxTicked}
            setAsDefault={isDefaultCard}
            onContinue={proceed}
            finishing={finishing}
            setIsFinishing={setFinishing}
            setGenericError={setGenericError}
            setFormLoaded={setFormLoaded}
            user={user}
            setIsCardBrandAllowed={setIsCardBrandAllowed}
          />
        </OptionallyVisible>
        <OptionallyVisible visible={isRememberCheckboxVisible || isDefaultControlVisible}>
          <div className={classes.actions}>
            <OptionallyVisible visible={isRememberCheckboxVisible}>
              <Checkbox
                className={cx(classes.rememberCardCheckbox, {
                  [classes.rememberCardCheckboxActive]: isRememberCardCheckboxTicked,
                })}
                id={KEYS.REMEMBER_CARD_CHECKBOX_ID}
                value={isRememberCardCheckboxTicked}
                onChange={onRememberCardClick}
                label={t(isDefaultControlVisible ? LABELS.SAVE_CARD_CHECKBOX_SHORT : LABELS.SAVE_CARD_CHECKBOX)}
              />
            </OptionallyVisible>
            <OptionallyVisible visible={isDefaultControlVisible}>
              <Button
                className={cx(classes.setDefaultCardButton, {
                  [classes.setDefaultCardButtonActive]: isDefaultCard,
                })}
                onClick={onSetDefaultClick}
              >
                {isDefaultCard
                  ? <IconBookmarkFilled width={24} height={24} color={theme.colors.icons.accentDark} />
                  : <IconBookmark width={24} height={24} color={theme.colors.icons.inactive} />}
                {t(LABELS.SET_DEFAULT_CARD)}
              </Button>
            </OptionallyVisible>
          </div>
        </OptionallyVisible>
      </Card>
      <SlideModal
        visible={isCardManagementOverlayVisible}
        onClose={onCancelEditClick}
        title={t(COMMON_LABELS.SELECT_PAYMENT_METHOD)}
      >
        <OptionallyVisible visible={!cards.error}>
          {/*
            TODO:
            Below is a container nested under a different container.
            See if this bring any issues in future, refactor if needed.
          */}
          <CardsPageContainer
            canSelectPaymentMethod
            onSelect={closeModal}
          />
        </OptionallyVisible>
        <OptionallyVisible visible={cards.error}>
          <div className={classes.listOfCardsErrorWrapper}>
            <IconError className={classes.errorIcon} />
            <Trans i18nKey={COMMON_LABELS.GENERIC_ERROR} components={{ br: <br /> }} />
          </div>
        </OptionallyVisible>
        <OptionallyVisible visible={!cards.error}>
          <div className={classes.addCardButton}>
            <Button
              onClick={onAddCardClick}
              endAdornment={(
                <IconCross
                  color={theme.colors.icons.secondary}
                  className={classes.plusIcon}
                  width={theme.scale(20)}
                  height={theme.scale(20)}
                />
              )}
            >
              {t(COMMON_LABELS.ADD_CARD)}
            </Button>
          </div>
        </OptionallyVisible>
      </SlideModal>
      <div className={classes.proceedButtonWrapper}>
        <OptionallyVisible visible={Boolean(genericError)}>
          <div className={classes.genericFormError} {...QALocator(locators.page.bankDetails.formGenericError)}>
            {genericError}
          </div>
        </OptionallyVisible>
        <OptionallyVisible visible={!isCardBrandAllowed}>
          <div className={classes.cardErrorMessage}>{t(LABELS.CARD_BRAND_ERROR_TEXT)}</div>
        </OptionallyVisible>
        <Button
          isContinue
          onClick={onContinueClick}
          isLoading={finishing}
          disabled={!isExistingCardVisible && !isCreditCardFormVisible}
          {...ctaButtonProps}
          {...QALocator(locators.page.bankDetails.proceedButton)}
        >
          {ctaButtonProps?.children ?? proceedLabel}
        </Button>
      </div>
    </>
  );
};

export default Form;
