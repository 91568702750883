export interface Asset {
  symbol: string;
  name: string;
  chainCode: string;
  type: string;
  model: string;
  minAmount: string;
  maxAmount?: string;
  stablecoin: boolean;
  precision: number;
  tagName: string | null;
  feeAsset: string;
  contractAddress: string;
  addressRegexp?: string;
  addressTagRegexp?: string;
  iconUrl: string | null;
  cryptoNetworkIconUrl: string | null;
  displayDecimals: number;
  nyAllowed: boolean;
  countries?: string[];
  isIpCountryCurrency?: boolean;
  defaultAmount?: number;
}

export interface AssetWithLabel extends Asset {
  id: string;
  label: string;
  subLabel: string;
  group?: string;
}

export interface Quote {
  fiatAmount: string;
  processingFeeFiatAmount: string;
  cryptoAmount: string;
  cryptoPrice: string;
  side: string;
  quoteId: string;
  expireTs: number;
  networkFeeFiatAmount: string;
  cryptoCurrencyCode: string;
  fiatCurrencyCode: string;
  cryptoCostFiatAmount: string;
}

export enum CurrencyCategory {
  Popular = 'Popular',
}

export enum CurrencyList {
  Fiat = 'fiat',
  Crypto = 'crypto'
}

export enum CurrencyType {
  Fiat = 'fiat',
  Crypto = 'crypto'
}
