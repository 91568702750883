import { CSSObject } from 'tss-react';

import WIDGET_DIMENSIONS from 'constants/dimensions';

import { breakpointValues } from './breakpoints';
import getScaleRatio from './ratio';

export enum FontSize {
  XXS = 8,
  XS = 10,
  S = 12,
  M = 14,
  L = 16,
  XL = 18,
  XXL = 22,
}

export const fontSizeMixin = (size: FontSize): CSSObject => ({
  fontSize: `calc((100vw / ${WIDGET_DIMENSIONS.WIDTH}) * ${size})`,
  [`@media (min-width: ${breakpointValues.widget}px), (max-width: ${WIDGET_DIMENSIONS.WIDTH}px)`]: {
    fontSize: size,
  },
});

// Iframe needs JS assistance as it doesnt know the size by CSS
export const fontSizeMixinForIframe = (size: FontSize) => ({
  fontSize: `${getScaleRatio() * size}px`,
  [`@media (min-width: ${breakpointValues.widget}px), (max-width: ${WIDGET_DIMENSIONS.WIDTH}px)`]: {
    fontSize: size,
  },
});
