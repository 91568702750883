import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    stepItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.scale(8),
    },
    stepItemIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: theme.scale(24),
      height: theme.scale(24),
    },
    stepItemIconDisabled: {
      width: theme.scale(24),
      height: theme.scale(14),
    },
    stepItemLabel: {
      color: theme.colors.text.primary,
      ...fontSizeMixin(FontSize.M),
      width: theme.scale(171),
    },
    stepItemLabelDisabled: {
      color: theme.colors.text.disabled,
      ...fontSizeMixin(FontSize.S),
      marginTop: theme.scale(3),
    },
  }),
);

export default useStyles;
