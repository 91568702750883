import { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import doesPathMatchCurrentPath from 'pages/route/utils';
import { selectUserPendingOrdersCacheEntry } from 'services/user';
import { OrderRecord } from 'types/order';
import PATHS from 'utils/navigation';

import { HeaderProps } from '.';

export interface HeaderPropsProviderValues {
  pendingOrders?: OrderRecord[] | undefined;
  isContinueFlow?: boolean;
}

const withGlobalHeaderProps = (Component: ComponentType<HeaderProps>) => (props: HeaderProps) => {
  const pendingOrders = useSelector(selectUserPendingOrdersCacheEntry)?.data?.data?.orders;
  const isContinueFlow = doesPathMatchCurrentPath(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);

  const headerProps = ({
    pendingOrders,
    isContinueFlow,
  });

  return <Component {...props} {...headerProps} />;
};

export default withGlobalHeaderProps;
