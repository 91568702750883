import { ComponentType, lazy } from 'react';

import AwaitingCryptoContainer from 'pages/awaitingCrypto/container';
import BankDetailsContainer from 'pages/bankDetails/container';
import ContinueBankDetailsContainerSell from 'pages/bankDetails/container.continue.sell';
import BankDetailsContainerSell from 'pages/bankDetails/container.sell';
import CheckoutContainer from 'pages/checkout/container';
import ContinueCheckoutContainerSell from 'pages/checkout/container.continue.sell';
import CheckoutContainerSell from 'pages/checkout/container.sell';
import CountryCheckFinalContainer from 'pages/country/final/container';
import IpCountryNotSupported from 'pages/country/ipCountryUnsupported/index';
import NotSupportedCountryScreenContainer from 'pages/country/unsupported/container';
import CountryCheckContainer from 'pages/countryCheck/container';
import ErrorScreenContainer from 'pages/error/container';
import KycRejectedContainer from 'pages/kycRejected/container';
import OrderProcessedContainer from 'pages/orderProcessed/container';
import OtpScreenContainer from 'pages/otp/container';
import PaymentMethodsContainer from 'pages/paymentMethods/container';
import PaymentMethodsContainerSell from 'pages/paymentMethods/container.sell';
import QuoteScreenContainer from 'pages/quote/container';
import QuoteScreenContainerSell from 'pages/quote/container.sell';
import SignInContainer from 'pages/signIn/container';
import TransactionProcessingContainer from 'pages/transactionProcessing/container';
import TransferCryptoContainer from 'pages/transferCrypto/container';
import WalletScreenContainer from 'pages/wallet/container';
import Flow from 'types/flow';
import { WorkflowStep } from 'types/step';

const KycScreenContainer = lazy(() => import('pages/kyc/container'));

const buySteps = {
  [WorkflowStep.Quote]: QuoteScreenContainer,
  [WorkflowStep.SignIn]: SignInContainer,
  [WorkflowStep.CountryCheck]: CountryCheckContainer,
  [WorkflowStep.CountryUnavailable]: NotSupportedCountryScreenContainer,
  [WorkflowStep.CountryUnavailableFinal]: CountryCheckFinalContainer,
  [WorkflowStep.IpCountryUnavailable]: IpCountryNotSupported,
  [WorkflowStep.Otp]: OtpScreenContainer,
  [WorkflowStep.Kyc]: KycScreenContainer,
  [WorkflowStep.KycRejected]: KycRejectedContainer,
  [WorkflowStep.Wallet]: WalletScreenContainer,
  [WorkflowStep.PaymentMethods]: PaymentMethodsContainer,
  [WorkflowStep.BankDetails]: BankDetailsContainer,
  [WorkflowStep.Checkout]: CheckoutContainer,
  [WorkflowStep.TransactionProcessing]: TransactionProcessingContainer,
  [WorkflowStep.OrderProcessed]: OrderProcessedContainer,
  [WorkflowStep.Error]: ErrorScreenContainer,
};

const sellSteps = {
  [WorkflowStep.Quote]: QuoteScreenContainerSell,
  [WorkflowStep.SignIn]: SignInContainer,
  [WorkflowStep.CountryCheck]: CountryCheckContainer,
  [WorkflowStep.CountryUnavailable]: NotSupportedCountryScreenContainer,
  [WorkflowStep.CountryUnavailableFinal]: CountryCheckFinalContainer,
  [WorkflowStep.IpCountryUnavailable]: IpCountryNotSupported,
  [WorkflowStep.Otp]: OtpScreenContainer,
  [WorkflowStep.Kyc]: KycScreenContainer,
  [WorkflowStep.KycRejected]: KycRejectedContainer,
  [WorkflowStep.PaymentMethods]: PaymentMethodsContainerSell,
  [WorkflowStep.BankDetails]: BankDetailsContainerSell,
  [WorkflowStep.RequestNewCard]: ContinueBankDetailsContainerSell,
  [WorkflowStep.Checkout]: CheckoutContainerSell,
  [WorkflowStep.ConfirmNewQuote]: ContinueCheckoutContainerSell,
  [WorkflowStep.TransferCrypto]: TransferCryptoContainer,
  [WorkflowStep.AwaitingCrypto]: AwaitingCryptoContainer,
  [WorkflowStep.TransactionProcessing]: TransactionProcessingContainer,
  [WorkflowStep.OrderProcessed]: OrderProcessedContainer,
  [WorkflowStep.Error]: ErrorScreenContainer,
};

export const steps = (flow: Flow): { [key in WorkflowStep]?: ComponentType<any> } => (({
  [Flow.Buy]: buySteps,
  [Flow.Sell]: sellSteps,
})[flow]);

export default steps;
