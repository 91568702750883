import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import countriesApi from 'services/countries';
import userApi from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import { changeStep } from 'state/slices/stepSlice';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { WorkflowStep } from 'types/step';

import SubscribeScreen from '.';

export const NotSupportedCountryScreenContainer = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken) ?? '';
  const [updateUser,
    {
      isLoading: subscribeToUpdatesLoading,
    }] = userApi.useUpdateUserMutation();

  const {
    data: countries,
    isLoading: areCountriesLoading,
    error: getCountriesError,
  } = countriesApi.useGetCountriesQuery();

  const {
    data: user,
    isLoading: isUserDataLoading,
    error: userError,
  } = userApi.useGetUserInfoQuery(authToken);
  const { proceed, goBack } = useNavigation();

  const countryCode = user?.data?.country;
  const countryName = countries?.data.countries.find(country => country.isoAlpha2 === countryCode);
  const isScreenLoading = isUserDataLoading || areCountriesLoading;
  const isSubscribed = Boolean(user?.data?.notifyWhenServiceAvailable);

  const showErrorScreen = (errorType: ScreenErrorType) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, goBackToScreen: WorkflowStep.CountryCheck }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const onSubscribe = async (wantsSubscription: boolean) => {
    const result = await updateUser({ authToken, user: { notifyWhenServiceAvailable: wantsSubscription } });
    if ('error' in result) {
      showErrorScreen(ScreenErrorType.UnableToSubscribeToUpdates);
      return;
    }
    const serverReturnedUserData = Boolean(result.data?.data);
    if (serverReturnedUserData) {
      proceed();
    }
  };

  useEffect(() => {
    if (getCountriesError) {
      showErrorScreen(ScreenErrorType.UnableToGetCountriesList);
    }
  }, [getCountriesError]);

  useEffect(() => {
    if (userError) {
      showErrorScreen(ScreenErrorType.UserStatus);
    }
  }, [userError]);

  return (
    <SubscribeScreen
      isSubscribed={isSubscribed}
      isScreenLoading={isScreenLoading}
      isSubscriptionLoading={subscribeToUpdatesLoading}
      countryName={countryName?.name}
      goBack={goBack}
      onSubscribe={onSubscribe}
    />
  );
};

export default NotSupportedCountryScreenContainer;
