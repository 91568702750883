import {
  ComponentType,
  createRef, Ref, Suspense,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import LoadingWrapper from 'components/loading';
import Spinner from 'components/spinner';
import { SCREEN_TRANSITION_DURATION, SCREEN_TRANSITION_EVENT_NAME } from 'constants/transitions';
import usePrevious from 'hooks/usePrevious';
import Flow from 'types/flow';
import { WorkflowStep } from 'types/step';
import { TransitionStateDetails } from 'types/transition';

import KEYS from './keys';
import steps from './steps';
import useStyles from './styles';

export interface RootProps {
  flow: Flow;
  step: WorkflowStep;
  logoSrc?: string | null;
  isLoading?: boolean;
}

const Root = ({
  flow,
  step,
  logoSrc,
  isLoading,
}: RootProps) => {
  const flowStepsComponents = steps(flow);
  const orderedSteps = Object.keys(flowStepsComponents);
  const previousStep = usePrevious<WorkflowStep>(step) ?? WorkflowStep.Quote;
  const previousStepNumber = orderedSteps.findIndex(step => step === previousStep) ?? 0;
  const stepNumber = orderedSteps.findIndex(s => s === step) ?? 0;
  const isGoingBack = previousStepNumber > stepNumber;
  const { classes } = useStyles({ isGoingBack });
  const Component = flowStepsComponents[step] as ComponentType<any>;

  const refs = Object.keys(steps).reduce((acc, step) => ({
    ...acc,
    [step]: createRef(),
  }), {} as Record<WorkflowStep, Ref<any>>);

  const nodeRef = refs[step];

  const emitTransitionFinished = () => {
    window.dispatchEvent(
      new CustomEvent<TransitionStateDetails>(SCREEN_TRANSITION_EVENT_NAME, {
        detail: {
          exitingFrom: step,
        },
      }),
    );
  };

  const mainScreenSpinner = (
    <div className={classes.spinnerWrapper}>
      <Spinner scale={1.25} />
    </div>
  );

  return (
    <TransitionGroup
      className={classes.fade}
    >
      <CSSTransition
        key={step}
        nodeRef={nodeRef}
        classNames={KEYS.TRANSITION_CLASS}
        timeout={SCREEN_TRANSITION_DURATION}
        onExited={emitTransitionFinished}
      >
        <div className={classes.fullSize} ref={nodeRef}>
          <LoadingWrapper
            isLoading={Boolean(isLoading)}
            loadingIndicator={mainScreenSpinner}
          >
            <Suspense fallback={mainScreenSpinner}>
              <Component forwardRef={refs[step]} logoSrc={logoSrc} />
            </Suspense>
          </LoadingWrapper>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Root;
