import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'components/spinner';
import COMMON_LABELS from 'constants/messages';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import { getPathParams } from 'pages/route/utils';
import paymentsApi, { selectPlacedOrder } from 'services/payments';
import { OrderCompletionResponse, SavedCard } from 'services/payments/types';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import { selectCardAuthorizationErrorMessage, selectTokenizedCard, setTokenizedCard } from 'state/slices/paymentSlice';
import { changeStep } from 'state/slices/stepSlice';
import { CardPaymentToken } from 'types/card';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { HTTPStatus, ReduxToolkitError } from 'types/http';
import { WorkflowStep } from 'types/step';
import PATHS from 'utils/navigation';

import BankDetails from '.';
import { LABELS } from './keys';

export const ContinueBankDetailsContainerSell = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken) ?? '';
  const orderFromCurrentFlow = useSelector(selectPlacedOrder);
  const {
    data, isLoading, isError, error,
  } = paymentsApi.useGetPaymentsConfigQuery(authToken);
  const tokenizedCard = useSelector(selectTokenizedCard);
  const { proceed, goBack, canGoBack } = useNavigation();
  const { data: cardsResponse, isLoading: areCardsLoading, error: cardsError } = paymentsApi.useGetUserCardsQuery(authToken);
  const [
    updatePaymentMethod,
    { isLoading: isPaymentMethodUpdateLoading, error: paymentMethodUpdateError },
  ] = paymentsApi.useUpdatePaymentMethodMutation();

  const setCard = (tokenizedCard: CardPaymentToken | SavedCard, setCardAsDefault: boolean) => dispatch(setTokenizedCard({
    tokenizedCard,
    rememberCard: true,
    setCardAsDefault,
  }));
  const showErrorScreen = (errorType: ScreenErrorType, isFatalError = false) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, isFatalError, goBackToScreen: WorkflowStep.BankDetails }));
    dispatch(changeStep(WorkflowStep.Error));
  };
  const placedOrderError = useSelector(selectCardAuthorizationErrorMessage);
  const updatePaymentErrorMessage = (paymentMethodUpdateError as ReduxToolkitError)?.data?.errorMessage ?? t(LABELS.PAYMENT_FAILED_MESSAGE);
  const buttonLabel = isPaymentMethodUpdateLoading ? <Spinner /> : t(COMMON_LABELS.CONTINUE);

  useEffect(() => {
    if (!error) {
      return;
    }

    const toolkitError = error as ReduxToolkitError;
    if (!toolkitError?.status && error) {
      throw new Error('Unhandled error: Fetching payment configuration failed');
    }

    if (toolkitError.status === HTTPStatus.Unauthorized) {
      return;
    }
    if (toolkitError.data?.errorType === ApiErrorCode.RegionError) {
      showErrorScreen(ScreenErrorType.RegionUnsupported, true);
      return;
    }

    showErrorScreen(ScreenErrorType.UnableToGetPaymentsConfig);
  }, [error]);

  const onProceedClick = async () => {
    if (!tokenizedCard) {
      throw new Error('Cannot proceed without payment token');
    }

    const orderUuid = getPathParams(PATHS.CONTINUE_ORDER_PATH, window.location.pathname).orderId
      ?? (orderFromCurrentFlow as OrderCompletionResponse)?.orderUuid;
    const resp = await updatePaymentMethod({ uuid: orderUuid, paymentToken: tokenizedCard as CardPaymentToken });
    if ('error' in resp) {
      return;
    }

    proceed();
  };

  return (
    <BankDetails
      paymentsConfig={{
        shift4PublicKey: data?.data?.shift4PublicKey,
        merchantId: data?.data?.merchantId,
        isLoading,
        isError,
      }}
      ctaButtonProps={{
        children: buttonLabel,
        disabled: isPaymentMethodUpdateLoading,
      }}
      proceed={onProceedClick}
      goBack={goBack}
      tokenizedCard={tokenizedCard}
      setTokenizedCard={setCard}
      showErrorScreen={showErrorScreen}
      canGoBack={canGoBack}
      orderError={placedOrderError}
      errorMessage={updatePaymentErrorMessage}
      isAddingNewCard
      cards={{
        cards: cardsResponse ?? [],
        isLoading: areCardsLoading,
        error: cardsError,
      }}
      showSetDefaultControl={false}
      showSaveCardControl={false}
      showNotice={false}
    />
  );
};

export default ContinueBankDetailsContainerSell;
