import { tss } from 'theme';

export const useStyles = tss.create(
  ({ theme }) => ({
    message: {
      color: theme.colors.text.inactive,
    },
  }),
);

export default useStyles;
