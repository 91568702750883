import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import QuoteDetailsSell from 'components/quoteDetails/sell';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import useShift4Components from 'pages/bankDetails/useShift4Components';
import { getPathParams } from 'pages/route/utils';
import assetsApi from 'services/assets';
import paymentsApi, { selectPaymentMethod, selectPlacedOrder } from 'services/payments';
import { OrderCompletionResponse, SavedCard } from 'services/payments/types';
import quotesApi, { QUOTE_CACHE_KEY, QuoteAction } from 'services/quote';
import { QuotePayload } from 'services/quote/transactions/payloads';
import transactionApi from 'services/transaction';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import {
  selectCryptoAsset,
  selectFiatAsset,
  selectOrder,
  setExecutedQuoteId,
  setTransactionId,
} from 'state/slices/orderSlice';
import {
  selectTokenizedCard,
  setCardAuthorizationErrorMessage,
  setHasCardAuthorizationError,
  setPaymentMethod,
  setTokenizedCard,
} from 'state/slices/paymentSlice';
import { changeStep, setIsAddingCard } from 'state/slices/stepSlice';
import { RootState } from 'state/store';
import { AssetWithLabel, CurrencyType, Quote } from 'types/assets';
import { CardPaymentToken } from 'types/card';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import Flow from 'types/flow';
import { ReduxToolkitError, ReduxToolkitResponse } from 'types/http';
import { PaymentMethod } from 'types/payment';
import { WorkflowStep } from 'types/step';
import { TransactionId } from 'types/transactions';
import { formatCryptoAmountWithSymbolAtEnd } from 'utils/currencies';
import PATHS from 'utils/navigation';
import verify3DS from 'utils/shift4';

import CheckoutPage from '.';
import CardPreview from './components/cardPreview';
import CryptoAmountPreview from './components/cryptoPreview';
import KEYS, { LABELS } from './keys';
import QuoteAdditionalInfoSell from './quoteAdditionalInfo.sell';
import useStyles from './styles';

const ContinueCheckoutContainerSell = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [quoteMutation, {
    data: quoteData,
    isLoading: quoteIsLoading,
    isError: isQuoteError,
    error: quoteError,
  }] = quotesApi.useGetQuoteMutation({
    fixedCacheKey: QUOTE_CACHE_KEY,
  });

  const [updateQuoteMutation, {
    error: updateQuoteError,
    reset: resetUpdateQuoteError,
  }] = paymentsApi.useConfirmUpdatedQuoteMutation();

  const tokenizedCard = useSelector(selectTokenizedCard);
  const stateOrder = useSelector(selectOrder);
  const placedOrder = useSelector(selectPlacedOrder);
  const authToken = useSelector(selectAuthToken) ?? '';
  const defaultPaymentMethod = useSelector(selectPaymentMethod);
  const paymentMethod = useSelector((state : RootState) => state.payment.paymentMethod);
  const selectedPaymentMethod = paymentMethod ?? defaultPaymentMethod;

  const { data } = paymentsApi.useGetPaymentsConfigQuery(authToken);
  const { shift4Utils } = useShift4Components(data?.data?.shift4PublicKey, data?.data?.merchantId);
  const { proceed, goBack, canGoBack } = useNavigation();
  const { data: cardsResponse, isLoading: areCardsLoading, error: cardsError } = paymentsApi.useGetUserCardsQuery(authToken);
  const orderId = getPathParams(PATHS.CONTINUE_ORDER_PATH, window.location.pathname)?.orderId
    ?? (placedOrder as OrderCompletionResponse)?.orderUuid;
  const { data: transaction, isLoading: transactionIsLoading } = transactionApi.useGetTransactionQuery({
    includeToken: true,
    transactionId: orderId,
  });
  const isPayReady = Boolean(shift4Utils && tokenizedCard);

  const quote = quoteData?.data.quote;
  const continueOrderCryptoAsset = assetsApi
    .useGetActiveAssetsSellQuery()
    .data?.data.assets.find(asset => asset.symbol === transaction?.data.order?.cryptoCurrencyCode);
  const continueOrderFiatAsset = assetsApi
    .useGetActiveAssetsSellQuery()
    .data?.data.assets.find(asset => asset.symbol === transaction?.data.order?.fiatCurrencyCode);
  const selectedFiatAsset = useSelector(selectFiatAsset) ?? continueOrderFiatAsset as AssetWithLabel;
  const selectedCryptoAsset = useSelector(selectCryptoAsset) ?? continueOrderCryptoAsset as AssetWithLabel;

  const setCard = (
    tokenizedCard: CardPaymentToken | SavedCard,
    rememberCard: boolean,
    setCardAsDefault: boolean,
  ) => dispatch(setTokenizedCard({
    tokenizedCard, rememberCard, setCardAsDefault,
  }));

  const sellingAmount = quote?.cryptoAmount
    ?? transaction?.data.order.cryptoAmount
    ?? (placedOrder as OrderCompletionResponse).quote.cryptoAmount
    ?? stateOrder.cryptoAmount;
  const withdrawalAddress = stateOrder.walletAddress;
  const withdrawalAddressTag = stateOrder.tagOrMemo ? stateOrder.tagOrMemo : undefined;
  const proceedLabel = sellingAmount && selectedCryptoAsset
    ? t(LABELS.SELL_AMOUNT, {
      amount: formatCryptoAmountWithSymbolAtEnd(sellingAmount, selectedCryptoAsset),
    })
    : t(LABELS.SELL);

  useEffect(() => {
    fetchQuote();
  }, []);

  useEffect(() => {
    if (updateQuoteError) {
      resetUpdateQuoteError();
    }
  }, []);

  const completePayment = (newQuoteId: string) => {
    const cardToken = tokenizedCard?.id;

    if (!cardToken) {
      throw new Error('Unable to complete payment. Missing card info.');
    }

    return updateQuoteMutation({ uuid: orderId, quoteId: newQuoteId }) as Promise<ReduxToolkitResponse<OrderCompletionResponse>>;
  };

  const fetchQuote = () => {
    if (!authToken || !selectedCryptoAsset || !selectedFiatAsset) {
      throw new Error('Missing quote payload');
    }

    let quoteDataWhichDependsOnExchangeDirection: Partial<QuotePayload> = stateOrder.exchangeDirection === CurrencyType.Fiat
      ? { fiatAmount: transaction?.data.order?.fiatAmount?.toString() ?? stateOrder.fiatAmount }
      : { cryptoAmount: transaction?.data.order?.cryptoAmount ?? stateOrder.cryptoAmount };

    if (transaction?.data.order) {
      quoteDataWhichDependsOnExchangeDirection = { cryptoAmount: transaction.data.order.cryptoAmount };
    }

    const payload: QuoteAction = {
      payload: {
        cryptoCurrencyCode: selectedCryptoAsset!.symbol,
        fiatCurrencyCode: selectedFiatAsset!.symbol,
        walletAddress: withdrawalAddress,
        walletTag: withdrawalAddressTag,
        side: Flow.Sell,
        paymentMethod: defaultPaymentMethod ?? undefined,
        ...quoteDataWhichDependsOnExchangeDirection,
      },
      includeToken: Boolean(authToken),
    };

    return quoteMutation(payload) as Promise<ReduxToolkitResponse<{ quote: Quote }>>;
  };

  const onContinue = (
    transactionId: TransactionId | undefined,
    quoteId: string | undefined,
    hasCardAuthorizationError: boolean,
    cardAuthorizationErrorMessage?: string,
  ) => {
    // TODO: Do not even call this func if theres no transactionId or quoteId
    if (transactionId) {
      dispatch(setTransactionId(transactionId));
    }

    if (quoteId) {
      dispatch(setExecutedQuoteId(quoteId));
    }

    if (cardAuthorizationErrorMessage) {
      dispatch(setCardAuthorizationErrorMessage(cardAuthorizationErrorMessage));
    }

    dispatch(setHasCardAuthorizationError(hasCardAuthorizationError));

    if (hasCardAuthorizationError) {
      dispatch(changeStep(WorkflowStep.BankDetails));
      return;
    }
    proceed();
  };

  const onPaymentMethodPick = (paymentMethod: PaymentMethod | null) => {
    if (!paymentMethod) {
      return;
    }
    dispatch(setPaymentMethod(paymentMethod));
  };

  const onAddCardClick = () => {
    dispatch(setIsAddingCard(true));
    dispatch(changeStep(WorkflowStep.BankDetails));
  };

  const showErrorScreen = (errorType: ScreenErrorType) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, goBackToScreen: WorkflowStep.Checkout }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const onPayClick = async () => {
    if (!shift4Utils || !tokenizedCard) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const quote = await fetchQuote();
    const newQuoteId = quote.data?.data?.quote.quoteId;
    const quoteErrorMessage = quote.error?.data?.errorMessage;

    if (quoteErrorMessage) {
      return;
    }
    if (!newQuoteId) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    try {
      if (!selectedFiatAsset) {
        throw new Error('Unknown fiat currency');
      }

      const threeDSVerifiedToken = await verify3DS(shift4Utils, tokenizedCard, KEYS.OFFRAMP_3DS_AMOUNT, selectedFiatAsset);

      if (threeDSVerifiedToken.error) {
        throw threeDSVerifiedToken.error;
      }
    } catch (e) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    let cardPayment;
    try {
      cardPayment = await completePayment(newQuoteId);
    } catch (e) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const transactionId = cardPayment?.data?.data?.orderUuid;
    const quoteId = cardPayment?.data?.data?.quote.quoteId;
    const hasCardAuthorizationError = cardPayment?.error?.data?.errorType === ApiErrorCode.CardAuthorizationFailed;

    const hasOtherPaymentErrorMessage = cardPayment?.error?.data?.errorMessage && !hasCardAuthorizationError;
    if (hasOtherPaymentErrorMessage) {
      return;
    }

    const hasGenericError = !transactionId && !hasCardAuthorizationError;
    if (hasGenericError) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    onContinue(transactionId, quoteId, hasCardAuthorizationError, cardPayment?.error?.data?.errorMessage);
  };

  if (!selectedFiatAsset || !selectedCryptoAsset) {
    return null;
  }

  return (
    <CheckoutPage
      canGoBack={canGoBack}
      quote={{
        details: quote,
        isLoading: quoteIsLoading || transactionIsLoading,
        isError: isQuoteError,
        error: quoteError as ReduxToolkitError,
      }}
      payment={{
        isError: Boolean(updateQuoteError),
        error: updateQuoteError as ReduxToolkitError,
        method: selectedPaymentMethod ?? null,
      }}
      disclaimer={(<Trans i18nKey={LABELS.CONFIRM_QUOTE_DISCLAIMER} components={{ br: <br /> }} />)}
      previews={(
        <>
          <CryptoAmountPreview
            label={t(LABELS.SELLING)}
            asset={selectedCryptoAsset}
            amount={sellingAmount}
          />

          <div className={classes.previews}>
            <CardPreview
              paymentMethod={selectedPaymentMethod}
              onPaymentMethodPick={onPaymentMethodPick}
              label={t(LABELS.SEND_TO)}
              isCardManagementVisibleByDefault={false}
              cardInfo={tokenizedCard}
              setTokenizedCard={setCard}
              onAddCardClick={onAddCardClick}
              cards={{
                cards: cardsResponse ?? [],
                isLoading: areCardsLoading,
                error: cardsError,
              }}
            />
          </div>
        </>
      )}
      additionalInfo={(
        <QuoteAdditionalInfoSell
          isError={isQuoteError}
          isLoading={quoteIsLoading || transactionIsLoading}
          cryptocurrency={selectedCryptoAsset!}
          fiatCurrency={selectedFiatAsset!}
          fetchQuote={fetchQuote}
          price={quote?.cryptoPrice}
          fiatAmount={quote?.fiatAmount ?? '0'}
          refetch={!(updateQuoteError as ReduxToolkitError)?.data?.errorMessage}
        />
      )}
      proceedLabel={proceedLabel}
      selectedFiatAsset={selectedFiatAsset!}
      selectedCryptoAsset={selectedCryptoAsset!}
      goBack={goBack}
      fetchQuote={fetchQuote}
      onPayClick={onPayClick}
      QuoteBreakdown={QuoteDetailsSell}
      isPayReady={isPayReady}
      regulatoryText={t(LABELS.REGULATORY_TEXT_SELL)}
    />
  );
};

export default ContinueCheckoutContainerSell;
