import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ApplePayIcon from 'assets/images/paymentMethods/applePay.svg';
import GooglePayIcon from 'assets/images/paymentMethods/googlePay.svg';
import Button from 'components/button';
import OptionallyVisible from 'components/optionallyVisible';
import SlideModal from 'components/slideModal';
import CARD_BRANDS_IMAGE_SOURCE from 'constants/cardBrands';
import COMMON_LABELS from 'constants/messages';
import IconCross from 'icons/cross';
import IconEdit from 'icons/edit';
import IconError from 'icons/error';
import maskContent from 'libs/sentry/mask';
import CardsPageContainer from 'pages/cards';
import { SavedCard } from 'services/payments/types';
import { CardPaymentToken } from 'types/card';
import PaymentMethod from 'types/payment';
import { CardBrand } from 'types/transactions';
import { locators, QALocator } from 'utils/tests/QA';

import { LABELS } from '../keys';
import { useStyles } from './preview.styles';

export interface CardPreviewProps {
  paymentMethod?: PaymentMethod | null;
  onPaymentMethodPick: (paymentMethod: PaymentMethod | null) => void;
  label: string;
  isCardManagementVisibleByDefault: boolean;
  cardInfo: {
    last4: CardPaymentToken['last4'];
    brand: CardPaymentToken['brand'];
  } | null;
  cards: {
    cards: SavedCard[];
    isLoading: boolean;
    error: any;
  };
  setTokenizedCard: (tokenizedCard: CardPaymentToken | SavedCard, rememberCard: boolean, setAsDefault: boolean) => void;
  onAddCardClick: () => void;

}

const CardPreview = ({
  paymentMethod,
  label,
  isCardManagementVisibleByDefault,
  cardInfo,
  cards,
  onAddCardClick,
}: CardPreviewProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const [isCardManagementOverlayVisible, setCardManagementOverlayVisible] = useState(isCardManagementVisibleByDefault);

  const renderIcon = () => {
    if (!paymentMethod) {
      return null;
    }
    const isThirdPartyPaymentMethod = paymentMethod !== PaymentMethod.Card
      && [PaymentMethod.ApplePay, PaymentMethod.GooglePay].includes(paymentMethod as PaymentMethod);
    if (isThirdPartyPaymentMethod) {
      return ({
        [PaymentMethod.ApplePay]: <img src={ApplePayIcon} width={31} height={20} alt={t(COMMON_LABELS.APPLE_PAY)} />,
        [PaymentMethod.GooglePay]: <img src={GooglePayIcon} width={37.6} height={20} alt={t(COMMON_LABELS.GOOGLE_PAY)} />,
      })[paymentMethod];
    }

    const cardBrand = cardInfo?.brand;
    const cardImageUrl = CARD_BRANDS_IMAGE_SOURCE[cardBrand as CardBrand] ?? CARD_BRANDS_IMAGE_SOURCE[CardBrand.Unknown];

    return (
      <img className={classes.cardBrandIcon} width={theme.scale(35)} height={theme.scale(20)} alt={cardBrand} src={cardImageUrl} />
    );
  };

  const onCloseModalClick = () => {
    setCardManagementOverlayVisible(false);
  };

  const onEditClick = () => {
    setCardManagementOverlayVisible(true);
  };

  const closeModal = () => {
    setCardManagementOverlayVisible(false);
  };

  const getPaymentMethodLabel = () => {
    const isThirdPartyPaymentMethod = paymentMethod
      && paymentMethod !== PaymentMethod.Card
      && [PaymentMethod.ApplePay, PaymentMethod.GooglePay].includes(paymentMethod as PaymentMethod);
    if (isThirdPartyPaymentMethod) {
      return ({
        [PaymentMethod.ApplePay]: t(COMMON_LABELS.APPLE_PAY),
        [PaymentMethod.GooglePay]: t(COMMON_LABELS.GOOGLE_PAY),
      })[paymentMethod];
    }

    if (!cardInfo) {
      return t(LABELS.CARD_MISSING);
    }

    return [
      LABELS.WALLET_MASK_FILL,
      cardInfo?.last4,
    ].join(' ');
  };

  return (
    <>
      <div className={classes.infoBlockLabel}>
        {label}
      </div>
      <div className={classes.infoBlockValue}>
        {renderIcon()}
        <div
          {...maskContent()}
        >
          {getPaymentMethodLabel()}
        </div>
        <Button
          className={classes.editButton}
          onClick={onEditClick}
          {...QALocator(locators.page.checkout.editCardButton)}
        >
          <IconEdit color={theme.colors.icons.accentDark} />
        </Button>
      </div>
      <SlideModal
        visible={isCardManagementOverlayVisible}
        onClose={onCloseModalClick}
        title={t(COMMON_LABELS.SELECT_PAYMENT_METHOD)}
      >
        <OptionallyVisible visible={!cards.error}>
          {/*
            TODO:
            Below is a container nested under a different container.
            See if this bring any issues in future, refactor if needed.
          */}
          <CardsPageContainer
            canSelectPaymentMethod
            onSelect={closeModal}
          />
        </OptionallyVisible>
        <OptionallyVisible visible={cards.error}>
          <div className={classes.listOfCardsErrorWrapper}>
            <IconError className={classes.errorIcon} />
            <Trans i18nKey={COMMON_LABELS.GENERIC_ERROR} components={{ br: <br /> }} />
          </div>
        </OptionallyVisible>
        <OptionallyVisible visible={!cards.error}>
          <div className={classes.addCardButton}>
            <Button
              onClick={onAddCardClick}
              endAdornment={(
                <IconCross
                  color={theme.colors.icons.secondary}
                  className={classes.plusIcon}
                  width={theme.scale(20)}
                  height={theme.scale(20)}
                />
              )}
            >
              {t(COMMON_LABELS.ADD_CARD)}
            </Button>
          </div>
        </OptionallyVisible>
      </SlideModal>
    </>
  );
};

export default CardPreview;
