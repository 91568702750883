import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    buyOrderBadge: {
      display: 'flex',
      border: '0.5px solid rgba(151, 30, 247, 0.30)',
      backgroundColor: 'rgba(151, 30, 247, 0.05)',
      boxShadow: '0px 2.727px 10.909px 0px rgba(8, 155, 23, 0.05)',
      color: '#971EF7',
      span: {
        color: '#971EF7',
      },
      path: {
        stroke: '#971EF7',
      },
    },
    sellOrderBadge: {
      border: '0.5px solid rgba(34, 83, 255, 0.30)',
      backgroundColor: 'rgba(34, 83, 255, 0.05)',
      boxShadow: '0px 2.727px 10.909px 0px rgba(8, 155, 23, 0.05)',
      color: '#2253FF',
      span: {
        color: '#2253FF',
      },
      path: {
        stroke: '#2253FF',
      },
    },
    rotated: {
      transform: 'rotate(180deg)',
    },
    orderBadge: {
      display: 'flex',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.scale(5),
      width: 'auto',
      height: theme.scale(20),
      span: {
        ...fontSizeMixin(FontSize.XS),
        fontWeight: 500,
      },
    },
    iconOrderBadge: {
      width: theme.scale(20),
    },
  }),
);

export default useStyles;
