import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M21.125 28.625H24.875V32.375H21.125V28.625ZM21.125',
  '13.625H24.875V24.875H21.125V13.625ZM22.9812 4.25C12.6312',
  '4.25 4.25 12.65 4.25 23C4.25 33.35 12.6312 41.75 22.9812',
  '41.75C33.35 41.75 41.75 33.35 41.75 23C41.75 12.65 33.35 4.25',
  '22.9812 4.25ZM23 38C14.7125 38 8 31.2875 8 23C8 14.7125 14.7125',
  '8 23 8C31.2875 8 38 14.7125 38 23C38 31.2875 31.2875 38 23 38Z',
].join(' ');

export const IconError = ({
  width = 45,
  height = 45,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 46 46"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconError;
