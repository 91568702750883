import {
  Trans,
  useTranslation,
} from 'react-i18next';

import Button from 'components/button';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import CONTACT_DATA from 'constants/contactData';
import useScreenTransitionFinish from 'hooks/useScreenTransitionFinish';
import IconError from 'icons/error';
import { ScreenError, ScreenErrorType } from 'types/errors/errorScreen';
import { WorkflowStep } from 'types/step';
import { TransitionStateDetails } from 'types/transition';
import { locators, QALocator } from 'utils/tests/QA';

import LABELS from './keys';
import { useStyles } from './styles';

export interface ErrorScreenProps {
  error: ScreenError;
  goBack: () => void;
  clearError?: () => void;
}

const ErrorScreen = ({
  error,
  clearError,
  goBack,
}: ErrorScreenProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const onTransitionFinished = (e?: CustomEvent<TransitionStateDetails>) => {
    const isExitingErrorScreen = [WorkflowStep.Error, WorkflowStep.Error].some(step => step === e?.detail.exitingFrom);
    if (isExitingErrorScreen) {
      clearError?.();
    }
  };

  const getContent = () => {
    if (error.errorType === ScreenErrorType.KycRejected) {
      return (
        <Trans
          i18nKey={LABELS.CONTACT_SUPPORT}
          components={{
            a: <a href={`${CONTACT_DATA.S4C_URL}`} target="_blank">url</a>,
          }}
        />
      );
    }

    return error.message;
  };

  useScreenTransitionFinish(onTransitionFinished);

  return (
    <div className={classes.container}>
      <Header hasBackButton={!error.isFatalError} onBackClick={goBack}>
        {t(LABELS.ERROR)}
      </Header>
      <div className={classes.content}>
        <IconError className={classes.icon} />
        <div className={classes.messages}>
          <h3 className={classes.title}>
            {error.title}
          </h3>
          <p className={classes.text}>
            {getContent()}
          </p>
        </div>
      </div>
      <OptionallyVisible visible={!error.isFatalError}>
        <Button
          isBack
          onClick={goBack}
          {...QALocator(locators.page.error.goBackMainButton)}
        >
          {t(LABELS.BACK)}
        </Button>
      </OptionallyVisible>
    </div>
  );
};

export default ErrorScreen;
