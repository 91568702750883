import { NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';
import { isFinishingOrder } from 'utils/navigation';

import getInitialStep from './init';
import { checkIfShouldSkipQuoteStep } from './queryParams';

export const canGoBackFromSignInScreen = ({ user }: NavigationEvaluationFuncParams): boolean => {
  const initialStep = getInitialStep({ user });
  const skipQuoteStep = checkIfShouldSkipQuoteStep();
  const isContinueFlow = isFinishingOrder();

  if (skipQuoteStep) {
    return false;
  }

  if (isContinueFlow) {
    return false;
  }

  return initialStep !== WorkflowStep.SignIn;
};

export default canGoBackFromSignInScreen;
