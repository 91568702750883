import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import Input from 'components/input';
import OptionallyVisible from 'components/optionallyVisible';
import IconEnvelope from 'icons/envelope';
import maskContent from 'libs/sentry/mask';

import useStyles from './emailForm.styles';
import KEYS, { LABELS } from './keys';

interface EmailFormProps {
  emailValue: string;
  onEmailChange: ChangeEventHandler<HTMLInputElement>;
  emailError?: string;
}

export const EmailForm = ({
  emailValue,
  onEmailChange,
  emailError,
}: EmailFormProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <div className={classes.formItem}>
        <label className={classes.label} htmlFor={KEYS.EMAIL_INPUT}>{t(LABELS.YOUR_EMAIL)}</label>
        <Input
          className={classes.input}
          id={KEYS.EMAIL_INPUT}
          placeholder={t(LABELS.EMAIL_PLACEHOLDER)}
          slotProps={{
            input: {
              type: 'email',
            },
          }}
          startAdornment={(
            <IconEnvelope color={theme.colors.text.inactive} />
          )}
          value={emailValue}
          error={Boolean(emailError)}
          onChange={onEmailChange}
          {...maskContent()}
        />
        <OptionallyVisible visible={Boolean(emailError)}>
          <div className={classes.formItemError}>{emailError}</div>
        </OptionallyVisible>
      </div>
    </Card>
  );
};

export default EmailForm;
