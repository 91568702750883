import { Illustration } from 'illustrations/types';

import useSharedStyles from '../styles';
import useStyles, { getColorBasedMasks } from './styles';

interface Shift4LogoIllustrationProps {
  height?: number;
  width?: number;
  color?: string | null;
}

const Shift4LogoIllustration = ({ color, height, width }: Shift4LogoIllustrationProps) => {
  const { classes, cx, theme } = useStyles({ color });
  const { classes: sharedClasses } = useSharedStyles({ height: height ?? 25, width });

  const mapMaskToNode = (_: string, index: number) => (
    <div
      key={index}
      className={cx(sharedClasses.icon, `icon--${Illustration.Shift4}-${index}`)}
    />
  );

  const illustration = Object
    .keys(getColorBasedMasks(theme, color))
    .map(mapMaskToNode);

  return (
    <div className={cx(classes.container, sharedClasses.icon)}>
      {illustration}
    </div>
  );
};

export default Shift4LogoIllustration;
