import { useTranslation } from 'react-i18next';

import { useStyles } from './countryCheckInfoBlock.styles';
import { LABELS } from './keys';

const CountryCheckInfoBlock = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.disclaimer}>
      <span>{t(LABELS.INFO_BLOCK_TEXT_PARAGRAPH_1)}</span>
      <span>{t(LABELS.INFO_BLOCK_TEXT_PARAGRAPH_2)}</span>
      <span>
        {t(LABELS.INFO_BLOCK_TEXT_PARAGRAPH_3)}
      </span>
    </div>
  );
};

export default CountryCheckInfoBlock;
