import { makeLabels } from 'utils/i18n';

const MESSAGES_NAMESPACE = 'orderHistory';
export const LABELS = makeLabels({
  EMAIL: 'email',
  CREATE_ORDER: 'createOrder',
  PLACEHOLDER: 'placeholder',
}, MESSAGES_NAMESPACE);

export default LABELS;
