import { AnyAction } from '@reduxjs/toolkit';

import paymentsApi, { FINISH_PAYMENT_CACHE_KEY } from 'services/payments';
import transactionApi from 'services/transaction';
import { WidgetEvent } from 'types/events';
import ConfigQueryParam from 'types/queryParams';
import { OrderStepStatus } from 'types/transactions';
import emitEvent from 'utils/events';
import getQueryParam from 'utils/queryParams';

import { SideEffectsMiddlewareHandler } from './types';

interface OrderFlags {
  placed: boolean;
  completed: boolean;
  failed: boolean;
  stuck: boolean;
}

let memoizedOrder: OrderFlags = {
  placed: false,
  completed: false,
  failed: false,
  stuck: false,
};

const checkIfOrderIsPlaced = (action: AnyAction) => {
  const isFulfilledCompletePaymentQuery = paymentsApi.endpoints.completePayment.matchFulfilled(action);

  return isFulfilledCompletePaymentQuery;
};

const checkIfOrderIsCompleted = (action: AnyAction) => {
  const isFulfilledTransactionStepQuery = transactionApi.endpoints.getTransactionStepsStatus.matchFulfilled(action);
  if (!isFulfilledTransactionStepQuery) {
    return false;
  }

  const order = action.payload?.data;

  return order.steps.every(step => step.status === OrderStepStatus.Success);
};

const checkIfOrderHasFailed = (action: AnyAction) => {
  const isFulfilledTransactionStepQuery = transactionApi.endpoints.getTransactionStepsStatus.matchFulfilled(action);
  if (!isFulfilledTransactionStepQuery) {
    return false;
  }

  const order = action.payload?.data;

  return order.steps.some(step => step.status === OrderStepStatus.Failed);
};

const checkIfOrderIsStuck = (action: AnyAction) => {
  const isFulfilledTransactionStepQuery = transactionApi.endpoints.getTransactionStepsStatus.matchFulfilled(action);
  if (!isFulfilledTransactionStepQuery) {
    return false;
  }

  const order = action.payload?.data;

  return order.steps.some(step => step.status === OrderStepStatus.Stuck);
};

const orderPlacedHandler: SideEffectsMiddlewareHandler = (_, action) => {
  const isOrderPlaced = checkIfOrderIsPlaced(action);
  const shouldEmitOrderPlaced = !memoizedOrder.placed && isOrderPlaced;
  if (shouldEmitOrderPlaced) {
    memoizedOrder = {
      placed: true,
      completed: false,
      failed: false,
      stuck: false,
    };

    emitEvent({
      eventType: WidgetEvent.OrderPlaced,
      payload: null,
    });
  }
};

const orderCompletedHandler: SideEffectsMiddlewareHandler = ({ getState }, action) => {
  const isOrderCompleted = checkIfOrderIsCompleted(action);
  const isOrderCompletionRegisteredFirstTime = !memoizedOrder.completed && isOrderCompleted;
  if (isOrderCompletionRegisteredFirstTime) {
    memoizedOrder = {
      ...memoizedOrder,
      completed: true,
    };

    emitEvent({
      eventType: WidgetEvent.OrderCompleted,
      payload: null,
    });

    const orderPlacementResponse = getState()[paymentsApi.reducerPath].mutations[FINISH_PAYMENT_CACHE_KEY];
    const redirectUrl = orderPlacementResponse?.data?.data?.orderCompleteRedirectUrl;
    const isRedirectEnabled = window.partnerConfig?.enableRedirectUrl
      ?? getQueryParam(ConfigQueryParam.EnableRedirectURL) === 'true';
    const shouldRedirect = isRedirectEnabled && redirectUrl;
    if (shouldRedirect) {
      window.location.href = redirectUrl;
    }
  }
};

const orderFailedHandler: SideEffectsMiddlewareHandler = (_, action) => {
  const isOrderFailed = checkIfOrderHasFailed(action);
  const shouldEmitFailed = !memoizedOrder.failed && isOrderFailed;
  if (shouldEmitFailed) {
    memoizedOrder = {
      ...memoizedOrder,
      failed: true,
    };

    emitEvent({
      eventType: WidgetEvent.OrderFailed,
      payload: null,
    });
  }
};

const orderStuckHandler: SideEffectsMiddlewareHandler = (_, action) => {
  const isOrderStuck = checkIfOrderIsStuck(action);
  const shouldEmitStuck = !memoizedOrder.stuck && isOrderStuck;
  if (shouldEmitStuck) {
    memoizedOrder = {
      ...memoizedOrder,
      stuck: true,
    };

    emitEvent({
      eventType: WidgetEvent.OrderStuck,
      payload: null,
    });
  }
};

export const orderMiddlewareHandlers: SideEffectsMiddlewareHandler[] = [
  orderPlacedHandler,
  orderCompletedHandler,
  orderFailedHandler,
  orderStuckHandler,
];

export default orderMiddlewareHandlers;
