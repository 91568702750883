import { HexColor } from 'types/color';

const MIN_CONTRAST = 3;
const VALID_HEX_CHARS = '0123456789abcdefABCDEF';
const LIGHTNESS_THRESHOLD = 0.5;
const RED_HUE_FACTOR = 0;
const GREEN_HUE_FACTOR = 2;
const BLUE_HUE_FACTOR = 4;
const SATURATION_DIVISOR = 2;
const DEGREE_CONVERSION = 60;
const LOWER_END_OFFSET = 3;
const HIGHER_END_OFFSET = 9;
const COLOR_WHEEL_DIVISIONS = 12;
const MAX_ILLUSTRATION_SATURATION = 50;

class Color {
  private color: string;

  constructor(color: string) {
    this.color = color;
  }

  static isValidHexColor(c: string | undefined) {
    if (!c) {
      return false;
    }

    if (!c.startsWith('#')) {
      return false;
    }

    const color = c.trim().toLowerCase().slice(1);
    if (color.length !== 6) {
      return false;
    }

    for (const char of color) {
      if (!VALID_HEX_CHARS.includes(char)) {
        return false;
      }
    }

    return true;
  }

  get css() {
    return this.color;
  }

  get hex() {
    return this.color;
  }

  replaceHueAndSaturation(color: HexColor, lightnessMultiplier?: number) {
    const { saturation: originalSaturation, lightness } = this.calculateHsl(this.color);
    const { hue, saturation: providedSaturation } = this.calculateHsl(color);

    const targetSaturation = Math.min(originalSaturation, providedSaturation);

    this.color = this.calculateHex([hue, targetSaturation, lightness * (lightnessMultiplier ?? 1)]);

    return this;
  }

  replaceHueAndSaturationForIllustration(color: HexColor, lightnessMultiplier?: number) {
    const { saturation: originalSaturation, lightness } = this.calculateHsl(this.color);
    const { hue, saturation: providedSaturation } = this.calculateHsl(color);

    const targetSaturation = Math.max(originalSaturation, providedSaturation) > MAX_ILLUSTRATION_SATURATION
      ? MAX_ILLUSTRATION_SATURATION
      : providedSaturation;

    this.color = this.calculateHex([hue, targetSaturation, lightness * (lightnessMultiplier ?? 1)]);

    return this;
  }

  adjustLightness(lightness: number) {
    const { hue, saturation } = this.calculateHsl(this.color);

    this.color = this.calculateHex([hue, saturation, lightness]);

    return this;
  }

  darkModeIllustrationColor() {
    const { hue, saturation, lightness } = this.calculateHsl(this.color);

    this.color = this.calculateHex([
      hue,
      saturation,
      this.calculateDarkModeIllustrationLightness(lightness),
    ]);

    return this;
  }

  replaceIfContrastInvalid(background: HexColor, replaceWith: HexColor) {
    const isValid = this.isContrastRatioCorrect(this.color, background);
    if (isValid) {
      return this;
    }

    this.color = replaceWith;

    return this;
  }

  setSaturation(saturation: number) {
    const { hue, lightness } = this.calculateHsl(this.color);

    this.color = this.calculateHex([hue, saturation, lightness]);

    return this;
  }

  private calculateDarkModeIllustrationLightness(lightness: number) {
    // This formula is hard-coded
    const lightnessPercentage = lightness * 100;
    return 115 - lightnessPercentage;
  }

  private calculateRgb(color: HexColor) {
    return color.match(/\w\w/g)!.map(x => parseInt(x, 16));
  }

  private calculateHsl(color: HexColor) {
    const [r255, g255, b255] = this.calculateRgb(color);

    const r = r255 / 255;
    const g = g255 / 255;
    const b = b255 / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let hue = (max + min) / 2;
    let saturation = hue;
    const lightness = hue;

    if (max === min) {
      return ({
        hue: 0,
        saturation: 0,
        lightness,
      });
    }

    const diff = max - min;
    saturation = lightness >= LIGHTNESS_THRESHOLD ? diff / (SATURATION_DIVISOR - (max + min)) : diff / (max + min);
    switch (max) {
      case r:
        hue = ((g - b) / diff + RED_HUE_FACTOR) * DEGREE_CONVERSION;
        break;
      case g:
        hue = ((b - r) / diff + GREEN_HUE_FACTOR) * DEGREE_CONVERSION;
        break;
      case b:
        hue = ((r - g) / diff + BLUE_HUE_FACTOR) * DEGREE_CONVERSION;
        break;
      default:
        break;
    }

    return ({
      hue: Math.round(hue),
      saturation: Math.round(saturation * 100),
      lightness: Math.round(lightness * 100),
    });
  }

  private calculateHex([hue, saturation, lightness]: [number, number, number]) {
    const lightnessDecimal = lightness / 100;
    const saturationDecimal = saturation / 100;
    const alpha = (saturationDecimal * Math.min(lightnessDecimal, 1 - lightnessDecimal));
    const hueStep = hue / 30;
    const calculateColorComponent = (n: number) => {
      const colorStep = (n + hueStep) % COLOR_WHEEL_DIVISIONS;
      const color = lightnessDecimal - alpha * Math.max(Math.min(colorStep - LOWER_END_OFFSET, HIGHER_END_OFFSET - colorStep, 1), -1);
      const rgbColor = Math.round(255 * color);
      return rgbColor.toString(16).padStart(2, '0');
    };
    return `#${calculateColorComponent(0)}${calculateColorComponent(8)}${calculateColorComponent(4)}`;
  }

  private isContrastRatioCorrect(text: HexColor, background: HexColor) {
    return this.contrastRatio(text, background) >= MIN_CONTRAST;
  }

  private contrastRatio(text: HexColor, background: HexColor) {
    const textLum = this.relativeLuminance(text);
    const backgroundLum = this.relativeLuminance(background);

    return (Math.max(textLum, backgroundLum) + 0.05) / (Math.min(textLum, backgroundLum) + 0.05);
  }

  // https://www.w3.org/WAI/GL/wiki/Relative_luminance
  private relativeLuminance(color: HexColor) {
    const convertedColor = this.calculateRgb(color);
    const [r, g, b] = convertedColor.map((c) => {
      let channel = c / 255;
      channel = channel <= 0.03928 ? channel / 12.92 : ((channel + 0.055) / 1.055) ** 2.4;
      return channel;
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }
}

export default Color;
