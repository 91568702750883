import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import OptionallyVisible from 'components/optionallyVisible';
import PaymentMethodsList from 'components/paymentMethodsList';
import COMMON_LABELS from 'constants/messages';
import IconError from 'icons/error';
import paymentsApi, { selectAvailablePaymentMethods } from 'services/payments';
import { SavedCard } from 'services/payments/types';
import userApi, { selectUserCacheEntry } from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { setPaymentMethod, setTokenizedCard } from 'state/slices/paymentSlice';
import { CardPaymentToken } from 'types/card';
import { ReduxToolkitResponse, SuccessResponse } from 'types/http';
import PaymentMethod from 'types/payment';
import { KycStatus } from 'types/user';

import useStyles from './styles';

export interface CardsPageContainerProps {
  canSelectPaymentMethod: boolean;
  onSelect?: () => void;
}

const CardsPageContainer = ({
  canSelectPaymentMethod = false,
  onSelect,
}: CardsPageContainerProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken) ?? '';
  const user = useSelector(selectUserCacheEntry)?.data?.data;
  const userDefaultPaymentMethod = user?.defaultPaymentMethod;
  const { data: cardsResponse, isLoading: areCardsLoading, error: cardsError } = paymentsApi.useGetUserCardsQuery(authToken, {
    skip: user?.kycStatus !== KycStatus.Approved,
  });
  const [setDefaultCard, { isLoading: isSetDefaultCardLoading, error: setDefaultCardError }] = paymentsApi.useSetDefaultUserCardMutation();
  const [deleteCard, { isLoading: isDeleteCardLoading, error: deleteCardError }] = paymentsApi.useDeleteUserCardMutation();
  const [mutateUser, {
    isLoading: isUserMutationLoading,
  }] = userApi.useUpdateUserMutation();
  const availablePaymentMethods = useSelector(selectAvailablePaymentMethods);

  const cards = {
    cards: cardsResponse ?? [],
    isLoading: areCardsLoading,
    error: cardsError,
  };

  const defaultCardProps = {
    setDefaultCard: (card: CardPaymentToken | SavedCard) => setDefaultCard({ card, authToken }) as any,
    isLoading: isSetDefaultCardLoading,
    error: setDefaultCardError,
  };

  const deleteCardProps = {
    deleteCard: (card: CardPaymentToken | SavedCard) => deleteCard({ card, authToken }) as any,
    isLoading: isDeleteCardLoading,
    error: deleteCardError,
  };

  const updateUserPaymentMethod = async (paymentMethod: PaymentMethod | CardPaymentToken['id']) => {
    if (!authToken || !paymentMethod) {
      return {};
    }

    return mutateUser({ authToken, user: { defaultPaymentMethod: paymentMethod } }) as Promise<ReduxToolkitResponse<SuccessResponse>>;
  };

  const onPaymentMethodPick = (paymentMethod: PaymentMethod | SavedCard | null) => {
    if (!paymentMethod) {
      return;
    }

    if ((paymentMethod as SavedCard)?.id) {
      dispatch(setTokenizedCard({ tokenizedCard: paymentMethod as SavedCard }));
      dispatch(setPaymentMethod(PaymentMethod.Card));
      onSelect?.();
      return;
    }

    dispatch(setPaymentMethod(paymentMethod as PaymentMethod));
    onSelect?.();
  };

  return (
    <>
      <OptionallyVisible visible={Boolean(!cards.error)}>
        <PaymentMethodsList
          availablePaymentMethods={availablePaymentMethods}
          canSelectPaymentMethod={canSelectPaymentMethod}
          userDefaultPaymentMethod={userDefaultPaymentMethod ?? null}
          cards={cards.cards}
          onChange={canSelectPaymentMethod ? onPaymentMethodPick : () => {}}
          defaultCardProps={defaultCardProps}
          deleteCardProps={deleteCardProps}
          updateUserProps={{
            updateUser: updateUserPaymentMethod,
            isLoading: isUserMutationLoading,
          }}
        />
      </OptionallyVisible>
      <OptionallyVisible visible={Boolean(cards.error)}>
        <div className={classes.listOfCardsErrorWrapper}>
          <IconError className={classes.errorIcon} />
          <Trans i18nKey={COMMON_LABELS.GENERIC_ERROR} components={{ br: <br /> }} />
        </div>
      </OptionallyVisible>
    </>
  );
};

export default CardsPageContainer;
