import { createApi } from '@reduxjs/toolkit/query/react';

import fetchBaseQueryWithCustomHeaders, { CustomHeaderType, makeAuthHeader } from 'services/api';
import { Endpoint } from 'services/endpoints';
import HTTPMethod from 'types/http';

import { QuotePayload } from './transactions/payloads';
import { QuoteResponse } from './transactions/responses';

export const QUOTE_CACHE_KEY = 'quote';
const REDUCER_PATH = 'quoteApi';

export interface QuoteAction {
  payload: QuotePayload;
  includeToken: boolean;
}

export const quotesApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(),
  endpoints: builder => ({
    getQuote: builder.mutation<QuoteResponse, QuoteAction>({
      query: ({ payload, includeToken }) => ({
        body: payload,
        url: Endpoint.Quotes,
        method: HTTPMethod.POST,
        headers: includeToken ? { [CustomHeaderType.Auth]: makeAuthHeader().value } : {},
      }),
    }),
  }),
});

export default quotesApi;
