import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M9.99992 4.99992C10.9166 4.99992 11.6666 5.74992 11.6666 6.66659C11.6666 7.58325 10.9166 8.33325',
  '9.99992 8.33325C9.08325 8.33325 8.33325 7.58325 8.33325 6.66659C8.33325 5.74992 9.08325 4.99992',
  '9.99992 4.99992ZM9.99992 13.3333C12.2499 13.3333 14.8333 14.4083 14.9999 14.9999H4.99992C5.19159',
  '14.3999 7.75825 13.3333 9.99992 13.3333ZM9.99992 3.33325C8.15825 3.33325 6.66659 4.82492 6.66659',
  '6.66659C6.66659 8.50825 8.15825 9.99992 9.99992 9.99992C11.8416 9.99992 13.3333 8.50825 13.3333',
  '6.66659C13.3333 4.82492 11.8416 3.33325 9.99992 3.33325ZM9.99992 11.6666C7.77492 11.6666 3.33325',
  '12.7833 3.33325 14.9999V16.6666H16.6666V14.9999C16.6666 12.7833 12.2249 11.6666 9.99992 11.6666Z',
].join(' ');

export const IconMenuProfile = ({
  width = 20,
  height = 20,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
      />
    </svg>
  );
};
export default IconMenuProfile;
