import { UrlParam } from 'types/base';

export const Endpoint = {
  Account: (slug: UrlParam) => `/v1/accounts/landing/${slug}`,
  AccountMe: '/v1/accounts/me',
  Assets: '/v1/assets',
  ActiveAssetsSell: '/v1/assets?side=sell&isActive=true',
  ActiveAssets: '/v1/assets?isActive=true',
  Countries: '/v1/countries',
  Quotes: '/v1/quotes',
  PasswordlessStart: '/v1/auth/sign-in/passwordless/start',
  PasswordlessOtp: '/v1/auth/sign-in/passwordless/otp',
  Payments: {
    Config: '/v1/payments/config',
    Method: '/v1/payments/method',
  },
  PaymentMethods: '/v1/payment-methods',
  KycToken: '/v1/auth/kyc-token',
  UserInfo: '/v1/users/me',
  SavedCards: '/v1/users/cards',
  SavedCardById: (id: UrlParam) => `/v1/users/cards/${id}`,
  DefaultSavedCardById: (id: UrlParam) => `/v1/users/cards/${id}/default`,
  Orders: '/v1/orders',
  OrdersList: (page: UrlParam) => `/v1/orders?page=${page}`,
  PendingOrdersList: (page: UrlParam) => `/v1/orders/pending?page=${page}`,
  OrderByUuid: (uuid: UrlParam) => `/v1/orders/${uuid}`,
  OrderPaymentByUuid: (uuid: UrlParam) => `/v1/orders/${uuid}/payment`,
  OrderQuoteByUuid: (uuid: UrlParam) => `/v1/orders/${uuid}/quote`,
  OrderStepsStatusByUuid: (uuid: UrlParam) => `/v1/orders/${uuid}/status`,
  GooglePay: {
    PaymentMethod: '/v1/payments/google-pay/payment-method',
    Payment: 'v1/payments/google-pay/payment',
  },
};

export default Endpoint;
