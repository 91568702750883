export const KEYS = {
  QUOTE_DETAILS_EXPANDED_BY_DEFAULT: false,
  HIDE_ALERT_TIMEOUT: 5000,
  ALERT_TRANSITION_TIME: 300,
};

const MESSAGES_NAMESPACE = 'components.quoteDetails';
export const LABELS = {
  PROCESSING_FEE: `${MESSAGES_NAMESPACE}.processingFee`,
  NETWORK_FEE: `${MESSAGES_NAMESPACE}.networkFee`,
  SELL: `${MESSAGES_NAMESPACE}.sell`,
  RECEIVE: `${MESSAGES_NAMESPACE}.receive`,
  TOTAL: `${MESSAGES_NAMESPACE}.total`,
  ESTIMATE: `${MESSAGES_NAMESPACE}.estimate`,
  TOTAL_INCLUDING_FEES: `${MESSAGES_NAMESPACE}.totalIncludingFees`,
  PROCESSING_FEES_INFO: `${MESSAGES_NAMESPACE}.processingFeesInfo`,
};

export default KEYS;
