import { ImgHTMLAttributes } from 'react';

import useStyles from './styles';

interface LogoProps extends ImgHTMLAttributes<HTMLImageElement> {
  wrapperWidth?: number;
}

const Logo = ({ wrapperWidth, ...imgProps }: LogoProps) => {
  const { classes } = useStyles({ wrapperWidth });

  return (
    <div className={classes.logo}>
      <img alt={imgProps.alt} {...imgProps} />
    </div>
  );
};

export default Logo;
