import doesPathMatchCurrentPath from 'pages/route/utils';

export const PATHS = {
  DEFAULT_PATH: '/',
  MAIN_FLOW_PATH: '/',
  RECEIPT_PATH: '/orders/:orderId',
  CONTINUE_ORDER_PATH: '/orders/:orderId/continue',
  TERMS_OF_USE_PATH: '/terms-of-use',
  PRIVACY_POLICY_PATH: '/privacy-policy',
  PARTNER_PAGE_PATH: '/:any',
  OTHER_PATHS: '*',
  POP_STATE_EVENT: 'popstate',
  QUERY_START_SYMBOL: '?',
  PATH_VARIABLE_START_SYMBOL: ':',
  PATH_PARTS_SEPARATOR: '/',
};

export const isPartnerPage = () => {
  const slug = window.location.pathname.split('/')?.[1];

  const isExcludedPath = [
    PATHS.MAIN_FLOW_PATH,
    PATHS.TERMS_OF_USE_PATH,
    PATHS.PRIVACY_POLICY_PATH,
    PATHS.CONTINUE_ORDER_PATH,
  ].some(path => doesPathMatchCurrentPath(path, window.location.pathname));

  return slug && !isExcludedPath;
};

export const isFinishingOrder = () => doesPathMatchCurrentPath(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);

export default PATHS;
