import {
  ChangeEvent, Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, useFormik } from 'formik';

import OptionallyVisible from 'components/optionallyVisible';
import RefreshingAssetPrice from 'components/refreshingAssetPrice';
import isAmountUnlocked from 'config/amount';
import {
  CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION,
  FIAT_VALUE_DEFAULT_DECIMAL_PRECISION,
} from 'constants/defaults';
import { QuoteErrors } from 'services/quote/transactions/errors';
import { QuotePayload } from 'services/quote/transactions/payloads';
import {
  AssetWithLabel, CurrencyList, CurrencyType, Quote,
} from 'types/assets';
import ApiErrorCode from 'types/errors/api';
import { Flow } from 'types/flow';
import { Controller, ReduxToolkitApiCall } from 'types/http';
import { Order } from 'types/order';
import { formatCryptocurrency } from 'utils/currencies';
import debounce from 'utils/debounce';
import { formatFiatCurrencyI18n } from 'utils/i18n';
import truncateNumericValue from 'utils/number';
import { locators, QALocator } from 'utils/tests/QA';

import AssetInput, { AssetInputProps } from './assetInput';
import KEYS, { FormFields, LABELS } from './keys';
import useStyles from './styles';

interface QuoteFormProps {
  flow: Flow;
  order: Order;
  assets: {
    crypto: AssetWithLabel[];
    fiat: AssetWithLabel[];
    isLoading: boolean;
    isError: boolean;
    defaultCrypto?: AssetWithLabel;
    defaultFiat?: AssetWithLabel;
    defaultFiatAmount: string;
    defaultCryptoAmount: string;
  };
  quote: {
    details?: Quote;
    isLoading: boolean;
    isError: boolean;
    errors: {
      minAmount?: QuoteErrors[ApiErrorCode.InvalidAmount];
      maxAmount?: QuoteErrors[ApiErrorCode.InvalidAmount];
    };
  };
  setFiatAmount: Dispatch<SetStateAction<string | undefined>>;
  setCryptoAmount: Dispatch<SetStateAction<string | undefined>>;
  setChangedAmount: Dispatch<SetStateAction<CurrencyType>>;
  setFormErrors: Dispatch<SetStateAction<FormikErrors<{ crypto: string; fiat: string }>>>;
  quoteControllerRef: MutableRefObject<Controller | null>;
  fiatCurrency?: AssetWithLabel;
  setFiatCurrency: Dispatch<SetStateAction<AssetWithLabel | undefined>>;
  cryptocurrency?: AssetWithLabel;
  setCryptoCurrency: Dispatch<SetStateAction<AssetWithLabel | undefined>>;
  fetchQuote: ReduxToolkitApiCall<Quote>;
  openCurrenciesList: (list: CurrencyList) => () => void;
  makeFetchQuoteCall: (overrideQuotePayload?: Partial<QuotePayload>) => void;
}

export const QuoteForm = ({
  flow,
  order,
  assets,
  quote,
  setFiatAmount,
  setCryptoAmount,
  setFormErrors,
  setChangedAmount,
  quoteControllerRef,
  fiatCurrency,
  setFiatCurrency,
  cryptocurrency,
  setCryptoCurrency,
  fetchQuote,
  openCurrenciesList,
  makeFetchQuoteCall,
}: QuoteFormProps) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const formik = useFormik({
    initialValues: {
      [FormFields.Crypto]: order.cryptoAmount ?? assets.defaultCryptoAmount,
      [FormFields.Fiat]: order.fiatAmount ?? assets.defaultFiatAmount,
    },
    validate: values => ({
      [FormFields.Crypto]: validateCrypto(values),
      [FormFields.Fiat]: validateFiat(values),
    }),
    onSubmit: () => {},
  });
  const fiatAmount = formik.values.fiat;
  const cryptoAmount = formik.values.crypto;
  const isMinorQuoteInfoVisible = [
    !quote.isError,
    !quote.isLoading,
    fiatAmount,
    cryptoAmount,
    cryptocurrency,
    fiatCurrency,
    formik.isValid,
  ].every(Boolean);
  const hasAmountPreset = (order.isFiatAssetForced && order.isFiatAmountForced)
  || (order.isCryptoAssetForced && order.isCryptoAmountForced);
  const isAmountInputDisabled = hasAmountPreset && !isAmountUnlocked();

  useEffect(() => {
    if (!cryptocurrency) {
      setCryptoCurrency(assets.defaultCrypto);
    }
  }, [assets.defaultCrypto]);

  useEffect(() => {
    if (!fiatCurrency) {
      setFiatCurrency(assets.defaultFiat);
    }
  }, [assets.defaultFiat]);

  useEffect(() => {
    formik.setFieldError(CurrencyType.Fiat, undefined);
    formik.setFieldError(CurrencyType.Crypto, undefined);

    if (quote.errors?.maxAmount) {
      const formattedAmount = formatQuoteErrorAmount(quote.errors.maxAmount);
      const { meta } = quote.errors.maxAmount;

      const amountToSet = meta.requestAmount ?? '0';
      formik.setFieldValue(meta.currencyType, amountToSet, false);
      formik.setFieldError(
        meta.currencyType,
        t(
          LABELS.ERRORS_MAX_AMOUNT,
          {
            amount: formattedAmount,
          },
        ),
      );
    }

    if (quote.errors?.minAmount) {
      const formattedAmount = formatQuoteErrorAmount(quote.errors.minAmount);
      const { meta } = quote.errors.minAmount;

      const amountToSet = meta.requestAmount ?? '0';
      formik.setFieldValue(meta.currencyType, amountToSet, false);
      formik.setFieldError(
        meta.currencyType,
        t(
          LABELS.ERRORS_MIN_AMOUNT,
          {
            minAmount: formattedAmount,
          },
        ),
      );
    }
  }, [quote.errors]);

  useEffect(() => {
    const quoteData = quote.details;
    if (!quoteData?.cryptoAmount || !quoteData?.fiatAmount) {
      return;
    }

    const shouldValidateField = false;
    formik.setFieldValue(
      FormFields.Crypto,
      truncateNumericValue(quoteData.cryptoAmount, cryptocurrency?.displayDecimals ?? CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION),
      shouldValidateField,
    );
    formik.setFieldValue(
      FormFields.Fiat,
      truncateNumericValue(quoteData.fiatAmount, fiatCurrency?.displayDecimals ?? FIAT_VALUE_DEFAULT_DECIMAL_PRECISION),
      shouldValidateField,
    );
    formik.setErrors({});
  }, [quote]);

  useEffect(() => {
    setFiatAmount(formik.values[FormFields.Fiat]);
  }, [formik.values[FormFields.Fiat]]);

  useEffect(() => {
    setCryptoAmount(formik.values[FormFields.Crypto]);
  }, [formik.values[FormFields.Crypto]]);

  useEffect(() => {
    setFormErrors(formik.errors);
  }, [formik.errors]);

  const validateCrypto = (values: Record<FormFields.Crypto, string>) => {
    if (!cryptocurrency) {
      return false;
    }

    const isAmountLessThanMinimal = Number(values[FormFields.Crypto]) < Number(cryptocurrency.minAmount ?? 0);
    if (isAmountLessThanMinimal) {
      return t(
        LABELS.ERRORS_MIN_AMOUNT,
        {
          minAmount: formatCryptocurrency(cryptocurrency.symbol, cryptocurrency.minAmount),
        },
      );
    }
    return false;
  };

  const validateFiat = (values: Record<FormFields.Fiat, string>) => {
    if (!fiatCurrency) {
      return false;
    }

    const isAmountLessThanMinimal = Number(values[FormFields.Fiat]) < Number(fiatCurrency?.minAmount ?? 0);
    if (isAmountLessThanMinimal) {
      return t(
        LABELS.ERRORS_MIN_AMOUNT,
        {
          minAmount: formatFiatCurrencyI18n(i18n.language)(
            (Number(fiatCurrency?.minAmount ?? 0)),
            fiatCurrency,
          ),
        },
      );
    }
    return false;
  };

  const isLessThanMinimalValue = (isFiatAmount: boolean, value: string) => {
    if (isFiatAmount) {
      return Number(value) < Number(fiatCurrency?.minAmount ?? 0);
    }

    return Number(value) < Number(cryptocurrency?.minAmount ?? 0);
  };

  const [getQuoteDebounced] = debounce((payload: QuotePayload) => {
    const controller = fetchQuote(payload);
    quoteControllerRef.current = controller;
  }, KEYS.INPUT_DEBOUNCE_TIMEOUT);

  const debouncedChangeHandler = useCallback((payload: QuotePayload) => {
    getQuoteDebounced(payload);
  }, [KEYS.INPUT_DEBOUNCE_TIMEOUT]);

  const countDecimals = (value: number) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  const onAmountChange = (isFiatAmount: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const allowedNumberOfDecimals = isFiatAmount
      ? fiatCurrency?.displayDecimals ?? FIAT_VALUE_DEFAULT_DECIMAL_PRECISION
      : cryptocurrency?.displayDecimals ?? CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION;

    const numberOfDecimals = countDecimals(Number(e.target.value));
    if (numberOfDecimals > allowedNumberOfDecimals) {
      value = truncateNumericValue(e.target.value, allowedNumberOfDecimals);
    }

    quoteControllerRef.current?.abort();
    formik.setErrors({
      [FormFields.Crypto]: undefined,
      [FormFields.Fiat]: undefined,
    });
    setChangedAmount(isFiatAmount ? CurrencyType.Fiat : CurrencyType.Crypto);

    if (!cryptocurrency || !fiatCurrency) {
      return;
    }

    const quoteCallDirection: Partial<QuotePayload> = isFiatAmount
      ? { fiatAmount: value }
      : { cryptoAmount: value };

    const isLessThanMinimalAmount = isLessThanMinimalValue(isFiatAmount, value);
    const hasValuesForQuoteCall = !isLessThanMinimalAmount && value;

    if (hasValuesForQuoteCall) {
      debouncedChangeHandler({
        cryptoCurrencyCode: cryptocurrency.symbol,
        fiatCurrencyCode: fiatCurrency.symbol,
        ...quoteCallDirection,
      });
    }

    const fieldToChange = isFiatAmount ? FormFields.Fiat : FormFields.Crypto;
    const shouldValidate = true;
    formik.setFieldValue(fieldToChange, value.toString(), shouldValidate);
  };

  const formatQuoteErrorAmount = (error?: QuoteErrors[ApiErrorCode.InvalidAmount]) => {
    const currency = error?.meta.currencyType === CurrencyType.Fiat
      ? fiatCurrency
      : cryptocurrency;

    const amountToDisplay = error?.meta.maxAmount ?? error?.meta.minAmount ?? 0;
    if (!currency) {
      return amountToDisplay;
    }

    const formattedAmount = error?.meta.currencyType === CurrencyType.Fiat
      ? formatFiatCurrencyI18n(i18n.language)(Number(amountToDisplay), currency)
      : formatCryptocurrency(currency.symbol, amountToDisplay);

    return formattedAmount;
  };

  const assetInputsMeta: Record<Flow, AssetInputProps[]> = {
    [Flow.Buy]: [{
      asset: fiatCurrency,
      label: t(LABELS.YOU_PAY),
      placeholder: t(LABELS.AMOUNT_TO_PAY),
      buttonProps: {
        className: classes.currencyButton,
        disabled: order.isFiatAssetForced,
        onClick: openCurrenciesList(CurrencyList.Fiat),
        ...QALocator(locators.page.quote.fiatAssetButton),
      },
      value: formik.values.fiat,
      onAmountChange: onAmountChange(true),
      error: formik.errors.fiat,
      lang: i18n.language,
      disabled: isAmountInputDisabled,
      inputProps: {
        slotProps: {
          input: {
            ...QALocator(locators.page.quote.fiatInput),
          },
        },
      },
    },
    {
      asset: cryptocurrency,
      label: t(LABELS.YOU_RECEIVE_ESTIMATE),
      placeholder: t(LABELS.RECEIVED_CRYPTO),
      buttonProps: {
        className: classes.currencyButton,
        disabled: order.isCryptoAssetForced,
        onClick: openCurrenciesList(CurrencyList.Crypto),
        ...QALocator(locators.page.quote.cryptoAssetButton),
      },
      value: formik.values.crypto,
      onAmountChange: onAmountChange(false),
      error: formik.errors.crypto,
      disabled: isAmountInputDisabled,
      inputProps: {
        slotProps: {
          input: {
            ...QALocator(locators.page.quote.cryptoInput),
          },
        },
      },
    }],
    [Flow.Sell]: [{
      asset: cryptocurrency,
      label: t(LABELS.YOU_PAY),
      placeholder: t(LABELS.AMOUNT_TO_PAY),
      buttonProps: {
        className: classes.currencyButton,
        disabled: order.isCryptoAssetForced,
        onClick: openCurrenciesList(CurrencyList.Crypto),
        ...QALocator(locators.page.quote.cryptoAssetButton),
      },
      value: formik.values.crypto,
      onAmountChange: onAmountChange(false),
      error: formik.errors.crypto,
      disabled: isAmountInputDisabled,
      inputProps: {
        slotProps: {
          input: {
            ...QALocator(locators.page.quote.cryptoInput),
          },
        },
      },
    },
    {
      asset: fiatCurrency,
      label: t(LABELS.YOU_RECEIVE_ESTIMATE),
      placeholder: t(LABELS.FIAT_YOU_GET),
      buttonProps: {
        className: classes.currencyButton,
        disabled: order.isFiatAssetForced,
        onClick: openCurrenciesList(CurrencyList.Fiat),
        ...QALocator(locators.page.quote.fiatAssetButton),
      },
      value: formik.values.fiat,
      onAmountChange: onAmountChange(true),
      error: formik.errors.fiat,
      lang: i18n.language,
      disabled: isAmountInputDisabled,
      inputProps: {
        slotProps: {
          input: {
            ...QALocator(locators.page.quote.fiatInput),
          },
        },
      },
    }],
  };

  return (
    <>
      {/* eslint-disable-next-line react/prop-types */}
      {assetInputsMeta[flow].map(props => <AssetInput key={props.label} {...props} />)}
      <div className={classes.formItem}>
        <div className={classes.quoteMinorInfo}>
          <OptionallyVisible visible={isMinorQuoteInfoVisible}>
            <RefreshingAssetPrice
              reFetchIntervalInSeconds={KEYS.REFETCH_INTERVAL_IN_SECONDS}
              isError={quote.isError}
              isLoading={quote.isLoading}
              cryptocurrency={cryptocurrency!}
              fiatCurrency={fiatCurrency!}
              fetchQuote={makeFetchQuoteCall}
              price={quote.details?.cryptoPrice}
            />
          </OptionallyVisible>
          <OptionallyVisible visible={!quote.isError && quote.isLoading}>
            <span {...QALocator(locators.page.quote.refreshingQuoteInfo)}>
              {t(LABELS.REFRESHING)}
            </span>
          </OptionallyVisible>
        </div>
      </div>
    </>
  );
};

export default QuoteForm;
