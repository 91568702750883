import Spinner from 'components/spinner';
import IconCheckCircle from 'icons/checkCircle';
import IconCloseCircle from 'icons/closeCircle';
import { TransactionStepUIStatus } from 'types/transactions';
import { locators, QALocator } from 'utils/tests/QA';

import { useStyles } from './transactionProcessingStep.styles';
import { TransactionProcessingStepProps } from './types';

const uiStatusToIconMapping = {
  [TransactionStepUIStatus.Error]: <IconCloseCircle
    width={24}
    height={24}
    {...QALocator(locators.page.transactionProcessing.errorIcon)}
  />,
  [TransactionStepUIStatus.ErrorDisabled]: <IconCloseCircle
    width={14}
    height={14}
    disabled
    {...QALocator(locators.page.transactionProcessing.errorDisabledIcon)}
  />,
  [TransactionStepUIStatus.Completed]: <IconCheckCircle {...QALocator(locators.page.transactionProcessing.completedIcon)} />,
  [TransactionStepUIStatus.CompletedDisabled]: <IconCheckCircle
    disabled
    width={14}
    height={14}
    {...QALocator(locators.page.transactionProcessing.completedDisabledIcon)}
  />,
};

const TransactionProcessingStep = ({
  label,
  uiStatus,
  ...otherProps
}: TransactionProcessingStepProps) => {
  const { classes, theme, cx } = useStyles();
  const isDisabled = [TransactionStepUIStatus.CompletedDisabled, TransactionStepUIStatus.ErrorDisabled].includes(uiStatus);

  const renderIconOrSpinner = () => {
    if (uiStatus === TransactionStepUIStatus.InProgress) {
      return <Spinner color={theme.colors.spinner.accentDark} {...QALocator(locators.page.transactionProcessing.stuckWithdrawalSpinner)} />;
    }

    const iconForDefaultStatus = <IconCheckCircle isOutline />;
    const icon = uiStatusToIconMapping[uiStatus as keyof typeof uiStatusToIconMapping];

    return icon ?? iconForDefaultStatus;
  };

  return (
    <div className={classes.stepItem} {...QALocator(otherProps['data-cy'])}>
      <div className={cx(classes.stepItemIcon, { [classes.stepItemIconDisabled]: isDisabled })}>{renderIconOrSpinner()}</div>
      <div className={cx(classes.stepItemLabel, { [classes.stepItemLabelDisabled]: isDisabled })}>{label}</div>
    </div>
  );
};

export default TransactionProcessingStep;
