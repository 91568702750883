export const KEYS = {
  ABORT_ERROR_NAME: 'AbortError',
  PASSWORDLESS_OTP_CACHE_KEY: 'otp',
  OTP_EXPECTED_LENGTH: 6,
};

export enum FormFields {
  Otp = 'otp',
}

const MESSAGES_NAMESPACE = 'otp';
export const LABELS = {
  ERRORS_RATE: `${MESSAGES_NAMESPACE}.errorsRate`,
  ERRORS_CODE: `${MESSAGES_NAMESPACE}.errorsCode`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  VERIFICATION_CODE_NOTE: `${MESSAGES_NAMESPACE}.verificationCodeNote`,
  CONTINUE: `${MESSAGES_NAMESPACE}.continue`,
  DID_NOT_RECEIVE: `${MESSAGES_NAMESPACE}.didNotReceive`,
  RESEND_CODE: `${MESSAGES_NAMESPACE}.resendCode`,
  RESEND_CODE_HELP_TEXT: `${MESSAGES_NAMESPACE}.resendCodeHelpText`,

};

export default KEYS;
