export const KEYS = {
  EMAIL_INPUT: 'email',
};

export enum FormFields {
  Email = 'email',
}

const MESSAGES_NAMESPACE = 'signIn';
export const LABELS = {
  YOUR_EMAIL: `${MESSAGES_NAMESPACE}.yourEmail`,
  EMAIL_PLACEHOLDER: `${MESSAGES_NAMESPACE}.emailPlaceholder`,
  REQUIRED: `${MESSAGES_NAMESPACE}.required`,
  EMAIL_REGEX_ERROR: `${MESSAGES_NAMESPACE}.emailRegexError`,
  HEADER: `${MESSAGES_NAMESPACE}.header`,
  LOGIN_TEXT: `${MESSAGES_NAMESPACE}.logInText`,
  LOGIN: `${MESSAGES_NAMESPACE}.login`,
};

export default KEYS;
