import { useDispatch, useSelector } from 'react-redux';

import useNavigation from 'hooks/useNavigation';
import {
  selectCryptoAsset,
  selectOrder,
  setTagOrMemo,
  setWalletAddress,
} from 'state/slices/orderSlice';

import WalletScreen from '.';
import KEYS from './keys';

const WalletScreenContainer = () => {
  const { goBack, proceed, canGoBack } = useNavigation();

  const dispatch = useDispatch();

  const selectedCryptoAsset = useSelector(selectCryptoAsset);
  const order = useSelector(selectOrder);

  const onContinue = (walletAddress: string, tagOrMemo: string) => {
    dispatch(setWalletAddress(walletAddress));
    dispatch(setTagOrMemo(tagOrMemo));

    proceed();
  };

  return (
    <WalletScreen
      selectedCryptoAsset={selectedCryptoAsset}
      initialWalletAddress={order.walletAddress ?? window.s4cConfig.prefilledValues?.wallet ?? ''}
      initialTagOrMemo={order.tagOrMemo ?? ''}
      cryptoNetworkName={order.selectedCryptoAsset?.name ?? ''}
      shouldShowDestinationTag={selectedCryptoAsset?.tagName === KEYS.DESTINATION_TAG_API_FIELD_NAME}
      shouldShowMemo={selectedCryptoAsset?.tagName === KEYS.MEMO_NAME_API_FIELD}
      proceed={onContinue}
      goBack={goBack}
      canGoBack={canGoBack}
    />
  );
};

export default WalletScreenContainer;
