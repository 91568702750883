import { CSSInterpolation } from 'tss-react';

export const globalStyles: CSSInterpolation = {
  body: {
    '.grecaptcha-badge': {
      visibility: 'hidden',
    },
  },
};

export default globalStyles;
