import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';
import Spinner from 'components/spinner';
import CONTACT_DATA from 'constants/contactData';
import ApiErrorCode from 'types/errors/api';
import { locators, QALocator } from 'utils/tests/QA';

import getErrorCodeMessage from './errors';
import { LABELS } from './keys';
import { useStyles } from './resendOtpBlock.styles';

export interface ResendOtpBlockProps {
  onButtonClick: () => void;
  isLoading: boolean;
  errorType?: ApiErrorCode;
}

const ResendOtpBlock = ({
  onButtonClick,
  isLoading,
  errorType,
}: ResendOtpBlockProps) => {
  const { theme, classes, cx } = useStyles();
  const { t } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleResendCodeButtonClick = () => {
    setButtonClicked(true);
    onButtonClick();
  };

  return (
    <div className={classes.resendOtpContainer}>

      <div className={classes.buttonBlock}>

        <div className={classes.text}>
          {t(LABELS.DID_NOT_RECEIVE)}
        </div>

        <button
          type="button"
          className={cx(classes.resendCodeButton, {
            [classes.buttonDisabled]: isLoading,
          })}
          onClick={handleResendCodeButtonClick}
          disabled={isLoading}
          {...QALocator(locators.page.otp.resendOtpButton)}
        >
          {t(LABELS.RESEND_CODE)}
        </button>

        <div className={classes.loaderBlock}>
          <OptionallyVisible visible={isLoading}>
            <Spinner color={theme.colors.spinner.inactive} scale={0.8} />
          </OptionallyVisible>
        </div>
      </div>

      <OptionallyVisible visible={Boolean(errorType)}>
        <div className={classes.errorMessage} {...QALocator(locators.page.otp.resendOtpError)}>
          {getErrorCodeMessage(errorType! as ApiErrorCode.RateLimit)}
        </div>
      </OptionallyVisible>

      <OptionallyVisible visible={buttonClicked}>
        <div className={classes.text} {...QALocator(locators.page.otp.resendOtpHelpText)}>
          <Trans
            i18nKey={LABELS.RESEND_CODE_HELP_TEXT}
            components={{
              a: <a href={`mailto:${CONTACT_DATA.SUPPORT_EMAIL_ADDRESS}`}>email</a>,
            }}
            values={{ email: CONTACT_DATA.SUPPORT_EMAIL_ADDRESS }}
          />
        </div>
      </OptionallyVisible>

    </div>
  );
};

export default ResendOtpBlock;
