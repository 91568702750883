import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .create(
    ({ theme }) => ({
      disclaimerWrapper: {
        color: theme.colors.text.inactive,
        width: '100%',
        textAlign: 'center',
        marginBottom: theme.scale(5),
        ...fontSizeMixin(FontSize.XXS),
      },
    }),
  );

export default useStyles;
