import { useTranslation } from 'react-i18next';

import IconCheckMark from 'icons/checkMark';
import IconCross from 'icons/cross';
import IconMenuPendingOrders from 'icons/menuPendingOrders';
import IconProcessing from 'icons/processing';
import { OrderSide, OrderStatus } from 'types/order';
import checkIfOrderStatusIsPending from 'utils/order';

import LABELS from '../keys';
import { useStyles } from './styles';

export interface StatusTypeBadgeProps {
  side: OrderSide;
  status: OrderStatus;
}

export const StatusTypeBadge = ({ side, status }: StatusTypeBadgeProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const isPendingStatus = checkIfOrderStatusIsPending(status);

  const isCompleted = status === OrderStatus.Completed;
  const isProcessing = status === OrderStatus.Processing;
  const isPending = side === OrderSide.Sell && isPendingStatus;

  const getIcon = () => {
    if (isCompleted) {
      return <IconCheckMark width={12} height={12} color="#00850D" />;
    }
    if (isProcessing) {
      return <IconProcessing width={12} height={12} color="#344054" />;
    }
    if (isPending) {
      return <IconMenuPendingOrders width={12} height={12} color="#A95100" />;
    }
    return <IconCross width={12} height={12} color="#DC0030" />;
  };

  const content = t(LABELS[status as keyof typeof LABELS] || LABELS.GENERAL_STATUS_MESSAGE);

  return (
    <div className={cx({
      [classes.statusTypeBadge]: true,
      [classes.statusTypeBadgeCompleted]: isCompleted,
      [classes.statusTypeBadgeProcessing]: isProcessing,
      [classes.statusTypeBadgePending]: isPending,
    })}
    >
      {getIcon()}
      <span>{content}</span>
    </div>
  );
};
