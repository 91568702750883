import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { selectDefaultCardCacheEntry } from 'services/payments';
import { SavedCard } from 'services/payments/types';
import { RootState } from 'state/store';
import { CardPaymentToken } from 'types/card';
import PaymentMethod from 'types/payment';

const SLICE_NAME = 'payment';

interface PaymentState {
  paymentMethod: PaymentMethod | null;
  tokenizedCard: CardPaymentToken | SavedCard | null;
  hasCardAuthorizationError: boolean;
  cardAuthorizationErrorMessage?: string;
  rememberCard: boolean;
  setCardAsDefault: boolean;
}

interface SetTokenizedCardActionPayload {
  tokenizedCard: CardPaymentToken | SavedCard;
  rememberCard?: boolean;
  setCardAsDefault?: boolean;
}

export const initialState: PaymentState = {
  tokenizedCard: null,
  rememberCard: false,
  setCardAsDefault: false,
  hasCardAuthorizationError: false,
  paymentMethod: null,
};

export const stepSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setTokenizedCard: (state, action: PayloadAction<SetTokenizedCardActionPayload | null>) => {
      if (action.payload) {
        state.tokenizedCard = action.payload.tokenizedCard;
        if (action.payload.rememberCard) {
          state.rememberCard = action.payload.rememberCard;
        }
        if (action.payload.setCardAsDefault) {
          state.setCardAsDefault = action.payload.setCardAsDefault;
        }
        return;
      }
      state.tokenizedCard = null;
      state.rememberCard = false;
    },
    setHasCardAuthorizationError: (state, action: PayloadAction<boolean>) => {
      state.hasCardAuthorizationError = action.payload;
    },
    setCardAuthorizationErrorMessage: (state, action: PayloadAction<string>) => {
      state.cardAuthorizationErrorMessage = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
    },
  },
});

export const {
  setTokenizedCard, setHasCardAuthorizationError, setCardAuthorizationErrorMessage, setPaymentMethod,
} = stepSlice.actions;

export const selectTokenizedCard = (state: RootState) => state.payment.tokenizedCard
  ?? ((selectDefaultCardCacheEntry(state) ?? null) as CardPaymentToken | null);
export const selectRememberCard = (state: RootState) => state.payment.rememberCard;
export const selectIsDefaultCard = (state: RootState) => state.payment.setCardAsDefault;
export const selectHasCardAuthorizationError = (state: RootState) => state.payment.hasCardAuthorizationError;
export const selectCardAuthorizationErrorMessage = (state: RootState) => state.payment.cardAuthorizationErrorMessage;
export const selectPaymentMethod = (state: RootState) => state.payment.paymentMethod;

export default stepSlice.reducer;
