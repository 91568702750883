import { tss } from 'theme';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.scale(8),
      flexShrink: 0,
      flexGrow: 0,
      position: 'relative',
    },
    input: {
      display: 'block',
      height: theme.scale(18),
      width: theme.scale(18),
      position: 'absolute',
      overflow: 'hidden',
      left: theme.scale(-4),
      border: 'none !important',
      borderRadius: 2.5,
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(100%)',
      '&:focus + span': {
        outline: `2px solid ${theme.colors.icons.accentDark}`,
        outlineOffset: 1.5,
      },
    },
    fakeCheckbox: {
      position: 'relative',
      flexShrink: 0,
      height: theme.scale(18),
      width: theme.scale(18),
      border: `2px solid ${theme.colors.icons.inactive}`,
      display: 'inline-block',
      cursor: 'pointer',
      borderRadius: 2.5,
    },
    checkmark: {
      position: 'absolute',
      left: theme.scale(-5),
      top: theme.scale(-5),
    },
    text: {
      display: 'inline',
      cursor: 'pointer',
      userSelect: 'none',
    },
  }),
);

export default useStyles;
