const normalizeUrl = (url: string): string => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const checkIfRedirectUrlAllowed = (redirectUrl: string | null): boolean => {
  const allowedDomain = window.partnerConfig?.redirectDomain;

  if (!allowedDomain || !redirectUrl) {
    return false;
  }

  const allowedDomainUrl = new URL(normalizeUrl(allowedDomain));
  const redirectUrlParsed = new URL(normalizeUrl(redirectUrl));

  return allowedDomainUrl.hostname === redirectUrlParsed.hostname;
};

const getRedirectUrl = (redirectUrl: string | null): string | undefined => {
  if (!redirectUrl) {
    return undefined;
  }

  return checkIfRedirectUrlAllowed(redirectUrl) ? normalizeUrl(redirectUrl) : undefined;
};

export default getRedirectUrl;
