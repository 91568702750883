import GPButton from '@google-pay/button-react';

import isDarkMode from 'theme/darkMode';
import { appConfig } from 'utils/local';
import Logger from 'utils/logger';

import Spinner from '../../spinner';
import useStyles from './styles';
import {
  GooglePayPaymentData, GooglePayPaymentRequest, GooglePayTransactionInfo, MerchantInfo,
} from './types';

export interface GooglePayButtonProps {
  transactionData?: GooglePayTransactionInfo;
  merchantData: MerchantInfo;
  onPay: (transactionData: {
    fiatAmount: string;
    fiatCurrencyCode: string;
  }, token: string) => void;
  disabled?: boolean;
  isLoading?: boolean;
  onReadyToPayChange?: (result: any) => void;
  paymentRequest?: GooglePayPaymentRequest;
}

export const GooglePayButton = ({
  transactionData,
  merchantData,
  onPay,
  onReadyToPayChange,
  disabled,
  isLoading,
  paymentRequest,
}: GooglePayButtonProps) => {
  const { environment } = appConfig.googlePay;
  const { classes } = useStyles();
  const shouldShowWhiteButton = isDarkMode();

  const notReadyToPay = !paymentRequest || !transactionData || isLoading;
  if (notReadyToPay) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }

  const handleLoadPaymentData = async (paymentData: GooglePayPaymentData) => {
    onPay({
      fiatAmount: transactionData.totalPrice,
      fiatCurrencyCode: transactionData.currencyCode,
    }, paymentData.paymentMethodData.tokenizationData.token);
  };

  const handleError = (...args: any[]) => {
    Logger.error('Google Pay error: ', ...args);
  };

  const handleClick = (event: Event) => {
    if (disabled) {
      event.preventDefault();
    }
  };

  return (
    <GPButton
      environment={environment}
      paymentRequest={paymentRequest}
      onLoadPaymentData={handleLoadPaymentData}
      buttonType="long"
      onError={handleError}
      className={classes.googlePayButton}
      onClick={handleClick}
      onReadyToPayChange={onReadyToPayChange}
      buttonColor={shouldShowWhiteButton ? 'white' : 'default'}
    />
  );
};

export default GooglePayButton;
