import { createSelector } from '@reduxjs/toolkit';

import { DEFAULT_CRYPTO_AMOUNT, DEFAULT_FIAT_AMOUNT } from 'constants/defaults';
import { assetsApi } from 'services/assets';
import transactionApi from 'services/transaction';
import { CurrencyType } from 'types/assets';
import Flow from 'types/flow';
import { TransactionId } from 'types/transactions';
import { addLabelsToCryptoAssets, addLabelsToFiatAssets } from 'utils/currencies';

import { RootState } from '../../store';
import { getFallbackCryptoAsset, getFallbackFiatAsset } from './fallbackAsset';
import { filterCryptoAssets, filterFiatAssets } from './filterAssets';
import assignGroups from './groupAssets';

export const selectAssetsSelectorInput = (state: RootState) => {
  const assets = {
    [Flow.Buy]: assetsApi.endpoints.getActiveAssets.select()(state),
    [Flow.Sell]: assetsApi.endpoints.getActiveAssetsSell.select()(state),
  }[state.flow.flow];

  const exchangeDirectionMapping: Record<Flow, CurrencyType> = {
    [Flow.Buy]: CurrencyType.Fiat,
    [Flow.Sell]: CurrencyType.Fiat,
  };
  const defaultDirection = exchangeDirectionMapping[state.flow.flow];

  const { order } = state.order;

  const dataAssets = assets.data?.data.assets || [];
  const cryptoAssets = dataAssets.filter(asset => asset.type === CurrencyType.Crypto);
  const fiatAssets = dataAssets.filter(asset => asset.type === CurrencyType.Fiat);

  const cryptoAssetsWithLabels = addLabelsToCryptoAssets(cryptoAssets);
  const fiatAssetsWithLabels = addLabelsToFiatAssets(fiatAssets);

  const filteredCryptoAssets = filterCryptoAssets(cryptoAssetsWithLabels);
  const filteredFiatAssets = filterFiatAssets(fiatAssetsWithLabels);

  const grouppedCrypto = assignGroups(filteredCryptoAssets);

  const orderCryptoCurrency = cryptoAssetsWithLabels.find(crypto => order.selectedCryptoAsset?.symbol === crypto.symbol);
  const orderFiatCurrency = fiatAssetsWithLabels.find(fiat => order.selectedFiatAsset?.symbol === fiat.symbol);

  const fallbackDefaultCryptoCurrency = getFallbackCryptoAsset(cryptoAssetsWithLabels, filteredCryptoAssets);
  const fallbackDefaultFiatCurrency = getFallbackFiatAsset(cryptoAssetsWithLabels, filteredFiatAssets);

  const defaultFiatCurrency = orderFiatCurrency ?? fallbackDefaultFiatCurrency;
  const defaultFiatAmount = order.fiatAmount ?? defaultFiatCurrency?.defaultAmount ?? DEFAULT_FIAT_AMOUNT;

  const partnerDefaultCrypto = filteredCryptoAssets.find(asset => asset.symbol === window.partnerConfig?.defaultCryptoCurrencyCode);

  return {
    crypto: grouppedCrypto,
    fiat: filteredFiatAssets,
    isLoading: assets.isLoading,
    isError: assets.isError,
    error: assets.error,
    defaultCrypto: orderCryptoCurrency ?? partnerDefaultCrypto ?? fallbackDefaultCryptoCurrency,
    defaultFiat: orderFiatCurrency ?? fallbackDefaultFiatCurrency,
    defaultFiatAmount: defaultFiatAmount.toString(),
    defaultCryptoAmount: order.cryptoAmount ?? DEFAULT_CRYPTO_AMOUNT,
    direction: order.exchangeDirection ?? defaultDirection,
  };
};

const getOrderAssets = (state: RootState, transactionId: TransactionId) => {
  const assets = assetsApi.endpoints.getAssets.select()(state);
  const order = transactionApi.endpoints.getTransaction.select({
    transactionId,
    includeToken: true,
  })(state);

  const dataAssets = assets.data?.data.assets || [];
  const cryptoAssets = dataAssets.filter(asset => asset.type === CurrencyType.Crypto);
  const fiatAssets = dataAssets.filter(asset => asset.type === CurrencyType.Fiat);

  const cryptoAssetsWithLabels = addLabelsToCryptoAssets(cryptoAssets);
  const fiatAssetsWithLabels = addLabelsToFiatAssets(fiatAssets);

  const crypto = cryptoAssetsWithLabels.find(crypto => order.data?.data.order.cryptoCurrencyCode === crypto.symbol);
  const fiat = fiatAssetsWithLabels.find(fiat => order.data?.data.order.fiatCurrencyCode === fiat.symbol);

  return {
    [CurrencyType.Crypto]: crypto,
    [CurrencyType.Fiat]: fiat,
  };
};

export const selectAssets = createSelector([(state: RootState) => state], selectAssetsSelectorInput);
export const selectOrderAssets = createSelector(
  [
    (state: RootState) => state,
    (_: RootState, transactionId: string) => transactionId,
  ],
  getOrderAssets,
);

export default selectAssets;
