import { KeyboardEvent, KeyboardEventHandler, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';

import LoadingWrapper from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import AutoCloseAlert from 'components/quoteDetails/alertBox';
import Spinner from 'components/spinner';
import IconChevron from 'icons/chevron';
import { AssetWithLabel, Quote } from 'types/assets';
import WorkflowStep from 'types/step';
import getCurrencySymbol from 'utils/currencies';
import { formatFiatCurrencyI18n, formatFiatCurrencyI18nWithoutSymbol } from 'utils/i18n';

import IconInfo from '../../icons/info';
import KEYS, { LABELS } from './keys';
import useStyles from './styles';

interface QuoteDetailsProps {
  quote: {
    details?: Quote;
    isLoading: boolean;
    isError: boolean;
  };
  cryptocurrency: AssetWithLabel;
  fiatCurrency: AssetWithLabel;
  step: WorkflowStep;
}

const QuoteDetails = ({
  quote,
  cryptocurrency,
  fiatCurrency,
  step,
}: QuoteDetailsProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(KEYS.QUOTE_DETAILS_EXPANDED_BY_DEFAULT);
  const [hasShownAlert, setHasShownAlert] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [hasExitedAlert, setHasExitedAlert] = useState(false);
  const [applyBorder, setApplyBorder] = useState(!isDropdownOpened);
  const { classes, cx, theme } = useStyles({ isOpened: isDropdownOpened, applyBorder });
  const { t, i18n } = useTranslation();
  const currencySymbol = getCurrencySymbol(i18n.language)(fiatCurrency.symbol);
  const currencyWithoutSymbolFormatter = formatFiatCurrencyI18nWithoutSymbol(i18n.language);
  const shouldShowInfoIcon = step === WorkflowStep.Quote;

  const toggle = () => {
    if (!isDropdownOpened) {
      setApplyBorder(false);
    }
    setIsDropdownOpened(!isDropdownOpened);
  };

  const onClose = () => {
    setApplyBorder(true);
  };

  const onAlertExit = () => {
    setHasExitedAlert(true);
  };

  const handleInfoClick = () => {
    setAlertOpen(true);
    setHasShownAlert(true);
    setHasExitedAlert(false);
  };

  const onCloseAlertClick = () => {
    setAlertOpen(false);
  };

  const onCloseAlertAndDropdownClick = () => {
    setAlertOpen(false);
    toggle();
  };

  const onAlertKeyDown : KeyboardEventHandler<HTMLSpanElement> = (event: KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      handleInfoClick();
    }
  };

  return (
    <Collapsible
      open={isDropdownOpened}
      tabIndex={0}
      handleTriggerClick={toggle}
      onClose={onClose}
      trigger={(
        <div className={classes.collapsibleHeader}>
          <div className={classes.collapsibleHeaderText}>
            <LoadingWrapper
              isLoading={quote.isLoading}
              loadingIndicator={(
                <Spinner
                  className={cx(classes.inlineSpinner, classes.marginRight)}
                  color={theme.colors.spinner.inactive}
                  scale={0.7}
                />
                )}
            >
              <strong className={classes.total}>
                {formatFiatCurrencyI18n(i18n.language)(Number(quote.details?.fiatAmount ?? 0), fiatCurrency)}
              </strong>
            </LoadingWrapper>
            {' '}
            {t(LABELS.TOTAL_INCLUDING_FEES)}
          </div>
          <div className={classes.expandButton}>
            <IconChevron className={classes.icon} color={theme.colors.icons.accentDark} />
          </div>
        </div>
      )}
    >
      <div className={classes.gridWrapper}>
        <div className={classes.separator} />
        <div className={classes.grid}>
          <div className={classes.processingRow}>
            {t(LABELS.PROCESSING_FEE)}
            {' '}
            <OptionallyVisible visible={shouldShowInfoIcon}>
              <span
                role="button"
                tabIndex={0}
                onClick={handleInfoClick}
                onKeyDown={onAlertKeyDown}
              >
                <IconInfo
                  width={14}
                  height={14}
                  className={classes.infoIcon}
                />
              </span>
            </OptionallyVisible>
          </div>
          <div>{currencySymbol}</div>
          <div className={classes.alignRight}>
            <LoadingWrapper
              isLoading={quote.isLoading}
              loadingIndicator={(
                <Spinner
                  className={classes.inlineSpinner}
                  color={theme.colors.spinner.inactive}
                  scale={0.6}
                />
            )}
            >
              <div>
                {currencyWithoutSymbolFormatter(Number(quote.details?.processingFeeFiatAmount ?? 0), fiatCurrency)}
              </div>
            </LoadingWrapper>
          </div>
          <OptionallyVisible visible={!hasShownAlert || (!alertOpen && hasExitedAlert)}>
            <>
              <div>{t(LABELS.NETWORK_FEE)}</div>
              <div>{currencySymbol}</div>
              <div className={classes.alignRight}>
                <LoadingWrapper
                  isLoading={quote.isLoading}
                  loadingIndicator={(
                    <Spinner
                      className={classes.inlineSpinner}
                      color={theme.colors.spinner.inactive}
                      scale={0.6}
                    />
            )}
                >
                  <div>
                    {currencyWithoutSymbolFormatter(Number(quote.details?.networkFeeFiatAmount ?? 0), fiatCurrency)}
                  </div>
                </LoadingWrapper>
              </div>
              <div>{cryptocurrency?.symbol}</div>
              <div>{currencySymbol}</div>
              <div className={classes.alignRight}>
                <LoadingWrapper
                  isLoading={quote.isLoading}
                  loadingIndicator={(
                    <Spinner
                      className={classes.inlineSpinner}
                      color={theme.colors.spinner.inactive}
                      scale={0.6}
                    />
            )}
                >
                  <div>
                    {currencyWithoutSymbolFormatter(Number(quote.details?.cryptoCostFiatAmount ?? 0), fiatCurrency)}
                  </div>
                </LoadingWrapper>
              </div>
            </>
          </OptionallyVisible>

        </div>

        <AutoCloseAlert
          closeAlertAndDropdown={onCloseAlertAndDropdownClick}
          closeAlert={onCloseAlertClick}
          isVisible={alertOpen}
          onExited={onAlertExit}
        >
          {t(LABELS.PROCESSING_FEES_INFO)}
        </AutoCloseAlert>

      </div>
    </Collapsible>
  );
};

export default QuoteDetails;
