import { createTss } from 'tss-react';

import { HexColor } from 'types/color';
import Color from 'utils/colors/color';

import getBorderRadius from './borders';
import { Breakpoint, breakpointValues } from './breakpoints';
import { isDarkMode } from './darkMode';
import getScaleRatio from './ratio';

interface ThemeSchema {
  primary: Color;
  background: Color;
  secondary: Color;
  highlight: Color;
  darkAccent: Color;
  darkAccentOpposite: Color;
  error: Color;
  inactive: Color;
  text: Color;
  textOpposite: Color;
}

interface IllustrationsThemeSchema {
  saturation51: Color;
  saturation65: Color;
  saturation86: Color;
  saturation95: Color;
  saturation98: Color;
}

const DEFAULT_THEME_COLOR = '#2253FF';

const getIllustrationsSchema = (color: HexColor): IllustrationsThemeSchema => {
  if (isDarkMode()) {
    return ({
      saturation51: new Color('#818181').darkModeIllustrationColor().replaceHueAndSaturationForIllustration(color, 100),
      saturation65: new Color('#A5A5A5').darkModeIllustrationColor().replaceHueAndSaturationForIllustration(color, 100),
      saturation86: new Color('#DBDBDB').darkModeIllustrationColor().replaceHueAndSaturationForIllustration(color, 100),
      saturation95: new Color('#F3F3F3').darkModeIllustrationColor().replaceHueAndSaturationForIllustration(color, 100),
      saturation98: new Color('#F9F9F9').darkModeIllustrationColor().replaceHueAndSaturationForIllustration(color, 100),
    });
  }

  return ({
    saturation51: new Color('#818181').replaceHueAndSaturationForIllustration(color, 100),
    saturation65: new Color('#A5A5A5').replaceHueAndSaturationForIllustration(color, 100),
    saturation86: new Color('#DBDBDB').replaceHueAndSaturationForIllustration(color, 100),
    saturation95: new Color('#F3F3F3').replaceHueAndSaturationForIllustration(color, 100),
    saturation98: new Color('#F9F9F9').replaceHueAndSaturationForIllustration(color, 100),
  });
};

const getColorSchema = (color?: HexColor): ThemeSchema => {
  const darkMode = isDarkMode();
  if (!color && darkMode) {
    return ({
      background: new Color('#090304'),
      primary: new Color(DEFAULT_THEME_COLOR),
      secondary: new Color(DEFAULT_THEME_COLOR).adjustLightness(15),
      highlight: new Color(DEFAULT_THEME_COLOR).adjustLightness(10),
      darkAccent: new Color(DEFAULT_THEME_COLOR).adjustLightness(70),
      darkAccentOpposite: new Color('#090304'),
      inactive: new Color(DEFAULT_THEME_COLOR).adjustLightness(70),
      error: new Color('#DC0030').adjustLightness(70),
      text: new Color('#FFFFFF'),
      textOpposite: new Color('#383838'),
    });
  }

  if (!color) {
    return ({
      background: new Color('#FFFFFF'),
      primary: new Color(DEFAULT_THEME_COLOR),
      secondary: new Color('#EEF1F7'),
      highlight: new Color('#F2F7FF'),
      darkAccent: new Color('#0047B1'),
      darkAccentOpposite: new Color('#FFFFFF'),
      inactive: new Color('#737373'),
      error: new Color('#DC0030'),
      text: new Color('#383838'),
      textOpposite: new Color('#FFFFFF'),
    });
  }

  if (darkMode) {
    return ({
      background: new Color('#090304'),
      primary: new Color(color),
      secondary: new Color(color).adjustLightness(15),
      highlight: new Color(color).adjustLightness(10),
      darkAccent: new Color(color).adjustLightness(70),
      darkAccentOpposite: new Color('#090304'),
      inactive: new Color(color).adjustLightness(70),
      error: new Color('#DC0030').adjustLightness(70),
      text: new Color('#FFFFFF'),
      textOpposite: new Color('#000000'),
    });
  }

  return ({
    background: new Color('#FFFFFF'),
    primary: new Color(color),
    secondary: new Color('#EEF1F7').replaceHueAndSaturation(color),
    highlight: new Color(color).adjustLightness(95),
    darkAccent: new Color(color).adjustLightness(20),
    darkAccentOpposite: new Color('#FFFFFF'),
    inactive: new Color('#737373'),
    error: new Color('#DC0030'),
    text: new Color('#383838'),
    textOpposite: new Color('#FFFFFF'),
  });
};

export const getTheme = (color: HexColor) => {
  const schema = getColorSchema(color);
  const illustrationsSchema = getIllustrationsSchema(color);

  return ({
    fonts: {
      primary: 'Inter',
    },
    colors: {
      icons: {
        accentDark: schema.darkAccent.css,
        accentDarkOpposite: schema.darkAccentOpposite.css,
        inactive: schema.inactive.css,
        error: schema.error.css,
        secondary: schema.secondary.css,
        text: schema.text.css,
      },
      illustrations: {
        saturation51: illustrationsSchema.saturation51.css,
        saturation65: illustrationsSchema.saturation65.css,
        saturation86: illustrationsSchema.saturation86.css,
        saturation95: illustrationsSchema.saturation95.css,
        saturation98: illustrationsSchema.saturation98.css,
        secondary: schema.secondary.css,
        background: schema.background.css,
      },
      text: {
        beer: '#C43B01',
        primary: schema.text.css,
        primaryOpposite: schema.textOpposite.css,
        secondary: new Color('#FFFFFF').replaceIfContrastInvalid(schema.primary.hex, '#383838').css,
        inactive: schema.inactive.css,
        accent: schema.primary.css,
        accentDark: schema.darkAccent.css,
        accentDarkOpposite: schema.darkAccentOpposite.css,
        disabled: '#A5A5A5',
        error: schema.error.css,
        white: '#FFFFFF',
        zeroHash: schema.text.css,
      },
      background: {
        primary: schema.background.css,
        containerFill: schema.secondary.css,
        inactive: schema.secondary.css,
        accent: schema.primary.css,
        accentDark: schema.darkAccent.css,
        highlight: schema.highlight.css,
        transition: 'background .2s ease',
        error: schema.error.css,
      },
      border: {
        accentDark: schema.darkAccent.css,
        error: schema.error.css,
      },
      separator: {
        primary: schema.highlight.css,
      },
      spinner: {
        accentDark: schema.darkAccent.css,
        inactive: schema.inactive.css,
        secondary: schema.secondary.css,
      },
      logo: {
        font: isDarkMode() ? '#FFFFFF' : '#000000',
        black: '#000000',
        accent: '#136EF6',
        white: '#FFFFFF',
      },
    },
    scale: (num: number) => {
      const ratio = getScaleRatio();
      return num * ratio;
    },
    border: {
      radius: getBorderRadius(),
    },
    breakpoints: {
      values: {
        ...breakpointValues,
      },
      min: {
        [Breakpoint.Mobile]: `@media (min-width: ${breakpointValues.mobile}px)`,
        [Breakpoint.Widget]: `@media (min-width: ${breakpointValues.widget}px)`,
        [Breakpoint.Tablet]: `@media (min-width: ${breakpointValues.tablet}px)`,
        [Breakpoint.Laptop]: `@media (min-width: ${breakpointValues.laptop}px)`,
        [Breakpoint.Desktop]: `@media (min-width: ${breakpointValues.desktop})px`,
      },
    },
  });
};

const getPartnerThemeColor = () => window.s4cTheme ?? window.partnerConfig?.widgetColor ?? DEFAULT_THEME_COLOR;

export const useThemeContext = () => ({ theme: getTheme(getPartnerThemeColor()) });
export const { tss } = createTss({
  useContext: useThemeContext,
});
