import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
      gap: theme.scale(12),
    },
    icon: {
      fill: theme.colors.icons.error,
    },
    messages: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.scale(5),
      '& *': {
        margin: 0,
      },
    },
    title: {
      ...fontSizeMixin(FontSize.M),
      fontWeight: 600,
    },
    text: {
      ...fontSizeMixin(FontSize.M),
      fontWeight: 400,
    },
  }),
);

export default useStyles;
