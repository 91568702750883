import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWithCustomHeaders, makeAuthHeader } from 'services/api';
import { Endpoint } from 'services/endpoints';
import { AuthToken } from 'types/authentication';

const REDUCER_PATH = 'kycApi';

export interface KycTokenResponse {
  data: {
    token: string;
  };
}

export const kycApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(makeAuthHeader),
  endpoints: builder => ({
    getKycToken: builder.query<KycTokenResponse, AuthToken>({
      query: () => Endpoint.KycToken,
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    }),
  }),
});

export const { getKycToken } = kycApi.endpoints;

export default kycApi;
