import { Asset } from 'types/assets';

export const isAssetAmountValid = (asset: Asset, amount: string | null) => {
  if (!amount) {
    return true;
  }

  if ('minAmount' in asset) {
    const isMinAmountValid = Number(amount) >= Number(asset.minAmount);
    if (!isMinAmountValid) {
      return false;
    }
  }

  if ('maxAmount' in asset) {
    const isMaxAmountValid = Number(amount) <= Number(asset.maxAmount);
    if (!isMaxAmountValid) {
      return false;
    }
  }

  return true;
};

export default isAssetAmountValid;
