import { Pagination, RequestPagination, ServerPagination } from 'types/pagination';

export const DEFAULT_PAGINATION_NUMBER_OF_ITEMS_PER_PAGE = 15;
export const getDefaultPagination = (itemsPerPage = DEFAULT_PAGINATION_NUMBER_OF_ITEMS_PER_PAGE): Pagination => ({
  page: 1,
  itemsPerPage,
  count: 0,
  pages: 1,
});

export const decoratePagination = (paginationResponse: ServerPagination): Pagination => {
  const output: Pagination = {
    page: Number(paginationResponse.page),
    itemsPerPage: Number(paginationResponse.itemsPerPage),
    count: Number(paginationResponse.count),
    pages: 0,
  };

  const canCalculatePages = Boolean(paginationResponse.itemsPerPage)
    && paginationResponse.count > 0;

  if (canCalculatePages && paginationResponse?.itemsPerPage) {
    output.pages = Math.ceil(paginationResponse.count / paginationResponse.itemsPerPage);
  }

  return output;
};

export const undecoratePagination = (pagination: Pagination): RequestPagination => ({
  itemsPerPage: pagination.itemsPerPage,
  page: pagination.page,
});
