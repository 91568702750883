import { NavigationEvaluationFunc } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';

export const getCheckoutPreviousStepSell: NavigationEvaluationFunc<WorkflowStep> = (params) => {
  const skipBankDetails = Boolean(params.paymentMethod?.defaultCard);
  if (skipBankDetails) {
    return WorkflowStep.Quote;
  }
  return WorkflowStep.BankDetails;
};

export default getCheckoutPreviousStepSell;
