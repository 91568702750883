import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(15),
      height: '100%',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(12),
    },
    proceedButtonWrapper: {
      marginTop: 'auto',
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.scale(15),
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
    title: {
      ...fontSizeMixin(FontSize.XL),
      marginTop: 0,
      fontWeight: 500,
    },
    caption: {
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
    checkbox: {
      display: 'inline-flex',
      marginRight: theme.scale(8),
    },
    checkboxWrapper: {
      backgroundColor: theme.colors.background.primary,
      padding: `${theme.scale(8)}px ${theme.scale(12)}px`,
      borderRadius: theme.border.radius,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    checkboxLabel: {
      ...fontSizeMixin(FontSize.M),
      fontWeight: 500,
      color: theme.colors.text.primary,
    },
    captionTop: {
      color: theme.colors.text.primary,
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
    },
    captionBottom: {
      color: theme.colors.text.inactive,
      ...fontSizeMixin(FontSize.S),
      fontWeight: 400,
    },
    captionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `0 ${theme.scale(40)}px`,
      textAlign: 'center',
    },
    spinnerWrapper: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
