import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'partner';

interface PartnerState {
  apiKey: string | null;
}

const initialState: PartnerState = {
  apiKey: null,
};

export const partnerSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setApiKey: (state, action: PayloadAction<string | null>) => {
      state.apiKey = action.payload;
    },
  },
});

export const { setApiKey } = partnerSlice.actions;

export default partnerSlice.reducer;
