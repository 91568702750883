import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = 'M4.49995 8.10078L2.39995 6.00078L1.69995 6.70078L4.49995 9.50078L10.5 3.50078L9.79995 2.80078L4.49995 8.10078Z';

export const IconCheckMark = ({
  width = 12,
  height = 12,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconCheckMark;
