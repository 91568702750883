import { tss } from 'theme';

export const useStyles = tss
  .withParams<{ wrapperWidth?: number }>()
  .create(
    ({ theme, wrapperWidth }) => ({
      logo: {
        height: theme.scale(40),
        width: wrapperWidth || theme.scale(250),

        '& img': {
          float: 'left',
          height: '100%',
          objectFit: 'contain',
        },

      },
    }),
  );

export default useStyles;
