const MESSAGES_NAMESPACE = 'country';
export const LABELS = {
  UNSUPPORTED_FINAL_HEADER_ENABLED: `${MESSAGES_NAMESPACE}.unsupportedFinalHeaderEnabled`,
  UNSUPPORTED_FINAL_HEADER_NOT_ENABLED: `${MESSAGES_NAMESPACE}.unsupportedFinalHeaderNotEnabled`,
  UNSUPPORTED_FINAL_TITLE_ENABLED: `${MESSAGES_NAMESPACE}.unsupportedFinalHeaderTitleEnabled`,
  UNSUPPORTED_FINAL_TITLE_NOT_ENABLED: `${MESSAGES_NAMESPACE}.unsupportedFinalHeaderTitleNotEnabled`,
  UNSUPPORTED_FINAL_CAPTION_ENABLED: `${MESSAGES_NAMESPACE}.unsupportedFinalCaptionEnabled`,
  UNSUPPORTED_FINAL_CAPTION_NOT_ENABLED: `${MESSAGES_NAMESPACE}.unsupportedFinalCaptionNotEnabled`,
  IP_UNSUPPORTED_HEADER: `${MESSAGES_NAMESPACE}.ipUnsupportedHeader`,
  IP_UNSUPPORTED_TITLE: `${MESSAGES_NAMESPACE}.ipUnsupportedTitle`,
  IP_UNSUPPORTED_CAPTION: `${MESSAGES_NAMESPACE}.ipUnsupportedCaption`,
  UNSUPPORTED_HEADER: `${MESSAGES_NAMESPACE}.unsupportedHeader`,
  COUNTRY_NOT_SUPPORTED: `${MESSAGES_NAMESPACE}.countryNotSupported`,
  UNSUPPORTED_CAPTION_1: `${MESSAGES_NAMESPACE}.unsupportedCaption1`,
  UNSUPPORTED_CAPTION_2: `${MESSAGES_NAMESPACE}.unsupportedCaption2`,
  UNSUPPORTED_CHECKBOX_LABEL: `${MESSAGES_NAMESPACE}.unsupportedCheckboxLabel`,
  UNSUPPORTED_FINISH: `${MESSAGES_NAMESPACE}.unsupportedFinish`,
};

export default LABELS;
