import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path1 = [
  'M5.995 1C3.235 1 1 3.24 1 6C1 8.76 3.235 11 5.995 11C8.76 11 11 8.76 11',
  '6C11 3.24 8.76 1 5.995 1ZM6 10C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2C8.21 2 10 3.79 10 6C10 8.21 8.21 10 6 10Z',
].join(' ');

const path2 = [
  'M6.25 3.5H5.5V6.5L8.125 8.075L8.5 7.46L6.25 6.125V3.5Z',
].join(' ');

export const IconProcessing = ({
  width = 12,
  height = 12,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={path1} />
      <path d={path2} />
    </svg>
  );
};
export default IconProcessing;
