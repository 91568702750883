import { createApi } from '@reduxjs/toolkit/query/react';

import { Endpoint } from 'services/endpoints';
import { RootState } from 'state/store';
import {
  OrderStatus,
  Transaction,
  TransactionId,
} from 'types/transactions';

import fetchBaseQueryWithCustomHeaders, { CustomHeaderType, makeAuthHeader } from '../api';

const REDUCER_PATH = 'transactionStepsStatusApi';

interface TransactionResponse {
  data: {
    order: Transaction;
  };
}

interface TransactionStepsStatusResponse {
  data: OrderStatus;
}

interface TransactionQueryAction {
  transactionId: TransactionId;
  includeToken: boolean;
}

export const transactionApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(),
  endpoints: builder => ({
    getTransaction: builder.query<TransactionResponse, TransactionQueryAction>({
      query: ({ transactionId, includeToken }) => ({
        url: Endpoint.OrderByUuid(transactionId),
        headers: includeToken ? { [CustomHeaderType.Auth]: makeAuthHeader().value } : {},
      }),
    }),
    getTransactionStepsStatus: builder.query<TransactionStepsStatusResponse, TransactionQueryAction>({
      query: ({ transactionId, includeToken }) => ({
        url: Endpoint.OrderStepsStatusByUuid(transactionId),
        headers: includeToken ? { [CustomHeaderType.Auth]: makeAuthHeader().value } : {},
      }),
      forceRefetch: () => true,
    }),
  }),
});

export const { getTransaction, getTransactionStepsStatus } = transactionApi.endpoints;

export const selectOrderStatus = (transactionId: string) => (state: RootState) => {
  const order = transactionApi.endpoints.getTransaction.select({
    transactionId,
    includeToken: true,
  })(state);

  return order.data?.data.order;
};

export default transactionApi;
