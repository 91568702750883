import { ChangeEventHandler } from 'react';
import { InputProps as MuiInputProps } from '@mui/base/Input';
import { InputInputSlotPropsOverrides, InputOwnerState } from '@mui/base/Input/Input.types';
import { SlotComponentProps } from '@mui/base/utils';

import FormFieldError from 'components/form/common/fieldError';
import Input from 'components/input';
import OptionallyVisible from 'components/optionallyVisible';
import { locators, QALocateableElementProps, QALocator } from 'utils/tests/QA';

import useStyles from '../../styles';

type InputFormFieldProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  id: string;
  value: string;
  label?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  errorText?: string | JSX.Element;
  slotProps?: MuiInputProps['slotProps'] & {
    input?: SlotComponentProps<'input', InputInputSlotPropsOverrides, InputOwnerState> & QALocateableElementProps;
  };
} & MuiInputProps & QALocateableElementProps;

const InputFormField = ({
  onChange,
  id,
  label,
  value,
  leftIcon,
  rightIcon,
  errorText,
  ...otherProps
}: InputFormFieldProps) => {
  const { classes } = useStyles();
  const { 'data-cy': dataCyProp, ...otherPropsExcludingDataCy } = otherProps;

  return (
    <div className={classes.formItem} {...QALocator(otherProps['data-cy'])}>
      <div className={classes.formItemInputWrapper}>
        <OptionallyVisible visible={Boolean(label)}>
          <label className={classes.label} htmlFor={id}>
            {label}
          </label>
        </OptionallyVisible>

        <Input
          {...otherPropsExcludingDataCy}
          id={id}
          className={classes.input}
          onChange={onChange}
          value={value}
          error={Boolean(errorText)}
          startAdornment={leftIcon || null}
          endAdornment={rightIcon || null}
        />
      </div>
      <FormFieldError errorText={errorText} {...QALocator(locators.components.common.error)} />
    </div>
  );
};

export default InputFormField;
