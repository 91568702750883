import { useSelector } from 'react-redux';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

import countriesApi from 'services/countries';
import userApi from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';

import NotificationFinalScreen from '.';

export const CountryCheckFinalContainer = () => {
  const { data: countries } = countriesApi.useGetCountriesQuery();
  const authToken = useSelector(selectAuthToken) ?? '';
  const { data: user, status: userCallStatus } = userApi.useGetUserInfoQuery(authToken);
  const countryCode = user?.data?.country;
  const countryName = countries?.data.countries.find(country => country.isoAlpha2 === countryCode)?.name;
  const isSubscribed = Boolean(user?.data?.notifyWhenServiceAvailable);

  return (
    <NotificationFinalScreen
      countryName={countryName}
      isSubscribed={isSubscribed}
      isUserLoading={userCallStatus === QueryStatus.pending}
    />
  );
};

export default CountryCheckFinalContainer;
