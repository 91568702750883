import {
  ReactNode, useEffect, useRef,
} from 'react';
import { CSSTransition } from 'react-transition-group';

import Close from 'icons/close';

import useStyles from './alertBox.styles';
import KEYS from './keys';

type AlertProps = { children: ReactNode;
  closeAlert: () => void;
  closeAlertAndDropdown : () => void;
  isVisible: boolean; onExited: () => void; };

function AutoCloseAlert({
  children, onExited, isVisible, closeAlert, closeAlertAndDropdown,
}: AlertProps) {
  const { classes } = useStyles();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isVisible) {
      timeoutRef.current = setTimeout(() => closeAlert(), KEYS.HIDE_ALERT_TIMEOUT);
      return () => clearTimeout(timeoutRef.current!);
    }

    return undefined;
  }, [isVisible]);

  const onExit = () => {
    clearTimeout(timeoutRef.current!);
    onExited();
  };

  return (
    <CSSTransition
      in={isVisible}
      classNames="fade"
      timeout={KEYS.ALERT_TRANSITION_TIME}
      unmountOnExit
      onExited={onExit}
    >
      <div
        role="button"
        tabIndex={0}
        className={classes.container}
        onMouseDown={closeAlertAndDropdown}
      >
        <div className={classes.content}>{children}</div>
        <Close className={classes.closeIcon} />
      </div>
    </CSSTransition>
  );
}

export default AutoCloseAlert;
