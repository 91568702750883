import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useNavigation from 'hooks/useNavigation';
import { getPathParams } from 'pages/route/utils';
import transactionApi from 'services/transaction';
import { selectFlow } from 'state/slices/flowSlice';
import { selectOrder, selectTransactionId } from 'state/slices/orderSlice';
import { selectCardAuthorizationErrorMessage, selectHasCardAuthorizationError, selectTokenizedCard } from 'state/slices/paymentSlice';
import Flow from 'types/flow';
import {
  OrderStatus, OrderStatusStep, OrderStepStatus, TransactionStatus,
} from 'types/transactions';
import PATHS from 'utils/navigation';

import TransactionProcessing from '.';
import KEYS from './keys';

export const TransactionProcessingContainer = () => {
  const { proceed, goBack, canGoBack } = useNavigation();
  const [pollingInterval, setPollingInterval] = useState(KEYS.POLLING_INTERVAL_WHILE_WITHDRAWAL_STATUS_IS_UNKNOWN);
  const { orderId } = getPathParams(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  const transactionId = useSelector(selectTransactionId) ?? orderId ?? '';
  const hasCardAuthorizationError = useSelector(selectHasCardAuthorizationError);
  const cardAuthorizationErrorMessage = useSelector(selectCardAuthorizationErrorMessage);
  const tokenizedCard = useSelector(selectTokenizedCard);
  const flow = useSelector(selectFlow);
  const order = useSelector(selectOrder);
  const { data, isLoading, isError } = transactionApi.useGetTransactionStepsStatusQuery({
    transactionId,
    includeToken: true,
  }, {
    pollingInterval,
    skip: !transactionId,
  });

  const {
    data: fetchedOrder,
  } = transactionApi.useGetTransactionQuery({
    transactionId,
    includeToken: true,
  }, {
    pollingInterval: KEYS.ORDER_STATUS_POLLING_INTERVAL,
    skip: flow !== Flow.Sell,
  });

  const orderTransaction = data?.data;

  useEffect(() => {
    if (!orderTransaction) {
      return undefined;
    }

    updatePollingInterval(orderTransaction);
    const timeout = redirectIfTransactionIsCompleted(orderTransaction);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    orderTransaction,
  ]);

  useEffect(() => {
    if (!fetchedOrder) {
      return undefined;
    }

    const orderStatus = fetchedOrder?.data.order;
    const isConfirmQuoteRequired = orderStatus?.status === TransactionStatus.AwaitingClientConfirmation;
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (isConfirmQuoteRequired) {
      timeout = setTimeout(() => goBack({ orderStatus }), KEYS.TIMEOUT_BEFORE_ORDER_PROCESSED_REDIRECTION);
      return undefined;
    }

    const hasPaymentFailed = orderStatus?.status === TransactionStatus.PaymentFailed;
    if (hasPaymentFailed) {
      timeout = setTimeout(() => goBack({ orderStatus }), KEYS.TIMEOUT_BEFORE_ORDER_PROCESSED_REDIRECTION);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [fetchedOrder]);

  const updatePollingInterval = (transaction?: OrderStatus) => {
    const withdrawalStep = transaction?.steps.find(step => step.type === OrderStatusStep.Withdrawal);
    if (!withdrawalStep) {
      return;
    }

    const nextPollingInterval = withdrawalStep.status === OrderStepStatus.Unknown
      ? KEYS.POLLING_INTERVAL_WHILE_WITHDRAWAL_STATUS_IS_UNKNOWN
      : KEYS.POLLING_INTERVAL_WHILE_WITHDRAWAL_STATUS_IS_NOT_UNKNOWN;

    setPollingInterval(nextPollingInterval);
  };

  const redirectIfTransactionIsCompleted = (transaction?: OrderStatus) => {
    const shouldRedirect = transaction?.steps.every(step => step.status === OrderStepStatus.Success);
    if (shouldRedirect) {
      return setTimeout(proceed, KEYS.TIMEOUT_BEFORE_ORDER_PROCESSED_REDIRECTION);
    }

    return null;
  };

  const onGoBackClick = () => {
    goBack({ tokenizedCard });
  };

  return (
    <TransactionProcessing
      flow={flow}
      transactionStepsStatus={{
        data: orderTransaction,
        isError,
        isLoading,
      }}
      hasCardAuthorizationError={hasCardAuthorizationError}
      cardAuthorizationErrorMessage={cardAuthorizationErrorMessage}
      goBack={onGoBackClick}
      canGoBack={canGoBack}
      chainCode={order?.selectedCryptoAsset?.chainCode}
    />
  );
};

export default TransactionProcessingContainer;
