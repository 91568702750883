import { useThemeContext } from 'theme';

interface IconChevronProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const path = 'M11.56 5.72665L8.5 8.77999L5.44 5.72665L4.5 6.66665L8.5 10.6667L12.5 6.66665L11.56 5.72665Z';

export const IconChevron = ({
  width = 24,
  height = 24,
  color,
  className,
}: IconChevronProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 17 17"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={path} />
    </svg>
  );
};

export default IconChevron;
