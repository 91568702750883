import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59',
  '12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z',
].join(' ');

export const IconCross = ({
  width = 24,
  height = 24,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconCross;
