import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import QuoteDetailsSell from 'components/quoteDetails/sell';
import getEmailFromQueryParams from 'config/email';
import { getWalletAddressFromParams, getWalletTagFromParams, isWalletStepSkippable } from 'config/wallet';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import assetsApi from 'services/assets';
import authenticationApi, { PASSWORDLESS_START_CACHE_KEY } from 'services/authentication';
import { selectPaymentMethod } from 'services/payments';
import quotesApi, { QUOTE_CACHE_KEY, QuoteAction } from 'services/quote';
import { QuotePayload } from 'services/quote/transactions/payloads';
import { selectUserCacheEntry, selectUserPendingOrdersCacheEntry } from 'services/user';
import { selectAssets } from 'state/selectors/assets/assetsSelector';
import { setError } from 'state/slices/errorSlice';
import { changeFlow, selectFlow } from 'state/slices/flowSlice';
import { selectOrder, setOrder, setOrderEmail } from 'state/slices/orderSlice';
import { changeStep } from 'state/slices/stepSlice';
import { AssetWithLabel, Quote } from 'types/assets';
import { ContainerProps } from 'types/container';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import Flow from 'types/flow';
import { ReduxToolkitApiCall, ReduxToolkitError, Response } from 'types/http';
import { Order, OrderStatus } from 'types/order';
import { PreloadedValues } from 'types/preload';
import { WorkflowStep } from 'types/step';
import { removeFieldsOfUndefinedValue } from 'utils/equality';

import QuoteScreen from '.';
import KEYS from './keys';
import { getMaximumAmountError, getMinimumAmountError } from './quoteErrors';

export const QuoteScreenContainerSell = ({ logoSrc }: ContainerProps) => {
  const { proceed } = useNavigation();
  const dispatch = useDispatch();

  const assets = useSelector(selectAssets);
  const order = useSelector(selectOrder);
  const user = useSelector(selectUserCacheEntry);
  const flow = useSelector(selectFlow);
  const pendingOrders = useSelector(selectUserPendingOrdersCacheEntry)?.data?.data?.orders;
  const paymentMethod = useSelector(selectPaymentMethod);
  const shouldShowDotIndicator = pendingOrders?.some(order => [
    OrderStatus.PaymentFailed,
    OrderStatus.AwaitingClientConfirmation,
  ].includes(order.status));

  const [, { isLoading: isPasswordlessAuthLoading }] = authenticationApi.usePasswordlessStartMutation({
    fixedCacheKey: PASSWORDLESS_START_CACHE_KEY,
  });

  assetsApi.useGetActiveAssetsSellQuery();
  const [fetchQuoteAction, { data: quote, isLoading: isQuoteLoading, error: quoteError }] = quotesApi.useGetQuoteMutation({
    fixedCacheKey: QUOTE_CACHE_KEY,
  });
  const minAmount = getMinimumAmountError(quoteError as FetchBaseQueryError);
  const maxAmount = getMaximumAmountError(quoteError as FetchBaseQueryError);

  const createOrder = (cryptoAsset: AssetWithLabel, newOrder: Order) => {
    const walletAddress = getWalletAddressFromParams(cryptoAsset);
    const tagOrMemo = getWalletTagFromParams(cryptoAsset);

    const preloadedValues: PreloadedValues = {
      ...window.preloadedConfig,
      skipWalletScreen: isWalletStepSkippable(walletAddress, tagOrMemo),
    };

    window.preloadedConfig = preloadedValues;

    dispatch(setOrder({
      walletAddress,
      tagOrMemo,
      ...removeFieldsOfUndefinedValue(order),
      ...removeFieldsOfUndefinedValue(newOrder),
    }));
  };

  const showFatalError = () => {
    const error = getErrorScreenMessage(ScreenErrorType.Fatal);
    dispatch(setError({ ...error, isFatalError: true }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const setFlow = (flow: Flow) => {
    dispatch(changeFlow(flow));
  };

  useEffect(() => {
    const isInvalidApiKeyError = (assets.error as ReduxToolkitError)?.data?.errorType === ApiErrorCode.InvalidApiKey;
    if (isInvalidApiKeyError) {
      return;
    }

    if (assets.isError) {
      showFatalError();
    }
  }, [assets.isError]);

  useEffect(() => {
    if (!user?.data?.data?.username) {
      return;
    }

    if (!order.email) {
      dispatch(setOrderEmail(user.data.data.username));
    }
  }, [user?.data?.data?.username]);

  const fetchQuote = (payload: QuotePayload) => {
    const action: QuoteAction = {
      payload: {
        ...payload,
        paymentMethod: paymentMethod ?? undefined,
        side: Flow.Sell,
      },
      includeToken: false,
    };

    return fetchQuoteAction(action);
  };

  const onProceedClick = () => {
    if (user) {
      proceed();
      return;
    }

    const email = getEmailFromQueryParams();
    if (email) {
      dispatch(setOrderEmail(email));
      proceed({ email });

      return;
    }

    proceed();
  };

  return (
    <QuoteScreen
      flow={flow}
      assets={assets}
      order={order}
      user={{
        details: user?.data?.data,
        isLoading: Boolean(user?.isLoading),
        isError: Boolean(user?.isError),
      }}
      quote={{
        details: quote?.data?.quote,
        isLoading: isQuoteLoading,
        isError: Boolean(quoteError),
        errors: {
          minAmount,
          maxAmount,
          backendErrorMessage: ((quoteError as FetchBaseQueryError)?.data as Response<any>)?.errorMessage,
          isAbortError: Boolean((quoteError as SerializedError)?.name === KEYS.ABORT_ERROR),
        },
      }}
      createOrder={createOrder}
      fetchQuote={fetchQuote as ReduxToolkitApiCall<Quote>}
      proceed={onProceedClick}
      isProceedLoading={isPasswordlessAuthLoading}
      logoSrc={logoSrc}
      setFlow={setFlow}
      QuoteBreakdown={QuoteDetailsSell}
      showDotIndicator={shouldShowDotIndicator}
    />
  );
};

export default QuoteScreenContainerSell;
