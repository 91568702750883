import { tss } from 'theme';

import { Illustration, LayeredMask } from './types';

const useStyles = tss
  .withParams<{height: number; width?: number}>()
  .create(
    ({ height, width, theme }) => ({
      icon: {
        display: 'block',
        width: width ? theme.scale(width) : theme.scale(112),
        height: theme.scale(height),
        maskRepeat: 'no-repeat',
        position: 'relative',
        maskSize: '100%',
        color: 'rgba(0,0,0,0) !important',
        backgroundColor: 'currentcolor',
        '& > div': {
          position: 'absolute',
        },
      },
      placeholder: {
        height: theme.scale(height),
        width: '100%',
      },
    }),
  );

export const getIllustrationCSS = (
  illustration: Illustration,
  masks: LayeredMask[],
  staticSvgPath: string,
) => ({
  [`.icon--${illustration}`]: {
    ...getCombinedMasksCSS(masks, staticSvgPath),
  },
});

export const getCombinedMasksCSS = (
  masks: LayeredMask[],
  staticSvgPath: string,
) => masks.reduce((acc, { color, layerIds }, i) => ({
  ...acc,
  [`&-${i}`]: {
    maskImage: layerIds.map(svgPathIndex => `url(${staticSvgPath}#${svgPathIndex})`).join(', '),
    maskComposite: 'add',
    color: `${color} !important`,
  },
}), {});

export default useStyles;
