export enum WorkflowStep {
  Quote = 'Quote',
  SignIn = 'SignIn',
  CountryCheck = 'CountryCheck',
  CountryUnavailable = 'CountryUnavailable',
  CountryUnavailableFinal = 'CountryUnavailableFinal',
  IpCountryUnavailable = 'IpCountryUnavailable',
  Otp = 'Otp',
  Kyc = 'Kyc',
  KycRejected = 'KycRejected',
  Wallet = 'Wallet',
  PaymentMethods = 'PaymentMethods',
  BankDetails = 'BankDetails',
  RequestNewCard = 'RequestNewCard',
  Checkout = 'Checkout',
  ConfirmNewQuote = 'ConfirmNewQuote',
  TransferCrypto = 'TransferCrypto',
  AwaitingCrypto = 'AwaitingCrypto',
  TransactionProcessing = 'TransactionProcessing',
  OrderProcessed = 'OrderProcessed',
  Error = 'Error',
}

export default WorkflowStep;
