import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';
import hex2rgba from 'utils/colors/convert';

import KEYS from './keys';

export const useStyles = tss.create(
  ({ theme }) => ({
    overlay: {
      position: 'absolute',
      left: theme.scale(-5),
      top: theme.scale(-5),
      height: '100%',
      width: '100%',
      background: theme.colors.background.containerFill,
      zIndex: 101,
    },
    modalContent: {
      position: 'absolute',
      left: theme.scale(-5),
      top: theme.scale(-5),
      margin: theme.scale(5),
      padding: `${theme.scale(10)}px 0 0 ${theme.scale(10)}px`,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(12),
      background: theme.colors.background.primary,
      height: '100%',
      width: '100%',
      borderRadius: '5px 5px 0px 0px',
      boxShadow: `0px 10px 25px 0px ${hex2rgba(theme.colors.background.accentDark, 0.2)}`,
      transform: 'translateY(0)',
      zIndex: 102,
    },
    modalTitle: {
      ...fontSizeMixin(FontSize.M),
      margin: 'unset',
      fontWeight: 600,
    },
    modalHeader: {
      paddingRight: theme.scale(10),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeIcon: {
      fill: theme.colors.icons.accentDark,
    },
    closeButton: {
      height: theme.scale(24),
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      '& > div': {
        padding: 0,
        height: theme.scale(24),
      },
    },
    container: {
      '& .overlay-enter': {
        opacity: 0,
      },
      '& .overlay-enter-active': {
        opacity: 1,
        transition: `opacity ${KEYS.OVERLAY_TRANSITION_DURATION}ms ease-in`,
      },
      '& .overlay-exit': {
        opacity: 1,
      },
      '& .overlay-exit-active': {
        opacity: 0,
        transition: `opacity ${KEYS.OVERLAY_TRANSITION_DURATION}ms ease-in`,
      },
      '& .modal-enter': {
        transform: 'translateY(100%)',
      },
      '& .modal-enter-active': {
        transform: 'translateY(0)',
        transition: `transform ${KEYS.MODAL_TRANSITION_DURATION}ms ease-in`,
      },
      '& .modal-exit': {
        transform: 'translateY(0)',
      },
      '& .modal-exit-active': {
        transform: 'translateY(100%)',
        transition: `transform ${KEYS.MODAL_TRANSITION_DURATION}ms ease-in`,
      },
      '& .modal-exit-done': {
        transform: 'translateY(100%)',
        transition: `transform ${KEYS.MODAL_TRANSITION_DURATION}ms ease-in`,
      },
    },
  }),
);

export default useStyles;
