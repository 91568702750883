import Shif4Logo from 'assets/images/shift4Logo.svg';
import { getIllustrationCSS } from 'illustrations/styles';
import { Illustration } from 'illustrations/types';
import { tss, useThemeContext } from 'theme';

export const getColorBasedMasks = (theme: ReturnType<typeof useThemeContext>['theme'], color?: string | null) => ([
  {
    color: color ?? theme.colors.logo.font,
    layerIds: [0, 1, 2, 3, 4, 5],
  },
  { color: theme.colors.logo.accent, layerIds: [6] },
]);

export const useStyles = tss
  .withParams<{color?: string | null}>()
  .create(
    ({ theme, color }) => ({
      container: {
        ...getIllustrationCSS(
          Illustration.Shift4,
          getColorBasedMasks(theme, color),
          Shif4Logo,
        ),
      },
    }),
  );

export default useStyles;
