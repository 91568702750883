import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWithCustomHeaders, makeAuthHeader } from 'services/api';
import SERVICES_DATA_CONFIG from 'services/data';
import Endpoint from 'services/endpoints';
import { RootState } from 'state/store';
import { AuthToken } from 'types/authentication';
import HTTPMethod, { Response } from 'types/http';
import { OrderRecord } from 'types/order';
import { ServerPagination } from 'types/pagination';
import { User } from 'types/user';
import Logger from 'utils/logger';

const REDUCER_PATH = 'userApi';
export const USER_TAG = 'User';

export const userApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithCustomHeaders(makeAuthHeader),
  tagTypes: [USER_TAG],
  keepUnusedDataFor: SERVICES_DATA_CONFIG.STORE_FOREVER_TIME,
  endpoints: builder => ({
    getUserInfo: builder.query<Response<User>, AuthToken>({
      query: () => Endpoint.UserInfo,
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      providesTags: [USER_TAG],
    }),
    updateUser: builder.mutation<Response<User>, { authToken: AuthToken; user: Partial<User> }>({
      query: ({ user }) => ({
        url: Endpoint.UserInfo,
        method: HTTPMethod.PATCH,
        body: user,
      }),
      async onQueryStarted({ authToken, user }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (!authToken) {
            return;
          }

          dispatch(
            userApi.util.updateQueryData('getUserInfo', authToken, draft => (
              {
                ...draft,
                // @ts-ignore
                data: { ...draft.data, ...user },
              })),
          );
        } catch {
          Logger.error('Unable to update user record');
        }
      },
    }),
    getUserOrders: builder.query<Response<{ orders: OrderRecord[]; pagination: ServerPagination }>, number>({
      query: page => Endpoint.OrdersList(page),
      providesTags: [USER_TAG],
    }),
    getUserPendingOrders: builder.query<Response<{ orders: OrderRecord[]; pagination: ServerPagination }>, number>({
      query: page => Endpoint.PendingOrdersList(page),
      providesTags: [USER_TAG],
    }),
  }),
});

export const { getUserInfo, updateUser } = userApi.endpoints;

const selectUser = (state: RootState) => {
  if (!state.auth.token) {
    return undefined;
  }

  const user = userApi.endpoints.getUserInfo.select(state.auth.token)(state);

  return user;
};

const selectUserPendingOrders = (state: RootState) => {
  if (!state.auth.token) {
    return undefined;
  }

  const pendingOrders = userApi.endpoints.getUserPendingOrders.select(1)(state);

  return pendingOrders;
};

export const selectUserCacheEntry = createSelector([(state: RootState) => state], selectUser);
export const selectUserPendingOrdersCacheEntry = createSelector([(state: RootState) => state], selectUserPendingOrders);

export default userApi;
