import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Logo from 'components/logo';
import OptionallyVisible from 'components/optionallyVisible';
import Shift4LogoIllustration from 'illustrations/shift4';
import isDarkMode from 'theme/darkMode';
import { PartnerConfig } from 'types/config';
import getMobileFrameDimensions from 'utils/dimensions';

import FormFooter from './formFooter';
import KEYS, { LABELS } from './keys';
import useStyles from './root.styles';

export interface LayoutProps {
  children: ReactNode;
  showPartnersFooter: boolean;
  config?: PartnerConfig;
  isConfigLoading?: boolean;
}

const Layout = ({
  config,
  isConfigLoading,
  showPartnersFooter,
  children,
}: LayoutProps) => {
  const mobileFrameHeight = getMobileFrameDimensions();
  const { classes } = useStyles({ mobileFrameHeight });
  const targetConfigLogo = isDarkMode() ? config?.landingLogoDark : config?.landingLogoDark;
  const { t } = useTranslation();

  const logo = targetConfigLogo
    ? <Logo src={targetConfigLogo} />
    : <Shift4LogoIllustration height={37} width={177} />;

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <OptionallyVisible visible={!isConfigLoading}>
          {logo}
        </OptionallyVisible>
      </div>
      <div id={KEYS.FORM_ID} className={classes.form}>
        <div className={classes.content}>
          {children}
        </div>
        <FormFooter showPartnerLogo={showPartnersFooter} />
      </div>
      <footer className={classes.footer}>
        {t(LABELS.FOOTER_COPYRIGHT)}
      </footer>
    </div>
  );
};

export default Layout;
