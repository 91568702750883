import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@mui/base';

import Button from 'components/button';
import Card from 'components/card';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import Spinner from 'components/spinner';
import NotificationIllustration from 'illustrations/notification';
import { locators, QALocator } from 'utils/tests/QA';

import LABELS from '../keys';
import useStyles from './styles';

export interface SubscribeScreenProps {
  countryName?: string;
  isScreenLoading: boolean;
  isSubscribed: boolean;
  isSubscriptionLoading: boolean;
  onSubscribe: (shouldBeSubscribedFromNow: boolean) => void;
  goBack: () => void;
}

const SubscribeScreen = ({
  isScreenLoading,
  isSubscribed,
  countryName,
  isSubscriptionLoading,
  onSubscribe,
  goBack,
}: SubscribeScreenProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [wantsSubscription, setWantsSubscription] = useState<boolean>(isSubscribed);

  const handleProceed = () => {
    onSubscribe(wantsSubscription);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWantsSubscription(event.target.checked);
  };

  return (
    <div className={classes.container}>
      <Header
        hasBackButton
        onBackClick={goBack}
      >
        {t(LABELS.UNSUPPORTED_HEADER)}
      </Header>
      <OptionallyVisible visible={isScreenLoading}>
        <div className={classes.spinnerWrapper}>
          <Spinner
            scale={1.25}
            {...QALocator(locators.page.unsupportedCountry.viewSpinner)}
          />
        </div>
      </OptionallyVisible>
      <OptionallyVisible visible={!isScreenLoading}>
        <>
          <div className={classes.imageWrapper}>
            <NotificationIllustration />
            {t(LABELS.COUNTRY_NOT_SUPPORTED, { countryName })}
          </div>
          <Card className={classes.card}>
            <span className={classes.captionTop}>
              {t(LABELS.UNSUPPORTED_CAPTION_1)}
            </span>
            <div className={classes.checkboxWrapper}>
              <Input
                type="checkbox"
                value={wantsSubscription}
                className={classes.checkbox}
                onChange={handleCheckboxChange}
                slotProps={{
                  input: {
                    defaultChecked: isSubscribed,
                  },
                }}
              />
              <span className={classes.checkboxLabel}>
                {t(LABELS.UNSUPPORTED_CHECKBOX_LABEL)}
              </span>
            </div>
            <span className={classes.captionBottom}>
              {t(LABELS.UNSUPPORTED_CAPTION_2)}
            </span>
          </Card>
        </>
      </OptionallyVisible>
      <div
        className={classes.proceedButtonWrapper}
      >
        <Button
          disabled={isScreenLoading}
          onClick={handleProceed}
          isLoading={isSubscriptionLoading}
          {...QALocator(locators.page.unsupportedCountry.proceedButton)}
        >
          {t(LABELS.UNSUPPORTED_FINISH)}
        </Button>
      </div>
    </div>
  );
};

export default SubscribeScreen;
