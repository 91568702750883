import { Asset } from 'types/assets';
import ConfigQueryParam from 'types/queryParams';
import { isDefined } from 'utils/equality';
import Logger from 'utils/logger';
import getQueryParam from 'utils/queryParams';

export const getWalletAddressFromParams = (cryptoAsset?: Asset): string | undefined => {
  if (!cryptoAsset?.addressRegexp) {
    return undefined;
  }

  const walletAddressFromParam = getQueryParam(ConfigQueryParam.WalletAddress);
  const wasSpecified = isDefined(walletAddressFromParam);

  const assetRegex = new RegExp(cryptoAsset.addressRegexp);
  const isValidAddress = walletAddressFromParam?.match(assetRegex);
  if (wasSpecified && !isValidAddress) {
    Logger.error('Provided wallet address is incorrect');
  }

  if (!isValidAddress) {
    return undefined;
  }

  return walletAddressFromParam ?? undefined;
};

export const getWalletTagFromParams = (cryptoAsset?: Asset): string | undefined => {
  if (!cryptoAsset?.addressTagRegexp) {
    return undefined;
  }

  const walletTagFromParam = getQueryParam(ConfigQueryParam.WalletTag);
  const wasSpecified = isDefined(walletTagFromParam);

  const tagRegex = new RegExp(cryptoAsset.addressTagRegexp);
  const isValidTag = walletTagFromParam?.match(tagRegex);

  if (wasSpecified && !isValidTag) {
    Logger.error('Provided wallet tag is incorrect');
  }

  if (!isValidTag) {
    return undefined;
  }

  return walletTagFromParam ?? undefined;
};

export const isWalletStepSkippable = (walletAddress?: string, tagOrMemo?: string) => {
  const walletTagFromParam = getQueryParam(ConfigQueryParam.WalletTag);
  const wasTagSpecified = isDefined(walletTagFromParam);
  const skippable = wasTagSpecified
    ? walletAddress && tagOrMemo
    : walletAddress;

  return Boolean(skippable);
};
