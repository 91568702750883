import { appConfig } from 'utils/local';

import { GooglePayTransactionInfo, MerchantInfo } from './types';

export const getAllowedPaymentMethod = (publicKey?: string) => ({
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: ['VISA', 'MASTERCARD'],
  },
  tokenizationSpecification: {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'shift4payments',
      gatewayMerchantId: publicKey,
    },
  },
});

export const createGooglePaymentDataRequest = (transaction: GooglePayTransactionInfo, merchantInfo: MerchantInfo, publicKey?: string) => ({
  ...appConfig.googlePay.version,
  allowedPaymentMethods: [getAllowedPaymentMethod(publicKey)],
  transactionInfo: {
    totalPriceStatus: 'FINAL',
    ...transaction,
  },
  merchantInfo,
});
