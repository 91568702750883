import ConfigQueryParam from 'types/queryParams';
import { Email } from 'types/user';
import getQueryParam from 'utils/queryParams';

const getEmailFromQueryParams = (): Email | null => {
  const forcedEmailParam = getQueryParam(ConfigQueryParam.Email);
  if (!forcedEmailParam) {
    return null;
  }

  return forcedEmailParam;
};

export default getEmailFromQueryParams;
