import { useEffect, useRef } from 'react';

import { SCREEN_TRANSITION_EVENT_NAME, SCREEN_TRANSITION_TIMEOUT } from 'constants/transitions';
import { TransitionStateDetails } from 'types/transition';

export const useScreenTransitionFinish = (
  onTransitionFinished: (e?: CustomEvent<TransitionStateDetails>) => void,
) => {
  const wasHandlerExecuted = useRef(false);

  const handler = (e: CustomEvent<TransitionStateDetails>) => {
    onTransitionFinished(e);
    wasHandlerExecuted.current = true;
  };

  const handleTimeout = () => {
    setTimeout(() => {
      if (wasHandlerExecuted.current) {
        return;
      }

      onTransitionFinished();
    }, SCREEN_TRANSITION_TIMEOUT);
  };

  useEffect(() => {
    window.addEventListener(SCREEN_TRANSITION_EVENT_NAME, handler as (e: Event) => void);

    handleTimeout();

    return () => window.removeEventListener(SCREEN_TRANSITION_EVENT_NAME, handler as (e: Event) => void);
  }, []);
};

export default useScreenTransitionFinish;
