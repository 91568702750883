import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    orderRow: {
      padding: 0,
      paddingTop: theme.scale(6),
      paddingBottom: theme.scale(6),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    orderInfo: {
      flex: 5,
      gap: theme.scale(6),
      padding: theme.scale(6),
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    orderHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.scale(6),
    },
    orderDate: {
      ...fontSizeMixin(FontSize.XS),
      fontWeight: 500,
      color: theme.colors.text.inactive,
    },
    orderAssets: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.scale(8),
    },
    assetAmount: {
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
      color: theme.colors.text.primary,
      display: 'inline-flex',
      gap: theme.scale(6),
    },
    orderLink: {
      textDecoration: 'none',
    },
    assetIcon: {
      width: theme.scale(16),
      height: theme.scale(16),
      maxHeight: theme.scale(16),
      maxWidth: theme.scale(16),
      borderRadius: theme.scale(16),
    },
    asset: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: theme.scale(8),
    },
  }),
);

export default useStyles;
