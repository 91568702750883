import { useState } from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';

import Button from 'components/button';
import CaptchaDisclaimer from 'components/captcha/disclaimer';
import FormFieldError from 'components/form/common/fieldError';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import maskContent from 'libs/sentry/mask';
import ApiErrorCode from 'types/errors/api';
import {
  ReduxToolkitError,
} from 'types/http';
import Logger from 'utils/logger';
import { locators, QALocator } from 'utils/tests/QA';

import KEYS, { FormFields, LABELS } from './keys';
import ResendOtpBlock from './resendOtpBlock';
import { useStyles } from './styles';

export interface OtpScreenProps {
  email?: string;
  otpResendStatus: {
    isLoading: boolean;
    isError: boolean;
    error?: ReduxToolkitError;
  };
  canGoBack: boolean;
  onContinueClick: (otp: string) => Promise<void>;
  onResendCodeButtonClick: () => Promise<void>;
  goBack: () => void;
  hideHeader?: boolean;
  errorMessage?: string;
}

const OtpScreen = ({
  email,
  otpResendStatus,
  canGoBack,
  onContinueClick,
  onResendCodeButtonClick,
  goBack,
  hideHeader,
  errorMessage,
}: OtpScreenProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [isProceedLoading, setIsProceedLoading] = useState(false);
  const [isResendEmailLoading, setIsResendEmailLoading] = useState(false);
  const form = useFormik({
    initialValues: {
      [FormFields.Otp]: window.s4cConfig.prefilledValues?.otp ?? '',
    },
    onSubmit: () => {},
    validateOnChange: true,
  });
  const isOtpInputDisabled = isProceedLoading;
  const isProceedButtonDisabled = isProceedLoading;

  const onOtpChange = (code: string) => {
    form.setFieldValue(FormFields.Otp, code);
  };

  const handleContinue = async () => {
    try {
      setIsProceedLoading(true);
      await onContinueClick(form.values[FormFields.Otp]);
    } catch (e: any) {
      if (e.message) {
        form.setFieldError(FormFields.Otp, e.message);
        return;
      }
      Logger.error('Unable to proceed from otp screen due to error', e);
    } finally {
      setIsProceedLoading(false);
    }
  };

  const handleResend = async () => {
    setIsResendEmailLoading(true);
    await onResendCodeButtonClick();
    setIsResendEmailLoading(false);
  };

  return (
    <div className={cx(classes.container, { [classes.noHeader]: hideHeader })}>
      <OptionallyVisible visible={!hideHeader}>
        <Header
          hasBackButton={canGoBack}
          onBackClick={goBack}
        >
          {t(LABELS.HEADER)}
        </Header>
      </OptionallyVisible>
      <div className={classes.content}>
        <div>
          <h3 className={classes.note}>
            <Trans i18nKey={LABELS.VERIFICATION_CODE_NOTE} components={{ br: <br /> }} />
          </h3>
          <p
            className={classes.email}
            {...maskContent()}
          >
            {email}
          </p>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.inputsWrapper}>
            <OtpInput
              value={form.values[FormFields.Otp]}
              numInputs={KEYS.OTP_EXPECTED_LENGTH}
              onChange={onOtpChange}
              inputType="number"
              renderInput={props => (
                <input
                  {...props}
                  className={cx(classes.otpInput, {
                    [classes.inputDisabled]: isOtpInputDisabled,
                  })}
                  disabled={isOtpInputDisabled}
                  type="text"
                  {...maskContent()}
                />
              )}
            />
          </div>
          <FormFieldError errorText={form.errors[FormFields.Otp]} />
          <ResendOtpBlock
            onButtonClick={handleResend}
            isLoading={isResendEmailLoading}
            errorType={otpResendStatus.error?.data?.errorType as ApiErrorCode}
          />
        </div>
      </div>
      <div className={classes.proceedButtonWrapper}>
        <CaptchaDisclaimer />
        <OptionallyVisible visible={Boolean(errorMessage)}>
          <div className={classes.errorMessage}>{errorMessage || ''}</div>
        </OptionallyVisible>
        <Button
          isContinue
          isLoading={isProceedButtonDisabled}
          disabled={form.values[FormFields.Otp].length < KEYS.OTP_EXPECTED_LENGTH}
          onClick={handleContinue}
          {...QALocator(locators.page.otp.proceedButton)}
        >
          {t(LABELS.CONTINUE)}
        </Button>
      </div>
    </div>
  );
};

export default OtpScreen;
