export const isDefined = (value: any) => value !== null && value !== undefined;

export const removeFieldsOfUndefinedValue = (initial: Record<any, any>) => {
  const updatedObject = Object.entries(initial).reduce((acc, [key, value]) => {
    if (value === Object(value)) {
      acc[key] = removeFieldsOfUndefinedValue(value);
    } else if (value !== undefined) {
      acc[key] = value;
    }

    return acc;
  }, {} as Record<any, any>);
  return updatedObject;
};

export default isDefined;
