import React, { useEffect, useRef, useState } from 'react';

import useStyles from './styles';

interface WaitingMessageProps {
  shouldChangeMessage: boolean;
  initialMessage: string;
  delayedMessage: string;
}

const WaitingMessage = ({ shouldChangeMessage, initialMessage, delayedMessage }: WaitingMessageProps) => {
  const [message, setMessage] = useState(initialMessage);

  const delay = 180000;
  const { classes } = useStyles();

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearOnUnmount = () => {
    if (!timer.current) {
      return;
    }

    clearTimeout(timer.current);
  };

  useEffect(() => {
    if (shouldChangeMessage) {
      timer.current = setTimeout(() => {
        setMessage(delayedMessage);
      }, delay);
    }

    return clearOnUnmount;
  }, [shouldChangeMessage, delayedMessage]);

  return <span className={classes.message}>{message}</span>;
};

export default WaitingMessage;
