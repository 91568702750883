import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import API_CONFIG from 'constants/api';
import { RootState } from 'state/store';
import { AuthToken } from 'types/authentication';
import { Storage } from 'utils/storage';

const SLICE_NAME = 'auth';

const token = Storage.get(API_CONFIG.TOKEN_KEY);

interface AuthState {
  token: AuthToken | null;
}

const initialState: AuthState = {
  token,
};

export const stepSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      Storage.set(API_CONFIG.TOKEN_KEY, action.payload);
      state.token = action.payload;
    },
    logOut: (state) => {
      Storage.remove(API_CONFIG.TOKEN_KEY);
      state.token = null;
    },
  },
});

export const { setToken, logOut } = stepSlice.actions;
export const selectAuthToken = (state: RootState) => state.auth.token;

export default stepSlice.reducer;
