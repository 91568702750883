import { GooglePayConfig } from './payments/googlePay';

export interface AppConfig {
  enableLocalConfig?: boolean;
  backendUrl: string;
  enableLogs: boolean;
  enableQALocators: boolean;
  features: Record<string, boolean>;
  zeroHash: {
    envUrl: string;
  };
  prefilledValues?: {
    email?: string;
    otp?: string;
    wallet?: string;
    fiatAmount: string;
    popularCrypto?: string[];
  };
  sentry: {
    dsn: string;
    enabled: boolean;
    environment: 'dev' | 'prod';
  };
  reCaptchaSiteKey: string;
  applePay: {
    scriptSrc: string;
  };
  googlePay: GooglePayConfig;
}

export enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
  Browser = 'sameAsBrowser'
}

export interface PartnerConfig {
  uuid: string;
  name: string;
  landingPageEnabled: boolean;
  urlSlug: string | null;
  orderCompleteRedirectUrl: string | null;
  widgetLogo: string | null;
  widgetLogoDark: string | null;
  landingLogo: string | null;
  landingLogoDark: string | null;
  pageThemeMode: ThemeMode;
  pageTitle: string | null;
  pageColor: string | null;
  widgetColor: string | null;
  widgetThemeMode: ThemeMode;
  cornerRadius: number | null;
  displayIllustration: boolean;
  defaultCryptoCurrencyCode: string | null;
  supportedCryptoCurrenciesCodes: string[] | null;
  popularCryptoCurrenciesCodes: string[] | null;
  supportedFiatCurrenciesCodes: string[] | null;
  publicKey: string;
  enableRedirectUrl: boolean;
  onRampEnabled: boolean;
  offRampEnabled: boolean;
  redirectDomain: string | null;
}
