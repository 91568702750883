import { useDispatch, useSelector } from 'react-redux';
import { SerializedError } from '@reduxjs/toolkit';

import useNavigation from 'hooks/useNavigation';
import paymentsApi, { selectAvailablePaymentMethods } from 'services/payments';
import { setPaymentMethod } from 'state/slices/paymentSlice';
import { ReduxToolkitError } from 'types/http';
import PaymentMethod from 'types/payment';

import PaymentMethods from '.';

const PaymentMethodsContainer = () => {
  const { proceed, goBack } = useNavigation();
  const dispatch = useDispatch();
  const availablePaymentMethods = useSelector(selectAvailablePaymentMethods);

  const { isLoading: paymentMethodsLoading, error } = paymentsApi.useGetPaymentMethodsQuery();
  const errorMessage = (error as ReduxToolkitError)?.data?.errorMessage ?? (error as SerializedError)?.message;

  const onPaymentMethodClick = (method: PaymentMethod) => {
    dispatch(setPaymentMethod(method));
    proceed({
      paymentMethod: {
        default: method,
      },
    });
  };

  return (
    <PaymentMethods
      paymentMethods={availablePaymentMethods ?? [PaymentMethod.Card]}
      goBack={goBack}
      onPaymentMethodClick={onPaymentMethodClick}
      isLoading={paymentMethodsLoading}
      errorMessage={errorMessage}
    />
  );
};

export default PaymentMethodsContainer;
