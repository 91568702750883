import { NavigationEvaluationFunc, NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import PaymentMethod from 'types/payment';
import { WorkflowStep } from 'types/step';

import getInitialStep from './init';
import { checkIfShouldSkipWalletStep } from './queryParams';

export const getPaymentMethodNextStep = ({ paymentMethod }: NavigationEvaluationFuncParams): WorkflowStep => {
  const requestNewCard = paymentMethod?.default === PaymentMethod.Card && !paymentMethod?.defaultCard;
  if (requestNewCard) {
    return WorkflowStep.BankDetails;
  }

  return WorkflowStep.Checkout;
};

export const getPaymentMethodPreviousStep: NavigationEvaluationFunc<WorkflowStep> = () => {
  const skipWalletStep = checkIfShouldSkipWalletStep();
  if (skipWalletStep) {
    return WorkflowStep.Quote;
  }

  return WorkflowStep.Wallet;
};

export const canGoBackFromPaymentMethods: NavigationEvaluationFunc<boolean> = ({ user }) => {
  const initialStep = getInitialStep({ user });
  const shouldSkipWalletStep = checkIfShouldSkipWalletStep();
  if (!shouldSkipWalletStep) {
    return true;
  }

  return initialStep !== WorkflowStep.PaymentMethods;
};

export default getPaymentMethodNextStep;
