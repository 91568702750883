import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import API_CONFIG from 'constants/api';
import QUERY_PARAMS from 'constants/queryParams';
import { logOut } from 'state/slices/authSlice';
import { changeStep } from 'state/slices/stepSlice';
import { RootState } from 'state/store';
import { JWTokenErrorType } from 'types/authentication';
import { HTTPStatus } from 'types/http';
import { WorkflowStep } from 'types/step';
import { Storage } from 'utils/storage';

export enum CustomHeaderType {
  Auth = 'Authorization',
  ApiKey = 'x-public-key',
}

type CustomHeaderProvider = () => CustomHeader

interface CustomHeader {
  key: CustomHeaderType;
  value: string;
}

export const makeAuthHeader = (): CustomHeader => {
  const token = Storage.get(API_CONFIG.TOKEN_KEY);

  return ({
    key: CustomHeaderType.Auth,
    value: token ? `bearer ${token}` : '',
  });
};

const isAuthorizedCall = (additionalHeaders: CustomHeaderProvider[]) => additionalHeaders.some((headerProvider) => {
  const { key } = headerProvider();
  return key === CustomHeaderType.Auth;
});

const prepareCustomHeaders = (additionalHeaders: CustomHeaderProvider[]) => (headers: Headers) => {
  additionalHeaders.forEach((headerProvider) => {
    const { key, value } = headerProvider();
    headers.set(key, value);
  });

  const urlParams = new URLSearchParams(window.location.search);
  const apiKey = urlParams.get(QUERY_PARAMS.API_KEY);
  if (apiKey) {
    headers.set(CustomHeaderType.ApiKey, apiKey);
  }
};

const baseQueryWithCustomHeaders = (additionalHeaders: CustomHeaderProvider[]) => fetchBaseQuery({
  baseUrl: API_CONFIG.ROOT_URL,
  prepareHeaders: prepareCustomHeaders(additionalHeaders),
});

const apiKeyHeaderProvider = (apiKey: string) => () => ({
  key: CustomHeaderType.ApiKey,
  value: apiKey,
});

export const fetchBaseQueryWithCustomHeaders: (...additionalHeaders: CustomHeaderProvider[]) => BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
  > = (...additionalHeaders) => async (args, api, extraOptions) => {
    const { apiKey } = (api.getState() as RootState).partner;

    const headerProviders = apiKey
      ? [...additionalHeaders, apiKeyHeaderProvider(apiKey)]
      : additionalHeaders;

    const result = await baseQueryWithCustomHeaders(headerProviders)(args, api, extraOptions);

    // Note that isAuthorizedCall is not enough
    // for quote call we pass headers manually
    // instead of using this helper
    const shouldCheckAuth = isAuthorizedCall(additionalHeaders)
      || Boolean(((args as FetchArgs).headers as Record<string, any>)?.[CustomHeaderType.Auth]);
    if (!shouldCheckAuth) {
      return result;
    }

    const shouldLogOut = result.error?.status === HTTPStatus.Unauthorized
    || (result.error as any)?.data?.meta?.errorCode === JWTokenErrorType.ExpiredToken;

    if (shouldLogOut) {
      const { step } = (api.getState() as RootState);

      api.dispatch(logOut());

      const shouldNotRedirect = [
        WorkflowStep.Quote,
        WorkflowStep.SignIn,
      ].includes(step.step);

      if (!shouldNotRedirect) {
        api.dispatch(changeStep(WorkflowStep.SignIn));
      }
    }
    return result;
  };

export default fetchBaseQueryWithCustomHeaders;
