import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import paymentsApi from 'services/payments';
import { SavedCard } from 'services/payments/types';
import { selectUserCacheEntry } from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import {
  selectTokenizedCard, setHasCardAuthorizationError, setPaymentMethod, setTokenizedCard,
} from 'state/slices/paymentSlice';
import { changeStep, selectIsAddingCard, setIsAddingCard } from 'state/slices/stepSlice';
import { CardPaymentToken } from 'types/card';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { HTTPStatus, ReduxToolkitError } from 'types/http';
import PaymentMethod from 'types/payment';
import { WorkflowStep } from 'types/step';
import { KycStatus } from 'types/user';

import BankDetails from '.';

export const BankDetailsContainer = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken) ?? '';
  const {
    data, isLoading, isError, error,
  } = paymentsApi.useGetPaymentsConfigQuery(authToken);
  const tokenizedCard = useSelector(selectTokenizedCard);
  const { proceed, goBack, canGoBack } = useNavigation();
  const user = useSelector(selectUserCacheEntry)?.data?.data;
  const { data: cardsResponse, isLoading: areCardsLoading, error: cardsError } = paymentsApi.useGetUserCardsQuery(authToken, {
    skip: user?.kycStatus !== KycStatus.Approved,
  });
  const isAddingNewCard = useSelector(selectIsAddingCard);

  const setCard = (
    tokenizedCard: CardPaymentToken | SavedCard,
    rememberCard: boolean,
    setCardAsDefault: boolean,
  ) => {
    if (isAddingNewCard) {
      dispatch(setPaymentMethod(PaymentMethod.Card));
    }

    dispatch(setHasCardAuthorizationError(false));
    dispatch(setTokenizedCard({
      tokenizedCard, rememberCard, setCardAsDefault,
    }));
    dispatch(setIsAddingCard(false));
  };
  const showErrorScreen = (errorType: ScreenErrorType, isFatalError = false) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, isFatalError, goBackToScreen: WorkflowStep.BankDetails }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  useEffect(() => {
    if (!error) {
      return;
    }

    const toolkitError = error as ReduxToolkitError;
    if (!toolkitError?.status && error) {
      throw new Error('Unhandled error: Fetching payment configuration failed');
    }

    if (toolkitError.status === HTTPStatus.Unauthorized) {
      return;
    }
    if (toolkitError.data?.errorType === ApiErrorCode.RegionError) {
      showErrorScreen(ScreenErrorType.RegionUnsupported, true);
      return;
    }

    showErrorScreen(ScreenErrorType.UnableToGetPaymentsConfig);
  }, [error]);

  return (
    <BankDetails
      paymentsConfig={{
        shift4PublicKey: data?.data?.shift4PublicKey,
        merchantId: data?.data?.merchantId,
        isLoading,
        isError,
      }}
      proceed={proceed}
      goBack={goBack}
      tokenizedCard={tokenizedCard}
      setTokenizedCard={setCard}
      showErrorScreen={showErrorScreen}
      canGoBack={canGoBack}
      user={user}
      showSaveCardControl
      showSetDefaultControl
      isAddingNewCard={isAddingNewCard}
      cards={{
        cards: cardsResponse ?? [],
        isLoading: areCardsLoading,
        error: cardsError,
      }}
    />
  );
};

export default BankDetailsContainer;
