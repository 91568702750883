import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';
import { ScreenError } from 'types/errors/errorScreen';

const SLICE_NAME = 'error';

interface ErrorState {
  error: ScreenError | null;
}

const initialState: ErrorState = {
  error: null,
};

export const errorSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ScreenError | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setError } = errorSlice.actions;

export const selectError = (state: RootState) => state.error.error;

export default errorSlice.reducer;
