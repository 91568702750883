import IconArrowRight from 'icons/arrowRight';
import IconExternal from 'icons/external';
import { Asset } from 'types/assets';
import { OrderRecord, OrderSide } from 'types/order';

import { ORDER_PLACEHOLDER } from './keys';
import { OrderTypeBadge } from './orderTypeBadge/orderTypeBadge';
import { StatusTypeBadge } from './statusTypeBadge/statusTypeBadge';
import useStyles from './styles';

export interface OrderProps {
  order: OrderRecord;
  fiatAsset: Asset;
  cryptoAsset: Asset;
  orderPath: string;
}

export const OrderRow = ({
  order, fiatAsset, cryptoAsset, orderPath,
}: OrderProps) => {
  const { classes, theme } = useStyles();

  if (!fiatAsset || !cryptoAsset) {
    return null;
  }

  const getOrderLink = (uuid: string) => orderPath.replace(ORDER_PLACEHOLDER, uuid);
  const date = new Date(order.createdAt!);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const formattedDate = `${month} ${day}, ${year}`;

  const renderCryptoAsset = () => (
    <div className={classes.asset}>
      <span className={classes.assetAmount}>
        <span>
          {Number(Number(order.cryptoAmount).toFixed(cryptoAsset.displayDecimals))}
        </span>
        <span>
          {cryptoAsset.symbol}
        </span>
      </span>
      <img className={classes.assetIcon} src={cryptoAsset.iconUrl!} alt={cryptoAsset.symbol} />
    </div>
  );

  const renderFiatAsset = () => (
    <div className={classes.asset}>
      <span className={classes.assetAmount}>
        <span>
          {order.fiatAmount}
        </span>
        <span>
          {fiatAsset.symbol}
        </span>
      </span>
      <img className={classes.assetIcon} src={fiatAsset.iconUrl!} alt={fiatAsset.symbol} />
    </div>
  );

  const sourceAsset = order.side === OrderSide.Buy
    ? renderFiatAsset()
    : renderCryptoAsset();

  const targetAsset = order.side === OrderSide.Buy
    ? renderCryptoAsset()
    : renderFiatAsset();

  return (
    <a target="_blank" href={getOrderLink(order.uuid)} className={classes.orderLink}>
      <div key={order.uuid} className={classes.orderRow}>
        <div className={classes.orderInfo}>
          <div className={classes.orderHeader}>
            <span className={classes.orderDate}>{formattedDate}</span>
            <OrderTypeBadge side={order.side} />
            <StatusTypeBadge side={order.side} status={order.status} />
          </div>
          <div className={classes.orderAssets}>
            {sourceAsset}
            <IconArrowRight color={theme.colors.icons.inactive} />
            {targetAsset}
          </div>
        </div>
        <IconExternal color={theme.colors.icons.inactive} />
      </div>
    </a>
  );
};
