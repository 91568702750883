import { CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION } from 'constants/defaults';
import { Asset, AssetWithLabel } from 'types/assets';

import truncateNumericValue from './number';

interface CryptoFormatOptions {
  truncate: boolean;
}

export const convertToTitleCase = (inputString: string) => {
  const words = inputString.split('_');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  return capitalizedWords.join(' ');
};

export const getCurrencySymbol = (locale: string) => (currency: string) => (0)
  .toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  .replace(/\d/g, '')
  .trim();

export const getShortenedCryptoCurrencyName = (cryptocurrency?: AssetWithLabel) => {
  if (!cryptocurrency) {
    return '';
  }

  return cryptocurrency.symbol.split('.')[0];
};

export const getShortenedCryptoCurrencyCode = (cryptocurrencyCode?: string) => {
  if (!cryptocurrencyCode) {
    return '';
  }

  return cryptocurrencyCode.split('.')[0];
};

export const addLabelsToCryptoAssets = (cryptoAssets: Asset[]) => cryptoAssets.map(asset => ({
  id: asset.symbol,
  label: asset.name,
  subLabel: `${asset.symbol} - ${convertToTitleCase(asset.chainCode)}`,
  ...asset,
}));

export const addLabelsToFiatAssets = (fiatAssets: Asset[]) => fiatAssets.map(asset => ({
  id: asset.symbol,
  label: asset.name,
  subLabel: asset.symbol,
  ...asset,
}));

export const formatCryptocurrency = (symbol: string, amount: number | string) => `${symbol} ${amount}`;

export const formatCryptoAmountWithSymbolAtEnd = (
  amount: string,
  cryptocurrency: Asset,
  options: CryptoFormatOptions = ({ truncate: true }),
) => {
  if (!options.truncate) {
    return `${amount} ${cryptocurrency.symbol}`;
  }

  const truncatedAmount = truncateNumericValue(amount, cryptocurrency?.displayDecimals ?? CRYPTO_VALUE_DEFAULT_DECIMAL_PRECISION);

  return `${truncatedAmount} ${cryptocurrency.symbol}`;
};

export default getCurrencySymbol;
