import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    statusTypeBadge: {
      display: 'flex',
      backgroundColor: '#FFEDED',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.scale(5),
      width: 'auto',
      height: theme.scale(20),
      gap: theme.scale(4),
      span: {
        ...fontSizeMixin(FontSize.XS),
        fontWeight: 500,
        color: '#DC0030',
      },
    },
    statusTypeBadgeCompleted: {
      backgroundColor: '#F3FAF3',
      span: {
        color: '#00850D',
      },
    },
    statusTypeBadgeProcessing: {
      backgroundColor: '#F2F7FF',
      span: {
        color: '#344054',
      },
    },
    statusTypeBadgePending: {
      backgroundColor: 'rgba(247, 147, 26, 0.10)',
      span: {
        color: '#A95100',
      },
    },
  }),
);

export default useStyles;
