import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    stepsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.scale(15),
    },
    stepsList: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      gap: theme.scale(15),
    },
    waitingForWithdrawalText: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.inactive,
      maxWidth: theme.scale(210),
      paddingTop: theme.scale(12),
      textAlign: 'center',
    },
  }),
);

export default useStyles;
