/* eslint-disable react/no-array-index-key */
import { useThemeContext } from 'theme';

import { IconProps } from './types';

const paths = [
  [
    'M16.25 2.91675L15 1.66675L13.75 2.91675L12.5 1.66675L11.25 2.91675L10 1.66675L8.75 2.91675L7.5',
    '1.66675L6.25 2.91675L5 1.66675V13.3334H2.5V15.8334C2.5 17.2167 3.61667 18.3334 5 18.3334H15C16.3833',
    '18.3334 17.5 17.2167 17.5 15.8334V1.66675L16.25 2.91675ZM12.5 16.6667H5C4.54167 16.6667 4.16667 16.2917',
    '4.16667 15.8334V15.0001H12.5V16.6667ZM15.8333 15.8334C15.8333 16.2917 15.4583 16.6667 15 16.6667C14.5417',
    '16.6667 14.1667 16.2917 14.1667 15.8334V13.3334H6.66667V4.16675H15.8333V15.8334Z',
    '13.4916 9.53333 14 8 14Z',
  ].join(' '),
  'M12.5 5.83341H7.5V7.50008H12.5V5.83341Z',
  'M15 5.83341H13.3333V7.50008H15V5.83341Z',
  'M12.5 8.33341H7.5V10.0001H12.5V8.33341Z',
  'M15 8.33341H13.3333V10.0001H15V8.33341Z',
];

export const IconReceipt = ({
  width = 20,
  height = 20,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      {paths.map((path, i) => <path key={i} d={path} />)}
    </svg>
  );
};
export default IconReceipt;
