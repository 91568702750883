import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M7.58203 5.75H6.41536V4.58333H7.58203V5.75ZM7.58203 10.4167H6.41536L6.41536',
  '6.91667H7.58203L7.58203 10.4167ZM7.00453 13.3333C10.2245 13.3333 12.832 10.72 12.832',
  '7.5C12.832 4.28 10.2245 1.66667 7.00453 1.66667C3.7787 1.66667 1.16537 4.28',
  '1.16537 7.5C1.16537 10.72 3.7787 13.3333 7.00453 13.3333ZM6.9987 2.83333C9.57703 2.83333 ',
  '11.6654 4.92167 11.6654 7.5C11.6654 10.0783 9.57703 12.1667 6.9987 12.1667C4.42036 12.1667 2.33203',
  '10.0783 2.33203 7.5C2.33203 4.92167 4.42036 2.83333 6.9987 2.83333Z',
].join(' ');

export const IconInfo = ({
  width = 14,
  height = 15,
  className,
  color,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 14 15"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
      />
    </svg>
  );
};
export default IconInfo;
