import { useThemeContext } from 'theme';

import { IconProps } from './types';

const path = [
  'M19.1335 4.99967V14.9997C19.1335 15.458 18.9704 15.8505 18.6443 16.1772C18.3176',
  '16.5033 17.9251 16.6663 17.4668 16.6663H4.13346C3.67513 16.6663 3.28291 16.5033',
  '2.9568 16.1772C2.63013 15.8505 2.4668 15.458 2.4668 14.9997V4.99967C2.4668 4.54134',
  '2.63013 4.14912 2.9568 3.82301C3.28291 3.49634 3.67513 3.33301 4.13346 3.33301H17.4668C17.9251',
  '3.33301 18.3176 3.49634 18.6443 3.82301C18.9704 4.14912 19.1335 4.54134 19.1335 4.99967ZM4.13346',
  '6.66634H17.4668V4.99967H4.13346V6.66634ZM4.13346 9.99967V14.9997H17.4668V9.99967H4.13346ZM4.13346 14.9997V4.99967V14.9997Z',
].join(' ');

export const IconCard = ({
  width = 21,
  height = 20,
  className,
}: IconProps) => {
  const { theme } = useThemeContext();

  return (
    <svg
      className={className}
      width={theme.scale(width)}
      height={theme.scale(height)}
      viewBox="0 0 21 20"
      fill={theme.colors.icons.text}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
      />
    </svg>
  );
};
export default IconCard;
