import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SlideModal from 'components/slideModal';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constants/api';
import COMMON_LABELS from 'constants/messages';
import IconMenuCard from 'icons/menuCard';
import IconMenuLogout from 'icons/menuLogout';
import IconMenuPendingOrders from 'icons/menuPendingOrders';
import IconMenuProfile from 'icons/menuProfile';
import IconReceipt from 'icons/receipt';
import CardScreen from 'pages/cards';
import OrderHistoryContainer from 'pages/orderHistory/container';
import PendingOrdersContainer from 'pages/pendingOrders/container';
import ProfileScreen from 'pages/profile';
import { selectUserCacheEntry, selectUserPendingOrdersCacheEntry } from 'services/user';
import { logOut } from 'state/slices/authSlice';
import { changeStep } from 'state/slices/stepSlice';
import { OrderStatus } from 'types/order';
import WorkflowStep from 'types/step';
import { KycStatus, UserStatus } from 'types/user';
import { appendThemeParam } from 'utils/queryParams';

import KEYS, { LABELS } from './keys';
import MenuList, { MenuItem } from './menuList';
import { useStyles } from './menuList.styles';

interface MenuListContainerProps {
    visible: boolean;
    closeMenu: () => void;
    }

const MenuListContainer = ({ visible, closeMenu }: MenuListContainerProps) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserCacheEntry)?.data?.data;
  const pendingOrders = useSelector(selectUserPendingOrdersCacheEntry)?.data?.data?.orders;
  const shouldShowDotIndicator = pendingOrders?.some(order => [
    OrderStatus.PaymentFailed,
    OrderStatus.AwaitingClientConfirmation,
  ].includes(order.status));
  const getTermsOfServiceUrl = () => appendThemeParam(TERMS_OF_USE_URL);
  const getPrivacyPolicyUrl = () => appendThemeParam(PRIVACY_POLICY_URL);
  const isApproved = user?.kycStatus === KycStatus.Approved && user.status === UserStatus.Active;
  const isAuthenticated = Boolean(user);
  const hasPendingOrders = Boolean(pendingOrders?.length);
  const termsOfServiceUrl = getTermsOfServiceUrl();
  const privacyPolicyUrl = getPrivacyPolicyUrl();
  const { theme } = useStyles();
  const { t } = useTranslation();
  const [title, setTitle] = useState<string>(t(COMMON_LABELS.MENU));

  const menuItems: MenuItem[] = [
    {
      isShown: isAuthenticated && hasPendingOrders,
      icon: <IconMenuPendingOrders showDotIndicator={shouldShowDotIndicator} color={theme.colors.text.inactive} />,
      label: t(LABELS.PENDING_ORDERS),
      onOptionClick: () => {
        setTitle(t(LABELS.PENDING_ORDERS));
      },
      submenu: {
        Component: PendingOrdersContainer,
      },
    },
    {
      isShown: !isAuthenticated,
      icon: <IconMenuProfile color={theme.colors.text.inactive} />,
      label: t(LABELS.LOGIN),
      onOptionClick: () => {
        dispatch?.(changeStep(WorkflowStep.SignIn));
      },
    },
    {
      isShown: isAuthenticated,
      icon: <IconMenuProfile color={theme.colors.text.inactive} />,
      label: t(LABELS.PROFILE),
      onOptionClick: () => {
        setTitle(t(LABELS.PROFILE));
      },
      submenu: {
        Component: ProfileScreen,
      },
    },
    {
      isShown: isAuthenticated,
      icon: <IconReceipt color={theme.colors.text.inactive} />,
      label: t(LABELS.ORDER_HISTORY),
      onOptionClick: () => {
        setTitle(t(LABELS.ORDER_HISTORY));
      },
      submenu: {
        Component: OrderHistoryContainer,
      },
    },
    {
      isShown: isAuthenticated && isApproved,
      icon: <IconMenuCard color={theme.colors.text.inactive} />,
      label: t(LABELS.CARDS),
      onOptionClick: () => {
        setTitle(t(LABELS.CARDS));
      },
      submenu: {
        Component: CardScreen,
      },
    },
    {
      isShown: isAuthenticated,
      icon: <IconMenuLogout color={theme.colors.text.inactive} />,
      label: t(LABELS.LOGOUT),
      onOptionClick: () => {
        dispatch?.(logOut());
        dispatch?.(changeStep(WorkflowStep.SignIn));
      },
    },
  ].filter(item => item.isShown);

  const handleCloseMenu = () => {
    closeMenu();
    setTitle(t(COMMON_LABELS.MENU));
  };

  return (
    <SlideModal
      visible={visible}
      onClose={handleCloseMenu}
      title={t(title)}
    >
      <MenuList
        onMenuClose={handleCloseMenu}
        items={menuItems}
        termsOfServiceUrl={termsOfServiceUrl || KEYS.FALLBACK_URL}
        privacyPolicyUrl={privacyPolicyUrl || KEYS.FALLBACK_URL}
        hasPendingOrders={hasPendingOrders}
      />
    </SlideModal>
  );
};

export default MenuListContainer;
