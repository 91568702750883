import { FunctionComponent } from 'react';

import { RouteProps } from './types';
import usePathnameChangeDetector from './usePathnameChangeDetector';
import doesPathMatchCurrentPath from './utils';

export const Route: FunctionComponent<RouteProps> = ({
  path,
  element,
}: RouteProps) => {
  const { currentPath } = usePathnameChangeDetector();
  const shouldShowComponent = doesPathMatchCurrentPath(path, currentPath);

  if (shouldShowComponent) {
    return element;
  }

  return null;
};

export default Route;
