import WithIllustrationController from 'illustrations/controller';
import { Illustration } from 'illustrations/types';

import useSharedStyles from '../styles';
import useStyles, { getColorBasedMasks } from './styles';

const NotificationIllustration = () => {
  const { classes, cx, theme } = useStyles();
  const { classes: sharedClasses } = useSharedStyles({ height: 78 });

  const mapMaskToNode = (_: string, index: number) => (
    <div
      key={index}
      className={cx(sharedClasses.icon, `icon--${Illustration.Notification}-${index}`)}
    />
  );

  const illustration = Object
    .keys(getColorBasedMasks(theme))
    .map(mapMaskToNode);

  return (
    <div className={cx(classes.container, sharedClasses.icon)}>
      {illustration}
    </div>
  );
};

export default WithIllustrationController(NotificationIllustration);
