import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .create(
    ({ theme }) => ({
      infoContainer: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        rowGap: theme.scale(5),
        padding: theme.scale(12),
        background: theme.colors.background.primary,
        borderRadius: theme.border.radius,
        ...fontSizeMixin(FontSize.M),
      },
      smallerFont: {
        '& > *': {
          ...fontSizeMixin(FontSize.S),
          color: theme.colors.text.inactive,
          fill: theme.colors.text.inactive,
        },
      },
      boldFont: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
      },
      flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      cryptoIcon: {
        height: theme.scale(16),
        width: theme.scale(16),
        borderRadius: 8,
        marginRight: theme.scale(6),
      },
      iconWrapper: {
        width: theme.scale(16),
        height: theme.scale(16),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      textAndIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.scale(10),
      },
      tooltip: {
        top: theme.scale(32),
        left: 0,
        width: '100%',
      },
    }),
  );

export default useStyles;
